export const NMDisclosure = (partnerType, toPrint) => {
    return (
        "<table class='print-component'>" +
        "<tbody>" +
        "<tr>" +
        "<td>" +
        "<div class='mt-4'>" +
        "<h4 class='text-center mb-1' style='text-align:center !important'><b>CONSUMER INSURANCE SUMMARY</b></h4>" +
        "<p class='text-center' style='text-align:center !important'>(Life and Disability)</p>" +
        "<p><b>This is a summary of the coverages available, not an application for insurance. A separate application for insurance must be completed and signed to obtain coverage. The purchase of this coverage is <u>optional and is not required to obtain the loan or credit approval. This form does not contain all of the policy provisions that may affect your coverage. Full details, including exclusions, can be found in your Certificate of Coverage and/or policy.</u></b></p>" +
        "<p><b>An application for coverage will be provided for each product indicated on this summary. Borrowers must complete the application and meet eligibility requirements to obtain coverage.</b></p>" +
        "<div class='text-center'>" +
        "<p class='mb-0' style='text-align:center !important'><b>Insurers:</b> Minnesota Life Insurance Company, Securian Casualty Company</p>" +
        "<p class='mb-0' style='text-align:center !important'><b>Administrator:</b> Summit Administrators, 800-275-3414</p>" +
        (partnerType === 'Fortiva' ?
            "<p class='mb-0' style='text-align:center !important'><b>Creditor:</b> Bank of Missouri as issuer of credit as arranged by Fortiva Retail Credit on behalf of Daniel’s Jewelers</p>" :
            "<p class='mb-0' style='text-align:center !important'><b>Creditor: Daniel’s Jewelers • Agent License Number:</b> 3001348673</p>") +
        (partnerType === 'Fortiva' ? "<p style='text-align:center !important'><b>Agent License Number:</b> 3001348673</p>" : "" )+
        "</div>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Eligibility</b></h5>" +
        "<ul>" +
        "<li><b>Life and Disability Eligibility Conditions.</b> You are eligible for insurance if you have not reached your 72nd birthday for Life and 68th birthday for Disability. For Disability, you are eligible for insurance as to each advance only if you are working for wages or profit 30 hours a week or more on the date you sign for the advance. If you are not, that particular advance will not be insured until you return to work.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Life Benefit</b></h5>" +
        "<ul>" +
        "<li><b>Single Life Insurance Benefit.</b> If you die while you are insured for single life coverage, we will pay the amount of insurance in force at the time of your death after we receive proof of your death.</li>" +
        "<li><b>Joint Life Insurance Benefit.</b> If you or your co-borrower die while insured for joint life coverage, we will pay the amount of insurance in force at the time you or your co-borrower dies after we receive proof of the death. Only one death benefit is payable under the policy.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Disability Benefit</b></h5>" +
        "<ul>" +
        "<li><b>Total Disability Insurance Benefit.</b> If you are insured for total disability insurance, we will pay a benefit if you file written proof that you became totally disabled while insured and continue to be totally disabled for more than 14 days. Payment will be calculated from the 1st day of disability. The benefit for each day of disability to be compensated will be 1/30th of your required monthly loan payment or the Maximum Monthly Disability Benefit, whichever is less.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Amount of Life Insurance</b></h5>" +
        "<ul>" +
        "<li>The amount of life insurance is the total amount due on the loan (excluding unearned finance charges), outstanding on the date of the insured borrower’s death. But, we will not pay more than the Maximum Amount of Life Insurance.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>When Insurance Stops or Termination</b></h5>" +
        "<ul>" +
        "<li><b>Single or Joint Life and Disability. When Insurance Stops.</b> This insurance stops: on the first billing date after the creditor receives your written request to stop the insurance; or on the first billing date after you withdraw your authorization for the addition of charges for the insurance to your loan; or on the first billing date after you reach your 72nd birthday for Life and 68th birthday for Disability; or on the date your loan stops; or on the billing date upon which you are 3 months delinquent in making a minimum monthly payment on your loan; or on the date the Group Policy stops</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Exclusions</b></h5>" +
        "<ul>" +
        "<li><b>Life Exclusions.</b> As to each advance on an open-end loan, we will not pay a claim on that advance if death occurs within 6 months after the date of the advance and is caused by a pre-existing medical condition as defined below. A pre-existing medical condition is a condition for which you received or had medical treatment, advice or diagnostic tests either for that same condition or a related condition within the 6 month period immediately prior to the date of the advance.</br> As to each advance on an open-end loan, we will not pay a claim but we will refund premium paid for insurance on that advance if death occurs within two years after the date of the advance and is caused by suicide, whether sane or insane.</br> With respect to closed-end coverage, we will not pay any life claim if you, whether sane or insane, die by suicide within two years from the effective date of your insurance, but we will refund your premium. If you applied for joint life insurance, these limitations/exclusions also apply to your co-debtor.</li>" +
        "<li><b>Total Disability Exclusions.</b> We will not insure any loss resulting directly or indirectly from any total disability caused by: (1) intentionally self-inflicted injuries; or (2) normal pregnancies or normal childbirths. Complications due to pregnancy or childbirth will be covered only if the complications themselves are totally disabling; or (3) war or any act of war, whether such war is declared or undeclared; or (4) as to each advance on your loan; a condition for which you received or had medical treatment, advice or diagnostic tests either for that same condition or a related condition within the 6 month period immediately prior to the date of the advance and which results in total disability commencing within 6 months after the date of the advance.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Conditions or Other Limitations</b></h5>" +
        "<ul>" +
        "<li><b>Single or Joint Life and Disability Conditions or Other Limitations.</b> You are insured only for advances actually received by you. You are not insured for any unused credit which may be available to you. Only the Primary Borrower is eligible for disability insurance.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Options for All Programs</b></h5>" +
        "<ul>" +
        "<li><b>Available coverage options.</b> If you qualify, you may purchase life coverage with disability; and/or unemployment and/or property coverage together as a package.</li>" +
        "<li><b>Cancellation and refunds or credits.</b> You may cancel this insurance and receive a full refund within the first 30 days after receiving your policy or certificate. If you cancel after the 30 days, any unearned premium will be refunded in accordance with applicable law. </li>" +
        "<li><b>Other Insurance.</b> If you have other life, disability, unemployment, or property insurance that covers the same risk insured by the credit insurance being offered, you may not want or need to purchase this insurance.</li>" +
        "<li><b>Insurance Evaluation.</b> The endorsee is not qualified or authorized to evaluate the adequacy of your existing coverages unless the individual is licensed as an agent.</li>" +
        "<li><b>Finance charges.</b> If any portion of the premium for this insurance is financed, it will be subject to finance charges at the rate applicable to your loan.</li>" +
        "<li><b>Costs.</b> The insurance premium costs are shown below for each type of coverage available:</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        (toPrint ? 
            "<h5 class='p-1'><b>Price, Signatures and Notice All Plans per $100 per Month times your outstanding balance</b></h5>"
        :
            "<p class='p-1'><b>Price, Signatures and Notice All Plans per $100 per Month times your outstanding balance</b></p>")+
        "<b>" +
        "<ul>" +
        "<li>Life Single ............................................................. $0.039 per $100</li>" +
        "<li>Life Joint ................................................................ $0.057 per $100</li>" +
        "<li>Disability ................................................................ $0.100 per $100</li>" +
        "</ul>" +
        "</b>" +
        "</div>" +
        ( toPrint ? propertyValueToPrint() : propertyValueToEmail())+
        "</td>" +
        "</tr>" +
        "</tbody>" +
        "<tfoot class='table-footer'>" +
        "<tr>" +
        "<td>" +
        "<span>F.88728.30</span>" +
        "</td>" +
        "</tr>" +
        "</tfoot>" +
        "</table>" +

        "<table class='print-component'>" +
        "<tbody>" +
        "<tr>" +
        "<td>" +
        "<div class='mt-4'>" +
        "<h4 class='text-center mb-1' style='text-align:center !important'><b>CONSUMER INSURANCE SUMMARY</b></h4>" +
        "<p class='text-center' style='text-align:center !important'>(Involuntary Unemployment, Property Insurance)</p>" +
        "<p><b>This is a summary of the coverages available not an application for insurance. A separate application for insurance must be completed and signed to obtain coverage. The purchase of this coverage is <u>optional and is not required to obtain the loan or credit approval.</u></b></p>" +
        "<p><b>An application for coverage will be provided for each product indicated on this summary. Borrowers must complete the application and meet eligibility requirements to obtain coverage.</b></p>" +
        "<div class='text-center'>" +
        "<p class='mb-0' style='text-align:center !important'><b>Insurers:</b> Securian Casualty Company</p>" +
        "<p class='mb-0' style='text-align:center !important'><b>Administrator:</b> Summit Administrators, 800-275-3414</p>" +
        (partnerType === 'Fortiva' ?
            "<p class='mb-0' style='text-align:center !important'><b>Creditor:</b> Bank of Missouri as issuer of credit arranged by Fortiva Retail Credit on behalf of Daniel’s Jewelers</p>" :
            "<p class='mb-0' style='text-align:center !important'><b>Creditor:</b> Sherwood Management Co dba:Daniel’s Jewelers</p>") +
        "<p style='text-align:center !important'><b>Agent License Number:</b> 3001348673</p>" +
        "</div>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Eligibility</b></h5>" +
        "<ul>" +
        "<li><b>Unemployment Eligibility Conditions.</b> To be eligible for loss of employment benefits, as of the effective date of the Certificate, you must be insured under the Plan and gainfully employed on a full-time basis and in a nonseasonal occupation. Loss of income due to retirement is not covered.</li>" +
        "<li><b>Eligibility Conditions.</b> Property coverage covers the interest of the Creditor and/or the Debtor as outlined in the Certificate Declarations page, master policy and specific endorsements.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Unemployment Benefit</b></h5>" +
        "<ul>" +
        "<li><b>Unemployment Benefit.</b> We will pay the minimum monthly payment on Your account if you are involuntarily unemployed for more than 30 days. After the 30-day waiting period, benefits will be paid retroactively to the first day. Payments will stop when you are no longer involuntarily unemployed or when your loan with Daniel’s is paid off.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Property Benefit</b></h5>" +
        "<ul>" +
        "<li><b>Property Benefit.</b> In the event of a loss, our liability will be the lesser of the cost to repair or replace the property with material of like kind or quality or the amount of the original loan (limit of liability). We will endeavor to replace property that is the same or nearly identical to the property that is lost, but we are not required to do so and may substitute property of like kind or quality. Our liability will not exceed for the first 60 days after enrollment for insurance, an amount up to the highest balance in the debtor Revolving Agreement during that 60-day period. After the first 60 days after enrollment, an amount up to the highest balance on which a premium has been assessed during the 36 months before the date of loss. At an additional premium, the settlement options may be amended to include replacement cost rather than actual cash value.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>When Insurance Stops or Termination</b></h5>" +
        "<ul>" +
        "<li><b>Credit IUI - Termination.</b> Your individual Certificate will be automatically cancelled on the first billing date after the Creditor receives Your written request to stop the insurance or if earlier; or on the first billing date after you withdraw your authorization for the addition of charges for the insurance to your loan; or first billing date after You reach Your 66th birthday (this does not apply if you stated in your application that you are 66 or older and we do not refund your premium within 75 days of the Effective Date); or billing date upon which You are more than 90 days past due in making the required account payment; or date Your account is terminated; or date the Master Policy is cancelled.</li>" +
        "<li><b>Credit Property- Termination.</b> Coverage ends when the first of the following occurs: the expiration date shown on the Declarations Page, or the loan is paid off. Coverage will automatically terminate on the date you are more than 90 days late making the required account payment; 36 months from the date of purchase; date the secured property has been sold, transferred, abandoned or repossessed; or when your account is terminated. </li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Exclusions</b></h5>" +
        "<ul>" +
        "<li><b>Property Exclusions.</b> Coverage will not be provided against loss or damage: due to frost, cold weather, ice, snow or sleet; due to rain, snow, sand, or dust unless the building where the property is kept is first damaged by a covered peril; from wear, tear, or normal usage; from mechanical or electrical breakdown; to any form of money, notes, or manuscripts; to TV or radio antennas; due to nuclear or radioactivity; from the result of war-like action by any government or military force.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Conditions or Other Limitations </b></h5>" +
        "<ul>" +
        "<li><b>IUI Conditions or Other Limitations.</b> . Coverage will not apply to purchases made while You are receiving benefits under the Certificate.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Property Perils</b></h5>" +
        "<ul>" +
        "<li><b>Property Perils.</b> We will insure against sudden and direct accidental loss or damage to property caused by fire or lightning; windstorm or hail; explosion; riot or civil disturbance; aircraft or vehicles; smoke; flood; collapse of building; bursting, burning, or freezing of any appliance except water heaters; robbery or burglary with evidence of forced entry.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Options for All Programs</b></h5>" +
        "<ul>" +
        "<li><b>Available coverage options.</b> If you qualify, you may purchase life coverage with disability; and/or unemployment and/or property coverage together as a package.</li>" +
        "<li><b>Cancellation and refunds or credits.</b> You may cancel this insurance and receive a full refund within the first 30 days after receiving your policy or certificate. If you cancel after the 30 days, any unearned premium will be refunded in accordance with applicable law.</li>" +
        "<li><b>Other Insurance.</b> If you have other life, disability, unemployment, or property insurance that covers the same risk insured by the credit insurance being offered, you may not want or need to purchase this insurance.</li>" +
        "<li><b>Insurance Evaluation.</b> The endorsee is not qualified or authorized to evaluate the adequacy of your existing coverages unless the individual is licensed as an agent.</li>" +
        "<li><b>Finance charges.</b> If any portion of the premium for this insurance is financed, it will be subject to finance charges at the rate applicable to your loan.</li>" +
        "<li><b>Costs.</b> The insurance premium costs are shown below for each type of coverage available:</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Price, Signatures and Notice All Plans per $100 per Month times your outstanding balance</b></h5>" +
        "<b>" +
        "<ul>" +
        "<li>Involuntary Unemployment........................ $0.724 per $100</li>" +
        "<li style={{ wordBreak: 'break-word' }}>Property................................................................... $0.083 per $100</li>" +
        "</ul>" +
        "</b>" +
        "</div>" +
        ( toPrint ? propertyValueToPrint() : propertyValueToEmail())+
        "</td>" +
        "</tr>" +
        "</tbody>" +
        "<tfoot class='table-footer'>" +
        "<tr>" +
        "<td>" +
        "<span>F.88729 NM (0822)</span>" +
        "</td>" +
        "</tr>" +
        "</tfoot>" +
        "</table>"
    );
}

const propertyValueToPrint = () => {
    return (
        "<div class='mt-3 mb-4'>" +
        "<div class='container'>" +
        "<div class='row'>" +
        "<div class='col-6 border-top border-bottom border-right'>Proposed insured signature <p></p></div>" +
        "<div class='col-6 border-top border-bottom'>Date<p></p></div>" +
        "<div class='col-6 border-top border-bottom border-right'>Proposed joint insured<p></p></div>" +
        "<div class='col-6 border-top border-bottom'>Date<p></p></div>" +
        "</div>" +
        "</div>" +
        "</div>"
    )
}

const propertyValueToEmail = () => {
    return(
        "<div class='mt-3 mb-4'>" +
        "<table>" +
        "<tbody>" +
        "<tr>" +
        "<td>Proposed insured signature <p></p></td>" +
        "<td>Date<p></p></td>" +
        "</tr>" +
        "<tr>" +
        "<td>Proposed joint insured</td>" +
        "<td>Date</td>" +
        "</tr>" +
        "</tbody>" +
        "</table>" +
        "</div>"
    )
}