import React from "react";
import { Card } from "react-bootstrap";

export const CardView = ({
  data,
  handleCardSelect,
  activeID,
  subTableData,
}) => {
  return (
    <>
      <hr className="mt-1 mb-1"></hr>
      <div className="row">
        {data &&
          data.map((val) => (
            <div className={"col-sm-12 col-md-6 pt-2 pb-2 "+(subTableData.length > 0 ? "" : "d-flex")} key={val.id}>
              <Card
                className="text-left shadow"
                onClick={() =>
                  handleCardSelect(val.id, val.rcptId, val.rcptDt)
                }
              >
                <Card.Body
                  className={((subTableData.length > 0) && (activeID === val.id)) ? "ml-3 mt-3 mr-3 pt-0 mb-1 bg-secondary text-primary" : "m-3 pt-0 "}
                  style={{ border: "2px solid #1B1B1B" }}
                >
                  <div>
                  <div className="row mt-3">
                    <div className="col-6">Daniel's Account :</div>
                    <div className="col-6">{val.magixAccNo}</div>
                    <div className="col-6">Associate Id :</div>
                    <div className="col-6">{val.associateId}</div>
                    <div className="col-6">Receipt Date :</div>
                    <div className="col-6">{val.rcptDt}</div>
                    <div className="col-6">Receipt Id :</div>
                    <div className="col-6">{val.rcptId}</div>
                    <div className="col-6">Transaction Type :</div>
                    <div className="col-6">{val.transactionType}</div>
                    <div className="col-6">Store No. :</div>
                    <div className="col-6">{val.storeNumber}</div>
                    <div className="col-6">Receipt Total :</div>
                    <div className={"col-6 "+(val?.receiptTotal >= 0 ? "" : "text-danger")}>{val.receiptTotal?.toFixed(2)}</div>
                    <div className="col-6">Customer Balance :</div>
                    <div className={"col-6 "+(val?.customerBal >= 0 ? "" : "text-danger")}>{val.customerBal?.toFixed(2)}</div>
                  </div>
                  </div>
                </Card.Body>
                <div className={"pl-4 pr-4 " +((subTableData.length > 0) && (activeID === val.id) ? "" : "d-none")}>
                    {/* <hr style={{border:"1px solid"}}></hr> */}
                    <p></p>
                    {activeID === val.id ? (subTableData && subTableData.map((val) => (
                      <>
                            <div className="row">
                              <div className="col-6">Sale Site Id :</div>
                              <div className="col-6">{val?.storeNumber === null ? "" :val?.storeNumber}</div>
                              <div className="col-6">Product ID :</div>
                              <div className="col-6">{val.productId}</div>
                              <div className="col-6">Description :</div>
                              <div className="col-6">{val.description}</div>
                              <div className="col-6">Quantity :</div>
                              <div className="col-6">{val.quantity}</div>
                              <div className="col-6">Unit Amount :</div>
                              <div className={"col-6 "+(val?.unitAmount >= 0 ? "" : "text-danger")}>{val.unitAmount?.toFixed(2)}</div>
                              <div className="col-6">Total Amount :</div>
                              <div className={"col-6 "+(val?.totalAmt >= 0 ? "" : "text-danger")}>{val.totalAmt?.toFixed(2)}</div>
                            </div>
                            <hr></hr>
                      </>
                    ))) : null}
                  </div>
              </Card>
            </div>
          ))}
      </div>
    </>
  );
};