import React from 'react';
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import TenderFortiva from './component.tenderFortiva';
import TenderSynchrony from "./component.tenderSynchrony";
import DanielsAutoPay from "./component.danielsAutoPay";
import DanielsEStatement from "./component.danielsEStatement";

class TenderModal extends React.Component{
  constructor() {
    super();
    this.state = {
      tenderObj: {
        employee_id: "",
        pin: "",
      },
      currentTender: "",
      showTenderFortiva: false,
      showTenderSynchrony: false,
      showdanielsAutoPay : false,
      showDanielsEStatement : false,
      formErrors: {},
    };
  }

  validateField() {
    let formErrors = {};
    let formIsValid = true;

    formErrors["employeeIdErr"] = "";
    formErrors["pinErr"] = "";

    if (this.state.tenderObj.employee_id === "") {
      formErrors["employeeIdErr"] = "Employee # is Required.";
      formIsValid = false;
    }
    if (this.state.tenderObj.pin === "") {
      formErrors["pinErr"] = "Pin # is required.";
      formIsValid = false;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  triggerTender = (curTen) => {
    if (this.validateField()) {
      document.getElementById("tenderModalCloseBtn").click();
      if (curTen === "Fortiva") {
        this.setState({ showTenderFortiva: !this.state.showTenderFortiva });
      } else if (curTen === "Synchrony") {
        this.setState({ showTenderSynchrony: !this.state.showTenderSynchrony });
      } else if (curTen === "DanielsAutoPay") {
        this.setState({ showdanielsAutoPay: !this.state.showdanielsAutoPay });
      } else if (curTen === "DanielsEStatement") {
        this.setState({ showDanielsEStatement: !this.state.showDanielsEStatement });
      }
    }
  };

  triggerTenderFortiva = () => {
    this.setState({showTenderFortiva : !this.state.showTenderFortiva ,tenderObj : { ...this.state.tenderObj , employee_id : "", pin : ""}});
  }

  triggerTenderSynchrony = () => {
      this.setState({showTenderSynchrony : !this.state.showTenderSynchrony ,tenderObj : { ...this.state.tenderObj , employee_id : "", pin : ""}});
  }

  triggerDanielsAutoPay = () => {
    this.setState({showdanielsAutoPay : !this.state.showdanielsAutoPay ,tenderObj : { ...this.state.tenderObj , employee_id : "", pin : ""}});
  }

  triggerDanielsEStatement = () => {
    this.setState({showDanielsEStatement : !this.state.showDanielsEStatement ,tenderObj : { ...this.state.tenderObj , employee_id : "", pin : ""}});
  }

  onChange = (event) => {
    if (event.target.id === "tenderEmployeeId") {
      this.setState({
        tenderObj: {
          ...this.state.tenderObj,
          employee_id: event.target.value,
        },
      });
    } else if (event.target.id === "tenderPin") {
      this.setState({
        tenderObj: {
          ...this.state.tenderObj,
          pin: event.target.value,
        },
      });
    }
  };

  render() {
    const { employeeIdErr, pinErr } = this.state.formErrors;
    return (
      <>
          <Modal show={this.props.showTender} id="tender" centered size="lg">
            <Modal.Header className="pb-0 border-0">
              <p className="h5 mb-4">
                Enter your Employee# and Pin# to continue.
              </p>
              <div 
                id="tenderModalCloseBtn"
                onClick={() => this.props.triggerTender()}>
                <FontAwesomeIcon
                  icon={faClose}
                  className="btn"
                />
              </div>
            </Modal.Header>
            <Modal.Body className="pb-0">
              <div className="row mb-3">
                <div className="col-12 col-md-6 col-lg-5">
                  <div className="form-group d-flex">
                    <label className="text-secondary my-auto">
                      Employee&nbsp;#&nbsp;&nbsp;
                    </label>
                    <input
                      className={
                        "form-control " +
                        (employeeIdErr ? "border-danger" : "border")
                      }
                      id="tenderEmployeeId"
                      name="tenderEmployeeId"
                      value={this.state.tenderObj.employee_id}
                      onChange={(event) => this.onChange(event)}
                    />
                  </div>
                  {employeeIdErr && (
                    <div className="text-danger">
                      {employeeIdErr}
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6 col-lg-5">
                  <div className="form-group d-flex">
                    <label className="text-secondary  my-auto">
                      Pin&nbsp;#&nbsp;&nbsp;
                    </label>
                    <input
                      type="password"
                      className={
                        "form-control " + (pinErr ? "border-danger" : "border")
                      }
                      id="tenderPin"
                      name="tenderPin"
                      value={this.state.tenderObj.pin}
                      onChange={(event) => this.onChange(event)}
                    />
                  </div>
                  {pinErr && <div className="text-danger">{pinErr}</div>}
                </div>
                <div className="col-12 col-md-4 offset-md-8 offset-lg-0 col-lg-2 text-right">
                  <button
                    className="btn text-primary bg-secondary"
                    onClick={() => this.triggerTender(this.props.currentTender)}
                  >
                    Continue
                  </button>
                </div>
              </div>              
            </Modal.Body>
          </Modal>

          {this.state.showTenderFortiva ? 
            <TenderFortiva 
                showTenderFortiva  = {this.state.showTenderFortiva}
                triggerTenderFortiva = {this.triggerTenderFortiva}
                tenderObj = {this.state.tenderObj}
            />
          : null }

          {this.state.showTenderSynchrony ? 
            <TenderSynchrony 
                showTenderSynchrony  = {this.state.showTenderSynchrony}
                triggerTenderSynchrony = {this.triggerTenderSynchrony}
                tenderObj = {this.state.tenderObj}
            />
          : null }
          
          {this.state.showdanielsAutoPay ? 
            <DanielsAutoPay 
              showdanielsAutoPay  = {this.state.showdanielsAutoPay}
              triggerDanielsAutoPay = {this.triggerDanielsAutoPay}
              tenderObj = {this.state.tenderObj}
              currentTabAccnStore = {this.props.currentTabAccnStore}
            />
          : null }

          {this.state.showDanielsEStatement ? 
            <DanielsEStatement 
                showDanielsEStatement  = {this.state.showDanielsEStatement}
                triggerDanielsEStatement = {this.triggerDanielsEStatement}
                tenderObj = {this.state.tenderObj}
                currentTabAccnStore = {this.props.currentTabAccnStore}
            />
          : null }
      </>
    );
  }
}

export default TenderModal;
