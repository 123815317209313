export const GADisclosure = (partnerType, toPrint) => {
    return (
        "<table class='print-component'>" +
        "<tbody>" +
        "<tr>" +
        "<td>" +
        "<div class='mt-4'>" +
        "<h4 class='text-center mb-1' style='text-align:center !important'><b>CONSUMER INSURANCE SUMMARY</b></h4>" +
        "<p class='text-center' style='text-align:center !important'>(LIFE, DISABILITY, INVOLUNTARY UNEMPLOYMENT, PROPERTY INSURANCE)</p>" +
        "<p><b>This is a summary of the coverages available not an application for insurance. A separate application for insurance must be completed and signed to obtain coverage. The purchase of this coverage is <u>optional and is not required to obtain the loan or credit approval.</u></b></p>" +
        "<p><b>An application for coverage will be provided for each product indicated on this summary. Borrowers must complete the application and meet eligibility requirements to obtain coverage.</b></p>" +
        "<ul>" +
        "<li><b>Gold Plan - </b>Life, Disability, Unemployment and Property</li>" +
        "<li><b>Silver Plan - </b>Life, Disability and Property</li>" +
        "<li><b>Bronze Plan - </b>Property</li>" +
        "</ul>" +
        "<div class='text-center'>" +
        "<p class='mb-0' style='text-align:center !important'><b>Insurers:</b> Minnesota Life Insurance Company, Securian Casualty Company</p>" +
        "<p class='mb-0' style='text-align:center !important'><b>Administrator:</b> Summit Administrators, 800-275-3414</p>" +
        (partnerType === 'Fortiva' ?
            "<p class='mb-0' style='text-align:center !important'><b>Creditor: Bank of Missouri as issuer of credit as arranged by Fortiva Retail Credit on behalf of Daniel’s Jewelers • Agent License Number:</b> 220716</p>" :
            "<p class='mb-0' style='text-align:center !important'><b>Creditor: Daniel’s Jewelers • Agent License Number:</b> 220716</p>"
        ) +
        "</div>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Eligibility - Gold, Silver and Bronze</b></h5>" +
        "<ul>" +
        "<li><b>Life and Disability Eligibility Conditions.</b> You are eligible for insurance if you have not reached your 71st birthday for Life and 66th birthday for Disability. For Disability, you are eligible for insurance if you are presently working outside the home for wages or profit for 30 hours or more per week and have been so working for 30 days or more immediately prior to this date.</li>" +
        "<li><b>Unemployment Eligibility Conditions.</b> To be eligible for loss of employment benefits, as of the effective date of the Certificate, you must be insured under the Plan and gainfully employed on a full-time basis and in a nonseasonal occupation. Loss of income due to retirement is not covered.</li>" +
        "<li><b>Eligibility Conditions.</b> Property coverage covers the interest of the Creditor and/or the Debtor as outlined in the Certificate Declarations page, master policy and specific endorsements.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Life Benefit - Gold and Silver</b></h5>" +
        "<ul>" +
        "<li><b>Single Life Insurance Benefit.</b> The death benefit will be the lesser of: (1) the unpaid balance of your loan on the date of your death: or (2) the maximum amount of insurance specified on your application. This will be paid after we receive proof of your death.</li>" +
        "<li><b>Joint Life Insurance Benefit.</b> If you are jointly insured and you co-debtor dies, the death benefit will be determined on the same basis. Only one death benefit is payable, even if both jointly insured debtors should die on the same date.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Disability Benefit - Gold and Silver</b></h5>" +
        "<ul>" +
        "<li><b>Total Disability Insurance Benefit.</b> If you are insured for total disability insurance, we will pay a benefit if you file written proof that you became totally disabled while insured and continue to be totally disabled for more than 14 days. Payment will be calculated from the 1st day of disability. The benefit for each day of disability to be compensated will be 1/30th of your required monthly loan payment or the Maximum Monthly Disability Benefit, whichever is less.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Unemployment Benefit - Gold</b></h5>" +
        "<ul>" +
        "<li><b>Unemployment Benefit.</b> We will pay the minimum monthly payment on Your account if you are involuntarily unemployed for more than 30 days. After the 30-day waiting period, benefits will be paid retroactively to the first day. Payments will stop when you are no longer involuntarily unemployed or when your loan with Daniel’s is paid off.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Property Benefit - Gold, Silver and Bronze</b></h5>" +
        "<ul>" +
        "<li><b>Property Benefit.</b> In the event of a loss, our liability will be the lesser of the cost to repair or replace the property with material of like kind or quality or the amount of the original loan (limit of liability). We will endeavor to replace property that is the same or nearly identical to the property that is lost, but we are not required to do so and may substitute property of like kind or quality. Our liability will not exceed for the first 60 days after enrollment for insurance, an amount up to the highest balance in the debtor Revolving Agreement during that 60-day period. After the first 60 days after enrollment, an amount up to the highest balance on which a premium has been assessed during the 36 months before the date of loss. At an additional premium, the settlement options may be amended to include replacement cost rather than actual cash value.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Amount of Life Insurance - Gold and Silver</b></h5>" +
        "<ul>" +
        "<li>The amount of life insurance is the total amount due on the loan (excluding unearned finance charges), outstanding on the date of the insured borrower’s death. But, we will not pay more than the Maximum Amount of Life Insurance.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>When Insurance Stops or Termination - Gold, Silver and Bronze</b></h5>" +
        "<ul>" +
        "<li><b>Single or Joint Life and Disability. When Insurance Stops.</b> This insurance stops: on the date your loan is discharged through payment, prepayment, renewal or refinancing; or on the date the open-end loan account terminates; or the policyholder transfers the loan without recourse and no longer services the loan; or any required loan repayment which includes your insurance premium is more than 90 days overdue; or you request in writing that your insurance be terminated; or the group policy terminates provided you receive 30 days written notice; or you die. If joint insurance is in force, your insurance terminates on both you and your co-debtor on the date either you or your co-debtor die and a life benefit is paid; or the end of the month in which you reach age 66 and have disability insurance; or the end of the month in which you reach age 71 and have life insurance. If joint life insurance is in force, insurance will terminate on both debtors at the end of the month in which either debtor reaches age 71.</li>" +
        "<li><b>Credit IUI - Termination.</b> Your individual Certificate will be automatically cancelled on the first billing date after the Creditor receives Your written request to stop the insurance or if earlier; or on the first billing date after you withdraw your authorization for the addition of charges for the insurance to your loan; or first billing date after You reach Your 66th birthday (this does not apply if you stated in your application that you are 66 or older and we do not refund your premium within 75 days of the Effective Date); or billing date upon which You are more than 90 days past due in making the required account payment; date Your account is terminated; date the Maximum Total Indemnity Over Loan term has been reached; date the Maximum Term of Insurance has been reached; or date the Master Policy is cancelled.</li>" +
        "<li><b>Credit Property- Termination.</b> Coverage ends when the first of the following occurs: the expiration date shown on the Declarations Page, or the loan is paid off. Coverage will automatically terminate on the date you are more than 90 days late making the required account payment; 36 months from the date of purchase; date the secured property has been sold, transferred, abandoned or repossessed; or when your account is terminated.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Exclusions - Gold, Silver and Bronze</b></h5>" +
        "<ul>" +
        "<li><b>Life Exclusions.</b> We will not pay a claim for an advance on your loan if you commit suicide within one year after the date of the advance, but we will return the life insurance charge on that advance. This also applies to your co-borrower if you applied for joint life coverage.</li>" +
        "<li><b>Total Disability Exclusions.</b> We will not pay a claim or refund the disability insurance charge if your disability is a result of normal pregnancy or childbirth or elective abortions; or war or any act of war, whether such war is declared or undeclared; or intentionally self-inflicted injury; or as to each advance on your loan, begins within 6 months after the date of the advance as a result of a pre-existing medical condition. A pre-existing medical condition is one for which you saw or were under treatment by a physician or a chiropractor both within the 6 months before and the 6 months after the date of the advance.</li>" +
        "<li><b>Property Exclusions.</b> Coverage will not be provided against loss or damage: due to frost, cold weather, ice, snow or sleet; due to rain, snow, sand, or dust unless the building where the property is kept is first damaged by a covered peril; from wear, tear, or normal usage; from mechanical or electrical breakdown; to any form of money, notes, or manuscripts; to TV or radio antennas; due to nuclear or radioactivity; from the result of war-like action by any government or military force.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Conditions or Other Limitations - Gold and Silver</b></h5>" +
        "<ul>" +
        "<li><b>Single or Joint Life and Disability Conditions or Other Limitations.</b> You are insured only for advances actually received by you. You are not insured for any unused credit which may be available to you. Only the Primary Borrower is eligible for disability insurance.</li>" +
        "<li><b>IUI Conditions or Other Limitations.</b> Coverage will not apply to purchases made while You are receiving benefits under the Certificate.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Property Perils - Gold and Bronze</b></h5>" +
        "<ul>" +
        "<li><b>Property Perils.</b> We will insure against sudden and direct accidental loss or damage to property caused by fire or lightning; windstorm or hail; explosion; riot or civil disturbance; aircraft or vehicles; smoke; flood; collapse of building; bursting, burning, or freezing of any appliance except water heaters; robbery or burglary with evidence of forced entry.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Options for All Programs - Gold, Silver and Bronze</b></h5>" +
        "<ul>" +
        "<li><b>Available coverage options.</b> If you qualify, you may purchase life coverage with disability; and/or unemployment and/or property coverage together as a package.</li>" +
        "<li><b>Cancellation and refunds or credits.</b> You may cancel this insurance and receive a full refund within the first 30 days after receiving your policy or certificate. If you cancel after the 30 days, any unearned premium will be refunded in accordance with applicable law.</li>" +
        "<li><b>Other Insurance.</b> If you have other life, disability, unemployment, or property insurance that covers the same risk insured by the credit insurance being offered, you may not want or need to purchase this insurance.</li>" +
        "<li><b>Insurance Evaluation.</b> The endorsee is not qualified or authorized to evaluate the adequacy of your existing coverages unless the individual is licensed as an agent.</li>" +
        "<li><b>Finance charges.</b> If any portion of the premium for this insurance is financed, it will be subject to finance charges at the rate applicable to your loan.</li>" +
        "<li><b>Costs.</b> The insurance premium costs are shown below for each type of coverage available:</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='p-1'><b>Price, Signatures and Notice All Plans per $100 per Month times your outstanding balance</b></h5>" +
        "<b>" +
        "<ul>" +
        "<li>Life Single ............................................................. $0.070 per $100</li>" +
        "<li>Life Joint ................................................................ $0.105 per $100</li>" +
        "<li>Disability ................................................................ $0.280 per $100</li>" +
        "<li>Involuntary Unemployment........................"+(toPrint ? "" : "...........")+" $0.721 per $100</li>" +
        "<li style={{ wordBreak: 'break-word' }}>Property................................................................... $0.462 per $100</li>" +
        "</ul>" +
        "</b>" +
        "</div>" +
        (toPrint ? propertyValueToPrint() : propertyValueToEmail()) +
        "</td>" +
        "</tr>" +
        "</tbody>" +
        "<tfoot class='table-footer'>" +
        "<tr>" +
        "<td>" +
        "<span>F.88728.10</span>" +
        "</td>" +
        "</tr>" +
        "</tfoot>" +
        "</table>"
    );
}

const propertyValueToPrint = () => {
    return (
        "<div class='mt-3 mb-4'>" +
        "<div class='container'>" +
        "<div class='row'>" +
        "<div class='col-6 border-top border-bottom border-right'>Proposed insured signature <p></p></div>" +
        "<div class='col-6 border-top border-bottom'>Date<p></p></div>" +
        "<div class='col-6 border-top border-bottom border-right'>Proposed joint insured<p></p></div>" +
        "<div class='col-6 border-top border-bottom'>Date<p></p></div>" +
        "</div>" +
        "</div>" +
        "</div>"
    )
}

const propertyValueToEmail = () => {
    return (
        "<div class='mt-3 mb-4'>" +
        "<table>" +
        "<tbody>" +
        "<tr>" +
        "<td>Proposed insured signature <p></p></td>" +
        "<td>Date<p></p></td>" +
        "</tr>" +
        "<tr>" +
        "<td>Proposed joint insured<p></p></td>" +
        "<td>Date<p></p></td>" +
        "</tr>" +
        "</tbody>" +
        "</table>" +
        "</div>"
    )
}