export default function formatCC(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;

    else if (phoneNumberLength == 4) return phoneNumber.slice(0, 4);

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    // if (phoneNumberLength < 6) {
    return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4, 6)}`;
    // }

    // if (phoneNumberLength < 13) {
    //     return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4, 8)}-${phoneNumber.slice(8, 12)}`;
    // }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    // return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(4, 8)}-${phoneNumber.slice(8, 12)}-${phoneNumber.slice(12, 16)}`;
}