import { ApiWrapper } from "../../api/thunk.helper"; //storeNumber.actions.js
import HttpMethods from "../../redux/_http.methods";
import { ASSOCIATESTORECODE } from "./actionTypes";

export const addStorCode = (storeCode) => ({
  type: ASSOCIATESTORECODE,
  payload: {
      storeCode: storeCode,
  }
});

//this is anonymous api, we are getting access token from this api so we dont need to send the token.
export function getStoreDetails(searchFilter) {
  return function (dispatch) {
    let resourceUrl = "v1/associates";
    if (searchFilter.Filter1_AssociateEmail){
      resourceUrl += "?Filter1_AssociateEmail=" + searchFilter.Filter1_AssociateEmail;
      if (searchFilter.Filter2_AssociateObject){
        resourceUrl += "&Filter2_AssociateObject=" + searchFilter.Filter1_AssociateEmail;
      }
    }else{
      if (searchFilter.Filter2_AssociateObject){
        resourceUrl += "?Filter2_AssociateObject=" + searchFilter.Filter1_AssociateEmail;
      }
    }
    
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: searchFilter,
      ResourceUrl: resourceUrl,
      anonymouseApi:true,
      isAssociate : true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          dispatch(addStorCode(response.stores.data));
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function authorizeAssociate(searchFilter) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Get(),
            Resource: searchFilter,
            ResourceUrl: "v1/associates/v1/AuthorizeAssociate?Filter1_AssociateEmail=" + searchFilter.Filter1_AssociateEmail
                + "&Filter3_AssociatePassword=" + encodeURIComponent(searchFilter.Filter3_AssociatePassword),
            isAssociate: true
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function getCreditToken(searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: searchFilter,
      ResourceUrl: "v1/associates/credit-app/token/"+searchFilter,
      anonymouseApi:true,
      isAssociate : true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function getUserLoginCount(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: obj,
      ResourceUrl: "v1/associates/user-Login-count",
      isAssociate: true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function userLoginDetails(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: obj,
      ResourceUrl: "v1/associates/user-Login-details",
      isAssociate: true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getImageFileFromBlob(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      ResourceUrl: "v1/customers/GetImageFileFromBlob?fileName="+obj,
      anonymouseApi:true,
      isCustomer : true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function postDailyImage(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: obj,
      ResourceUrl: "v1/customers/UploadImageFileToBlob",
      isCSV:true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getText_Img() {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      ResourceUrl: "v1/customers/GetText_Img",
      anonymouseApi:true,
      isCustomer : true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}


export function postDailyNote(by, obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: obj,
      ResourceUrl: "v1/customers/UpdateImageText?modifiedBy="+by,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}