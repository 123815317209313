export const FLDisclosure = (partnerType, toPrint) => {
    return (
"        <table class='print-component'> "+
        "<tbody> "+
        "<tr> "+
        "<td> "+
        "<div class='mt-4'> "+
        "<h4 class='text-center mb-1' style='text-align:center !important'><b>CREDIT INSURANCE ELIGIBILITY DISCLOSURE</b></h4> "+
        "</div> "+
        "<div class='mt-3'> "+
        "<h5 class='p-1'><u><b>Property Eligibility - I acknowledge that:</b></u></h5> "+
        "<ul> "+
        "<li style='text-align:justify'>I have no valid and collectable insurance to offer the Creditor</li> "+
        "<li style='text-align:justify'>Any other applicable insurance is not available to assign or offer the Creditor</li>"+
        "</ul> "+
        "</div> "+
        "<div class='mt-3'> "+
        "<h5 class='p-1'><u><b>Involuntary Unemployment – I acknowledge that:</b></u></h5> "+
        "<ul> "+
        "<li style='text-align:justify'>I am working for salary or wages (employment income) at least 24 hours a week, and have done so for at least 3 consecutive months</li> "+
        "<li style='text-align:justify'>I have not received unemployment benefits within the past 2 years</li> "+
        "<li style='text-align:justify'>I am not self-employed, a seasonal worker, independent contractor or full-time military personnel.</li> "+
        "</ul> "+
        "</div> "+
        "<div class='mt-3'> "+
        "<h5 class='p-1'><u><b>Life and Total Disability Eligibility – I acknowledge that:</b></u></h5> "+
        "<ul> "+
        "<li style='text-align:justify'>I am under age 70</li>"+
        "<li style='text-align:justify'>I am presently working outside the home for wages or profit for 30 hours or more per week and been so working for 30 days or more immediately prior to this date</li>"+
        "<li style='text-align:justify'>During the last two years: (a) I have not been advised of or treated for cancer, heart attack or coronary artery disease, stroke, or cirrhosis by a physician or other health care provider; or (b) I have not been diagnosed with Acquired Immune Deficiency Syndrome (AIDS).</li> "+
        "</ul> "+
        "</div> "+
        "</td> "+
        "</tr> "+
        "</tbody>"+
        "</table>"
    );
}

