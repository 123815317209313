import React, { Component } from "react";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import "./component.adminUserList.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Card, Toast, ToastContainer } from "react-bootstrap";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import * as adminUserListActions from "../../redux/actions/adminUserList.actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import Loader from "react-loader-spinner";
import ConfirmDialog from "../../components/component.confirmDialog";
import { IMaskInput } from "react-imask";
import Multiselect from "multiselect-react-dropdown";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import Constants from "../../utilites/constants";

const RemotePagination = ({
  data,
  page,
  sizePerPage = 10,
  onTableChange,
  totalSize,
  dataField,
  order,
  columns,
  customTotal,
  selectRow,
  rowStyle,
  paginationSize,
  firstAndLast,
}) => (
  <div>
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize,
        paginationTotalRenderer: customTotal,
        paginationSize,
        withFirstAndLast: firstAndLast,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div
          className={` ${data.length !== 0 ? "userListTable" : "emptyTable"}`}
        >
          <BootstrapTable
            className="pagination"
            bootstrap4
            hover
            remote={{
              sort: true,
              pagination: true,
            }}
            bordered={false}
            condensed={true}
            keyField="associateEmail"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            selectRow={selectRow}
            rowStyle={rowStyle}
            sort={{ dataField: dataField, order: order }}
            {...paginationTableProps}
            filter={filterFactory()}
          />
          <div className="d-flex justify-content-between">
            <PaginationTotalStandalone {...paginationProps} />
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </PaginationProvider>
  </div>
);

class AdminUserList extends Component {
  constructor() {
    super();
    this.state = {
      toggled: false,
      show: false,
      userList: [],
      isEdit: false,
      associateEmail: "",
      isLoad: false,
      isPaginate: false,
      noDataFound: false,
      selectedRow: {},
      displayText: "",
      isSort: false,
      pageNo: 1,
      from: 1,
      to: 10,
      isFirstTime: false,
      dataField: "displayName",
      order: "asc",
      nextPageToken: "",
      prevPageToken: "",
      formObj: {
        id: "",
        associateEmail: "",
        password: "",
        userName: "",
        userId: "",
        role: "",
        homeStore: "",
        district: "",
        lastActivity: "",
        status: "",
        effectiveDate: "",
        allowPortalAccess: "",
      },
      filterObj: {
        id: "",
        associateEmail: "",
        password: "",
        userName: "",
        userId: "",
        role: "",
        homeStore: "",
        district: "",
        lastActivity: "",
        status: "",
        effectiveDate: "",
      },
      districtList: [],
      rolesList: [],
      storeList: [],
      portalList: [],
      isEditUserOpen: false,
      totalUsers: undefined,
      emailValidateMsg: "",
      userDeleteResponse: "",
      userAddorUpdateResponse: "",
      editToast: false,
      deleteToast: false,
      roles: [],
      site_page_details: [],
      sitePages: [],
      userRights: [],
      permission_status: "",
      tokensList: [],
      formErrors: {},
      paginationSize: 2,
      firstAndLast: false,
      isModalDeleteOpen: false,
      showSpinner: false,
      isSearchDropDown: "userName",
      filterValue: "",
      homeStoreCopy: "",
      lastActivity: "",
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("isLoggedIn") === "true") {
      this.setState({
        userList: [],
        totalUsers: undefined,
        pageNo: 1,
        associateEmail: "",
      });
      let obj = {
        filter_Username: "",
        filter_UserId: "",
        filter_Role: "",
        filter_Store: "",
        filter_District: "",
        filter_LastActivity: "",
        filter_Status: "",
        sortBy: this.state.dataField,
        sortOrder: this.state.order,
        pageSize: Constants.userPageSize,
        nextPageToken: "",
      };
      //get users list
      trackPromise(
        this.props.actions
          .getUserList(obj)
          .then((response) => {
            if (response.users.length === 0) {
              this.setState({ noDataFound: true });
            }
            if (response) {
              response.users.map((user) => {
                user.lastActivity =
                  user.lastActivity &&
                  Moment(user.lastActivity).format("MM/DD/YYYY");
              });
              let tokenCount = Math.ceil(response.count / 10);
              let arrList = [];
              for (var i = 0; i <= tokenCount; i++) {
                if (i === 1) {
                  let tokenObj = {
                    id: i + 1,
                    token: response.nextPageToken,
                  };
                  arrList.push(tokenObj);
                } else {
                  let tokenObj = {
                    id: i + 1,
                    token: "",
                  };
                  arrList.push(tokenObj);
                }
              }
              this.setState({
                userList: response.users,
                nextPageToken: response.nextPageToken,
                prevPageToken: response.nextPageToken,
                totalUsers: response.count,
                tokensList: arrList,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
      //get districts
      trackPromise(
        this.props.actions
          .getDistricts(obj)
          .then((response) => {
            if (response) {
              this.setState({ districtList: response.data });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
      //get the user roles
      trackPromise(
        this.props.actions
          .getUserRoles(obj)
          .then((response) => {
            if (response) {
              this.setState({ rolesList: response.data });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );

      trackPromise(
        this.props.actions
          .getPortalList("Portal")
          .then((response) => {
            if (response) {
              this.setState({ portalList: response.data });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );

      trackPromise(
        this.props.userRightsactions
          .getUserRights()
          .then((response) => {
            if (response) {
              this.setState({
                roles: response.roles,
                site_page_details: response.sitePageDetails,
                sitePages: response.sitePages,
                userRights: response.userRights,
              });
              this.getPermissions();
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    } else this.props.history.push("/");
  }

  getPermissions() {
    let role_name = localStorage.getItem("role_name"),
      page_name = "User List";
    const getsitepages = this.state.sitePages.filter((site_pages) => {
      return site_pages.name === "Admin Page";
    });
    const getSitepagedetails = this.state.site_page_details.filter(
      (site_page_details) => {
        return (
          site_page_details.sitePage_id === getsitepages[0].id &&
          site_page_details.name === page_name
        );
      }
    );
    const getRole = this.state.roles.filter((roles) => {
      return roles.name.toLowerCase() === role_name.toLowerCase();
    });
    let role_id = getRole[0].id;
    const role_permission_check = this.state.userRights.filter(
      (user_rights) => {
        return (
          user_rights.sitePageDetail_id === getSitepagedetails[0].id &&
          user_rights.role_id === role_id
        );
      }
    );
    this.setState({ permission_status: role_permission_check[0] });
  }

  handleToggleSidebar() {
    this.setState({ toggled: !this.state.toggled });
  }
  handleCardSelect = (email, row) => {
    this.setState({ isEdit: true, associateEmail: email, selectedRow: row });
  };

  handleEditUserToggle(data) {
    this.setState({
      show: !this.state.show,
      displayText: data,
      formObj: { ...this.state.formObj, district: [], allowPortalAccess: [] },
    });
    const row = Object.assign({}, this.state.selectedRow);
    let district = row.district?.split(",");
    district = district?.map((val) => {
      return val.toLowerCase();
    });
    let portalAccess = row.allowPortalAccess?.split(",");
    portalAccess = portalAccess?.map((val) => {
      return val.toLowerCase();
    });
    let distrobj = this.state.districtList?.filter((item) => {
      return district?.includes(item.name.toLowerCase());
    });
    let portalObj = this.state.portalList?.filter((item) => {
      return portalAccess?.includes(item.code.toLowerCase());
    });
    this.setState({
      formObj: {
        ...this.state.formObj,
        district: distrobj,
        allowPortalAccess: portalObj,
      },
    });
    const selectedObj = {
      id: row.id,
      associateEmail: row.associateEmail == null ? "" : row.associateEmail,
      password: row.password == null ? "" : row.password,
      userName: row.displayName == null ? "" : row.displayName,
      userId: row.userId == null ? "" : row.userId,
      role: row.roleName == null ? "" : row.roleName,
      homeStore: row.storeCode == null ? "" : row.storeCode,
      district: distrobj,
      lastActivity: row.lastActivity == null ? "" : row.lastActivity,
      status: row.status == null ? "" : row.status,
      effectiveDate: row.effectiveDate == null ? "" : row.effectiveDate,
      allowPortalAccess: portalObj,
    };
    this.setState({
      homeStoreCopy: row.storeCode,
      lastActivity: row.lastActivity,
    });
    if (district) {
      distrobj = Array(
        distrobj.map((val) => {
          return val.id;
        })
      );
      if (distrobj) {
        trackPromise(
          this.props.actions
            .getStoresByMultipleDistrictId(distrobj[0])
            .then((response) => {
              if (response) {
                this.setState({ storeList: response.data });
              }
            })
            .catch((err) => {
              APIFailedMessage.displayError(err);
            })
        );
      }
    }

    this.setState({ formObj: selectedObj });
  }
  cancel() {
    this.setState({
      show: !this.state.show,
      isEdit: false,
      associateEmail: "",
      formErrors: {},
      homeStoreCopy: "",
      storeList:[],
      formObj: {
        ...this.state.formObj,
        associateEmail: "",
        password: "",
        userName: "",
        userId: "",
        role: "",
        homeStore: "",
        district: [{}],
        lastActivity: "",
        status: "",
        effectiveDate: "",
        allowPortalAccess: [{}],
      },
    });
  }
  handleFormValidation(currStep) {
    let formErrors = {};
    let formIsValid = true;

    formErrors["emailErr"] = "";
    formErrors["passwordErr"] = "";
    formErrors["userNameErr"] = "";
    formErrors["customerNoErr"] = "";
    formErrors["userIdErr"] = "";
    formErrors["roleErr"] = "";
    formErrors["districtErr"] = "";
    formErrors["homeStoreErr"] = "";
    formErrors["statusErr"] = "";
    formErrors["effectiveDateErr"] = "";
    formErrors["portalListErr"] = "";

    if (this.state.formObj.associateEmail === "") {
      formIsValid = false;
      formErrors["emailErr"] = "Email is required";
    } else {
      if (!this.state.formObj.id) {
        let reg = /^[a-zA-Z0-9.]+$/;
        if (!reg.test(this.state.formObj.associateEmail)) {
          formIsValid = false;
          formErrors["emailErr"] =
            "letters (a-z and A-Z), numbers (0-9), and periods (.) are allowed.";
        } else {
          formIsValid = this.emailValidate(this.state.formObj.associateEmail);
        }
      }
    }
    if (!this.state.formObj.id) {
      if (this.state.formObj.password === "") {
        formIsValid = false;
        formErrors["passwordErr"] = "Password  is required";
      } else {
        let regexExp =
          /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;
        if (!regexExp.test(this.state.formObj.password)) {
          formIsValid = false;
          formErrors["passwordErr"] =
            "Password should contain alphanumeric,one special character,one upper case letter and minimum lenght is 8 characters";
        }
      }
    }

    if (this.state.formObj.userName === "") {
      formIsValid = false;
      formErrors["userNameErr"] = "UserName is required";
    }
    if (!this.state.formObj.userId) {
      formIsValid = false;
      formErrors["userIdErr"] = "UserId is required";
    }
    if (
      this.state.formObj.role === "" ||
      parseInt(this.state.formObj.role) === 0 ||
      this.state.formObj.role === null
    ) {
      formIsValid = false;
      formErrors["roleErr"] = "Role name is required";
    }
    // if (this.state.formObj.district==="" || parseInt(this.state.formObj.district)===0 || this.state.formObj.district===null) {
    //   formIsValid = false;
    //   formErrors["districtErr"] = "district is required";
    // }
    if (this.state.formObj.district?.length === 0) {
      formIsValid = false;
      formErrors["districtErr"] = "Distrist is required";
    }
    if (
      this.state.formObj.homeStore === "" ||
      parseInt(this.state.formObj.homeStore) === 0 ||
      this.state.formObj.homeStore === null
    ) {
      formIsValid = false;
      formErrors["homeStoreErr"] = "Home store is required";
    }
    if (
      this.state.formObj.status === "" ||
      parseInt(this.state.formObj.status) === 0 ||
      this.state.formObj.status === null
    ) {
      formIsValid = false;
      formErrors["statusErr"] = "Status is required";
    }
    if (this.state.formObj.id) {
      if (!this.state.formObj.effectiveDate) {
        formIsValid = false;
        formErrors["effectiveDateErr"] = "Effective Date is required";
      }
    }
    if (this.state.formObj.allowPortalAccess?.length === 0) {
      formIsValid = false;
      formErrors["portalListErr"] = "Portal access is required";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  saveUser(event) {
    event.preventDefault();
    if (this.handleFormValidation()) {
      let userList = this.state.userList;
      let email = "";
      if (this.state.formObj.id) {
        email = this.state.formObj.associateEmail;
      } else {
        email = this.state.formObj.associateEmail + "@danielsjewelers.com";
      }
      let userObj = {
        id: this.state.formObj.id,
        associateEmail: email,
        userName: this.state.formObj.userName,
        userId: this.state.formObj.userId,
        userRole: this.state.formObj.role,
        district: String(
          this.state.formObj.district?.map((val) => {
            return val.name;
          })
        ),
        status: this.state.formObj.status,
        homeStore: this.state.formObj.homeStore,
        createdBy: localStorage.getItem("loggedUserEmail"),
        allowPortalAccess: String(
          this.state.formObj.allowPortalAccess?.map((val) => {
            return val.code;
          })
        ),
        lastActivity: this.state.lastActivity,
      };
      if (this.state.isEdit) {
        userObj.effectiveDate = this.state.formObj.effectiveDate;
        userList.filter((user) => {
          if (user.id === userObj.id) {
            user.userId = this.state.formObj.userId;
            user.roleName = this.state.formObj.role;
            user.district = String(
              this.state.formObj.district?.map((val) => {
                return val.name;
              })
            );
            user.storeCode = this.state.formObj.homeStore;
            user.status = this.state.formObj.status;
            user.effectiveDate = this.state.formObj.effectiveDate;
          }
        });
        this.setState({ userList: userList });
      } else {
        userObj.password = this.state.formObj.password;
        userList.push(userObj);
        let count = this.state.totalUsers;
        this.setState({ userList: userList, totalUsers: count + 1 });
      }

      //trackPromise(
      this.props.actions
        .editUser(userObj, this.state.isEdit)
        .then((response) => {
          if (response) {
            if (response.requestStatus === "success") {
              this.setState({
                formObj: {
                  ...this.state.formObj,
                  associateEmail: "",
                  password: "",
                  userName: "",
                  userId: "",
                  role: "",
                  homeStore: "",
                  district: "",
                  lastActivity: "",
                  status: "",
                  effectiveDate: "",
                  allowPortalAccess: "",
                },
              });
              this.setState({
                show: false,
                editToast: !this.state.editToast,
                userAddorUpdateResponse: "User updated successfully!",
                associateEmail: "",
                isEdit: false,
              });

              setTimeout(() => {
                this.setState({ showSpinner: true });
              }, 1000);
              setTimeout(() => {
                this.handleSpinner();
              }, 15000);
            } else {
              this.setState({
                formObj: {
                  ...this.state.formObj,
                  associateEmail: "",
                  password: "",
                  userName: "",
                  userId: "",
                  role: "",
                  homeStore: "",
                  district: "",
                  lastActivity: "",
                  status: "",
                  effectiveDate: "",
                  allowPortalAccess: "",
                },
              });
              this.setState({
                show: false,
                editToast: !this.state.editToast,
                userAddorUpdateResponse: response.errorMessage,
                associateEmail: "",
                isEdit: false,
              });
            }
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        });

      //)
    }
  }
  handleSpinner() {
    this.setState({
      showSpinner: false,
      userAddorUpdateResponse: "",
      editToast: false,
      deleteToast: false,
    });
    this.paginationWithIndex(this.state.pageNo);
  }
  handleSearchDropdown = (e) => {
    this.setState({ isSearchDropDown: e.target.value, filterValue: "" });
  };
  handleChangeSearchDropdown = (e) => {
    this.setState({ filterValue: e.target.value });
    if (e.target.value) {
      if (e.target.value.length > 1) {
        let tokenObj = this.state.tokensList.find((token) => {
          return token.id === this.state.pageNo;
        });
        let obj = {
          filter_Username:
            this.state.isSearchDropDown === "userName" ? e.target.value : "",
          filter_UserId:
            this.state.isSearchDropDown === "userId" ? e.target.value : "",
          filter_Role:
            this.state.isSearchDropDown === "role" ? e.target.value : "",
          filter_Store:
            this.state.isSearchDropDown === "homeStore" ? e.target.value : "",
          filter_District:
            this.state.isSearchDropDown === "district" ? e.target.value : "",
          filter_LastActivity:
            this.state.isSearchDropDown === "lastActivity"
              ? e.target.value
              : "",
          filter_Status:
            this.state.isSearchDropDown === "status" ? e.target.value : "",
          sortBy: "",
          sortOrder: "",
          pageSize: Constants.userPageSize,
          nextPageToken: tokenObj.token,
        };

        trackPromise(
          this.props.actions
            .getUserList(obj)
            .then((response) => {
              if (response.users.length === 0) {
                this.setState({ noDataFound: true });
              }
              if (response) {
                response.users.map((user) => {
                  user.lastActivity =
                    user.lastActivity &&
                    Moment(user.lastActivity).format("MM/DD/YYYY");
                });
                this.setState({
                  userList: response.users,
                  nextPageToken: response.nextPageToken,
                });
              }
            })
            .catch((err) => {
              APIFailedMessage.displayError(err);
            })
        );
      }
    } else {
      this.paginationWithIndex(this.state.pageNo);
    }
  };

  onChange(event) {
    const name = event.target.name;
    if (name === "email") {
      this.setState({
        formObj: { ...this.state.formObj, associateEmail: event.target.value },
      });
    } else if (name === "password") {
      this.setState({
        formObj: { ...this.state.formObj, password: event.target.value },
      });
    } else if (name === "userName") {
      this.setState({
        formObj: { ...this.state.formObj, userName: event.target.value },
      });
    } else if (name === "userId") {
      this.setState({
        formObj: { ...this.state.formObj, userId: event.target.value },
      });
    } else if (name === "role") {
      this.setState({
        formObj: { ...this.state.formObj, role: event.target.value },
      });
    } else if (name === "homeStore") {
      this.setState({
        formObj: { ...this.state.formObj, homeStore: event.target.value },
      });
    } else if (name === "status") {
      this.setState({
        formObj: { ...this.state.formObj, status: event.target.value },
      });
    }
  }

  onDistristSelect(val) {
    this.setState({ formObj: { ...this.state.formObj, district: val } });
    val = val?.map((item) => {
      return item.id;
    });
    if (val) {
      trackPromise(
        this.props.actions
          .getStoresByMultipleDistrictId(val)
          .then((response) => {
            if (response) {
              let storeList = response.data;
              let homeStore = this.state.homeStoreCopy;
              let result = storeList.find((val) => {
                return val.code == homeStore;
              });
              if (result !== undefined) {
                this.setState({
                  formObj: { ...this.state.formObj, homeStore: homeStore },
                });
              }
              this.setState({ storeList: response.data });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    }
  }

  onDistristRemove(val) {
    this.setState({ formObj: { ...this.state.formObj, district: val } });
    val = val?.map((item) => {
      return item.id;
    });
    if (val.length > 0) {
      trackPromise(
        this.props.actions
          .getStoresByMultipleDistrictId(val)
          .then((response) => {
            if (response) {
              let storeList = response.data;
              let homeStore = this.state.homeStoreCopy;
              let result = storeList.find((val) => {
                return val.code == homeStore;
              });
              if (result === undefined) {
                this.setState({
                  formObj: { ...this.state.formObj, homeStore: "" },
                });
              }
              this.setState({ storeList: response.data });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    } else {
      this.setState({ storeList: [] });
    }
  }

  onPortalSelect(val) {
    this.setState({
      formObj: { ...this.state.formObj, allowPortalAccess: val },
    });
  }

  onPortalRemove(val) {
    this.setState({
      formObj: { ...this.state.formObj, allowPortalAccess: val },
    });
  }

  onDOBChange(event) {
    if (event === null) {
      this.setState({
        formObj: { ...this.state.formObj, effectiveDate: "" },
      });
    } else {
      this.setState({
        formObj: {
          ...this.state.formObj,
          effectiveDate: Moment(event).format("MM/DD/YYYY"),
        },
      });
    }
  }
  paginationWithIndex = (page, filters) => {
    let tokenObj = this.state.tokensList.find((token) => {
      return token.id === page;
    });
    let obj = {
      filter_Username: this.state.filterObj.userName,
      filter_UserId: this.state.filterObj.userId,
      filter_Role: this.state.filterObj.role,
      filter_Store: this.state.filterObj.storeCode,
      filter_District: this.state.filterObj.district,
      filter_LastActivity: this.state.filterObj.lastActivity,
      filter_Status: this.state.filterObj.status,
      sortBy: this.state.dataField,
      sortOrder: this.state.order,
      pageSize: Constants.userPageSize,
      nextPageToken: tokenObj.token,
    };
    window.scrollTo(0, 0);
    const prevToken = this.state.nextPageToken;
    this.setState({ prevPageToken: prevToken });
    trackPromise(
      this.props.actions
        .getUserList(obj)
        .then((response) => {
          if (response.users.length === 0) {
            this.setState({ noDataFound: true });
          }
          if (response) {
            response.users.map((user) => {
              user.lastActivity =
                user.lastActivity &&
                Moment(user.lastActivity).format("MM/DD/YYYY");
            });
            this.state.tokensList.filter((item) => {
              if (item.id === page + 1) item.token = response.nextPageToken;
            });
            this.setState({
              userList: response.users,
              nextPageToken: response.nextPageToken,
              pageNo: page,
              totalUsers: response.count
            });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  };
  emailValidate(event) {
    if (this.state.formObj.associateEmail !== "") {
      let formIsValid = true;
      let email = this.state.formObj.associateEmail + "@danielsjewelers.com";
      trackPromise(
        this.props.actions
          .validateUserExists(email)
          .then((response) => {
            let emailErr = "";
            if (response.requestStatus === "error") {
              emailErr = response.errorMessage;
              formIsValid = false;
            } else {
              emailErr = "";
            }
            const emailErrors = {
              emailErr: emailErr,
            };

            const newSelection = { ...this.state.formErrors, ...emailErrors };
            this.setState({ formErrors: newSelection });
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
      return formIsValid;
    }
  }
  deleteUser = (event) => {
    event.preventDefault();
    let userList = this.state.userList;
    trackPromise(
      this.props.actions
        .deleteUser(this.state.selectedRow.associateEmail)
        .then((response) => {
          if (response.requestStatus === "error") {
            this.setState({
              userDeleteResponse: response.errorMessage,
              deleteToast: !this.state.deleteToast,
              isEdit: !this.state.isEdit,
              isModalDeleteOpen: false,
            });
          } else {
            let count = this.state.totalUsers;
            this.setState({
              userDeleteResponse: "User deleted successfully!",
              deleteToast: !this.state.deleteToast,
              isEdit: !this.state.isEdit,
              isModalDeleteOpen: false,
            });
            let userIds = userList.map((user) => {
              return user.id;
            });
            let index = userIds.indexOf(this.state.selectedRow.id);
            userList.splice(index, 1);
            this.setState({
              userList: userList,
              totalUsers: count - 1,
              selectedRow: {},
              associateEmail: "",
            });
            setTimeout(() => {
              this.setState({ showSpinner: true });
            }, 1000);
            setTimeout(() => {
              this.handleSpinner();
            }, 10000);
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  };
  handleDeleteModalPopup = () => {
    this.setState({
      isModalDeleteOpen: false,
      associateEmail: "",
      isEdit: false,
    });
  };
  handleDeleteOpen = () => {
    this.setState({ isModalDeleteOpen: true });
  };

  render() {
    const {
      emailErr,
      passwordErr,
      userNameErr,
      userIdErr,
      roleErr,
      districtErr,
      homeStoreErr,
      statusErr,
      effectiveDateErr,
      portalListErr,
    } = this.state.formErrors;

    let adminMenus = [];
    adminMenus = localStorage.getItem("sitePageDetails");
    adminMenus = JSON.parse(adminMenus);

    const columns = [
      {
        dataField: "displayName",
        text: "User Name",
        sort: true,
        headerClasses: "input_form_control",
        filter: textFilter({
          placeholder: "User Name",
        }),
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
      {
        dataField: "userId",
        text: "User ID",
        //sort: true,
        filter: textFilter({
          placeholder: "User ID",
        }),
        // onSort: (field, order) => {
        //   this.setState({ isSort: true });
        // },
        headerClasses: "input_form_control userId",
      },
      {
        dataField: "roleName",
        text: "Role",
        // sort: true,
        filter: textFilter({
          placeholder: "Role",
        }),
        headerClasses: "input_form_control roleName",
        // onSort: (field, order) => {
        //   this.setState({ isSort: true });
        // },
        type: Number,
      },
      {
        dataField: "storeCode",
        text: "Home Store",
        headerClasses: "input_form_control homeStore",
        //sort: true,
        filter: textFilter({
          placeholder: "Home Store",
        }),
        // onSort: (field, order) => {
        //   this.setState({ isSort: true });
        // },
      },
      {
        dataField: "district",
        text: "District",
        //sort: true,
        headerClasses: "input_form_control",
        filter: textFilter({
          placeholder: "District",
        }),
        // onSort: (field, order) => {
        //   this.setState({ isSort: true });
        // },
      },
      {
        dataField: "lastActivity",
        text: "Last Activity",
        headerClasses: "input_form_control",
        //sort: true,
        filter: textFilter({
          placeholder: "Last Activity",
        }),
        // onSort: (field, order) => {
        //   this.setState({ isSort: true });
        // },
      },
      {
        dataField: "status",
        text: "Status",
        headerClasses: "input_form_control status",
        //sort: true,
        filter: textFilter({
          placeholder: "Status",
        }),
        // onSort: (field, order) => {
        //   this.setState({ isSort: true });
        // },
      },
    ];

    const sortingPage = (page) => {
      let obj = {
        filter_Username: "",
        filter_UserId: "",
        filter_Role: "",
        filter_Store: "",
        filter_District: "",
        filter_LastActivity: "",
        filter_Status: "",
        sortBy: this.state.dataField,
        sortOrder: this.state.order,
        pageSize: Constants.userPageSize,
        nextPageToken: "",
      };
      trackPromise(
        this.props.actions
          .getUserList(obj)
          .then((response) => {
            if (response.users.length === 0) {
              this.setState({ noDataFound: true });
            }
            if (response) {
              response.users.map((user) => {
                user.lastActivity =
                  user.lastActivity &&
                  Moment(user.lastActivity).format("MM/DD/YYYY");
              });
              this.state.tokensList.filter((item) => {
                if (item.id === page + 1) item.token = response.nextPageToken;
              });
              this.setState({ userList: response.users });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    };
    const sortingOnCards = (sortOrder, SortBy) => {
      let obj = {
        filter_Username: "",
        filter_UserId: "",
        filter_Role: "",
        filter_Store: "",
        filter_District: "",
        filter_LastActivity: "",
        filter_Status: "",
        sortBy: SortBy,
        sortOrder: sortOrder,
        pageSize: Constants.userPageSize,
        nextPageToken: "",
      };
      trackPromise(
        this.props.actions
          .getUserList(obj)
          .then((response) => {
            if (response.users.length === 0) {
              this.setState({ noDataFound: true });
            }
            if (response) {
              response.users.map((user) => {
                user.lastActivity =
                  user.lastActivity &&
                  Moment(user.lastActivity).format("MM/DD/YYYY");
              });
              this.state.tokensList.filter((item) => {
                if (item.id === this.state.pageNo + 1)
                  item.token = response.nextPageToken;
              });
              this.setState({ userList: response.users });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    };

    const searchHeader = (filters, page) => {
      let tokenObj = this.state.tokensList.find((token) => {
        return token.id === page;
      });
      let obj = {
        filter_Username: filters?.displayName?.filterVal,
        filter_UserId: filters?.userId?.filterVal,
        filter_Role: filters?.roleName?.filterVal,
        filter_Store: filters?.storeCode?.filterVal,
        filter_District: filters?.district?.filterVal,
        filter_LastActivity: filters?.lastActivity?.filterVal,
        filter_Status: filters?.status?.filterVal,
        sortBy: "",
        sortOrder: "",
        pageSize: Constants.userPageSize,
        nextPageToken: tokenObj.token,
      };
      if (
        !obj.filter_Username &&
        !obj.filter_UserId &&
        !obj.filter_Role &&
        !obj.filter_Store &&
        !obj.filter_District &&
        !obj.filter_LastActivity &&
        !obj.filter_Status
      ) {
        this.paginationWithIndex(this.state.pageNo);
      } else {
        trackPromise(
          this.props.actions
            .getUserList(obj)
            .then((response) => {
              if (response.users.length === 0) {
                this.setState({ noDataFound: true });
              }
              if (response) {
                response.users.map((user) => {
                  user.lastActivity =
                    user.lastActivity &&
                    Moment(user.lastActivity).format("MM/DD/YYYY");
                });
                this.setState({ userList: response.users, totalUsers: response.count });
              }
            })
            .catch((err) => {
              APIFailedMessage.displayError(err);
            })
        );
      }
    };

    const handleTableChange = (
      type,
      { page, sizePerPage, sortField, sortOrder, filters }
    ) => {
      if (type === "pagination") {
        this.setState({ userList: [], isPaginate: true, isSort: false });
        this.paginationWithIndex(page);
        let pagesCount = Math.ceil(this.state.totalUsers / 10);
        if (pagesCount === page) {
          this.setState({ firstAndLast: true });
        } else {
          if (this.state.paginationSize >= 4) {
            this.setState({ pageNo: page, paginationSize: 4 });
          } else {
            this.setState({ pageNo: page, paginationSize: page + 1 });
          }
        }
      } else if (type === "sort") {
        this.setState({ isFirstTime: false });
        if (this.state.isSort && !this.state.isFirstTime) {
          this.setState({ dataField: sortField, order: sortOrder });
          setTimeout(() => {
            sortingPage(page);
          }, 1000);
        }
      } else if (type === "filter") {
          this.setState({
            filterObj: {
              ...this.state.filterObj,
              displayName: filters?.displayName?.filterVal? filters?.displayName?.filterVal: "",
              userId: filters?.userId?.filterVal? filters?.userId?.filterVal: "",
              role: filters?.roleName?.filterVal? filters?.roleName?.filterVal: "",
              storeCode: filters?.storeCode?.filterVal? filters?.storeCode?.filterVal: "",
              district: filters?.district?.filterVal? filters?.district?.filterVal: "",
              lastActivity: filters?.lastActivity?.filterVal? filters?.lastActivity?.filterVal: "",
              status: filters?.status?.filterVal? filters?.status?.filterVal: "",
            },
          });
        if (
          !filters?.displayName &&
          !filters?.userId &&
          !filters?.roleName &&
          !filters?.storeCode &&
          !filters?.district &&
          !filters?.lastActivity &&
          !filters?.status
        ) {
          this.paginationWithIndex(this.state.pageNo);
        } else if (
          filters?.displayName?.filterVal.length > 1 ||
          filters?.userId?.filterVal.length > 1 ||
          filters?.roleName?.filterVal.length > 1 ||
          filters?.district?.filterVal.length > 1 ||
          filters?.lastActivity?.filterVal.length > 1 ||
          filters?.status?.filterVal.length > 1 ||
          filters?.storeCode?.filterVal.length > 1
        ) {
          searchHeader(filters, page);
        } else {
          this.paginationWithIndex(this.state.pageNo);
        }
      }
    };

    const customTotal = (from, to, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total ml-0 mt-3">
          <div className="bg-white pl-2 pr-2 pt-1 pb-1 rounded">
            {from}-{to} of {size}
          </div>
        </span>
      );
    };

    const selectRow = {
      clickToSelect: true,
      onSelect: (row) => {
        this.setState({
          isEdit: true,
          associateEmail: row.associateEmail,
          selectedRow: row,
        });
      },
      onSelectAll: this.handleOnSelectAll,
    };

    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (row.associateEmail === this.state.associateEmail) {
        style.backgroundColor = "#5C068C";
        style.color = "white";
      }

      return style;
    };

    const onValueChange = (e) => {
      if (e.target.value === "asc") {
        this.setState({ order: "asc", pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      } else if (e.target.value === "desc") {
        this.setState({ order: "desc", pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      } else {
        this.setState({ dataField: e.target.value, pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      }
    };

    const CardView = ({ data, handleCardSelect, activeEmail }) => {
      return (
        <>
          <hr className="mt-1 mb-1"></hr>
          <div className="row">
            {data &&
              data.map((val) => (
                <div
                  className="col-sm-12 col-md-6 pt-2 pb-2 d-flex"
                  key={val.associateEmail}
                >
                  <Card
                    className="text-left shadow"
                    onClick={() =>
                      handleCardSelect(val.associateEmail, {
                        associateEmail: val.associateEmail,
                        displayName: val.displayName,
                        district: val.district,
                        effectiveDate:
                          new Date().toJSON().slice(5, 7) +
                          "/" +
                          new Date().toJSON().slice(8, 10) +
                          "/" +
                          new Date().toJSON().slice(0, 4),
                        givenName: null,
                        id: val.id,
                        isActive: false,
                        lastActivity: val.lastActivity,
                        roleName: val.roleName,
                        status: val.status,
                        storeCode: val.storeCode,
                        userId: val.userId,
                      })
                    }
                  >
                    <Card.Body
                      className={`m-3 pt-0 ${
                        val.associateEmail === activeEmail
                          ? "bg-secondary text-primary"
                          : ""
                      }`}
                      style={{ border: "2px solid #1B1B1B" }}
                    >
                      <p className="h3 ml-0 mt-2 mb-0">
                        {val.displayName === null || undefined
                          ? ""
                          : val.displayName}
                      </p>
                      <hr
                        style={
                          val.associateEmail === activeEmail
                            ? { backgroundColor: "#f8f6fa" }
                            : { backgroundColor: "#1B1B1B" }
                        }
                      ></hr>
                      <div className="row">
                        <div className="col-6">User Id :</div>
                        <div className="col-6">{val.userId}</div>
                        <div className="col-6">Role :</div>
                        <div className="col-6">{val.roleName}</div>
                        <div className="col-6">Home Store:</div>
                        <div className="col-6">{val.storeCode}</div>
                        <div className="col-6">District :</div>
                        <div className="col-6">{val.district}</div>
                        <div className="col-6">Last Activity :</div>
                        <div className="col-6">{val.lastActivity}</div>
                        <div className="col-6">Status :</div>
                        <div className="col-6">{val.status}</div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </>
      );
    };

    return (
      <>
        <div
          className={` ${
            this.state.show ? "printSummaryPanelShow" : "printSummaryPanelHide"
          }`}
        >
          <div className="printSummaryPanel">
            <div className="container-fluid pt-4">
              <div className="row">
                <div className="col-12 pl-4 pr-4">
                  <p>
                    <span className="h5">
                      <b> {this.state.displayText}</b>
                    </span>
                    <span
                      className="btn closeIcon"
                      onClick={() => {
                        this.cancel();
                      }}
                    >
                      <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                    </span>
                  </p>
                  <form>
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary">
                        <label>Email</label>
                      </div>
                      <div
                        className="col-12 col-md-6 col-lg-6 pt-2"
                        style={{ marginRight: "-30px" }}
                      >
                        <input
                          type="text"
                          className="form-control border-dark "
                          name="email"
                          id="email"
                          placeholder="Email"
                          value={this.state.formObj.associateEmail}
                          disabled={this.state.isEdit ? true : false}
                          onChange={(event) => this.onChange(event)}
                          onBlur={(event) => this.emailValidate(event)}
                        ></input>
                        {!this.state.formObj.id ? (
                          <span
                            className=" m-0 pl-0"
                            style={{ wordBreak: "break-word" }}
                          >
                            @danielsjewelers.com
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                        {!this.state.formObj.id && (
                          <span className="text-danger">
                            {emailErr && emailErr}
                          </span>
                        )}
                      </div>
                    </div>
                    {!this.state.isEdit && (
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary">
                          <label>Password</label>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 pt-2">
                          <input
                            className="form-control border-dark"
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            onChange={(event) => this.onChange(event)}
                          ></input>
                        </div>
                        <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                          <span className="text-danger">
                            {passwordErr && passwordErr}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                        <label>UserName</label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 pt-2">
                        <input
                          className="form-control border-dark"
                          type="text"
                          name="userName"
                          id="userName"
                          placeholder="UserName"
                          disabled={this.state.isEdit ? true : false}
                          onChange={(event) => this.onChange(event)}
                          value={this.state.formObj.userName}
                        ></input>
                      </div>
                      <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                        <span className="text-danger">
                          {userNameErr && userNameErr}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                        <label>UserId</label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 pt-2">
                        <input
                          className="form-control border-dark"
                          type="text"
                          name="userId"
                          id="userId"
                          placeholder="UserId"
                          onChange={(event) => this.onChange(event)}
                          value={this.state.formObj.userId}
                        ></input>
                      </div>
                      <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                        <span className="text-danger">
                          {userIdErr && userIdErr}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                        <label>Role</label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 pt-2">
                        <select
                          name="role"
                          id="role"
                          placeholder="Role"
                          className="form-control border-dark drp"
                          onChange={(event) => this.onChange(event)}
                          value={this.state.formObj.role?.toLocaleLowerCase()}
                        >
                          <option value="0">Select</option>
                          {this.state.rolesList.map((item) => {
                            return (
                              <option value={item.name.toLocaleLowerCase()}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                        <span className="text-danger">
                          {roleErr && roleErr}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                        <label>District</label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 pt-2">
                        <Multiselect
                          name="district"
                          id="district"
                          placeholder="District"
                          options={this.state.districtList}
                          onSelect={(event) => this.onDistristSelect(event)}
                          onRemove={(event) => this.onDistristRemove(event)}
                          displayValue="name"
                          selectedValues={this.state.formObj.district}
                        />
                        {/* <select
                          name="district"
                          id="district"
                          placeholder="District"
                          className="form-control border-dark drp"
                          onChange={(event) => this.onChange(event)}
                          value={this.state.formObj.district?.toUpperCase()}
                        >
                          <option value="0">Select</option>
                          {this.state.districtList.map((item) => {
                            return <option  value={item.name}>{item.name}</option>;
                          })}
                        </select> */}
                      </div>
                      <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                        <span className="text-danger">
                          {districtErr && districtErr}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                        <label>Home Store</label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 pt-2">
                        <select
                          name="homeStore"
                          id="homeStore"
                          placeholder="Home Store (default from store)"
                          className="form-control border-dark drp"
                          onChange={(event) => this.onChange(event)}
                          value={this.state.formObj.homeStore}
                        >
                          <option value="0">Select</option>
                          {this.state.storeList.map((item) => {
                            return (
                              <option value={item.code}>{item.code}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                        <span className="text-danger">
                          {homeStoreErr && homeStoreErr}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                        <label>Status</label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 pt-2">
                        <select
                          name="status"
                          id="status"
                          placeholder="Status"
                          className="form-control border-dark drp"
                          onChange={(event) => this.onChange(event)}
                          value={this.state.formObj.status}
                        >
                          <option value="0">Select</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </div>
                      <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                        <span className="text-danger">
                          {statusErr && statusErr}
                        </span>
                      </div>
                    </div>
                    {this.state.isEdit && (
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                          <label>Effective Date</label>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 pt-2">
                          <DatePicker
                            className="form-control border-dark drp"
                            name="effectiveDate"
                            id="effectiveDate"
                            placeholderText="Effective Date"
                            selected={
                              this.state.formObj.effectiveDate
                                ? new Date(this.state.formObj.effectiveDate)
                                : null
                            }
                            onChange={(event) => this.onDOBChange(event)}
                            dateFormat="MM/dd/yyyy"
                            peekNextMonth
                            minDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={<IMaskInput mask="00/00/0000" />}
                          ></DatePicker>
                        </div>
                        <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                          <span className="text-danger">
                            {effectiveDateErr && effectiveDateErr}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                        <label>Portal Access</label>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 pt-2">
                        <Multiselect
                          name="portalAccess"
                          id="portalAccess"
                          placeholder="Portal Access"
                          options={this.state.portalList}
                          onSelect={(event) => this.onPortalSelect(event)}
                          onRemove={(event) => this.onPortalRemove(event)}
                          displayValue="description"
                          selectedValues={this.state.formObj.allowPortalAccess}
                        />
                      </div>
                      <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                        <span className="text-danger">
                          {portalListErr && portalListErr}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-4 mb-4">
                        <div className="row">
                          <div className="col-12 col-md-4 col-lg-4">
                            <button
                              type="button"
                              className="btn bg-primary text-secondary w-100 mb-3 mb-md-0 mb-lg-0"
                              onClick={() => {
                                this.cancel();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                          <div className="col-12 col-md-4 col-lg-4">
                            <button
                              className="btn bg-secondary text-primary w-100"
                              onClick={(event) => {
                                this.saveUser(event);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`app ${this.state.toggled ? "toggled" : ""}`}>
          <Sidebar
            toggled={this.state.toggled}
            handleToggleSidebar={() => {
              this.handleToggleSidebar();
            }}
            isAdmin={true}
            adminMenus={adminMenus}
          />
          {this.state.showSpinner ? (
            <div
              style={{
                flexDirection: "column",
              }}
              className="d-flex justify-content-center align-items-center w-100"
            >
              <Loader
                type="ThreeDots"
                color="#5C068C"
                height="100"
                width="100"
              />
            </div>
          ) : (
            <main>
              <CustomNavbar
                handleToggleSidebar={() => {
                  this.handleToggleSidebar();
                }}
                isAdmin={true}
              />
              <ToastContainer className="p-3" position="top-end">
                <Toast
                  bg="success"
                  onClose={() => {
                    this.setState({ editToast: !this.state.editToast });
                  }}
                  show={this.state.editToast}
                  delay={3000}
                  animation
                  autohide
                >
                  <Toast.Body className="text-white ml-2 mr-2">
                    {this.state.userAddorUpdateResponse}
                  </Toast.Body>
                </Toast>
              </ToastContainer>
              <ToastContainer className="p-3" position="top-end">
                <Toast
                  bg="success"
                  onClose={() => {
                    this.setState({ deleteToast: !this.state.deleteToast });
                  }}
                  show={this.state.deleteToast}
                  delay={3000}
                  animation
                  autohide
                >
                  <Toast.Body className="text-white ml-2 mr-2">
                    {this.state.userDeleteResponse}
                  </Toast.Body>
                </Toast>
              </ToastContainer>
              <div id="main" className="container-fluid mt-3">
                <div className="row mb-2 mt-3 pl-4 pr-4">
                  <div className=" col-6 d-flex justify-content-left">
                    <h4>
                      <b> User List</b>
                    </h4>
                  </div>
                </div>
                <div className="row m-1 mr-1 mb-2">
                  <div className="row m-0 pl-1 pr-1 mb-2 w-100">
                    {!this.state.permission_status?.canAdd &&
                    !this.state.permission_status.canEdit &&
                    !this.state.permission_status.canDelete ? null : (
                      <div className="col-12 command_bar">
                        {this.state.permission_status?.canEdit ? (
                          <button
                            className={
                              this.state.isEdit
                                ? "edit_button"
                                : "edit_button_unselect"
                            }
                            disabled={this.state.isEdit ? false : true}
                            onClick={() =>
                              this.handleEditUserToggle("Edit User")
                            }
                          >
                            <FontAwesomeIcon icon={faEdit} className="mr-1" />
                            Edit
                          </button>
                        ) : null}
                        {this.state.permission_status?.canDelete ? (
                          <button
                            className={
                              this.state.isEdit
                                ? "edit_button"
                                : "edit_button_unselect"
                            }
                            disabled={this.state.isEdit ? false : true}
                            onClick={() => this.handleDeleteOpen()}
                          >
                            <FontAwesomeIcon icon={faTrash} className="mr-1" />
                            Delete
                          </button>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-2 pl-1 pr-1">
                  <div className="col-12">
                    <>
                      <div id="cardrow">
                        <p className="h4 text-secondary mb-0 mt-3 ml-2">
                          Sort By
                        </p>
                        <div className="row pl-1 pr-3">
                          <div className="col-6 mt-3 mb-2">
                            <select
                              value={this.state.dataField}
                              className="form-select pt-2 pb-2 drp"
                              onChange={(e) => onValueChange(e)}
                            >
                              <option value="userName">UserName</option>
                            </select>
                          </div>
                          <div className="col-3 mt-3 mb-2">
                            <select
                              value={this.state.order}
                              className="form-select pt-2 pb-2 drp"
                              onChange={(e) => onValueChange(e)}
                            >
                              <option value="asc">ASC</option>
                              <option value="desc">DESC</option>
                            </select>
                          </div>
                        </div>
                        <p className="h4 text-secondary mb-0 mt-3 ml-2">
                          Filter By
                        </p>
                        <div className="row pl-1 pr-3">
                          <div className="col-6 mt-3 mb-2">
                            <select
                              value={this.state.isSearchDropDown}
                              className="form-select pt-2 pb-2 drp"
                              onChange={(e) => this.handleSearchDropdown(e)}
                            >
                              <option value="userName">UserName</option>
                              <option value="userId">User ID</option>
                              <option value="role">Role</option>
                              <option value="homeStore">Home Store</option>
                              <option value="district">District</option>
                              <option value="lastActivity">
                                Last Activity
                              </option>
                              <option value="status">Status</option>
                            </select>
                          </div>
                          <div className="col-6 mt-3 mb-2 form-group text-white mt-md-2 input_width ">
                            <form className="form_pad">
                              {this.state.isSearchDropDown === "status" ? (
                                <select
                                  value={this.state.filterValue}
                                  name="status"
                                  className="form-select pt-2 pb-2"
                                  style={{ width: "100%" }}
                                  onChange={(e) =>
                                    this.handleChangeSearchDropdown(e)
                                  }
                                >
                                  <option value="0">Select</option>
                                  <option value="Active">Active</option>
                                  <option value="InActive">InActive</option>
                                </select>
                              ) : (
                                <input
                                  type="text"
                                  className="form-control bg-secondary text-white text_box_style"
                                  placeholder={this.state.isSearchDropDown}
                                  value={this.state.filterValue}
                                  onChange={(e) =>
                                    this.handleChangeSearchDropdown(e)
                                  }
                                ></input>
                              )}
                            </form>
                          </div>
                        </div>
                        <CardView
                          data={this.state.userList}
                          handleCardSelect={this.handleCardSelect}
                          activeEmail={this.state.associateEmail}
                        />
                      </div>
                      <RemotePagination
                        data={this.state.userList}
                        page={this.state.pageNo}
                        from={this.state.from}
                        to={this.state.to}
                        sizePerPage={10}
                        firstPageText="<<"
                        totalSize={this.state.totalUsers}
                        onTableChange={handleTableChange}
                        dataField={this.state.dataField}
                        order={this.state.order}
                        columns={columns}
                        customTotal={customTotal}
                        selectRow={selectRow}
                        rowStyle={rowStyle}
                        paginationSize={this.state.paginationSize}
                        firstAndLast={this.state.firstAndLast}
                      />
                    </>
                    <>
                      <div
                        id="matchnotfound"
                        className="text-center mw-100 d-flex justify-content-center align-items-center"
                      >
                        {this.state.userList.length === 0 &&
                        !this.state.isPaginate ? (
                          <p className="mb-0 pt-0 text-secondary font-weight-bold">
                            No data found
                          </p>
                        ) : this.state.isPaginate ? (
                          <p className="mb-0 pt-0 text-secondary"></p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </main>
          )}
        </div>
        <ConfirmDialog
          show={this.state.isModalDeleteOpen}
          handleModalPopup={this.handleDeleteModalPopup}
          message={"<p>Are you sure you want to delete?</p>"}
          button1_name="Cancel"
          button1={this.handleDeleteModalPopup}
          button2_name="Delete"
          button2={this.deleteUser}
        />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(adminUserListActions, dispatch),
    userRightsactions: bindActionCreators(userRightsActions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(AdminUserList);
