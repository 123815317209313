import React,{Component} from 'react';
import './component.applicationHistory.css';
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import IdleTimeModal from "../../components/component.idleTimeModal";
import Moment from 'moment';
import {trackPromise} from 'react-promise-tracker';
import APIFailedMessage from "../../components/component.apiFailedMessage";
import Constants from '../../utilites/constants';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as creditApplication from "../../redux/actions/creditApplication.actions";
import RemotePagination from '../../components/component.applicationHistoryTable';
import * as customerDetailsActions from "../../redux/actions/customerDetails.actions";
import CreditAppView from '../../components/component.creditappView';
import * as applyNowActions from "../../redux/actions/applyNow.actions";
import Loader from 'react-loader-spinner';
import CardView from "../../components/component.creditAppMobileView";
import PrintSummaryView from './component.printSummaryView';
import * as synchronyActions from "../../redux/actions/synchronyLookup.actions";
import formatPhoneNumber from "../../components/component.formatPhoneNumber";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FortivaOffersStatus, SynchronyOffersStatus, ProgressiveOffersStatus, UOwnOffersStatus } from "../../components/component.applicationStatus";
import GetInfo from '../../components/component.getInfo';
import * as accountSummaryAction from "../../redux/actions/accountSummary.actions";

class ApplicationHistory extends Component{
    constructor() {
        super();
        this.state = {
          toggled: false,
          show: false,
          creditAppList: [],
          creditId: "",
          isLoad: false,
          isPaginate: false,
          noDataFound: false,
          isSort: false,
          pageNo: 1,
          from: 1,
          to: Constants.ApplicationHistorySize,
          isFirstTime: false,
          dataField: "modifiedDate",
          order: "desc",
          filterObj: {
            idNumber: "",
            creditId: "",
            password: "",
            userName: "",
            userId: "",
            role: "",
            homeStore: "",
            district: "",
            lastActivity: "",
            status: "",
            modifiedDate: "",
            mobileNo: "",
          },
          totalCreditApps: undefined,
          roles: [],
          site_page_details: [],
          sitePages: [],
          userRights: [],
          permission_status: "",
          showSpinner: false,
          isSearchDropDown: "firstName",
          filterValue: "",
          printsummaryShow:false,
          printSummarymobileVerificationExpand:false,
          formObj: {
            credit_Id: 0,
            storeCode: "",
            suffix: "",
            firstName: "",
            middleName: "",
            lastName: "",
            mailingAdr: "",
            emailAdr: "",
            aptUnit: "",
            dob: "",
            city: "",
            mobileNo: "",
            state: "",
            secPhone: "",
            zipCode: "",
            empName: "",
            empStartDate: "",
            empPhoneNo: "",
            netIncome: "",
            duties: "",
            housingInfo: "",
            ssn: "",
            primaryIdType: "",
            primaryIdNo: "",
            primaryIdState: "",
            relativeName: "",
            relativePhone: "",
            relativeRelation: "",
            verificationCode: "",
            oftenPaid: "",
            cc: "",
            lastPayDay: "",
            ccExpiryDate: "",
            nextPayDay: "",
            ccName: "",
            primarySourceIncome: "",
            creditCardBillingAddress: false,
            ccmailingAdr: "",
            ccaptUnit: "",
            cccity: "",
            ccstate: "",
            cczipCode: "",
            bankRouteNo: "",
            checkAccNo: "",
            autopay: "",
            bankAccountDuration: "",
            associateEmployeeNo: "",
            associatePassword: "",
            primaryIdExpiryDate: "",
            primaryIdNumber: "",
            secondIdType: "",
            secondIdIssuedBy: "",
            secondIdExpiryDate: "",
            rdApply: "DS",
            lstSuffix: [],
            stateList: [],
            lstCCState: [],
            lstHousingInfo: [],
            lstPrimaryIdType: [],
            lstPrimaryIdState: [],
            lstOftenPaid: [],
            lstPrimarySourceIncome: [],
            lstBankAccountDuration: [],
            lstSecondIdType: [],
            lstStoreCode: [],
            mobileNoVerified: "",
            smsCodeSent: "",
            smsOverride: "",
            isSynchrony:false
          },
          printSummaryformObj: {
            account_Id:"",
            storeCode:"",
            title: "",
            borrowerName: "",
            borrowerSuffix:"",
            address: "",
            apt: "",
            city: "",
            state: "",
            zipCode: "",
            mobileNo: "",
            emailAdr: "",
            secPhone:"",
            dob: "",
            ssn:"",
            idNumber:"",
            empName: "",
            empPhoneNo: "",
            duties: "",
            monthlyIncome: "",
            empStartDate: "",
            cborrowerTitle:"",
            coborrowerName:"",
            coborrowerSuffix:"",
            coborrowermobileNo:"",
            coborroweremailAddress:"",
            coborrowersecondaryPhone:"",
            coborrowerbirthDate:"",
            coborrowerssn:"",
            coborroweridNumber:"",
            coborrowerempName:"",
            coborrowerempPhone:"",
            coborrowerworkJob:"",
            coborrowermonthlyIncome:"",
            coborrowerhowLong:"",
            mobileNoVerified: false,
            smsCodeSent: false,
            smsOverride: false,
            printAtMyStore:false,
            sendToCredit:false,
            sendToMagixAccHomeStore: false,
            createdDate:"",
            comments:""
          },
          summaryCreatedDate:"",
          cusPreApproval: [],
          mobileVerificationExpand: true,
          psInformationExpand: false,
          residenceExpand: false,
          employmentExpand: false,
          identificationExpand: false,
          synchronyExpand: false,
          progressiveExpand: false,
          uownExpand: false,
          autopayRollExpand: false,
          estatementExpand: false,
          getCodeMaster: {
            suffix: [],
            house_info: [],
            state: [],
            p_id_type: [],
            s_id_type: [],
          },
          applicationId: "",
          customerId: "",
          psBorrowerExpand:true,
          psCo_BorrowerExpand:false,
          customerPreApprove:false,
          customerPreApproveSummary:false,
          isCrediappView:false,
          isprintSummaryView:false,
          printSummaryId:"",
          custAcctStat:"",
          borrowerInfo: {
            title_code:"",
            account: "",
            firstName: "",
            middleName: "",
            lastName: "",
            suffix_Id:"",
            mailingAdr: "",
            city: "",
            state: "0",
            zipCode: "",
            mobileNo: "",
            emailAdr: "",
            empName: "",
            empPhone: "",
            duties: "",
            empStartDate: "",
            netIncome: "",
            ssn: "",
            dob: "",
            primaryIdNo: "",
            state_Id:""
        },
        coBorrowerInfo: {
          title_code: "",
          secondaryPhone: "",
          birthDate: "",
          suffix_Id: "",
          masked_ssn: "",
          masked_idNumber: "",
          emailAddress: "",
          workJob: "",
          monthlyIncome: "",
          howLong: "",
          account: "",
          firstName: "",
          middleName: "",
          lastName: "",
          mailingAdr: "",
          city: "",
          state: "",
          zipCode: "",
          mobileNo: "",
          emailAdr: "",
          empName: "",
          empPhone: "",
          duties: "",
          empStartDate: "",
          netIncome: "",
          ssn: "",
          dob: "",
          primaryIdNo: "",
        },
        stateList_credit:[],
        synchronyOffers:undefined,
        double_data: {
          click_id: null,
          click_no: 0,
        },
        subTableData : [],
        isDaniel:false,
        danielsStatus:undefined,
        noneExpandRowIndex : [],
        };
      }
    componentDidMount() {
        if (sessionStorage.getItem("isLoggedIn") === "true") {

             // get code master
            this.getCodeMasterDetails("Suffix");
            this.getCodeMasterDetails("HousingInfo");
            this.getCodeMasterDetails("PrimaryIdType");
            this.getCodeMasterDetails("State");
            this.getCodeMasterDetails("State_Credit");
            this.getCodeMasterDetails("OftenPaid");
            this.getCodeMasterDetails("SecondIdType");
            let page = 1;
            let obj = {
                        name: "",
                        address: "",
                        phone: "",
                        idNumber: "",
                        ssn: "",
                        store: "",
                        account: "",
                        applicationDate: "",
                        customerID:"",
                        pageIndex: 1,
                        pageSize: Constants.ApplicationHistorySize,
                        sortBy: "modifiedDate",
                        sortOrder: "desc",
                        magix_Id: "",
                        magixStoreCode: ""
                      };
            if (localStorage.getItem("applicationId") !== "null") {
              obj.customerID=localStorage.getItem("applicationId");
              this.getCreditList(obj,page);
            }
            else{
              let obj={
                        magix_Id: localStorage.getItem("magixassociateId"),
                        printSummary_Id: "0",
                        pageIndex: 1,
                        pageSize: Constants.ApplicationHistorySize,
                        sortBy: "modifiedDate",
                        sortOrder: "desc",
                        }
              this.getMagixData(obj,page);
            }
          
        } else this.props.history.push("/");
    }

    loadViewOffers(obj) {
      this.props.creditApplication.getApplicationViewOffers(GetInfo.getCustomerId(),obj).then(
          response => {
            let isFortiva = response?.data?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" })[0];
            if(isFortiva) {
              trackPromise(
                this.props.accountSummaryAction.getFortivaActSummmary(GetInfo.getCustomerId(), obj).then(
                    res => {
                        response.data.map(item => {
                          if(item.fpType?.toLowerCase() === "fortiva") {
                            item.openToBuy = res.openToBuy;
                          }
                          return item;
                        });
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
            }
            this.setState({ cusPreApproval: response.data });
          }).catch(err => {
              APIFailedMessage.displayError(err);
          })
    }
    
    paginationWithIndex = (page, filters, type) => {
        let obj;
        if (localStorage.getItem("applicationId") !== "null") {
          obj = {
              name: filters?.firstName?.filterVal ? filters?.firstName?.filterVal : this.state.filterObj?.firstName,
              address: filters?.address?.filterVal ? filters?.address?.filterVal  : this.state.filterObj?.address,
              phone: filters?.mobileNo?.filterVal ? filters?.mobileNo?.filterVal  : this.state.filterObj?.mobileNo,
              idNumber: filters?.idNumber?.filterVal ? filters?.idNumber?.filterVal : this.state.filterObj?.idNumber,
              ssn: filters?.ssn?.filterVal ? filters?.ssn?.filterVal: this.state.filterObj?.ssn,
              store: filters?.store_code?.filterVal ? filters?.store_code?.filterVal : this.state.filterObj?.store_code,
              account: filters?.account?.filterVal ? filters?.account?.filterVal : this.state.filterObj?.account,
              applicationDate: filters?.modifiedDate?.filterVal ? filters?.modifiedDate?.filterVal : this.state.filterObj?.modifiedDate,
              pageIndex: page,
              customerID:localStorage.getItem("applicationId") !== "null"?localStorage.getItem("applicationId"):"",
              pageSize: Constants.ApplicationHistorySize,
              sortBy: this.state.dataField === "firstName" ? "name" : this.state.dataField,
              sortOrder: this.state.order,
              magix_Id: "",
              magixStoreCode: ""
            };
        }
        else{
          obj = {
            name: filters?.firstName?.filterVal ? filters?.firstName?.filterVal : this.state.filterObj?.firstName,
            address: filters?.address?.filterVal ? filters?.address?.filterVal  : this.state.filterObj?.address,
            phone: filters?.mobileNo?.filterVal ? filters?.mobileNo?.filterVal  : this.state.filterObj?.mobileNo,
            idNumber: filters?.idNumber?.filterVal ? filters?.idNumber?.filterVal : this.state.filterObj?.idNumber,
            ssn: filters?.ssn?.filterVal ? filters?.ssn?.filterVal: this.state.filterObj?.ssn,
            store: filters?.store_code?.filterVal ? filters?.store_code?.filterVal : this.state.filterObj?.store_code,
            account: filters?.account?.filterVal ? filters?.account?.filterVal : this.state.filterObj?.account,
            applicationDate: filters?.modifiedDate?.filterVal ? filters?.modifiedDate?.filterVal : this.state.filterObj?.modifiedDate,
            pageIndex: page,
            customerID:"",
            pageSize: Constants.ApplicationHistorySize,
            sortBy: this.state.dataField === "firstName" ? "name" : this.state.dataField,
            sortOrder: this.state.order,
            magix_Id: localStorage.getItem("magixassociateId"),
            magixStoreCode: localStorage.getItem('storeCode')
          };
        }
        window.scrollTo(0, 0);
        this.getCreditList(obj,page);
    };
    prefixChanger = async (value, last_count, type) => {
        let final_value;
        if (type === "idNumber") {
          let data = value.slice(value.length - last_count);
          final_value = "XXX" + data;
        } else if (type === "ssn") {
          let data = value.slice(value.length - last_count);
          final_value = "XXXX-XX-" + data;
        } else if (type === "credit") {
          let data = value.slice(value.length - last_count);
          final_value = "XXXX-" + data;
        } else if (type === "checkaccount") {
          let data = value.slice(value.length - last_count);
          final_value = "XXXXX" + data;
        }
        return final_value;
    };
    getCodeMasterDetails = async (type) => {
    trackPromise(
        this.props.actions.getCodeMaster({ type: type }).then((response) => {
        if (response.totalCount > 0) {
            if (type === "Suffix")
            this.setCodeMasterState("suffix", response.data);
            if (type === "HousingInfo")
            this.setCodeMasterState("house_info", response.data);
            else if (type === "State") {
              this.setState({ stateList: response.data });
            }
            if (type === "OftenPaid")
            this.setCodeMasterState("oftern_paid", response.data);
            if (type === "PrimaryIdType")
            this.setCodeMasterState("p_id_type", response.data);
            if (type === "SecondIdType")
            this.setCodeMasterState("s_id_type", response.data);
            if (type === "State_Credit") {
              this.setState({stateList_credit : response.data});
          }
        }
        })
    );
    };
    
    setCodeMasterState = (type, data) => {
    this.setState({
        getCodeMaster: { ...this.state.getCodeMaster, [type]: data },
    });
    }; 

    getCreditList(obj,page){
        trackPromise(
            this.props.creditApplication
                .getCreditApplication(obj)
                .then((response) => {
                if (response.data.length === 0) {
                    this.setState({ noDataFound: true});
                }
                if (response) {
                    response.data.map((item,index) => {
                      if(item.mobileNo!=null){
                          if(!item.mobileNo.includes("-")){
                            item.mobileNo = formatPhoneNumber(item.mobileNo)
                        }
                      }
                    item.name =
                        (item.firstName == null ? "" : item.firstName + " ") +
                        (item.middleName == null ? "" : item.middleName + " ") +
                        (item.lastName == null ? "" : item.lastName);
                    item.customerName=(item.firstName == null ? "" : item.firstName + " ") +
                                      (item.middleName == null ? "" : item.middleName + " ") +
                                      (item.lastName == null ? "" : item.lastName);
                    item.modifiedDate =
                        item.modifiedDate &&
                        Moment(item.modifiedDate).format("MM/DD/YYYY");
                        item.createdDateDisplay = Moment(item.createdDate).format("MM/DD/YYYY");
                        let data=item.modifiedBy_Associate;
                        item.associateId=data.split("@")[0];
                        if(item.responseSource==="application"){
                          item.applicationType=item.application_Type;
                          item.applicationId=item.application_id;
                        }
                        else{
                          item.applicationType="Print Summary";
                          this.setState({noneExpandRowIndex : [...this.state.noneExpandRowIndex , ('P'+item?.application_id)]});
                          item.applicationId=item.customer_Id;
                        }
                    });
                    this.setState({
                        creditAppList: response.data,
                        pageNo: page,
                        totalCreditApps: response.totalCount,
                      });
                }
                })
                .catch((err) => {
                APIFailedMessage.displayError(err);
                })
            );
    }
    getMagixData(obj,page){
      trackPromise(
          this.props.creditApplication.getPrintSummaryMagix(obj)
              .then((response) => {
              if (response.data.length === 0) {
                  this.setState({ noDataFound: true});
              }
              if (response) {
                  response.data.map((item,index) => {
                  item.customerName = item.borrowerName;
                   item.account= item.magix_Id;
                   item.store_code=item.magixStoreCode;
                   item.custAcctStat=item.id;
                   item.createdDateDisplay = Moment(item.modifiedDate).format("MM/DD/YYYY");
                      let data=item.modifiedBy_Associate;
                      item.associateId=data.split("@")[0];
                      if(item.responseSource==="application"){
                        item.applicationType="Credit Application";
                      }
                      else{
                        item.applicationType="Print Summary";
                        this.setState({noneExpandRowIndex : [...this.state.noneExpandRowIndex , (item?.id)]});
                      }
                  });
                  this.setState({
                      creditAppList: response.data,
                      pageNo: page,
                      totalCreditApps: response.totalCount,
                    });
              }
              })
              .catch((err) => {
              APIFailedMessage.displayError(err);
              })
          );
  }
    
    handleToggleSidebar() {
        this.setState({
            toggled: !this.state.toggled
        });
    };
    
    handleCardSelect = (application_id,creditId, customer_Id,responseSource,magixStoreCode,magixId,isDaniel,createdDate,modifiedDate) => {
      if(responseSource==="application"){
            this.setState({ isCrediappView:true});
      }
      else{
        if(localStorage.getItem("applicationId") !== "null"){
          this.setState({  isprintSummaryView:true, printSummaryId:creditId,custAcctStat:creditId,
            summaryCreatedDate: createdDate}); 
        }
        else{
          this.setState({  isprintSummaryView:true, printSummaryId:creditId,custAcctStat:creditId,
            summaryCreatedDate: modifiedDate}); 
        }       
      }
      this.setState({ creditId: creditId, applicationId: application_id,customerId: customer_Id});     
      
      this.doubleClickOnCards(creditId);
      if (this.state.double_data.click_no === 1 && this.state.double_data.click_id === creditId) {
        this.setState({ double_data: { click_id: null, click_no: 0,show:true } });
        if (localStorage.getItem("applicationId") !== "null") {
          this.handleViewCreditDetials();
        }
        else{
          let row={
            custAcctStat:creditId,
            magix_Id:magixId,
            magixStoreCode:magixStoreCode,
        };
         this.handleViewMagixCredentials(row);
        }
      }
      this.setState({
        creditId: creditId,
        applicationId: application_id,
        customerId: customer_Id,
        isDaniel:isDaniel,
      });
    };

    currencyFormatter(amt) {
      let formatting_options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 3,
     }
     return amt?.toLocaleString("en-US",formatting_options)?.split('.')[0];
    }

    statusMessage(row) {
    }

    handleSubCardSelect = async(row) => {
      if(row.applicationType === "InStore Application" || row.applicationType === "PreQualification" || row.applicationType === "Online Application") {
      if(this.state.creditId == row.application_id) {
        this.setState({subCardtoggle : !this.state.subCardtoggle, subTableData: [], creditId : ""});
      }
      else {
        let synchronyCDResponse = {};
        if(row.isSynchronyCD) {
          let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
          await trackPromise(
                this.props.customerDetailsActions.getSynchronyViewOffers(GetInfo.getCustomerId(),row.application_id,homeStore).then(
                   response => {
                       synchronyCDResponse= response;     
                   }).catch(err => {
                       APIFailedMessage.displayError(err);
                   })
           )
        }
        trackPromise(
        this.props.creditApplication.getApplicationViewOffers(GetInfo.getCustomerId(),row.application_id).then(
          response => {
            if (response) {
              if (response?.length === 0) {
                this.setState({ noDataFound: true });
              } else {
                if(row.isSynchronyCD) {
                  response?.data?.map((row, index) => {
                    if(row.fpType?.toLowerCase() === "synchrony") {
                      row.statusDescription = synchronyCDResponse?.status
                      row.applicationId = synchronyCDResponse?.applicationId
                      row.approvalLimit = synchronyCDResponse?.approvalLimit
                    }
                  });
                }
                else {
                  response?.data?.map((row, index) => {
                    if(row.fpType?.toLowerCase() === "synchrony") {
                      row.statusDescription = "Applied"
                    }
                  });
                }
                if(row.isFortiva) {
                  response?.data?.map((row, index) => {
                    if(row.fpType?.toLowerCase() === "fortiva") {
                      if(row.applicationStatus?.toLowerCase() === "a")
                        row.statusDescription = "Approved"
                      else if (row.applicationStatus?.toLowerCase() === "d" || row.applicationStatus?.toLowerCase() === "ec" || row.applicationStatus?.toLowerCase() === "cr" || row.applicationStatus?.toLowerCase() === "r") 
                        row.statusDescription = "Declined"
                      else if(row.applicationStatus?.toLowerCase() === "p")
                        row.statusDescription = "Error 1"
                      else if(row.applicationStatus?.toLowerCase() === "ca")
                        row.statusDescription = "Error 2"
                      else if(row.applicationStatus?.toLowerCase() === "an")
                        row.statusDescription = "Error 3"
                    }
                  })
                }
                if(row.isProgressive) {
                  response?.data?.map((row, index) => {
                    if(row.fpType?.toLowerCase() === "progressive") {
                      if(row.status !== "") {
                        row.statusDescription = row.status
                      }
                      else if(row.errorMessage?.length > 0) {
                        row.statusDescription = "Error"
                      }
                    }
                  })
                }
                if(row.isUown) {
                  response?.data?.map((row, index) => {
                    if(row.fpType?.toLowerCase() === "uown") {
                      if(row.status?.toLowerCase() === "e0")
                        row.statusDescription = "Approved"
                      else if(row.status?.toLowerCase() === "e1")
                        row.statusDescription = "Pending"
                      else if(row.status?.toLowerCase() === "e4")
                        row.statusDescription = "Declined"
                      else if(row.status?.toLowerCase() === "e3")
                        row.statusDescription = "Error"
                    }
                  })
                }
                this.setState({
                  subTableData: response?.data,
                  subCardtoggle : !this.state.subCardtoggle,
                  creditId : row.application_id
                });
                if(row.isDaniel == true) {
                  let obj = {
                    "accountNumber": "",
                    "applicationId": "",
                    "merchantCustomerId": "",
                    "approvalLimit": 0,
                    "status": "",
                    "message": "",
                    "errorMessage": "",
                    "providerURL": "",
                    "authorizationNumber": "",
                    "fieldInError1": "",
                    "fieldInError2": "",
                    "fieldInError3": "",
                    "fieldInError4": "",
                    "fieldInError5": "",
                    "submittedDate": "0001-01-01T00:00:00",
                    "approval_status": null,
                    "account_Balance": null,
                    "statusDescription": "Applied",
                    "createdDate": row.createdDate,
                    "leaseId": null,
                    "fpType": "Daniels",
                    "apr": "",
                    "minPaymentAmt": "",
                    "annualFee": "",
                    "duration": "",
                    "latePaymentFee": "",
                    "returnedPaymentFee": "",
                    "periodicRate": "",
                    "minPaymentPct": "",
                    "offerId": "",
                    "downPaymentPct": "",
                    "penaltyAPR": 0,
                    "transactionFee": 0,
                    "autoDeductStatus": "",
                    "applicationStatus": "",
                    "applicationDate": "",
                    "offerExpirationDate": ""
                  }
                  this.setState({
                    subTableData: this.state.subTableData.concat(obj)
                  });
                }
              }
            } else {
              this.setState({ noDataFound: true });
            }
          }).catch(err => {
              APIFailedMessage.displayError(err);
          })
        );
      }
    }
    };

    doubleClickOnCards(id) {
      if (this.state.click_id === id) {
        this.setState({
          double_data: {
            click_id: id,
            click_no: this.state.double_data.click_no + 1,
          },
        });
      } else {
        this.setState({ double_data: { click_id: id, click_no: 1 } });
      }
    }


    handleSpinner() {
        this.setState({ showSpinner: false });
        this.paginationWithIndex(this.state.pageNo);
    }
    
    cancel() {
      this.setState({
        show: false,
        printsummaryShow:false,
        creditId: "",
        custAcctStat:"",
        customerId: "",
        autopayRollExpand: false,
        estatementExpand : false,
        identificationExpand: false,
        employmentExpand: false,
        residenceExpand: false,
        isCrediappView:false,
        isprintSummaryView:false,
        customerPreApproveSummary:false,
        customerPreApprove:false,
        psInformationExpand:false,
        progressiveExpand:false,
        synchronyExpand:false,
        uownExpand:false,
        printSummarymobileVerificationExpand:false,
        psCo_BorrowerExpand:false
      });
    }

  handleViewCreditDetials = () => {
      if(this.state.isprintSummaryView){
        let customerId=0;
        trackPromise(
        this.props.creditApplication
        .getPrintSummary(this.state.applicationId,customerId)
        .then(async (response) => {
          this.setState({ printsummaryShow: true });
          if (response.data.length === 0) {
              this.setState({ noDataFound: true});
          }
          else {
            let printSummaryData=response.data[0];
            // await this.loadViewOffers(printSummaryData.customer_Id);
            await this.getCustomerDetails(printSummaryData); 
          }
        })
        .catch((err) => {
        APIFailedMessage.displayError(err);
        }))

      }
      if(this.state.isCrediappView){
        this.setState({ show: true });
        trackPromise(
        this.props.customerDetailsActions
          .getCustomerDetails(this.state.applicationId)
          .then(async (response) => {
            if (response.length === 0) {
              this.setState({ noDataFound: true});
          }
          else{
            let customerInfo = response[0];
            if (customerInfo.suffix_Id) {
              let result = await this.state.getCodeMaster.suffix.filter((data) => {
                return data.id === customerInfo.suffix_Id;
              });
              customerInfo.suffix_Id = result[0]?.description;
            }
            if (customerInfo.state_Id) {
              let state=this.state.stateList_credit?.find((val) => val.id === customerInfo.state_Id);
              if(state) {
                state=this.state.stateList?.find((val) => val.description?.toLowerCase() === state.description?.toLowerCase());
                customerInfo.state_Id = state.description;
            }
            else {
              let result = await this.state.stateList.filter((data) => {
                return data.id === customerInfo.state_Id;
              });
              customerInfo.state_Id = result[0]?.description;
            }
            }
            if (customerInfo.primaryState_Id) {
              let result = await this.state.stateList.filter((data) => {
                return data.id === customerInfo.primaryState_Id;
              });
              customerInfo.primaryState_Id = result[0]?.description;
            }
            if (customerInfo.housingInfo_Id) {
              let result = await this.state.getCodeMaster.house_info.filter(
                (data) => {
                  return data.id === customerInfo.housingInfo_Id;
                }
              );
              customerInfo.housingInfo_Id = result[0]?.description;
            }
            if (customerInfo.primaryType_Id) {
              let result = await this.state.getCodeMaster.p_id_type.filter(
                (data) => {
                  return data.id === customerInfo.primaryType_Id;
                }
              );
    
              customerInfo.primaryType_Id = result[0]?.description;
            }
    
            if (customerInfo.howOftenPaid_Id) {
              let result = await this.state.getCodeMaster.oftern_paid.filter(
                (data) => {
                  return data.id === customerInfo.howOftenPaid_Id;
                }
              );
              customerInfo.howOftenPaid_Id = result[0]?.description;
            }
    
            if (customerInfo.ccState_Id) {
              let result = await this.state.stateList.filter((data) => {
                return data.id === customerInfo.ccState_Id;
              });
              customerInfo.ccState_Id = result[0]?.description;
            }
    
            if (customerInfo.secondIdType_Id) {
              let result = await this.state.getCodeMaster.s_id_type.filter(
                (data) => {
                  return data.id === customerInfo.secondIdType_Id;
                }
              );
              customerInfo.secondIdType_Id = result[0]?.description;
            }
    
            if (customerInfo.creditCardBin) {
              const primaryData = await this.prefixChanger(
                customerInfo.creditCardBin,
                2,
                "credit"
              );
              customerInfo.creditCardBin = primaryData;
            }
    
            if (customerInfo.checkingAccountNo) {
              const primaryData = await this.prefixChanger(
                customerInfo.checkingAccountNo,
                4,
                "checkaccount"
              );
              customerInfo.checkingAccountNo = primaryData;
            }
            if(customerInfo.mobileNo!=null){
                if(!customerInfo.mobileNo.includes("-")){
                  customerInfo.mobileNo = formatPhoneNumber(customerInfo.mobileNo)
              }
            }
            this.setState({
              formObj: {
                ...this.state.formObj,
                storeCode: customerInfo.store_code,
                suffix: customerInfo.suffix_Id,
                credit_Id: customerInfo.LastDraftCreditId,
                id: customerInfo.id,
                firstName: customerInfo.firstName,
                middleName: customerInfo.middleName,
                lastName: customerInfo.lastName,
                mailingAdr: customerInfo.address,
                emailAdr: customerInfo.emailAddress,
                ccmailingAdr: customerInfo.ccAddress,
                apt: customerInfo.apt,
                city: customerInfo.city,
                dob:
                  customerInfo.birthDate === null
                    ? ""
                    : Moment(customerInfo.birthDate).format("MM/DD/YYYY"),
                mobileNo: customerInfo.mobileNo,
                state: customerInfo.state_Id,
                secPhone: customerInfo.secondaryPhone,
                zipCode: customerInfo.zipCode,
                empName: customerInfo.empName,
                empPhoneNo: customerInfo.empPhone,
                monthlyIncome: customerInfo.monthlyIncome,
                isDaniel:customerInfo.isDaniel,
                duties: customerInfo.workJob,
                empStartDate:
                  customerInfo.howLong === null
                    ? ""
                    : Moment(customerInfo.howLong).format("MM/YYYY"),
                housingInfo: customerInfo.housingInfo_Id==0?"":customerInfo.housingInfo_Id,
                ssn: customerInfo.ssn,
                // ssn: customerInfo.masked_ssn,
                // primaryIdNumber: customerInfo.masked_idNumber,
                primaryIdNumber: customerInfo.idNumber,
                primaryIdType: customerInfo.primaryType_Id==0?"":customerInfo.primaryType_Id,
                ciprimaryIdNoty: customerInfo.idNumber,
                primaryIdState: customerInfo.primaryState_Id,
                relativeName: customerInfo.relativeName,
                relativePhone: customerInfo.relativePhone,
                relativeRelation: customerInfo.relativeRelationship,
                createdDate: customerInfo.createdDate,
                primaryIdExpiryDate:
                  customerInfo.primaryIdExpDate === null
                    ? ""
                    : (customerInfo.primaryIdExpDate == "1900-01-01T00:00:00") ? "" : Moment(customerInfo.primaryIdExpDate).format("MM/YYYY"),
                secondIdType: customerInfo.secondIdType_Id == "0" ? "" : customerInfo.secondIdType_Id,
                secondIdIssuedBy: customerInfo.secondIdIssuedBy_Id,
                secondIdExpiryDate:
                  customerInfo.secondIdExpDate === null
                    ? ""
                    : (customerInfo.primaryIdExpDate == "1900-01-01T00:00:00") ? "" : Moment(customerInfo.secondIdExpDate).format("MM/YYYY"),
                oftenPaid: customerInfo.howOftenPaid_Id,
                lastPayDay:
                  customerInfo.lastPayDay === null
                    ? ""
                    : Moment(customerInfo.lastPayDay).format("MM/DD/YYYY"),
                nextPayDay:
                  customerInfo.nextPayDay === null
                    ? ""
                    : Moment(customerInfo.nextPayDay).format("MM/DD/YYYY"),
                bankRouteNo: customerInfo.bankRoutingNo,
                checkAccNo: customerInfo.checkingAccountNo,
                cc: customerInfo.creditCardBin,
                ccExpiryDate:
                  customerInfo.creditCardExpDate === null
                    ? ""
                    : Moment(customerInfo.creditCardExpDate).format("MM/YYYY"),
                ccName: customerInfo.nameOnCreditCard,
                creditCardBillingAddress: customerInfo.creditCardHomeAddress,
                ccAddress: customerInfo.ccAddress,
                aptUnit: customerInfo.apt,
                ccaptUnit: customerInfo.ccApt,
                cccity: customerInfo.ccCity,
                ccstate: customerInfo.ccState_Id,
                cczipCode: customerInfo.ccZipCode,
                autopay: customerInfo.autoPayStatus,
                mobileNoVerified: customerInfo.isMobileNoVerified,
                smsCodeSent: customerInfo.isSMSCodeSent,
                smsOverride: customerInfo.isSMSOverride,
                danielsStatus: customerInfo.danielsStatus,
                synchronyStatus: customerInfo.synchronyStatus,
                progressiveStatus: customerInfo.progressiveStatus,
                uownStatus: customerInfo.uownStatus,
                isSynchrony:customerInfo.isSynchrony,
                creditCardHomeAddress:customerInfo.creditCardHomeAddress,
                isDanielEStatementDisclosure : customerInfo.isDanielEStatementDisclosure,
              },
            });
            this.loadViewOffers(this.state.applicationId);
            if(response[0].isSynchrony){
              this.synchronyOffers(response[0].application_id, response[0]);
          }
          }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
        );
      }
  };

  handleViewMagixCredentials = (row) => {
    let id=row.custAcctStat;
    let originalMagixData= this.state.creditAppList.find(item=>{return item.custAcctStat==row.custAcctStat})
    let obj={
      magix_Id: "0",
      printSummary_Id: `${id}`,
      pageIndex: 1,
      pageSize: Constants.ApplicationHistorySize,
      sortBy: "modifiedDate",
      sortOrder: "desc",
      }
      trackPromise(
        this.props.creditApplication.getPrintSummaryMagix(obj)
      .then(async (response) => {
        this.setState({ printsummaryShow: true });
        if (response.data.length === 0) {
            this.setState({ noDataFound: true});
        }
        else {
            let printSummaryData=response.data[0];
            let stateObj= await this.state.stateList.find((state) => { return printSummaryData.state_Id === state.id });
            let res="";
          printSummaryData.comments?.split("\n")?.map(val => {
                  res += `<p class="mb-0">${val}</p>`
            });
            if(!printSummaryData.mobileNo.includes("-")){
              printSummaryData.mobileNo = formatPhoneNumber(printSummaryData.mobileNo);
            }
          this.setState({
                printSummaryformObj: {
                  ...this.state.printSummaryformObj,
                  account_Id: printSummaryData.magix_Id,
                  magixHomeStore:response.data[0].magixStoreCode,
                  homeStore: printSummaryData.magixStoreCode,
                  applyStore:printSummaryData.store_Id,
                  title:printSummaryData.title,
                  borrowerName:printSummaryData.borrowerName,
                  borrowerSuffix: printSummaryData.borrowerSuffix,
                  address: printSummaryData.address,
                  apt: printSummaryData.apt,
                  city: printSummaryData.city,
                  state: stateObj.description,
                  zipCode: printSummaryData.zipCode,
                  mobileNo: printSummaryData.mobileNo,
                  emailAdr: printSummaryData.emailAddress,
                  secPhone: printSummaryData.secondaryPhone,
                  createdDate:this.state.summaryCreatedDate,
                  dob:
                  printSummaryData.birthDate === null
                      ? ""
                      : Moment(printSummaryData.birthDate).format("MM/DD/YYYY"),
                  ssn: printSummaryData.ssn,
                  // ssn: "XXX-XX-"+printSummaryData.ssn?.slice(-4),
                  idNumber:printSummaryData.idNumber,
                  empName: printSummaryData.empName,
                  empPhoneNo: printSummaryData.empPhone,
                  duties: printSummaryData.workJob,
                  monthlyIncome: printSummaryData.monthlyIncome,
                  comments:res,
                  empStartDate:
                  printSummaryData.howLong === null
                      ? ""
                      : Moment(printSummaryData?.howLong).format("MM/YYYY"),
                  cborrowerTitle:printSummaryData.cborrowerTitle?printSummaryData.cborrowerTitle:"",
                  coborrowerName:printSummaryData.coborrowerName?printSummaryData.coborrowerName:"",
                  coborrowerSuffix:printSummaryData.coborrowerSuffix?printSummaryData.coborrowerSuffix:"",
                  coborrowermobileNo:printSummaryData.coborrowermobileNo,
                  coborroweremailAddress:printSummaryData.coborroweremailAddress,
                  coborrowersecondaryPhone:printSummaryData.coborrowersecondaryPhone,
                  coborrowerbirthDate:printSummaryData.coborrowerbirthDate==null?"":Moment(printSummaryData?.coborrowerbirthDate).format("MM/DD/YYYY"),
                  coborrowerssn:printSummaryData.coborrowerssn,
                  coborroweridNumber:printSummaryData.coborroweridNumber,
                  coborrowerempName:printSummaryData.coborrowerempName,
                  coborrowerempPhone:printSummaryData.coborrowerempPhone,
                  coborrowerworkJob:printSummaryData.coborrowerworkJob,
                  coborrowermonthlyIncome:printSummaryData.coborrowermonthlyIncome==0?"":printSummaryData.coborrowermonthlyIncome,
                  coborrowerhowLong:printSummaryData.coborrowerhowLong==null?"":Moment(printSummaryData?.coborrowerhowLong).format("MM/YYYY"),
                  id: printSummaryData.id,
                  mobileNoVerified: printSummaryData.isMobileNoVerified,
                  smsCodeSent: printSummaryData.isSMSCodeSent,
                  smsOverride: printSummaryData.isSMSOverride,
                  printAtMyStore:printSummaryData.printAtMyStore,
                  sendToCredit:printSummaryData.sendToCredit,
                  sendToMagixAccHomeStore: printSummaryData.sendToMagixAccHomeStore
                }
            });
              
            let obj1={
              associateId:row.magix_Id,
              storecode:row.magixStoreCode
            }
            trackPromise(
              this.props.customerDetailsActions.getMagixCustomerDetails(obj1).then(
                async response => {
                    if(response){
                      let state=this.state.stateList_credit?.find((val) => val.id === response[0].state_Id);
                    if(state) {
                      state=this.state.stateList?.find((val) => val.description?.toLowerCase() === state.description?.toLowerCase());
                      response[0].state_Id = state.description;
                    }
                    else {
                      let custState= await this.state.stateList.find((state) => state.id=== response[0].state_Id);
                      response[0].state_Id=custState?.description;
                    }
                    let suffixObj= await this.state.getCodeMaster.suffix.find((suffix) => { return response[0].suffix_Id === suffix.id });
                    response[0].suffix_Id=suffixObj?.description;
                  }
                  this.setState({ borrowerInfo: response[0] });
                  }
              ).catch(err => {
                  APIFailedMessage.displayError(err);
              })
          )
          }
        })
      .catch((err) => {
      APIFailedMessage.displayError(err);
      }))
  };  

  getCustomerDetails(printSummaryData){
    let summaryCustId=printSummaryData.customer_Id;
    this.props.customerDetailsActions
    .getCustomerDetails(summaryCustId)
    .then(async (response) => {
      this.setState({danielsStatus:response[0].danielsStatus});
      let state=this.state.stateList_credit?.find((val) => val.id === response[0].state_Id);
      if(state) {
        state=this.state.stateList?.find((val) => val.description?.toLowerCase() === state.description?.toLowerCase());
        response[0].state_Id = state.description;
      }
      else {
        let custState= await this.state.stateList.find((state) => state.id=== response[0].state_Id);
        response[0].state_Id=custState?.description;
      }
      let suffixObj= await this.state.getCodeMaster.suffix.find((suffix) => { return response[0].suffix_Id === suffix.id });
      response[0].suffix_Id=suffixObj?.description;
      this.setState({ borrowerInfo: response[0] });
      if (response[1] !== undefined) {
        this.setState({ coBorrowerInfo: response[1] });
      }
      //update print summary object
      let stateObj= await this.state.stateList.find((state) => { return printSummaryData.state_Code === state.code });
        let res="";
      printSummaryData.comments?.split("\n")?.map(val => {
              res += `<p class="mb-0">${val}</p>`
        })
        if(!printSummaryData.mobileNo.includes("-")){
          printSummaryData.mobileNo = formatPhoneNumber(printSummaryData.mobileNo);
        }
      this.setState({
        printSummaryformObj: {
          ...this.state.printSummaryformObj,
          account_Id: printSummaryData.account_Id,
          magixHomeStore:response[0].magixStoreCode,
          homeStore: response[0].store_code,
          isDaniel:response[0].isDaniel,
          applyStore:printSummaryData.store_Id,
          title:printSummaryData.title,
          borrowerName:printSummaryData.borrowerName,
          borrowerSuffix: printSummaryData.borrowerSuffix,
          address: printSummaryData.address,
          apt: printSummaryData.apt,
          city: printSummaryData.city,
          state: stateObj.description,
          zipCode: printSummaryData.zipCode,
          mobileNo: printSummaryData.mobileNo,
          emailAdr: printSummaryData.emailAddress,
          secPhone: printSummaryData.secondaryPhone,
          createdDate:this.state.summaryCreatedDate,
          dob:
          printSummaryData.birthDate === null
              ? ""
              : Moment(printSummaryData.birthDate).format("MM/DD/YYYY"),
          // ssn: "XXX-XX-"+printSummaryData.ssn?.slice(-4),
          ssn : printSummaryData.ssn,
          idNumber:printSummaryData.idNumber,
          empName: printSummaryData.empName,
          empPhoneNo: printSummaryData.empPhone,
          duties: printSummaryData.workJob,
          monthlyIncome: printSummaryData.monthlyIncome,
          comments:res,
          empStartDate:
          printSummaryData.howLong === null
              ? ""
              : Moment(printSummaryData.howLong).format("MM/YYYY"),
          cborrowerTitle:printSummaryData.cborrowerTitle?printSummaryData.cborrowerTitle:"",
          coborrowerName:printSummaryData.coborrowerName?printSummaryData.coborrowerName:"",
          coborrowerSuffix:printSummaryData.coborrowerSuffix?printSummaryData.coborrowerSuffix:"",
          coborrowermobileNo:printSummaryData.coborrowermobileNo,
          coborroweremailAddress:printSummaryData.coborroweremailAddress,
          coborrowersecondaryPhone:printSummaryData.coborrowersecondaryPhone,
          coborrowerbirthDate:printSummaryData.coborrowerbirthDate==null?"":Moment(printSummaryData.coborrowerbirthDate).format("MM/DD/YYYY"),
          coborrowerssn:printSummaryData.coborrowerssn,
          coborroweridNumber:printSummaryData.coborroweridNumber,
          coborrowerempName:printSummaryData.coborrowerempName,
          coborrowerempPhone:printSummaryData.coborrowerempPhone,
          coborrowerworkJob:printSummaryData.coborrowerworkJob,
          coborrowermonthlyIncome:printSummaryData.coborrowermonthlyIncome==0?"":printSummaryData.coborrowermonthlyIncome,
          coborrowerhowLong:printSummaryData.coborrowerhowLong==null?"":Moment(printSummaryData.coborrowerhowLong).format("MM/YYYY"),
          id: printSummaryData.id,
          mobileNoVerified: printSummaryData.isMobileNoVerified,
          smsCodeSent: printSummaryData.isSMSCodeSent,
          smsOverride: printSummaryData.isSMSOverride,
          printAtMyStore:printSummaryData.printAtMyStore,
          sendToCredit:printSummaryData.sendToCredit,
          sendToMagixAccHomeStore: printSummaryData.sendToMagixAccHomeStore,
          isSynchrony:response[0].isSynchrony
        }
                     });
                     if(response[0].isSynchrony){
              this.synchronyOffers(response[0].application_id, response[0]);
          }
                    //  this.setState({ printsummaryShow: true });
                   
    }).catch(error=>{
      APIFailedMessage.displayError();
    })
  } 
  
  synchronyOffers(applicationId, rowData) {
     let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
         trackPromise(
             this.props.customerDetailsActions.getSynchronyViewOffers(GetInfo.getCustomerId(),applicationId,homeStore).then(
                 response => {
                     if(response){
                      let filterObj = {
                        ssn:rowData?.ssn?.replace(/-/g,""),
                        zipCode:rowData?.zipCode,
                        phoneNumber:"",
                        accountNumber:"",
                        homeStore : rowData?.store_code
                    }
                    trackPromise(
                        this.props.syncActions.getsynchronyLookup(filterObj).then(
                            res => {
                                if(res.accountNumber){
                                    response.openToBuy = res.opentobuy;
                                }
                            }
                        ).catch(err => {
                            APIFailedMessage.displayError(err);
                        })
                    );
                         this.setState({ synchronyOffers: response });
                     }
                 
                 }).catch(err => {
                     APIFailedMessage.displayError(err);
                 })
         )
   }
   
  //  getAccountSummary(offersResponse,obj,mobileNo,ssn,zipCode) {
  //    let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
  //    if(mobileNo.includes("-")){
  //      mobileNo = mobileNo.slice(0,3)+mobileNo.slice(4,7)+mobileNo.slice(8,12);
  //    }
  //    trackPromise(
  //     this.props.syncActions.getsynchronyActSummmary(GetInfo.getCustomerId(),obj,mobileNo,homeStore,ssn,zipCode).then(
  //            response => {
  //                if(response.accountNumber){
  //                    offersResponse.approvalLimit = response.creditLine;
  //                }
  //                this.setState({ synchronyOffers: offersResponse });
                
  //            }
  //        ).catch(err => {
  //            APIFailedMessage.displayError(err);
  //        })
  //    );
  //  }

  expand=(data)=>{
    
    if(data==="show"){
      this.setState({ show:!this.state.show})
    }
    if(data==="mobileVerification"){
      this.setState({ mobileVerificationExpand:!this.state.mobileVerificationExpand})
    }
    if(data==="personalInformation"){
      this.setState({ psInformationExpand:!this.state.psInformationExpand})
    }
    if(data==="residence"){
      this.setState({ residenceExpand:!this.state.residenceExpand})
    }
    if(data==="identification"){
      this.setState({ identificationExpand:!this.state.identificationExpand})
    }
    if(data==="employment"){
      this.setState({ employmentExpand:!this.state.employmentExpand})
    }
    if(data==="synchrony"){
      this.setState({ synchronyExpand:!this.state.synchronyExpand})
    }
    if(data==="progressive"){
      this.setState({ progressiveExpand:!this.state.progressiveExpand})
    }
    if(data==="uown"){
      this.setState({ uownExpand:!this.state.uownExpand})
    }
    if(data==="autopay"){
      this.setState({ autopayRollExpand:!this.state.autopayRollExpand})
    }
    if(data==="estatement"){
      this.setState({ estatementExpand:!this.state.estatementExpand})
    }
    if(data==="customerPreApprove"){
      this.setState({ customerPreApprove:!this.state.customerPreApprove})
    }
    if(data==="cancel"){
      this.cancel();
    }
  }

  expandPrintSummary=(data)=>{
    if(data==="show"){
      this.setState({ show:!this.state.show})
    }
    if(data==="psBorrowerExpand"){
      this.setState({ psBorrowerExpand:!this.state.psBorrowerExpand})
    }
    if(data==="psCo_BorrowerExpand"){
      this.setState({ psCo_BorrowerExpand:!this.state.psCo_BorrowerExpand})
    }
    if(data==="customerPreApproveSummary"){
      this.setState({ customerPreApproveSummary:!this.state.customerPreApproveSummary})
    }
    if(data==="mobileVerification"){
      this.setState({ printSummarymobileVerificationExpand:!this.state.printSummarymobileVerificationExpand})
    }
    if(data==="cancel"){
      this.cancel();
    }

  }

  loadFortivaOffers=() => {
      let offers = this.state.cusPreApproval?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" })[0];
     return FortivaOffersStatus(offers);
  }
  loadSynchronyOffers=()=> {
    if(this.state?.formObj?.isSynchrony===true ){
      let offers = this.state.synchronyOffers;
      return SynchronyOffersStatus(offers,this.state.formObj?.createdDate);
   } 
   if(this.state.printSummaryformObj?.isSynchrony===true){
      let offers = this.state.synchronyOffers;
      return SynchronyOffersStatus(offers,this.state.printSummaryformObj?.createdDate);
   }
    else {
      return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span>";
    }      
}

loadProgressiveOffers=()=> {
    let offers = this.state.cusPreApproval?.filter((val) => { return val.fpType?.toLowerCase() === "progressive" })[0];
   return ProgressiveOffersStatus(offers);
}

loadUOwnOffers=()=> {
    let offers = this.state.cusPreApproval?.filter((val) => { return val.fpType?.toLowerCase() === "uown" })[0];
    return UOwnOffersStatus(offers);
}

handleRowSelect = async(row) => {
  if(row.applicationType === "InStore Application" || row.applicationType === "PreQualification" || row.applicationType === "Online Application") {
  if(this.state.selectedRowId == row.application_id) {
    this.setState({subCardtoggle : !this.state.subCardtoggle, subTableData: [], selectedRowId : ""});
  }
  else {
    let synchronyCDResponse = {};
    if(row.isSynchrony) {
      let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
      //  trackPromise(
           await this.props.customerDetailsActions.getSynchronyViewOffers(GetInfo.getCustomerId(),row.application_id,homeStore).then(
               response => {
                   synchronyCDResponse= response;
               }).catch(err => {
                   APIFailedMessage.displayError(err);
               })
      //  )
    }
    // trackPromise(
    this.props.creditApplication.getApplicationViewOffers(GetInfo.getCustomerId(),row.application_id).then(
      response => {
        if (response) {
          if (response?.length === 0) {
            this.setState({ noDataFound: true });
          } else {
            if(row.isSynchrony) {
              if(synchronyCDResponse?.applicationId){
              response?.data?.map((row, index) => {
                if(row.fpType?.toLowerCase() === "synchrony") {
                  row.statusDescription = synchronyCDResponse?.status
                  row.applicationId = synchronyCDResponse?.applicationId
                  row.approvalLimit = synchronyCDResponse?.approvalLimit
                }
              });
            }
            else {
              response?.data?.map((row, index) => {
                if(row.fpType?.toLowerCase() === "synchrony") {
                  row.statusDescription = "Applied"
                }
              });
            }
          }
            if(row.isFortiva) {
              response?.data?.map((row, index) => {
                if(row.fpType?.toLowerCase() === "fortiva") {
                  if(row.applicationStatus?.toLowerCase() === "a")
                    row.statusDescription = "Approved"
                  else if (row.applicationStatus?.toLowerCase() === "d" || row.applicationStatus?.toLowerCase() === "ec" || row.applicationStatus?.toLowerCase() === "cr" || row.applicationStatus?.toLowerCase() === "r") 
                    row.statusDescription = "Declined"
                  else if(row.applicationStatus?.toLowerCase() === "p")
                    row.statusDescription = "Error 1"
                  else if(row.applicationStatus?.toLowerCase() === "ca")
                    row.statusDescription = "Error 2"
                  else if(row.applicationStatus?.toLowerCase() === "an")
                    row.statusDescription = "Error 3"
                }
              })
            }
            if(row.isProgressive) {
              response?.data?.map((row, index) => {
                if(row.fpType?.toLowerCase() === "progressive") {
                  if(row.status !== "") {
                    if (row.status?.toLowerCase()  === "pre-approved") {
                      row.statusDescription = "Pending"
                    }else{
                      row.statusDescription = row.status
                    }
                  }
                  else if(row.errorMessage?.length > 0) {
                    row.statusDescription = "Error"
                  }
                }
              })
            }
            if(row.isUown) {
              response?.data?.map((row, index) => {
                if(row.fpType?.toLowerCase() === "uown") {
                  if(row.status?.toLowerCase() === "e0")
                    row.statusDescription = "Approved"
                  else if(row.status?.toLowerCase() === "e1")
                    row.statusDescription = "Pending"
                  else if(row.status?.toLowerCase() === "e4")
                    row.statusDescription = "Declined"
                  else if(row.status?.toLowerCase() === "e3")
                    row.statusDescription = "Error"
                }
              })
            }
            this.setState({
              subTableData: response?.data,
              selectedRowId : row.application_id,
            })
            if(row.isDaniel == true) {
              let obj = {
                "accountNumber": "",
                "applicationId": "",
                "merchantCustomerId": "",
                "approvalLimit": 0,
                "status": "",
                "message": "",
                "errorMessage": "",
                "providerURL": "",
                "authorizationNumber": "",
                "fieldInError1": "",
                "fieldInError2": "",
                "fieldInError3": "",
                "fieldInError4": "",
                "fieldInError5": "",
                "submittedDate": "0001-01-01T00:00:00",
                "approval_status": null,
                "account_Balance": null,
                "statusDescription": "Applied",
                "createdDate": row.createdDate,
                "leaseId": null,
                "fpType": "Daniels",
                "apr": "",
                "minPaymentAmt": "",
                "annualFee": "",
                "duration": "",
                "latePaymentFee": "",
                "returnedPaymentFee": "",
                "periodicRate": "",
                "minPaymentPct": "",
                "offerId": "",
                "downPaymentPct": "",
                "penaltyAPR": 0,
                "transactionFee": 0,
                "autoDeductStatus": "",
                "applicationStatus": "",
                "applicationDate": "",
                "offerExpirationDate": ""
              }
              this.setState({
                subTableData: this.state.subTableData.concat(obj),
              })
            }
          }
        } else {
          this.setState({ noDataFound: true });
        }
      }).catch(err => {
          APIFailedMessage.displayError(err);
      })
    // )
  }
}
};

  render(){
  //  const Approved = () => {
  //   return(
  //        <span className='financial'>A</span>
  //   ) 
  // };
  // const Declined = () => {
  //   return(
  //        <span className='financial declined'>X</span>
  //   ) 
  // };
  // const Pending = () => {
  //   return(
  //        <span className='financial pending'>P</span>
  //   ) 
  // };
  // const Reapply = () => {
  //   return(
  //        <span className='financial reApply'>E</span>
  //   ) 
  // };

  //  const DisplayStatus = (props) => {
  //   const {status }=props;
  //    return(
  //     status?.toLowerCase()=="approved"?
  //     <Approved/>:
  //     status?.toLowerCase()=="declined" || status?.toLowerCase()=="denied"?
  //     <Declined/>:
  //     status?.toLowerCase()=="pending" || status?.toLowerCase()=="in process" || status?.toLowerCase()=="pre-approved"?
  //     <Pending/>:
  //     status?.toLowerCase()=="eligible to reapply"?
  //     <Reapply/>
  //     :""
  //   ) 
  // };
  
  // const columns = [
  //     {
  //       dataField: "createdDateDisplay",
  //       text: "Date",
  //       headerClasses: "input_form_control appType",
  //     },
  //     {
  //       dataField: "applicationType",
  //       text: "Application Type",
  //       headerClasses: "input_form_control appType",
  //     },
  //     {
  //       dataField: "account",
  //       text: "Customer ID",
  //       headerClasses: "input_form_control appType",
  //     },
  //     {
  //       dataField: "customerName",
  //       text: "Customer Name",
  //       headerClasses: "input_form_control",
  //       // formatter: (cell, row) => {
  //       //   return (
  //       //     <div>{`${row.firstName === null ? "" : row.firstName} ${
  //       //       row.middleName === null ? "" : row.middleName
  //       //     } ${row.lastName === null ? "" : row.lastName}`}</div>
  //       //   );
  //       // }
  //     },
  //     {
  //       dataField: "associateId",
  //       text: "Associate ID",
  //       headerClasses: "input_form_control",
  //     },
  //     {
  //         dataField: "store_code",
  //         text: "Apply Store",
  //         headerClasses: "input_form_control",
  //     },
  //     {
  //       dataField: "Daniels",
  //       text: "D",
  //       headerClasses: "input_form_control appCol",
  //       // formatter: (cell, row) => {
  //       //   return (
  //       //     <div>
  //       //       {row.responseSource==="application"?row.isDaniel?<Approved/>:"":""}
  //       //     </div>
  //       //   );
  //       // },
  //     },
  //     {
  //       dataField: "Synchrony",
  //       text: "S",
  //       headerClasses: "input_form_control appCol",
  //       // formatter: (cell, row) => {
  //       //   return (
  //       //     <div>
  //       //       {row.responseSource==="application"?
  //       //       (row.isSynchrony && row.isSynchronyCD)?
  //       //       (row.synchronyStatus==null || row.synchronyStatus=="")?<span className='financial'>A</span>:
  //       //       <DisplayStatus status={row.synchronyStatus}/>:
  //       //       (row.isSynchrony && !row.isSynchronyCD)?<span className='financial'>A</span>:"":""
  //       //     }
  //       //     </div>
  //       //   );
  //       // },
  //     },
  //     {
  //       dataField: "Progr",
  //       text: "P",
  //       headerClasses: "input_form_control appCol",
  //       // formatter: (cell, row) => {
  //       //   return (
  //       //     <div>
  //       //     {row.responseSource==="application"?
  //       //     <DisplayStatus status={row.progressiveStatus}/>
  //       //      :""}
  //       //   </div>
  //       //   );
  //       // },
  //     },
  //     {
  //       dataField: "Uown",
  //       text: "U",
  //       headerClasses: "input_form_control appCol",
  //       // formatter: (cell, row) => {
  //       //   return (
  //       //     <div>
  //       //     {row.responseSource==="application"?
  //       //      <DisplayStatus status={row.uownStatus}/>
  //       //       :""}
  //       //   </div>
  //       //   );
  //       // },
  //     },
  //     ];

  const columns = [
            {
              dataField: "createdDateDisplay",
              text: "Application Date",
              headerClasses: "input_form_control dateCol",
            },
            {
              dataField: "applicationType",
              text: "Application Type",
              headerClasses: "input_form_control appTypeCol",
            },
            {
              dataField: "applicationId",
              text: "Application ID",
              headerClasses: "input_form_control appIdCol",
            },
            {
              dataField: "customerName",
              text: "Customer Name",
              headerClasses: "input_form_control",
            },
            {
              dataField: "associateId",
              text: "Associate ID",
              headerClasses: "input_form_control",
            },
            {
              dataField: "store_code",
              text: "Apply Store",
              headerClasses: "input_form_control",
              formatter: (cell, row) => {
                return (
                    row.applicationType==="Print Summary"? row?.store_Id : row?.store_code
                );
              },
          },
        {
          dataField: "Daniels",
          text: "D",
          headerClasses: "input_form_control appCol",
          formatter: (cell, row) => {
            return (
              <div className="d-flex justify-content-center align-items-center h-100">
                {row.responseSource==="application"?row.isDaniel? <FontAwesomeIcon icon={faCircle} className="vendorDenotation"/>:"":""}
              </div>
            );
          },
        },
        {
          dataField: "Fortiva",
          text: "F",
          headerClasses: "input_form_control appCol",
          formatter: (cell, row) => {
            return (
              <div className="d-flex justify-content-center align-items-center h-100">
                {row.responseSource==="application"?row.isFortiva? <FontAwesomeIcon icon={faCircle} className="vendorDenotation"/>:"":""}
              </div>
            );
          },
        },
        {
          dataField: "Synchrony",
          text: "S",
          headerClasses: "input_form_control appCol",
          formatter: (cell, row) => {
            return (
              <div className="d-flex justify-content-center align-items-center h-100">
              {row.responseSource==="application"?row.isSynchrony? <FontAwesomeIcon icon={faCircle} className="vendorDenotation"/>:"":""}
            </div>
            );
          },
        },
        {
          dataField: "Progr",
          text: "P",
          headerClasses: "input_form_control appCol",
          formatter: (cell, row) => {
            return (
              <div className="d-flex justify-content-center align-items-center h-100">
              {row.responseSource==="application"?row.isProgressive? <FontAwesomeIcon icon={faCircle} className="vendorDenotation"/>:"":""}
            </div>
            );
          },
        },
        {
          dataField: "Uown",
          text: "U",
          headerClasses: "input_form_control appCol",
          formatter: (cell, row) => {
            return (
              <div className="d-flex justify-content-center align-items-center h-100">
              {row.responseSource==="application"?row.isUown? <FontAwesomeIcon icon={faCircle} className="vendorDenotation"/>:"":""}
            </div>
            );
          },
        },
        ];

      const nextedColumns = [
        {
          dataField: "fpType",
          text: "Application Selected",
          headerClasses: "input_form_control",
        },
        {
          dataField: "applicationId",
          text: "Application/ Lease ID",
          headerClasses: "input_form_control",
          formatter : (cell, row) => {
            return (
              <div>
                {row.fpType?.toLowerCase() === "progressive" ? row.accountNumber : 
                row.fpType?.toLowerCase() === "uown" ? row.authorizationNumber : row.applicationId}
              </div>
            )
          }
        },
        {
          dataField: "statusDescription",
          text: "Response Decision Status",
          headerClasses: "input_form_control",
        },
        {
          dataField: "approvalLimit",
          text: "Approval Amount",
          headerClasses: "input_form_control",
          formatter : (cell, row) => {
            return (
              <div>
                {row.fpType?.toLowerCase() === "daniels" ? "Credit dept needs to confirm." : this.currencyFormatter(row.approvalLimit) }
              </div>
            )
          }
        },
        {
          dataField: "",
          text: "Status Message",
          headerClasses: "input_form_control",
          formatter:(cell,row) => {
            return (
              <div>
                
              </div>
            )
          }
        },
        ];

  const handleTableChange = ( type, { page, sizePerPage, sortField, sortOrder, filters }) => {
      if (type === "pagination") {
          this.setState({ creditAppList: [], isPaginate: true, isSort: false });
          this.paginationWithIndex(page, filters);
      }
      else if (type === "sort") {
          this.setState({ isFirstTime: false });
          if (this.state.isSort && !this.state.isFirstTime) {
              this.setState({ dataField: sortField, order: sortOrder });
              setTimeout(() => {
              sortingPage(page, filters);
              }, 1000);
          }
      }
      else if (type === "filter") {
          this.setState({
              filterObj: {
              ...this.state.filterObj,
              firstName: filters?.firstName?.filterVal? filters?.firstName?.filterVal: "",
              address: filters?.address?.filterVal? filters?.address?.filterVal: "",
              mobileNo: filters?.mobileNo?.filterVal? filters?.mobileNo?.filterVal: "",
              idNumber: filters?.idNumber?.filterVal? filters?.idNumber?.filterVal: "",
              ssn: filters?.ssn?.filterVal? filters?.ssn?.filterVal: "",
              store_code: filters?.store_code?.filterVal? filters?.store_code?.filterVal: "",
              account: filters?.account?.filterVal? filters?.account?.filterVal: "",
              modifiedDate:filters?.modifiedDate?.filterVal? filters?.modifiedDate?.filterVal: "",
              },
          });
          if (!filters?.firstName && !filters?.address && !filters?.mobileNo && !filters?.idNumber && !filters?.ssn && !filters?.store_code && !filters?.account && !filters?.modifiedDate) {
              this.paginationWithIndex(this.state.pageNo);
          } 
          else if ( filters?.firstName?.filterVal.length > 1 ||filters?.mobileNo?.filterVal.length > 1 ||filters?.address?.filterVal.length > 1 || filters?.idNumber?.filterVal.length > 1 ||filters?.ssn?.filterVal.length > 1 ||filters?.store_code?.filterVal.length > 1 || filters?.account?.filterVal.length > 1 ) {
              if(filters?.modifiedDate?.filterVal){
              if((Moment(filters?.modifiedDate?.filterVal, 'MM/DD/YYYY',true).isValid() || Moment(filters?.modifiedDate?.filterVal, 'MM-DD-YYYY',true).isValid())){
                  filterHeader(filters, page);
              }
              }
              else{
              filterHeader(filters, page);
              }
              
          } else if(filters?.modifiedDate?.filterVal){
              if((Moment(filters?.modifiedDate?.filterVal, 'MM/DD/YYYY',true).isValid() || Moment(filters?.modifiedDate?.filterVal, 'MM-DD-YYYY',true).isValid())){
                  filterHeader(filters, page);
              }
          }
      }
  }

  const sortingPage = (page, filters) => {
    let customerId=localStorage.getItem("customerId") !== "null"?localStorage.getItem("customerId"):"";
      let obj = {
          name: filters?.firstName?.filterVal ? filters?.firstName?.filterVal  : this.state.filterObj?.firstName,
          address: filters?.address?.filterVal  ? filters?.address?.filterVal  : this.state.filterObj?.address,
          phone: filters?.mobileNo?.filterVal   ? filters?.mobileNo?.filterVal : this.state.filterObj?.mobileNo,
          idNumber: filters?.idNumber?.filterVal ? filters?.idNumber?.filterVal : this.state.filterObj?.idNumber,
          ssn: filters?.ssn?.filterVal  ? filters?.ssn?.filterVal : this.state.filterObj?.ssn,
          store: filters?.store_code?.filterVal  ? filters?.store_code?.filterVal : this.state.filterObj?.store_code,
          account: filters?.account?.filterVal ? filters?.account?.filterVal: this.state.filterObj?.account,
          applicationDate: filters?.modifiedDate?.filterVal ? filters?.modifiedDate?.filterVal : this.state.filterObj?.modifiedDate,
          customerID:customerId,
          pageIndex: this.state.pageNo,
          pageSize: Constants.ApplicationHistorySize,
          sortBy: this.state.dataField === "firstName" ? "name" : this.state.dataField,
          sortOrder: this.state.order,
      };
      this.getCreditList(obj,page);
  };

  

  
  
  const filterHeader = (filters, page) => {
    let customerId=localStorage.getItem("customerId") !== "null"?localStorage.getItem("customerId"):"";
  let obj = {
      name: filters?.firstName?.filterVal,
      address: filters?.address?.filterVal,
      phone: filters?.mobileNo?.filterVal,
      idNumber: filters?.idNumber?.filterVal,
      ssn: filters?.ssn?.filterVal,
      store: filters?.store_code?.filterVal,
      account: filters?.account?.filterVal,
      applicationDate: filters?.modifiedDate?.filterVal,
      customerID:customerId,
      pageIndex: this.state.pageNo,
      pageSize: Constants.ApplicationHistorySize,
      sortBy: this.state.dataField === "firstName" ? "name" : this.state.dataField,
      sortOrder: this.state.order
  };
  if (!obj.filter_Username && obj.filter_UserId &&  !obj.filter_Role &&   !obj.filter_Store &&  !obj.filter_District && !obj.filter_LastActivity && !obj.filter_Status) {
      this.paginationWithIndex(this.state.pageNo);
  } else {
      this.getCreditList(obj,page);
  }
  };
  const customTotal = (from, to, size) => {
      return (
          <span className="react-bootstrap-table-pagination-total ml-0 mt-3">
          <div className="bg-white pl-2 pr-2 pt-1 pb-1 rounded">
          {this.state.totalCreditApps?  `${from}-${to} of ${size}` :""}
          </div>
          </span>
      );
  };
  const selectRow = {
      clickToSelect: true,
      onSelect: (row) => {
        if(row.responseSource === "application"){
          this.setState({ isCrediappView:true});
        }
        else{
          if(localStorage.getItem("applicationId") !== "null"){
            this.setState({  isprintSummaryView:true, printSummaryId:row.custAcctStat,custAcctStat:row.custAcctStat,
              summaryCreatedDate: row.createdDate}); 
          }
          else{
            this.setState({  isprintSummaryView:true, printSummaryId:row.custAcctStat,custAcctStat:row.custAcctStat,
              summaryCreatedDate: row.modifiedDate}); 
          }
          
        }
        this.setState({ creditId: row.custAcctStat, applicationId: row.application_id,customerId: row.customer_Id,isDaniel:row.isDaniel});
      },
      onSelectAll: this.handleOnSelectAll,
  };
  const rowStyle = (row, rowIndex) => {
    const style = {};
      if(row.custAcctStat === this.state.creditId) {
        style.backgroundColor = "#5C068C";
        style.color = "white";
      }
    

    return style;
  };
  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      if (localStorage.getItem("applicationId") !== "null") {
        this.handleViewCreditDetials();
      }
      else{
       this.handleViewMagixCredentials(row);
      }

    },
  };
  const expandRow = {
    renderer: row => (
      <div id='expandRow'>
        <BootstrapTable
          bordered={false}
          keyField="id"
          data={this.state.subTableData}
          columns={nextedColumns}
        />
    </div>
    ),
    showExpandColumn: true,
    expandColumnPosition: 'right',
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    nonExpandable: this.state.noneExpandRowIndex,
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if(expanded) {
        return <p>View <FontAwesomeIcon icon={faAngleUp} /></p>
      }
      if(expandable) {
        this.setState({subTableData : []});
        return <p>View <FontAwesomeIcon icon={faAngleDown} /></p>
      }
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return <p className="mt-3">View Details</p> 
    },
    onExpand: (row, isExpand, rowIndex, e) => {
      if(isExpand){
        this.handleRowSelect(row);
      }
    },
  };
  return(
      <>
        <CreditAppView
        applicationId={this.state.applicationId}
        customerId={this.state.customerId}
        show={this.state.show}
        creditId={this.state.creditId}
        formObj={this.state.formObj}
        mobileVerificationExpand={this.state.mobileVerificationExpand}
        residenceExpand={this.state.residenceExpand}
        uownExpand={this.state.uownExpand}
        synchronyExpand={this.state.synchronyExpand}
        progressiveExpand={this.state.progressiveExpand}
        autopayRollExpand={this.state.autopayRollExpand}
        estatementExpand={this.state.estatementExpand}
        employmentExpand={this.state.employmentExpand}
        psInformationExpand={this.state.psInformationExpand}
        identificationExpand={this.state.identificationExpand}
        expand={this.expand}
        customerPreApprove={this.state.customerPreApprove}
        loadSynchronyOffers={this.loadSynchronyOffers}
        loadProgressiveOffers={this.loadProgressiveOffers}
        loadUOwnOffers={this.loadUOwnOffers}
        loadFortivaOffers={this.loadFortivaOffers}
      />
      <PrintSummaryView
        applicationId={this.state.applicationId}
        printsummaryShow={this.state.printsummaryShow}
        psBorrowerExpand={this.state.psBorrowerExpand}
        psCo_BorrowerExpand={this.state.psCo_BorrowerExpand}
        printSummaryformObj={this.state.printSummaryformObj}
        expandPrintSummary={this.expandPrintSummary}
        customerPreApproveSummary={this.state.customerPreApproveSummary}
        loadSynchronyOffers={this.loadSynchronyOffers}
        loadProgressiveOffers={this.loadProgressiveOffers}
        loadUOwnOffers={this.loadUOwnOffers}
        loadFortivaOffers={this.loadFortivaOffers}
        customerId={this.state.customerId}
        printSummarymobileVerificationExpand={this.state.printSummarymobileVerificationExpand}
        borrowerInfo={this.state.borrowerInfo}
        coBorrowerInfo={this.state.coBorrowerInfo}
      />
      <IdleTimeModal />
      <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
          <Sidebar
              toggled={this.state.toggled}
              handleToggleSidebar={() => { this.handleToggleSidebar() }}
          />
           {this.state.showSpinner ? (
            <div
              style={{
                flexDirection: "column",
              }}
              className="d-flex justify-content-center align-items-center w-100"
            >
              <Loader
                type="ThreeDots"
                color="#5C068C"
                height="100"
                width="100"
              />
            </div>
          ) : (
          
          <main>
              <CustomNavbar handleToggleSidebar={() => { this.handleToggleSidebar() }} currentPage={this.props.history.location.pathname} />
              <div id="main" className="container-fluid mt-3">
                 <div className="row mb-2 mt-3 pl-4 pr-4">
                    <div className=" col-md-6 col-12 d-flex justify-content-left">
                              <h4>
                              <b>Application History</b>
                              </h4>
                          </div>
                 </div>
                  <div className="row mt-2 pl-1 pr-1">
                  <div className="col-12">
                            <div id="cardrow">
                              <CardView
                                data={this.state.creditAppList}
                                handleCardSelect={this.handleCardSelect}
                                handleSubCardSelect={this.handleSubCardSelect}
                                activeCreditId={this.state.creditId}
                                handleViewCreditDetials={this.handleViewCreditDetials}
                                subTableData={this.state.subTableData}
                              />
                            </div>
                            <RemotePagination
                              data={this.state.creditAppList}
                              page={this.state.pageNo}
                              from={this.state.from}
                              to={this.state.to}
                              sizePerPage={Constants.ApplicationHistorySize}
                              firstPageText="<<"
                              totalSize={this.state.totalCreditApps}
                              onTableChange={handleTableChange}
                              dataField={this.state.dataField}
                              order={this.state.order}
                              columns={columns}
                              customTotal={customTotal}
                              selectRow={selectRow}
                              rowStyle={rowStyle}
                              rowEvents={rowEvents}
                              expandRow={expandRow}
                            />
                    
                          <>
                            <div
                              id="matchnotfound"
                              className="text-center mw-100 d-flex justify-content-center align-items-center"
                            >
                              {this.state.creditAppList.length === 0 &&
                              !this.state.isPaginate ? (
                                <p className="mb-0 pt-0 text-secondary font-weight-bold">
                                  No data found
                                </p>
                              ) : this.state.isPaginate ? (
                                <p className="mb-0 pt-0 text-secondary"></p>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        </div>
                    </div>
                    {/* {this.state.totalCreditApps?
                    <div className="row m-1 mr-1 mb-4">
                        <div className="col-lg-3 col-md-6 footerNote">
                        <Approved/><span className='hf'> - Applied/Purchased</span>
                        </div>
                        <div className="col-lg-3 col-md-6 footerNote">
                          <Declined/><span className='hf'> - Declined</span>
                        </div>
                        <div className="col-lg-3 col-md-6 footerNote">
                          <Reapply/><span className='hf'> - Eligible to Reapply</span>
                        </div>
                        <div className="col-lg-3 col-md-6 footerNote">
                          <Pending/><span className='hf'> - Offer Pending</span>
                        </div>
                  </div>:""} */}
              </div>
          </main>
          )}
      </div>
      </>
  )
  }
}
const mapDispatchToProps = (dispatch) => {
    return {
      creditApplication: bindActionCreators(creditApplication, dispatch),
      customerDetailsActions: bindActionCreators(customerDetailsActions,  dispatch),
      actions: bindActionCreators(applyNowActions, dispatch),
      syncActions: bindActionCreators(synchronyActions, dispatch),
      accountSummaryAction: bindActionCreators(accountSummaryAction, dispatch),
    };
  };
  
  export default connect(null, mapDispatchToProps)(ApplicationHistory);