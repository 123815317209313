import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import './component.addressSuggestionForm.css';

const AddressSuggestedForm = ({ onUseOriginalAddressClicked, onUseSuggestedAddressClicked, onClose, show, originalAddress, suggestedAddress }) => {
    return (
        <Modal id="addressSuggestionForm" show={show} onHide={onClose} centered>
            <Modal.Header className="pb-2 d-flex justify-content-between align-items-center bg-white border-bottom">
                <b className="text h4" style={{ color: "#5c068c" }}>Address Verification Needed</b>
                <FontAwesomeIcon icon={faClose} className="btn text" onClick={onClose} />
            </Modal.Header>
            <Modal.Body className="pb-4 text-center">
                <div className="pb-3">
                    <strong style={{ textAlign: 'center', color: '#ff0000', paddingBottom: '24px' }}>The address was not found. An incorrect address may cause issues with the application. Would you like to use the suggested address?</strong>
                </div>
                <div className="p-3 bg-gray rounded mb-2 border text-muted">
                    <strong>Original Address:</strong> <br /> {originalAddress}
                </div>
                <div className="p-3 bg-primary text rounded border" style={{ color: "#5c068c" }}>
                    <strong>Suggested Address:</strong> <br /> {suggestedAddress}
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between border-0">
                <button className="btn flex-grow-1 me-2 cancel-button" onClick={onUseOriginalAddressClicked}>
                    Use Original Address
                </button>
                <button className="btn flex-grow-1 confirm-button" onClick={onUseSuggestedAddressClicked}>
                    Use Suggested Address
                </button>
            </Modal.Footer>
        </Modal >
    );
};

export default AddressSuggestedForm;