import { ASSOCIATESTORECODE } from "../actions/actionTypes";
import { initialState } from "../initialState";


const reducer = (state = {}, action) => {
  switch (action.type) {
    case ASSOCIATESTORECODE:
      return {...state, associateStoreDetails: action.payload.storeCode};
    default:
      return state;
  }
};
export default reducer;