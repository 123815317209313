import React from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as tenderActions from "../redux/actions/tenders.actions";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import GetInfo from "./component.getInfo";

class DanielsAutoPay extends React.Component {
  constructor() {
    super();
    this.state = {
      iframeUrl: "",
      iframeError: "",
    };
  }

  async componentDidMount() {
      let obj = {
        custStore_Id: this.props.currentTabAccnStore?.split("-")[1],
        cust_Id: this.props.currentTabAccnStore?.split("-")[0],
        eSatementRequest: false,
        emplNo: this.props.tenderObj.employee_id,
        emplStoreNo: JSON.parse(localStorage.getItem("associateStores")),
        pinNo: this.props.tenderObj.pin,
        allowlogout: "N"
      }
      // await trackPromise(
        await this.props.actions.getDanielsDownPayment(obj).then((response) => {
          if (response) {
            if (response?.result?.toLowerCase() === "error") {
              this.setState({ iframeError: response?.errorMsg });
            } else {
              this.setState({
                iframeUrl: window.env.LINKS_TENDERS_AND_DANIELS_AUTOPAY_URL +
                  "pgs/recurring.aspx?key=" +
                  response?.urlkey,
                showdanielsAutoPay : !this.state.showdanielsAutoPay,
              });
            }
          }
        })
      // );
  }
  render() {
    return (
      <Modal
        show={this.props.showdanielsAutoPay}
        id="danielsAutoPay"
        centered
        size="lg"
      >
        <Modal.Header className="pb-0">
          <b className="text-secondary h4">Daniel's AutoPay</b>
          {(this.state.iframeError === "" && this.state.iframeUrl === "") ? null :
            <FontAwesomeIcon
              icon={faClose}
              className="btn"
              onClick={() => this.props.triggerDanielsAutoPay()}
            />
          }
        </Modal.Header>
        <Modal.Body className="pb-0" style={{ minWidth: "1030px !important" }}>
        {this.state.iframeError === "" && this.state.iframeUrl === "" ? (
            <>
            <div
              style={{
                flexDirection: "column",
                opacity : "0.5"
              }}
              className="d-flex justify-content-center align-items-center w-100"
            >
              <Loader
                type="ThreeDots"
                color="#5C068C"
                height="100"
                width="100"
              />
            </div>
            </>
          ) : this.state.iframeError?.length > 0 ? (
            <>
              <h2 className="text-danger text-center mt-3">ERROR!</h2>
              <p className="text-center mb-5">{this.state.iframeError}</p>
            </>
          ) : (
            <iframe
              title="danielsAutoPay"
              src={"" + this.state.iframeUrl + ""}
              height="500"
              width="100%"
            ></iframe>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(tenderActions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(DanielsAutoPay);
