import React from "react";
import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import APIFailedMessage from "./component.apiFailedMessage";
import { bindActionCreators } from "redux";
import * as customerLookupActions from "../redux/actions/customerLookup.actions";
import { Toast, ToastContainer } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

class EmailApplyLink extends React.Component{
  constructor() {
    super();
    this.state = {
        emailAdr : "",
        emailAdrErr : "",
        isenableButton : false,
        toastModal: false,
        isSMSError : false,
    };
  }

  emailValidate = (e) => {
    let res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    this.setState({ emailAdr: e.target.value });
    if (!e.target.value) {
      this.setState({ emailAdrErr: "", isenableButton: false });
    } else if (!res.test(e.target.value)) {
      this.setState({
        emailAdrErr: "Enter a valid email address....",
        isenableButton: false,
      });
    } else {
      this.setState({ emailAdrErr: "", isenableButton: true });
    }
}

sendApplyEmailLinks = (event) => {
  let emailApplyLink = window.env.LINKS_EMAIL_APPLY_LINK;
    let obj = {
      toEmail: this.state.emailAdr,
      cc: "",
      subject: "Daniels Jewelers In-Store Portal",
      emailContent:
        "Please click "+emailApplyLink+" to view Daniel's Jewelers credit application.",
      attachmentContent: "",
      is_insurance: "",
      financial_partner : "",
    };
    trackPromise(
      this.props.action
        .sendApplyEmailLink(obj)
        .then((response) => {
          if (response.requestStatus?.toLowerCase() === "success") {
            this.setState({
              toastModal: true,
              emailAdr: "",
              emailAdrErr: "",
              isenableButton: false,
              isSMSError : false,
            });
          } else if (response.errorMessage?.length > 0) {
            this.setState({ toastModal: !this.state.toastModal, isSMSError : true });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
}

  render() {
    return (
      <>
      <ToastContainer className="p-3" id="emailLinkToastContainer">
          <Toast
            bg={this.state.isSMSError ? "danger" : "success"}
            onClose={() => {
              this.setState({ toastModal: !this.state.toastModal });
            }}
            show={this.state.toastModal}
            delay={3000}
            animation
            autohide
          >
            <Toast.Body className="text-white ml-2 mr-2">
              {this.state.isSMSError ? (
                <FontAwesomeIcon icon={faCircleXmark} className="pr-2" />
              ) : (
                <FontAwesomeIcon icon={faCircleCheck} className="pr-2" />
              )}
              {this.state.isSMSError
                ? "Error in sending sms"
                : "Link sent successfully"}
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <div className="row">
          <div className="col-12 col-md-8 offset-md-4">
            <div className="row">
              <div className="col-12 col-md-6 mt-4 pr-lg-0 pr-md-0 pl-md-0">
                <p className="mb-2 mb-lg-0 mt-lg-2 text-md-right text-lg-right">
                  Email "Apply" link to customer
                </p>
              </div>
              <div className="col-8 col-md-4">
                <div className="form-group mt-lg-4 mt-2 mt-md-3">
                  <input
                    type="text"
                    className={
                      this.state.emailAdrErr
                        ? "form-control border-dark-error"
                        : "form-control border-dark"
                    }
                    name="txtEmailAdr"
                    id="txtEmailAdr"
                    placeholder="Enter Email Address"
                    onChange={(event) => this.emailValidate(event)}
                    value={this.state.emailAdr}
                    maxLength="100"
                  ></input>
                  {this.state.emailAdrErr && (
                    <div style={{ color: "red", paddingBottom: 10 }}>
                      {this.state.emailAdrErr}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4 col-md-2 pl-0">
                <button
                  disabled={!this.state.isenableButton ? true : false}
                  onClick={this.sendApplyEmailLinks}
                  className="btn bg-secondary text-primary mt-lg-4 mt-2 mt-md-3 w-100 pl-0 pr-0"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        action : bindActionCreators(customerLookupActions,dispatch),
    }
}

export default connect(null, mapDispatchToProps)(EmailApplyLink);
