import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
  } from "react-bootstrap-table2-paginator";
  
  import Constants from '../utilites/constants';
  import BootstrapTable from "react-bootstrap-table-next";

const RemotePagination = ({data, page, sizePerPage = Constants.CreditAppPageSize, onTableChange,totalSize, dataField, order,columns, customTotal,selectRow, rowStyle, rowEvents, expandRow}) =>
 {
    return(
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize,
          paginationTotalRenderer: customTotal,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div
          className={` ${
            data.length !== 0 ? "creditOrPrintSummary" : "emptyCreditTable"
          }`}
        >
            <BootstrapTable
              className="pagination"
              bootstrap4
              hover
              remote={{
                sort: true,
                pagination: true,
              }}
              bordered={false}
              condensed={true}
              keyField="custAcctStat"
              data={data}
              columns={columns}
              onTableChange={onTableChange}
              selectRow={selectRow}
              rowStyle={rowStyle}
              rowEvents={rowEvents}
              sort={{ dataField: dataField, order: order }}
              expandRow={ expandRow }
              {...paginationTableProps}
            />
            <div className="d-flex justify-content-between">
              <PaginationTotalStandalone {...paginationProps} />
              <PaginationListStandalone {...paginationProps} />
            </div>
          </div>
        )}
      </PaginationProvider>
    </div>)
 };
  export default RemotePagination;