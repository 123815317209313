export default class Constants {
  static userPageSize = 10;
  static CreditAppPageSize = 5;
  static ApplicationHistorySize=20;
  static LookUpPageSize = 5;
  static signOutInMins = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 27, 28, 29, 30,
  ];
  static augmentesTimeMinutes = [
    15,30,45,60,75,90
  ];
  static addressAutoFillMaxRecord = 20;
  static addressAutoFillOptions = "poboxes:true";
  static addressAutoFillCols = "AddrObjectResultCode";
}
