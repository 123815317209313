import { ApiWrapper } from "../../api/thunk.helper";
import HttpMethods from "../../redux/_http.methods";

export function getMagixDetails(searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: searchFilter,
      ResourceUrl: "v1/magix/search-customers",
      isMagix : true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getCustomers(searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: searchFilter,
      ResourceUrl:
        "v1/customers?PageIndex=" +
        searchFilter.pageIndex +
        "&PageSize=" +
        searchFilter.pageSize,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function sendApplyLink(searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: searchFilter,
      ResourceUrl: "v1/customers/twilio-mobile-sendcodeormsg",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}


export function sendApplyEmailLink(searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: searchFilter,
      ResourceUrl: "v1/customers/search-customers/sendEmail",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getCustomers_new(searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: searchFilter,
      ResourceUrl: "v1/customers/search-customerLookup",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}