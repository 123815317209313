import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

const RemotePagination = ({data, page, sizePerPage = 6, totalSize, onTableChange, columns, customTotal, rowStyle, expandRow}) =>
{
  return(
  <div>
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize,
        paginationTotalRenderer: customTotal,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div   className="transactionHistoryTable"
        >
          <BootstrapTable
            className="pagination"
            bootstrap4
            hover
            remote={{
              pagination: true,
            }}
            bordered={false}
            condensed={true}
            keyField="id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            {...paginationTableProps}
            expandRow={ expandRow }
            rowStyle={ rowStyle }
          />
          <div className="d-flex justify-content-between">
            <PaginationTotalStandalone {...paginationProps} />
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </PaginationProvider>
  </div>)
};

export default RemotePagination;
