import Moment from "moment";

export const DanielsOffersStatus = (isDaniel,createdDate,selectedMagixAcct) => {
   if (isDaniel) {
    return (
      "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Applied</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span >" +
      (createdDate === undefined ? "" : createdDate) +
      "</span></span><br>"+
      ((localStorage.getItem("magix_account_no") != "null" && localStorage.getItem("magix_account_no") != "") ? 
      "<span class='offersDesign'><b>Current Balance </b>: $ "+(parseFloat(selectedMagixAcct?.currentBalance)?.toFixed(2))+"</span>" : "" )
    );
  } else if(localStorage.getItem("magix_account_no") != "null" && localStorage.getItem("magix_account_no") != "") {
    return (
      "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span><br>"+
      "<span class='offersDesign'><b>Decision Date </b>: -</span><br>"+
      "<span class='offersDesign'><b>Current Balance </b>: $ "+(selectedMagixAcct !== undefined ? parseFloat(selectedMagixAcct?.currentBalance)?.toFixed(2) : "-")+"</span>"
    );
  } else {
    return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span>";
  }
};

export const FortivaOffersStatus = (offers) => {
  if (offers) {
    if (offers?.applicationStatus === "null" || offers?.applicationStatus === null) {
      return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span>";
    }
    else if (offers.applicationStatus?.toLowerCase() === "a") {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span>Approved</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span >" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span><br>"+
        "<span class='offersDesign'><b>Open To Buy </b>:</span><span> "+((offers?.openToBuy !== null && offers?.openToBuy !== "null") ? "$ "+offers?.openToBuy : "-")+"</span><br>"+
        "<span class='offersDesign'><b>Offer Status </b>: "+
        ((offers?.responseType==null && offers?.responseTypeOutcome==null) ? "Needs Customer Review" : ((offers?.responseType=="AcceptOffer" && offers?.responseTypeOutcome=="S") ? "Customer Accepted" : "Customer Rejected"))+
        "</span>"
      );
    } else if (
      offers.applicationStatus?.toLowerCase() === "d" ||
      offers.applicationStatus?.toLowerCase() === "ec" ||
      offers.applicationStatus?.toLowerCase() === "cr" ||
      offers.applicationStatus?.toLowerCase() === "r"
    ) {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1 text-danger'>Declined</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span >" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else if (offers.applicationStatus?.toLowerCase() === "p") {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1 text-danger'>Error 1</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span >" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else if (offers.applicationStatus?.toLowerCase() === "ca") {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1 text-danger'>Error 2</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span >" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else if (offers.applicationStatus?.toLowerCase() === "an") {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1 text-danger'>Error 3</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span >" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else {
      return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>No Offers</span></span>";
    }
  } else {
    return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span>";
  }
};

export const SynchronyOffersStatus = (offers,createdDate) => {
  if (offers) {
    if (offers?.applicationId) {
      if (offers.status?.toLowerCase() === "approved") {
        return (
          "<span class='offersDesign'><b>Decision Status </b>: <span>" +
          offers.status +
          "</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
          Moment(offers.createdDate).format("MM/DD/YYYY") +
          "</span></span><br>"+
          "<span class='offersDesign'><b>Open To Buy </b>:</span><span>"+((offers?.openToBuy !== null && offers?.openToBuy !== "null") ? "$ "+offers?.openToBuy : "")+"</span>"
        );
      } else if (
        offers.status?.toLowerCase() == "declined" ||
        offers.status?.toLowerCase() == "denied"
      ) {
        return (
          "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1 text-danger'>Declined</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
          Moment(offers.createdDate).format("MM/DD/YYYY") +
          "</span></span><br>"+
          "<span class='offersDesign'><b>Open To Buy </b>:</span><span>"+((offers?.openToBuy !== null && offers?.openToBuy !== "null") ? "$ "+offers?.openToBuy : "")+"</span>"
        );
      } else if (
        offers.status?.toLowerCase() == "pending" ||
        offers.status?.toLowerCase() == "pre-approved"
      ) {
        return (
          "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Pending</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
          Moment(offers.createdDate).format("MM/DD/YYYY") +
          "</span></span><br>"+
          "<span class='offersDesign'><b>Open To Buy </b>:</span><span>"+((offers?.openToBuy !== null && offers?.openToBuy !== "null") ? "$ "+offers?.openToBuy : "")+"</span>"
        );
      } else if (offers.status?.toLowerCase() == "in process") {
        return (
          "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>In Process</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
          Moment(offers.createdDate).format("MM/DD/YYYY") +
          "</span></span><br>"+
          "<span class='offersDesign'><b>Open To Buy </b>:</span><span>"+((offers?.openToBuy !== null && offers?.openToBuy !== "null") ? "$ "+offers?.openToBuy : "")+"</span>"
        );
      } else {
        return (
          "<span class='offersDesign'><b>Decision Status </b>:<span class='mb-1'>" +
          offers.errorMessage +
          "</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
            Moment(offers.createdDate).format("MM/DD/YYYY") +
            "</span></span><br>"+
            "<span class='offersDesign'><b>Open To Buy </b>:</span><span>"+((offers?.openToBuy !== null && offers?.openToBuy !== "null") ? "$ "+offers?.openToBuy : "")+"</span><br>"
          );
      }
    } else {
      return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Applied</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" + (createdDate!==undefined?Moment(createdDate).format("MM/DD/YYYY"):"")+
       "</span></span><br>"+
       "<span class='offersDesign'><b>Open To Buy </b>:</span><span>"+((offers?.openToBuy !== null && offers?.openToBuy !== "null") ? "$ "+offers?.openToBuy : "")+"</span>"
    }
  } else {
    return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span>";
  }
};

export const ProgressiveOffersStatus = (offers) => {
  if (offers) {
    if (offers.status?.toLowerCase() === "approved") {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span>" +
        offers.status +
        "</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else if (offers.status?.toLowerCase() === "in process") {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span>" +
        offers.status +
        "</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else if (
      offers.status?.toLowerCase() === "pre-approved" ||
      offers.status?.toLowerCase() === "pending"
    ) {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span>Pending</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else if (offers.status?.toLowerCase() === "denied") {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1 text-danger'>Denied</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else if (offers?.errorMessage !== "") {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1 text-danger'>Rejected</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else {
      return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>No Offers</span></span>";
    }
  } else {
    return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span>";
  }
};

export const UOwnOffersStatus = (offers) => {
  if (offers) {
    if (offers.status?.toLowerCase() === "e0") {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span>Approved</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else if (offers.status?.toLowerCase() === "e1") {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span>" +
        offers.status +
        "</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else if (offers.status?.toLowerCase() === "e4") {
      return (
        "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1 text-danger'>Declined</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
        Moment(offers.createdDate).format("MM/DD/YYYY") +
        "</span></span>"
      );
    } else if (offers?.errorMessage !== "") {
      return ("<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1 text-danger'>Error</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span>" +
          Moment(offers.createdDate).format("MM/DD/YYYY") +
          "</span></span>");
    } else {
      return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>No Offers</span></span>";
    }
  } else {
    return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span>";
  }
};

//Daniels offer section for application history and credit application page
export const DanielsOffersCard = (isDaniel,createdDate,selectedMagixAcct) => {
   if (isDaniel) {
    return (
      "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Applied</span></span><br><span class='offersDesign'><b>Decision Date </b>: <span >" +
      (createdDate === undefined ? "" : createdDate) +
      "</span></span><br>"
    );
  } else {
    return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span>";
  }
};
