export default class RouteName {
  //Portal pages
  static STORENUMBER = "/";
  static LOGIN = "/login";
  static HOME = "/home";
  static CUSTOMERLOOKUP = "/customer-lookup";
  static CUSTOMERDETAILS = "/customer-details/:id";
  static VIEWOFFERS = "/view-offers";
  static APPLYNOW = "/apply-now";
  static APPLICATIONHISTORY="/application-history";
  static SYNCHRONYLOOKUP="/synchrony-lookup";
  static TRANSACTIONHISTORY="/transaction-history";
  static DANIELSACCOUNTSUMMARY="/account-summary-daniels";
  static SYNCHRONYACCOUNTSUMMARY="/account-summary-synchrony";
  static PROGRESSIVEACCOUNTSUMMARY="/account-summary-progressive";
  static UOWNACCOUNTSUMMARY="/account-summary-uown";
  static FORTIVAACCOUNTSUMMARY="/account-summary-fortiva";

  //Admin Pages
  static ADMIN= "/admin";
  static ADMINUSERLIST="/admin-user";
  static ADMINSTORE= "/admin-store";
  static ADMINDISTRICTS="/admin-districts";
  static ADMINUSERROLE= "/admin-userRole";
  static ADMINUSERRIGHTS="/admin-userRights";
  static CREDITAPPLICATIONLIST="/credit-application";
  static STORESLIST="/stores";
  static ADMINFEATURES="/admin-features"
  static ADMINCUSTOMERDETAILSUPDATE="/admin-customer-details-update";
  static APPLICATIONREQRESPONSE="/application-req-response";
  static ADMININSURANCEMATRIX = "/admin-insurance-matrix";
  static BILLINGCYCLE = "/billing-cycle";
  //Disclosure
  static RICA="/rica";
}
