import { ApiWrapper } from "../../api/thunk.helper";
import HttpMethods from "../../redux/_http.methods";
import Constants from "../../utilites/constants";
import { ADDFORTIVA } from "./actionTypes";

export const addFortivaObj = (obj) => ({
  type: ADDFORTIVA, payload: {obj: obj}
});

export function getCodeMaster(searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: searchFilter,
      ResourceUrl:
        "v1/customers/apply-now/code-master/" +
        searchFilter.type
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function getIpAddressNew() {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      ResourceUrl:"https://api.ipify.org/",
      thirdPartyApi:true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

  export function saveIpAddress(ipAddress,associate) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Post(),
        Resource: ipAddress,
        ResourceUrl:"v1/associates/saveUserIPbyStore?userIP="+ipAddress+"&associate_Id="+associate,
        isAssociate: true
       
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

  export function getIpAddressAndLocation(ipAddress) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        ResourceUrl:`https://www.ip2location.com/demo/${ipAddress}`,
        thirdPartyApi:true
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

export function creditSubmit(reqData, searchFilter) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Post(),
            Resource: reqData,
            ResourceUrl: "v1/customers/apply-now/credit/submit?SessionId="+searchFilter.SessionId+"&associateEmail="+searchFilter.associateEmail,
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                  // if(reqData.isSynchrony && reqData.isFortiva && reqData.customerId==0){
                  //   dispatch (addFortivaObj(reqData));
                  // }
                return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
}
export function creditSubmitFortiva(customerId,saveCustResponseFortiva,associateId,store_Id ) {
  let isFortiva=true;
  let isElectronicDisclosure=true;
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: customerId,
          ResourceUrl: "v1/customers/apply-now/submit-credit-fortiva?customer_Id="+customerId+"&saveCustResponseFortiva="+saveCustResponseFortiva+"&isFortiva="+isFortiva+"&isElectronicDisclosure="+isElectronicDisclosure+"&associateId="+associateId+"&store_Id="+store_Id,
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
              return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function creditSubmitProgUown(reqData, searchFilter) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: reqData,
          ResourceUrl: "v1/customers/Update-waterfall-check?applicationId="+searchFilter.applicationId+"&credit_Id="+searchFilter.creditId,
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
              return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function mobilesendcodeormsg(searchFilter) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Post(),
            Resource: searchFilter,
            ResourceUrl: "v1/customers/twilio-mobile-sendcodeormsg",
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function loadAutoPay() {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Get(),
            Resource: "",
            ResourceUrl:
                "v1/customers/apply-now/credit/loadinfinity-autppay/"
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function sessionIdVerification(searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: searchFilter,
      ResourceUrl: "v1/associates/validate-session?sessionId="+encodeURIComponent(searchFilter),
      anonymouseApi:true,
      isAssociate : true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function cancelSessionLink(sessionID) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Delete(),
          Resource: sessionID,
          ResourceUrl: 'v1/customers/apply-now/credit/cancel?SessionId='+sessionID
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function djoMatchFoundValidate(searchFilter) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: searchFilter,
          ResourceUrl: "v1/customers/search-customers/djo-validate",
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function getAddress(searchObj) {
    return function (dispatch) {
      let addressAutoFillKey = window.env.KEYS_ADDRESS_AUTOFILL_KEY;

      const data = {
        MethodType: HttpMethods.Get(),
        ResourceUrl:"ExpressAddress?format=JSON&id="+addressAutoFillKey+"&line1="+searchObj.line1+"&city="+searchObj.city+"&state="+searchObj.state+"&maxrecords="+Constants?.addressAutoFillMaxRecord+"&opt="+Constants?.addressAutoFillOptions+"&cols="+Constants?.addressAutoFillCols,
        isAutoFill:true
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }
export function validateABANumber(cust_Id,searchFilter) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Get(),
          Resource: searchFilter,
          ResourceUrl: 'v1/customers/ValidateABANumber_Progressive?customerId='+cust_Id+'&ABANumber='+searchFilter,
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function fortivaDisclosures(applicationDisclosure) {
  let store_Id= localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
  applicationDisclosure.store_Id=store_Id;
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: applicationDisclosure,
          ResourceUrl: '/v1/customers/legal-document-fortiva',
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function validateDuplicateCustomer(ssn,dob) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Get(),
          Resource: "",
          ResourceUrl: 'v1/customers/GetExsistingCustomer?ssn='+ssn+'&birthDate='+dob,
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}
export function createCustomer(customerInfo) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: customerInfo,
          ResourceUrl: "v1/customers/createcustomer-Application",
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function checkIsMobileVerifiedSameDay(mobNo) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Post(),
        Resource: mobNo,
        ResourceUrl:
          "v1/customers/IsMobileVerifiedSameDay?mobileNo="+mobNo
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

  export function getPopUpMessage(pageName) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Get(),
            Resource: pageName,
            ResourceUrl: "v1/associates/GetPopUpMessage?pageName="+pageName,
            isAssociate: true
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
  }
  
  export function postPopUpMessage(obj) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Post(),
            Resource: obj,
            ResourceUrl: "v1/associates/UpdateMessage?name="+obj.name+"&description="+obj.description+"&modifiedBy="+obj.modifiedBy+"&pageName="+obj.pageName,
            isAssociate: true
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
  }