import React, { Component } from "react";
import danielsLogo from "../../images/DanielBlack.png";
import "./component.customerLookup.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import * as customerLookupActions from "../../redux/actions/customerLookup.actions";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HamburgerMenu from "../../components/component.hamburgerMenu";
import DatePicker from "react-datepicker";
import { IMaskInput } from "react-imask";
import formatPhoneNumber from "../../components/component.formatPhoneNumber";
import GetDateFromMMDDYYYY from "../../components/component.GetDateFromMMDDYYYY";
import {
  faMinus,
  faPlus,
  faCircle,
  faSortDown,
  faQuestion,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import {Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as authActions from "../../redux/actions/authActions";
import { trackPromise } from "react-promise-tracker";
import IdleTimeModal from "../../components/component.idleTimeModal";
import * as adminUserListActions from "../../redux/actions/adminUserList.actions";
import Moment from "moment";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import ConfirmDialog from "../../components/component.confirmDialog";
import RemotePagination from "./component.TableView";
import { CardView,MagixCardView } from "./component.CardView";
import Constants from "../../utilites/constants";
import EmailApplyLink from "../../components/component.emailApplyLink";
import * as applyNowActions from '../../redux/actions/applyNow.actions';
import * as adminActions from "../../redux/actions/admin.actions";
import { Modal } from "react-bootstrap"

class CustomerLookup extends Component {
  constructor() {
    super();
    this.state = {
      toggled: false,
      show: false,
      isSerarch: false,
      lookupResults: [],
      magixlookupResults: [],
      isContinuePortal: false,
      isModal: false,
      creditModal: false,
      customerId: "",
      magixassociateId: "",
      isLoad: false,
      isPaginate: false,
      magixisPaginate: false,
      noDataFound: false,
      magixnoDataFound: false,
      searchObj: {
        ssn: "",
        phone: "",
        accountId: "",
        danielsAccountId:"",
        id: "",
        name: "",
        birthdate: "",
        zipCode: "",
        pageIndex: 0,
        pageSize: 0,
        sortBy: "",
        sortOrder: "",
      },
      table_select: {
        row_index: null,
        type: "",
      },
      isSort: true,
      magixisSort: true,
      pageNo: 1,
      magixpageNo: 1,
      from: 1,
      magixfrom: 1,
      to: 5,
      magixto: 5,
      isFirstTime: false,
      dataField: "modifiedDate",
      magixdataField: "maxCustBal",
      order: "desc",
      magixorder: "asc",
      totalCustomers: undefined,
      magixtotalCustomers: undefined,
      hasAdminAccess: false,
      hasPortalAccess: false,
      roles: [],
      site_page_details: [],
      sitePages: [],
      userRights: [],
      permission_status: "",
      lookupResultGrid: true,
      magixGrid: true,
      phoneNumber: "",
      toastModal: false,
      applyNowPermission: {},
      magixRowIndex: null,
      magixRowStore: null,
      phoneNumErr: false,
      popupShow: false,
      double_data: {
        click_id: null,
        click_no: 0,
      },
      getCodeMaster: {
        suffix: [],
        house_info: [],
        state: [],
      },
      validateMagix:false
    };
  }

  handleModal = () => {
    this.setState({ show: !this.state.show, toggled: !this.state.toggled });
  };

  onChange(event) {
    if (event.target.name === "phone") {
      let reg = /^[^a-zA-Z]+$/;
      if (event.target.value === "" || reg.test(event.target.value)) {
        this.setState({
          searchObj: {
            ...this.state.searchObj,
            phone: event.target.value?.trimStart(),
          },
        });
      }
    } else if (event.target.name === "ssn") {
      let reg = /^[^a-zA-Z]+$/;
      if (event.target.value === "" || reg.test(event.target.value)) {
        this.setState({
          searchObj: {
            ...this.state.searchObj,
            ssn: event.target.value?.trimStart(),
          },
        });
      }
    } else if (event.target.name === "lastName") {
      this.setState({
        searchObj: {
          ...this.state.searchObj,
          name: event.target.value?.trimStart(),
        },
      });
    } else if (event.target.name === "id") {
      this.setState({
        searchObj: {
          ...this.state.searchObj,
          id: event.target.value?.trimStart(),
        },
      });
    } else if (event.target.name === "accountId") {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({
          searchObj: { ...this.state.searchObj, accountId: event.target.value },
        });
      }
    } else if (event.target.name === "zipCode") {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({
          searchObj: { ...this.state.searchObj, zipCode: event.target.value },
        });
      }
    }
    else if (event.target.name === "danielsAccountId") {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({
          searchObj: { ...this.state.searchObj, danielsAccountId: event.target.value },
        });
      }
    }

    //Storing searchObj to localStorage
    let searchObj = Object.assign({}, this.state.searchObj);
    for (let key in searchObj) {
      if (key === event.target.name) {
        searchObj[key] = event.target.value;
      }
    }
    sessionStorage.setItem("searchObj", JSON.stringify(searchObj));
  }

  onDOBChange(event) {
    if (event === null) {
      this.setState({
        searchObj: { ...this.state.searchObj, birthdate: "" },
      });
    } else {
      this.setState({
        searchObj: {
          ...this.state.searchObj,
          birthdate: Moment(event).format("MM/DD/YYYY"),
        },
      });
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.state.hasPortalAccess) {
      this.setState({ isModal: true });
    } else {
      sessionStorage.setItem("searchObj", JSON.stringify(this.state.searchObj));
      this.setState(
        {
          magixRowIndex: "",
          noDataFound: false,
          magixnoDataFound: false,
          isSerarch: true,
          lookupResults: [],
          magixlookupResults: [],
          totalCustomers: undefined,
          magixtotalCustomers: undefined,
          pageNo: 1,
          magixpageNo: 1,
          customerId: "",
          magixassociateId: "",
          isContinuePortal: false,
        },
        () => {
          this.loadData(this.state.searchObj);
          this.getMagixData(this.state.searchObj);
        }
      );
    }
  };

  clearSearch() {
    this.setState({
      lookupResults: [],
      magixlookupResults: [],
      magixnoDataFound: false,
      noDataFound: false,
    });
    this.setState({
      searchObj: {
        ...this.state.searchObj,
        phone: "",
        ssn: "",
        name: "",
        birthdate: "",
        id: "",
        accountId: "",
        danielsAccountId:"",
        zipCode: "",
      },
    });
    sessionStorage.removeItem("searchObj");
  }

  paginationWithIndex = (page) => {
    let searchObj = this.state.searchObj;
    let obj = {
      phone: searchObj.phone,
      name: searchObj.name,
      accountId: searchObj.accountId,
      danielsAccId :searchObj.danielsAccountId,
      id: searchObj.id,
      ssn: searchObj.ssn,
      zipCode: searchObj.zipCode,
      birthdate: searchObj.birthdate,
      pageIndex: page,
      pageSize: Constants.LookUpPageSize,
      sortBy: this.state.dataField,
      sortOrder: this.state.order,
    };
    window.scrollTo(0, 0);
    trackPromise(
      this.props.actions
        .getCustomers_new(obj)
        .then((response) => {
          if (response.data.length === 0) {
            this.setState({ noDataFound: true });
          } else {
            response.data.map((item) => {
              item.name =
                (item.firstName == null ? "" : item.firstName + " ") +
                (item.middleName == null ? "" : item.middleName + " ") +
                (item.lastName == null ? "" : item.lastName);
              item.modifiedDate = this.GetDateFromString(item.modifiedDate);
              if (item.mobileNo != null) {
                if (!item.mobileNo.includes("-")) {
                  item.mobileNo = formatPhoneNumber(item.mobileNo);
                }
              }
            });
            this.setState({
              lookupResults: response.data,
              isLoad: true,
              totalCustomers: response.totalCount,
              isPaginate: false,
              pageNo: page,
            });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  };
  magixpaginationWithIndex = (page) => {
    let searchObj = this.state.searchObj;
    let obj = {
      phone: searchObj.phone.trim(""),
      ssn: searchObj.ssn.trim(""),
      name: searchObj.name.trim(""),
      birthDate: searchObj.birthdate,
      id: searchObj.id.trim(""),
      accountId: searchObj.accountId.trim(""),
      danielsAccId :searchObj.danielsAccountId.trim(""),
      zipCode: searchObj.zipCode.trim(""),
      pageIndex: page,
      pageSize: Constants.LookUpPageSize,
      sortBy: (this.state.magixdataField === "customer_Id") ? "account" : (this.state.magixdataField === "strbalanceDue") ? "balanceDue" : this.state.magixdataField,
      sortOrder: this.state.magixorder,
    };
    window.scrollTo(0, 0);
    trackPromise(
      this.props.actions
        .getMagixDetails(obj)
        .then((response) => {
          if (response.data.length === 0) {
            this.setState({ magixnoDataFound: true });
          } else {
            response.data.map((item) => {
              item.name =
                (item.firstName == null ? "" : item.firstName + " ") +
                (item.middleName == null ? "" : item.middleName + " ") +
                (item.lastName == null ? "" : item.lastName);
            });
            this.setState({
              magixlookupResults: response.data,
              isLoad: true,
              magixtotalCustomers: response.totalCount,
              isPaginate: false,
              magixpageNo: page,
            });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  };

  componentDidMount() {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", this.actionHandler);
    if (JSON.parse(sessionStorage.getItem("isLoggedIn"))) {
      localStorage.setItem("magix_account_no", "");
      sessionStorage.setItem("multipleMagixId","");
      let searchObj = JSON.parse(sessionStorage.getItem("searchObj"));
      if (searchObj) {
        this.setState({ searchObj: searchObj });
        this.loadData(searchObj);
        this.getMagixData(searchObj);
      }
      sessionStorage.setItem("isNewApplication", "false");
      sessionStorage.setItem("fortivaOffer_Accepted", undefined);
      sessionStorage.setItem("updated_fortiva_response", null);
      localStorage.removeItem("fortivaAPIErrorMsg");
      let hasAdminAccess = localStorage.getItem("hasAdminAccess");
      hasAdminAccess = JSON.parse(hasAdminAccess);
      this.setState({ hasAdminAccess: hasAdminAccess });
      let hasPortalAccess = localStorage.getItem("hasPortalAccess");
      hasPortalAccess = JSON.parse(hasPortalAccess);
      this.setState({ hasPortalAccess: hasPortalAccess });
      trackPromise(
        this.props.userRightsactions
          .getUserRights()
          .then((response) => {
            if (response) {
              this.setState({
                roles: response.roles,
                site_page_details: response.sitePageDetails,
                sitePages: response.sitePages,
                userRights: response.userRights,
              });
              this.getPermissions();
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
      let districtList = {};
      this.props.userListactions
        .getDistricts()
        .then((response) => {
          if (response) {
            districtList = response.data;
            this.props.userListactions
              .getUser(localStorage.getItem("loggedUserEmail"))
              .then((response) => {
                if (response) {
                  sessionStorage.setItem(
                    "userStoresInfo",
                    JSON.stringify(response)
                  );
                  let userObj = {
                    id: response.id,
                    associateEmail: response.associateEmail,
                    userName: response.displayName,
                    userId: response.userId,
                    userRole: response.roleName,
                    district: response.district,
                    status: response.status,
                    homeStore: response.storeCode,
                    createdBy: response.createdBy,
                    effectiveDate: response.effectiveDate,
                    allowPortalAccess: response.allowPortalAccess,
                    lastActivity: Moment(new Date()).format("MM/DD/YYYY"),
                  };
                  this.props.userListactions
                    .editUser(userObj, true)
                    .then((response) => {})
                    .catch((err) => {
                      APIFailedMessage.displayError(err);
                    });
                  let district = response.district?.split(",");
                  district = district.map((val) => {
                    return val.toLowerCase();
                  });
                  let distrobj = districtList.filter((item) => {
                    return district?.includes(item.name.toLowerCase());
                  });
                  distrobj = distrobj.map((val) => {
                    return val.id;
                  });
                  if (distrobj) {
                    this.props.userListactions
                      .getStoresByMultipleDistrictId(distrobj)
                      .then((response) => {
                        if (response) {
                          sessionStorage.setItem(
                            "storeList",
                            JSON.stringify(response.data)
                          );
                        }
                      })
                      .catch((err) => {
                        APIFailedMessage.displayError(err);
                      });
                  }
                }
              })
              .catch((err) => {
                APIFailedMessage.displayError(err);
              });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        });

         // get code master
      this.getCodeMasterDetails("Suffix");
      this.getCodeMasterDetails("HousingInfo");
      this.getCodeMasterDetails("State");
      this.getCodeMasterDetails("State_Credit");
      this.getFeatures();
      sessionStorage.setItem("createdDate", "");
      sessionStorage.setItem("addOn", false);
      sessionStorage.setItem("reOpen", false);
      sessionStorage.setItem("newApplication", false);
    } else this.props.history.push("/");
  }

  getFeatures() {
    trackPromise(
        this.props.adminActions.getSignOutTime().then(
          response=>{
            if(response){
              sessionStorage.setItem("coBorrower",response?.isCoBorrowerEnable);
            }
          }
        ).catch(err=>{
           APIFailedMessage.displayError(err);
        })
    )
  }

  getCodeMasterDetails = async (type) => {
    trackPromise(
      this.props.applyNowActions.getCodeMaster({ type: type }).then((response) => {
        if (response.totalCount > 0) {
          if (type === "Suffix")
            this.setCodeMasterState("suffix", response.data);
          if (type === "HousingInfo")
            this.setCodeMasterState("house_info", response.data);
          if (type === "State") 
            this.setCodeMasterState("state", response.data);
        }
      })
    );
  };

  setCodeMasterState = (type, data) => {
    this.setState({
      getCodeMaster: { ...this.state.getCodeMaster, [type]: data },
    });
  };
  handleToggleSidebar() {
    this.setState({
      toggled: !this.state.toggled,
    });
  }
  loadData(searchObj) {
    let obj = {
      phone: searchObj.phone.trim(""),
      ssn: searchObj.ssn.trim(""),
      name: searchObj.name.trim(""),
      birthdate: searchObj.birthdate,
      id: searchObj.id.trim(""),
      accountId: searchObj.accountId.trim(""),
      danielsAccId :searchObj.danielsAccountId.trim(""),
      zipCode: searchObj.zipCode.trim(""),
      pageIndex: 1,
      pageSize: Constants.LookUpPageSize,
      sortBy: this.state.dataField,
      sortOrder: this.state.order,
    };
    trackPromise(
      this.props.actions
        .getCustomers_new(obj)
        .then((response) => {
          if (response) {
            if (response.data.length === 0) {
              this.setState({ noDataFound: true, lookupResultGrid: true });
            } else {
              response.data.map((item) => {
                item.name =
                  (item.firstName == null ? "" : item.firstName + " ") +
                  (item.middleName == null ? "" : item.middleName + " ") +
                  (item.lastName == null ? "" : item.lastName);
                item.modifiedDate = this.GetDateFromString(item.modifiedDate);
                if (item.mobileNo != null) {
                  if (!item.mobileNo.includes("-")) {
                    item.mobileNo = formatPhoneNumber(item.mobileNo);
                  }
                }
              });
              this.setState({
                lookupResults: response.data,
                isLoad: true,
                totalCustomers: response.totalCount,
                isFirstTime: true,
                lookupResultGrid: true,
              });
            }
          } else {
            this.setState({ noDataFound: true, lookupResultGrid: true });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  }
  getMagixData(searchObj) {
    let obj = {
      phone: searchObj.phone.trim(""),
      ssn: searchObj.ssn.trim(""),
      name: searchObj.name.trim(""),
      birthDate: searchObj.birthdate,
      id: searchObj.id.trim(""),
      accountId: searchObj.accountId.trim(""),
      danielsAccId :searchObj.danielsAccountId.trim(""),
      zipCode: searchObj.zipCode.trim(""),
      pageIndex: 1,
      pageSize: Constants.LookUpPageSize,
      sortBy: this.state.magixdataField,
      sortOrder: this.state.magixorder,
    };
    trackPromise(
      this.props.actions
        .getMagixDetails(obj)
        .then((response) => {
          if (response) {
            if (response.data.length === 0) {
              this.setState({ magixnoDataFound: true, magixGrid: true });
            } else {
              response.data.map((item) => {
                item.name =
                  (item.firstName == null ? "" : item.firstName + " ") +
                  (item.middleName == null ? "" : item.middleName + " ") +
                  (item.lastName == null ? "" : item.lastName);
              });
              this.setState({
                magixlookupResults: response.data,
                isLoad: true,
                magixtotalCustomers: response.totalCount,
                isFirstTime: true,
                magixGrid: true,
              });
            }
          } else {
            this.setState({ magixnoDataFound: true, magixGrid: true });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  }

  redirectToLogin = (event) => {
    event.preventDefault();
    this.setState({ popupShow: false });
    sessionStorage.removeItem("searchObj");
    window.location.pathname = "/";
  };
  handleModalPopup = () => {
    this.setState({ popupShow: !this.state.popupShow });
  };

  actionHandler = () => {
    window.history.pushState(null, document.title, window.location.href);
    this.setState({ popupShow: true });
  };
  async getPermissions() {
    let role_name = localStorage.getItem("role_name");
    const getPageDetailsArray =
      this.state.sitePages.length > 0
        ? this.state.sitePages.map((data) => {
            return this.state.site_page_details.filter((site_page_details) => {
              return site_page_details.sitePage_id === data.id;
            });
          })
        : [];
    let role_permissions = await Promise.all(getPageDetailsArray);
    const getSitepagedetails = [...role_permissions[0], ...role_permissions[1]];
    const getRole = this.state.roles.filter((roles) => {
      return roles.name.toLowerCase() === role_name.toLowerCase();
    });
    let role_id = getRole[0].id;
    const applyNowPermission = this.state.userRights.filter((user_rights) => {
      return (
        user_rights.sitePageDetail_id === getSitepagedetails[2].id &&
        user_rights.role_id === role_id
      );
    });
    this.setState({
      applyNowPermission: applyNowPermission && applyNowPermission[0],
    });
    let permisson_roles = {
      admin_settings: false,
      user_list: false,
      user_role: false,
      user_rights: false,
      customer_details: false,
      account_summary: false,
      account_summary_synchrony: false,
      account_summary_progressive: false,
      account_summary_uown: false,
      account_summary_fortiva: false,
      view_offer: false,
      apply_now: false,
      credit_application: false,
      application_history: false,
      transaction_history: false,
      store_list: false,
      features: false,
      customer_details_update: false,
      creditApp_reqResp:false,
    };
    const role_permissions_check_1 =
      getSitepagedetails.length > 0 &&
      getSitepagedetails.map((data) => {
        const role_permission_check = this.state.userRights.filter(
          (user_rights) => {
            return (
              user_rights.sitePageDetail_id === data.id &&
              user_rights.role_id === role_id
            );
          }
        );
        if (data.name === "User List") {
          permisson_roles.user_list = role_permission_check[0]?.canView;
          sessionStorage.setItem(
            "user_list_permission",
            role_permission_check[0]?.canView
          );
        } else if (data.name === "User Role") {
          permisson_roles.user_role = role_permission_check[0]?.canView;
          sessionStorage.setItem(
            "user_role_permission",
            role_permission_check[0]?.canView
          );
        } else if (data.name === "User Rights") {
          permisson_roles.user_rights = role_permission_check[0]?.canView;
          sessionStorage.setItem(
            "user_rights_permission",
            role_permission_check[0]?.canView
          );
        } else if (data.name === "Admin Settings") {
          permisson_roles.admin_settings = role_permission_check[0]?.canView;
          sessionStorage.setItem(
            "admin_settings_permission",
            role_permission_check[0]?.canView
          );
        } else if (data.name === "Customer Details") {
          permisson_roles.customer_details = role_permission_check[0]?.canView;
          sessionStorage.setItem(
            "customer_details_permission",
            role_permission_check[0]?.canView
          );
        } else if (data.name === "Account Summary") {
          permisson_roles.account_summary = role_permission_check[0]?.canView;
          sessionStorage.setItem(
            "account_summary_permission",
            role_permission_check[0]?.canView
          );
        } else if (data.name === "View Offers") {
          permisson_roles.view_offer = role_permission_check[0]?.canView;
          sessionStorage.setItem(
            "view_offer_permission",
            role_permission_check[0]?.canView
          );
        } else if (data.name === 'Add-On/Re-Open/Apply') {
          localStorage.setItem("apply_now", role_permission_check[0]?.canView);
          sessionStorage.setItem(
            "apply_now_permission",
            role_permission_check[0]?.canView
          );
          permisson_roles.apply_now = role_permission_check[0]?.canView;
        } else if (data.name === "Credit Application") {
          sessionStorage.setItem(
            "user_creditApp_permission",
            role_permission_check[0]?.canView
          );
          permisson_roles.credit_application =
            role_permission_check[0]?.canView;
        } else if (data.name === "Application History") {
          sessionStorage.setItem(
            "apphistory_permission",
            role_permission_check[0]?.canView
          );
          permisson_roles.application_history =
            role_permission_check[0]?.canView;
        } else if (data.name === "Transaction History") {
          sessionStorage.setItem(
            "transhistory_permission",
            role_permission_check[0]?.canView
          );
          permisson_roles.transaction_history =
            role_permission_check[0]?.canView;
        } else if (data.name === "Store List") {
          sessionStorage.setItem(
            "store_list",
            role_permission_check[0]?.canView
          );
          permisson_roles.store_list = role_permission_check[0]?.canView;
        } else if (data.name === "Customer Details update") {
          sessionStorage.setItem(
            "customer_details_update",
            role_permission_check[0]?.canView
          );
          permisson_roles.customer_details_update = role_permission_check[0]?.canView;
        }else if (data.name === 'Credit Application Req/Resp') {
          sessionStorage.setItem(
            "creditApp_reqResp",
            role_permission_check[0]?.canView
          );
          permisson_roles.creditApp_reqResp = role_permission_check[0]?.canView;
        } else if (data.name === "Features") {
          sessionStorage.setItem(
            "features",
            role_permission_check[0]?.canView
          );
          permisson_roles.features = role_permission_check[0]?.canView;
        } else if (data.name === "Account Summary Synchrony") {
          sessionStorage.setItem(
            "account_summary_synchrony",
            role_permission_check[0]?.canView
          );
          permisson_roles.account_summary_synchrony =
            role_permission_check[0]?.canView;
        } else if (data.name === "Account Summary Progressive") {
          sessionStorage.setItem(
            "account_summary_progressive",
            role_permission_check[0]?.canView
          );
          permisson_roles.account_summary_progressive =
            role_permission_check[0]?.canView;
        } else if (data.name === "Account Summary Uown") {
          sessionStorage.setItem(
            "account_summary_uown",
            role_permission_check[0]?.canView
          );
          permisson_roles.account_summary_uown =
            role_permission_check[0]?.canView;
        } else if (data.name === "Account Summary Fortiva") {
          sessionStorage.setItem(
            "account_summary_fortiva",
            role_permission_check[0]?.canView
          );
          permisson_roles.account_summary_fortiva =
            role_permission_check[0]?.canView;
        }
      });

    this.setState({ permission_status: permisson_roles });
  }

  creditApplication = () => {
    this.setState({ creditModal: !this.state.creditModal });
    sessionStorage.removeItem("progUownApplications");
  };

  stayInAndContinue = () => {
    sessionStorage.setItem("isNewApplication", "true");
    this.setState({ creditModal: !this.state.creditModal });
    let securityKey = window.env.KEYS_SECURITY_KEY;
    
    const CryptoJS = require("crypto-js");
    sessionStorage.setItem("logout/continue", false);
    var encryptedBytes = CryptoJS.AES.encrypt(
      securityKey,
      securityKey
    );
    localStorage.setItem("magix_account_no", "");
    sessionStorage.setItem("multipleMagixId", "");
    this.props.history.push("/apply-now?" + encryptedBytes);
  };

  logOutAndContinue = (event) => {
    event.preventDefault();
    this.setState({ creditModal: !this.state.creditModal });
    sessionStorage.clear();
    const CryptoJS = require("crypto-js");
    sessionStorage.setItem("logout/continue", true);
    var url =
      "associateEmail=" +
      localStorage.getItem("loggedUserEmail") +
      "&token=" +
      localStorage.getItem("token") +
      "&type=all";
    let securityKey = window.env.KEYS_SECURITY_KEY;
    var encryptedBytes = CryptoJS.AES.encrypt(
      url,
      securityKey
    );
    localStorage.setItem("magix_account_no", "");
    sessionStorage.setItem("multipleMagixId", "");
    this.props.history.push("/apply-now?" + encryptedBytes);
  };

  GetDateFromString(date) {
    if (date) {
      let currentDate;
      const year = parseInt(date.substring(0, 4));
      const month = parseInt(date.substring(5, 7));
      const day = parseInt(date.substring(8, 10));
      currentDate = month + "/" + day + "/" + year;
      return currentDate;
    }
  }

  onAdmin(event) {
    event.preventDefault();
    if (this.state.permission_status?.admin_settings) {
      this.props.history.push("/admin");
    } else if (this.state.permission_status?.user_list) {
      this.props.history.push("/admin-user");
    } else if (this.state.permission_status?.user_role) {
      this.props.history.push("/admin-userRole");
    } else if (this.state.permission_status?.user_rights) {
      this.props.history.push("/admin-userRights");
    } else if (this.state.permission_status?.credit_application) {
      this.props.history.push("/credit-application");
    } else if (this.state.permission_status?.store_list) {
      this.props.history.push("/stores");
    } else if (this.state.permission_status?.features) {
      this.props.history.push("/admin-features");
    }
  }

  handleCardSelect = (val, key) => {
    this.setState({
      isContinuePortal: true,
      customerId: val.customer_Id,
      table_select: { row_index: key, type: "exist_application" },
    });
    localStorage.setItem("selectedUserName", val.name);
    localStorage.setItem("storeCode", val.storeCode);
    localStorage.setItem("selectedCustomer", val.customer_Id);
    sessionStorage.setItem(
      "selectedUserPhone",
      val.mobileNo == null ? "" : val.mobileNo
    );
    sessionStorage.setItem(
      "selectedUserEmail",
      val.emailAddress == null ? "" : val.emailAddress
    );
    sessionStorage.setItem("isSynchrony", val.isSynchrony);
    sessionStorage.setItem("isDaniel", val.isDaniel);
    sessionStorage.setItem("isFortiva", val.isFortiva);
    sessionStorage.setItem("createdDate", val.createdDate);
    // let obj = {
    //   isSynchrony: val.isSynchrony,
    //   customerId: val.customer_Id,
    // };
    //localStorage.setItem("synchronyObj", JSON.stringify(obj));
    this.doubleClickOnCards(val.customer_Id);
    if (
      this.state.double_data.click_no === 1 &&
      this.state.double_data.click_id === val.customer_Id
    ) {
      this.redirctToPortal(val);
      this.setState({ double_data: { click_id: null, click_no: 0 } });
    }
  };

  magixhandleCardSelect = (
    val,
    key
  ) => {
    this.setState({
      isContinuePortal: true,
      magixassociateId: val.customer_Id,
      magixRowStore: val.store_code,
      table_select: { row_index: key, type: "magix" },
    });
    localStorage.setItem("selectedUserName", val.name);
    localStorage.setItem("storeCode", val.store_code);
    localStorage.setItem("selectedCustomer", val.customer_Id);
    sessionStorage.setItem(
      "selectedUserPhone",
      val.mobileNo == null ? "" : val.mobileNo
    );
    sessionStorage.setItem(
      "selectedUserEmail",
      val.emailAddress == null ? "" : val.emailAddress
    );
    sessionStorage.setItem("isSynchrony", val.isSynchrony);
    sessionStorage.setItem("isDaniel", val.isDaniel);
    sessionStorage.setItem("isFortiva", val.isFortiva);
    sessionStorage.setItem("createdDate", val.createdDate);
    this.doubleClickOnCards(val.customer_Id);
    if (
      this.state.double_data.click_no === 1 &&
      this.state.double_data.click_id === val.customer_Id
    ) {
      this.redirctToPortal(val);
      this.setState({ double_data: { click_id: null, click_no: 0 } });
    }
  };

  handlePortalAccess = () => {
    this.setState({ isModal: false });
  };

  logOut = async () => {
    try {
      await this.props.authActions.logout();
    } catch (error) {
      APIFailedMessage.displayError(error);
    }
  };

  doubleClickOnCards(id) {
    if (this.state.click_id === id) {
      this.setState({
        double_data: {
          click_id: id,
          click_no: this.state.double_data.click_no + 1,
        },
      });
    } else {
      this.setState({ double_data: { click_id: id, click_no: 1 } });
    }
  }

  async redirctToPortal(row) {
    sessionStorage.setItem("isNewApplication", "false");
    localStorage.setItem("synchronyAppId", row.synchronyAppId);
    if (this.state.customerId !== "") {
      localStorage.setItem("customerId", this.state.customerId);
      localStorage.setItem("magixassociateId", null);
      if (this.state.permission_status?.customer_details) {
        this.props.history.push(`/customer-details/${this.state.customerId}`);
      } else if (this.state.permission_status?.view_offer) {
        this.props.history.push("/view-offers");
      } else if (this.state.permission_status?.apply_now) {
        this.props.history.push("/apply-now");
      } else if (this.state.permission_status?.application_history) {
        this.props.history.push("/creditOrprintsummary-details");
      } else if (this.state.permission_status?.transaction_history) {
        this.props.history.push("/transaction-history");
      }
    } else {
      localStorage.setItem("magixassociateId", this.state.magixassociateId);
       //row.ssn="6238893337";
      if(row.ssn){
        if(row.ssn.includes("-")){
          let ssnsplit = row.ssn.split('-');
          let actualSSN = ssnsplit.join('');
          if(actualSSN.length==9 && actualSSN != "000000000"){
            //let birthDate="2008-05-31T00:00:00";
            let birthDate=row.birthDate;
          if(birthDate) {
            if(parseInt(birthDate?.split('-')[0]) < 1900) {
                  this.setState({validateMagix:true});
            }
            else if (birthDate?.length !== 0) {
                var today = new Date();
                let birthDateFormatted = new Date(birthDate);
                var age = today.getFullYear() - birthDateFormatted.getFullYear();
                var m = today.getMonth() - birthDateFormatted.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDateFormatted.getDate())) {
                    age--;
                }
                if (age < 18) {
                    this.setState({validateMagix:true});
                }
                else{
                  let customerId= await this.validateDuplicateCustomer(row);
                  //localStorage.setItem("customerId", null);
                  if(customerId){
                    if (this.state.permission_status?.customer_details) {
                      this.props.history.push(
                        `/customer-details/${customerId}`
                      );
                    } else if (this.state.permission_status?.view_offer) {
                      this.props.history.push("/view-offers");
                    } else if (this.state.permission_status?.apply_now) {
                      this.props.history.push("/apply-now");
                    } else if (this.state.permission_status?.application_history) {
                      this.props.history.push("/creditOrprintsummary-details");
                    } else if (this.state.permission_status?.transaction_history) {
                      this.props.history.push("/transaction-history");
                    }
                  }
              }
            }
         }
          else
            this.setState({validateMagix:true});
          }
          else
              this.setState({validateMagix:true});
        }
        else
          this.setState({validateMagix:true});
    }
    else
        this.setState({validateMagix:true});
    }
  }

  validate() {
    let obj = this.state.searchObj;
    if (
      obj.phone !== "" ||
      obj.ssn !== "" ||
      obj.name !== "" ||
      obj.birthdate !== "" ||
      obj.id !== "" ||
      obj.accountId !== "" ||
      obj.zipCode !== ""||
      obj.danielsAccountId!==""
    )
      return false;
    return true;
  }
  validateDuplicateCustomer=async(row)=> {
    let dob= Moment(row.birthDate).format("MM/DD/YYYY");
    let formatdob=GetDateFromMMDDYYYY(dob);
      if(row.birthDate!=null && row.ssn!=null){
          let response= await this.props.applyNowActions.validateDuplicateCustomer(row.ssn,formatdob);
          if(response){
              if(response.customer_id===0){
                let customerId= await this.createCustomer(row,formatdob);
                if(customerId){
                  return customerId;
                }
              }
              else {
                    localStorage.setItem("applicationId",response.customer_Id);
                    localStorage.setItem("customerId",response.customer_id);
                    return response.customer_id;
                  }
          } 
      }
    }
    async createCustomer(selectedRow,formatdob){
      let name=selectedRow.firstName.split(",");
      
        if(selectedRow.state_Code){
          let result  = await this.state.getCodeMaster.state.filter((data) => {
          return data.code?.toLowerCase() === selectedRow.state_Code?.toLowerCase();
        });
        selectedRow.state_Id = result[0]?.id;
      }
      if (selectedRow.suffix_Code) {
        let result = await this.state.getCodeMaster.suffix.filter((data) => {
          return data.code?.toLowerCase() === selectedRow.suffix_Code?.toLowerCase();
        });
        selectedRow.suffix_Id = result[0]?.id;
      }
      let customerInfo={
              application_id: 0,
              firstName: name[1].trim(""),
              middleName: selectedRow.middleName,
              lastName: name[0],
              suffix_Id:selectedRow.suffix_Id,
              address: selectedRow.address,
              city: selectedRow.city,
              state_Id:  selectedRow.state_Id,
              zipCode: selectedRow.zipCode,
              apt:selectedRow.apt,
              emailAddress: selectedRow.emailAddress,
              customer_Id: "",
              portalStatus: "",
              mobileNo:selectedRow.mobileNo,
              secondaryPhone: selectedRow.secondaryPhone === "" ? null : selectedRow.secondaryPhone ,
              idNumber: "",
              ssn: selectedRow.ssn,
              status_Id: 0,
              balanceDue: 0,
              strbalanceDue: "",
              birthDate: formatdob,
              empName: "",
              empPhone: "",
              howLong: null,
              workJob: null,
              monthlyIncome: 0,
              housingInfo_Id:  parseInt(selectedRow.housingInfo_Id),
              primaryType_Id: 0,
              primaryState_Id: 0,
              relativeName: "",
              relativePhone: "",
              relativeRelationship: "",
              createdDate: new Date(),
              createdBy_Associate:  localStorage.getItem('loggedUserEmail'),
              modifiedBy_Associate:  localStorage.getItem('loggedUserEmail'),
              modifiedDate:  new Date(),
              store_Id: JSON.parse(localStorage.getItem("associateStores")),
              store_code: "",
              store_name: "",
              lastDraftCreditId: 0,
              primaryIdExpDate: new Date(),
              secondIdType_Id: 0,
              secondIdIssuedBy_Id: "",
              secondIdExpDate: new Date(),
              howOftenPaid_Id: 0,
              lastPayDay: new Date(),
              nextPayDay: new Date(),
              primaryIncome_Id: 0,
              bankRoutingNo: "",
              checkingAccountNo: "",
              creditCardBin: "",
              creditCardExpDate: new Date(),
              nameOnCreditCard: "",
              creditCardHomeAddress: false,
              ccAddress: "",
              ccApt: "",
              ccCity: "",
              ccState_Id: 0,
              ccZipCode: "",
              responseSource: "",
              title_code: "",
              notesModifiedDate: new Date(),
              suffix_Code: "",
              state_Code: "",
              magixStoreCode: selectedRow.store_code,
              magix_Id: selectedRow.customer_Id,
              isMobileNoVerified: false,
              isSMSCodeSent: false,
              isSMSOverride : false,
              poBox: "",
              printContent: "",
              totalPrint: 0,
              fortivaResponse_Id: 0,
              isDaniel: false,
              isProgressive: false,
              isUown: false,
              application_Type: "",
              uownStatus: "",
              progressiveStatus: "",
              synchronyStatus: "",
              fortivaStatus: "",
              maxCustBal: 0,
              strmaxCustBal: "",
              custAcctStat: "",
              isSynchrony: false,
              isSynchronyCD: false,
              isFortiva: false,
              danielAppId: "",
              synchronyAppId: "",
              progressiveAppId: "",
              uownAppId: "",
              fortivaAppId: ""
      }
        let response=  await this.props.applyNowActions.createCustomer(customerInfo)
            if(response){
                localStorage.setItem("customerId",response.customer_id);
                return response.customer_id;
            }
  }

  render() {
    const sortingPage = (page) => {
      let searchObj = this.state.searchObj;
      let obj = {
        phone: searchObj.phone.trim(""),
        ssn: searchObj.ssn.trim(""),
        name: searchObj.name.trim(""),
        birthdate: searchObj.birthdate,
        id: searchObj.id.trim(""),
        accountId: searchObj.accountId.trim(""),
        danielsAccId :searchObj.danielsAccountId.trim(""),
        zipCode: searchObj.zipCode.trim(""),
        pageIndex: 1,
        pageSize: Constants.LookUpPageSize,
        sortBy: this.state.dataField == "id" ? "account" : this.state.dataField,
        sortOrder: this.state.order,
      };
      trackPromise(
        this.props.actions
          .getCustomers_new(obj)
          .then((response) => {
            response.data.map((item) => {
              item.name =
                (item.firstName == null ? "" : item.firstName + " ") +
                (item.middleName == null ? "" : item.middleName + " ") +
                (item.lastName == null ? "" : item.lastName);
              item.modifiedDate = this.GetDateFromString(item.modifiedDate);
              if (item.mobileNo != null) {
                if (!item.mobileNo.includes("-")) {
                  item.mobileNo = formatPhoneNumber(item.mobileNo);
                }
              }
            });
            if (response.data.length === 0) {
              this.setState({ noDataFound: true });
            }
            this.setState({
              lookupResults: response.data,
              isLoad: true,
              totalCustomers: response.totalCount,
              isPaginate: false,
            });
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    };

    const sortingOnCards = (sortOrder, SortBy) => {
      let searchObj = this.state.searchObj;
      let obj = {
        phone: searchObj.phone.trim(""),
        ssn: searchObj.ssn.trim(""),
        name: searchObj.name.trim(""),
        birthdate: searchObj.birthdate,
        id: searchObj.id.trim(""),
        accountId: searchObj.accountId.trim(""),
        danielsAccId :searchObj.danielsAccountId.trim(""),
        zipCode: searchObj.zipCode.trim(""),
        pageIndex: this.state.pageNo,
        pageSize: Constants.LookUpPageSize,
        sortBy: SortBy,
        sortOrder: sortOrder,
      };
      trackPromise(
        this.props.actions
          .getCustomers_new(obj)
          .then((response) => {
            response.data.map((item) => {
              item.name =
                (item.firstName == null ? "" : item.firstName + " ") +
                (item.middleName == null ? "" : item.middleName + " ") +
                (item.lastName == null ? "" : item.lastName);
              item.modifiedDate = this.GetDateFromString(item.modifiedDate);
              if (item.mobileNo != null) {
                if (!item.mobileNo.includes("-")) {
                  item.mobileNo = formatPhoneNumber(item.mobileNo);
                }
              }
            });
            if (response.data.length === 0) {
              this.setState({ noDataFound: true });
            }
            this.setState({
              lookupResults: response.data,
              isLoad: true,
              totalCustomers: response.totalCount,
              isPaginate: false,
            });
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    };
    const magixsortingPage = (page) => {
      let searchObj = this.state.searchObj;
      let obj = {
        phone: searchObj.phone.trim(""),
        ssn: searchObj.ssn.trim(""),
        name: searchObj.name.trim(""),
        birthDate: searchObj.birthdate,
        id: searchObj.id.trim(""),
        accountId: searchObj.accountId.trim(""),
        danielsAccId :searchObj.danielsAccountId.trim(""),
        zipCode: searchObj.zipCode.trim(""),
        pageIndex: page,
        pageSize: Constants.LookUpPageSize,
        sortBy: (this.state.magixdataField === "customer_Id") ? "account" : (this.state.magixdataField === "strbalanceDue") ? "balanceDue" : this.state.magixdataField,
        sortOrder: this.state.magixorder,
      };
      trackPromise(
        this.props.actions
          .getMagixDetails(obj)
          .then((response) => {
            if (response.data.length === 0) {
              this.setState({ noDataFound: true });
            } else {
              response.data.map((item) => {
                item.name =
                  (item.firstName == null ? "" : item.firstName + " ") +
                  (item.middleName == null ? "" : item.middleName + " ") +
                  (item.lastName == null ? "" : item.lastName);
              });
              this.setState({
                magixlookupResults: response.data,
                isLoad: true,
                magixtotalCustomers: response.totalCount,
                isPaginate: false,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    };

    const magixsortingOnCards = (sortOrder, SortBy) => {
      let searchObj = this.state.searchObj;
      let obj = {
        phone: searchObj.phone.trim(""),
        ssn: searchObj.ssn.trim(""),
        name: searchObj.name.trim(""),
        birthDate: searchObj.birthdate,
        id: searchObj.id.trim(""),
        accountId: searchObj.accountId.trim(""),
        danielsAccId :searchObj.danielsAccountId.trim(""),
        zipCode: searchObj.zipCode.trim(""),
        pageIndex: 1,
        pageSize: Constants.LookUpPageSize,
        sortBy: this.state.magixdataField,
        sortOrder: this.state.magixorder,
      };
      trackPromise(
        this.props.actions
          .getMagixDetails(obj)
          .then((response) => {
            if (response.data.length === 0) {
              this.setState({ noDataFound: true });
            } else {
              response.data.map((item) => {
                item.name =
                  (item.firstName == null ? "" : item.firstName + " ") +
                  (item.middleName == null ? "" : item.middleName + " ") +
                  (item.lastName == null ? "" : item.lastName);
              });
              this.setState({
                magixlookupResults: response.data,
                isLoad: true,
                magixtotalCustomers: response.totalCount,
                isPaginate: false,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    };

    const columns = [
      {
        dataField: "customer_Id",
        text: "Customer Id",
        sort: true,
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
      {
        dataField: "firstName",
        text: "Name",
        sort: true,
        formatter: (cell, row) => {
          return (
            <div>{`${row.firstName === null ? "" : row.firstName} ${
              row.middleName === null ? "" : row.middleName
            } ${row.lastName === null ? "" : row.lastName}`}</div>
          );
        },
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
      {
        dataField: "address",
        text: "Address",
        sort: true,
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
      {
        dataField: "mobileNo",
        text: "Phone",
        sort: true,
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
        type: Number,
      },
      {
        dataField: "idNumber",
        text: "ID#",
        sort: true,
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
      {
        dataField: "ssn",
        text: "SSN/ITIN",
        sort: true,
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
      {
        dataField: "magix_Id",
        text: "Daniel's Account",
        formatter: (cell, row) => {
          return (
            <div className="d-flex justify-content-center align-items-center h-100">
              {row.magix_Id?.length > 0 && row.magix_Id != "null" ? (
                <p>Y</p>
              ) : <p>N</p>}
            </div>
          );
        },
      },
      {
        dataField: "isDaniel",
        text: "D",
        headerClasses: "input_form_control venderCol",
        formatter: (cell, row) => {
          return (
            <div className="d-flex justify-content-center align-items-center h-100">
              {row.isDaniel ? (
                <FontAwesomeIcon icon={faCircle} className="vendorDenotation" />
              ) : null}
            </div>
          );
        },
      },
      {
        dataField: "isFortiva",
        text: "F",
        headerClasses: "input_form_control venderCol",
        formatter: (cell, row) => {
          return (
            <div className="d-flex justify-content-center align-items-center h-100">
              {row.isFortiva ? (
                <FontAwesomeIcon icon={faCircle} className="vendorDenotation" />
              ) : null}
            </div>
          );
        },
      },
      {
        dataField: "isSynchrony",
        text: "S",
        headerClasses: "input_form_control venderCol",
        formatter: (cell, row) => {
          return (
            <div className="d-flex justify-content-center align-items-center h-100">
              {row.isSynchrony ? (
                <FontAwesomeIcon icon={faCircle} className="vendorDenotation" />
              ) : null}
            </div>
          );
        },
      },
      {
        dataField: "isProgressive",
        text: "P",
        headerClasses: "input_form_control venderCol",
        formatter: (cell, row) => {
          return (
            <div className="d-flex justify-content-center align-items-center h-100">
              {row.isProgressive ? (
                <FontAwesomeIcon icon={faCircle} className="vendorDenotation" />
              ) : null}
            </div>
          );
        },
      },
      {
        dataField: "isUown",
        text: "U",
        headerClasses: "input_form_control venderCol",
        formatter: (cell, row) => {
          return (
            <div className="d-flex justify-content-center align-items-center h-100">
              {row.isUown ? (
                <FontAwesomeIcon icon={faCircle} className="vendorDenotation" />
              ) : null}
            </div>
          );
        },
      },
    ];

    const magixcolumns = [
      {
        dataField: "firstName",
        text: "Name",
        sort: true,
        formatter: (cell, row) => {
          return (
            <div>{`${row.firstName === null ? "" : row.firstName} ${
              row.middleName === null ? "" : row.middleName
            } ${row.lastName === null ? "" : row.lastName}`}</div>
          );
        },
        onSort: (field, order) => {
          this.setState({ magixisSort: true });
        },
      },
      {
        dataField: "address",
        text: "Address",
        sort: true,
        onSort: (field, order) => {
          this.setState({ magixisSort: true });
        },
      },
      {
        dataField: "mobileNo",
        text: "Phone",
        sort: true,
        onSort: (field, order) => {
          this.setState({ magixisSort: true });
        },
        type: Number,
      },
      {
        dataField: "idNumber",
        text: "ID#",
        sort: true,
        onSort: (field, order) => {
          this.setState({ magixisSort: true });
        },
      },
      {
        dataField: "ssn",
        text: "SSN/ITIN",
        sort: true,
        onSort: (field, order) => {
          this.setState({ magixisSort: true });
        },
      },
      {
        dataField: "store_code",
        text: "Home Store",
        sort: true,
        onSort: (field, order) => {
          this.setState({ magixisSort: true });
        },
        type: Number,
      },
      {
        dataField: "customer_Id",
        text: "Account ID",
        sort: true,
        onSort: (field, order) => {
          this.setState({ magixisSort: true });
        },
        type: Number,
      },
      //   { dataField: "portalStatus", text: "Status", sort: true,
      //   onSort: (field, order) => {
      //     this.setState({ magixisSort: true })
      //   },
      //  },
      {
        dataField: "strbalanceDue",
        text: "Balance Due",
        sort: true,
        headerClasses: "strbalanceDue",
        onSort: (field, order) => {
          this.setState({ magixisSort: true });
        },
        type: Number,
      },
      {
        dataField: "maxCustBal",
        text: "Credit Rating",
        sort: true,
        formatter: (cell, row) => {
          return (
            <div>{`${row.custAcctStat === null ? "" : row.custAcctStat} ${row.maxCustBal === null ? "" : row.maxCustBal}`}</div>
          );
        },
        headerClasses: "maxCustBal",
        onSort: (field, order) => {
          // console.group(order, field)
          this.setState({ magixisSort: true, isFirstTime: false });
        },
      },
    ];

    const handleTableChange = (
      type,
      { page, sizePerPage, sortField, sortOrder }
    ) => {
      this.setState({
        isLoad: false
      });
      if (type === "pagination") {
        //const currentIndex = (page - 1) * sizePerPage;
        this.setState({ lookupResults: [], isPaginate: true, isSort: false });
        this.paginationWithIndex(page);
        this.setState({ pageNo: page });
      } else if (type === "sort") {
        this.setState({ isFirstTime: false });
        if (this.state.isSort && !this.state.isFirstTime) {
          this.setState({ dataField: sortField, order: sortOrder, pageNo: 1 });
          setTimeout(() => {
            sortingPage(page);
          }, 0);
        }
      }
    };
    const magixhandleTableChange = (
      type,
      { page, sizePerPage, sortField, sortOrder }
    ) => {
      this.setState({
        isLoad: false
      });
      if (type === "pagination") {
        //const currentIndex = (page - 1) * sizePerPage;
        this.setState({
          magixlookupResults: [],
          magixisPaginate: true,
          magixisSort: false,
        });
        this.magixpaginationWithIndex(page);
        this.setState({ magixpageNo: page });
      } else if (type === "sort") {
        this.setState({ isFirstTime: false });
        if (this.state.magixisSort && !this.state.isFirstTime) {
          this.setState({
            magixdataField: sortField,
            magixorder: sortOrder,
            magixpageNo: 1,
          });
          setTimeout(() => {
            magixsortingPage(page);
          }, 0);
        }
      }
    };

    const customTotal = (from, to, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total ml-0 mt-3">
          <div className="bg-white pl-2 pr-2 pt-1 pb-1 rounded">
            {from}-{to} of {size}
          </div>
        </span>
      );
    };
    const magixcustomTotal = (magixfrom, magixto, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total ml-0 mt-3">
          <div className="bg-white pl-2 pr-2 pt-1 pb-1 rounded">
            {magixfrom}-{magixto} of {size}
          </div>
        </span>
      );
    };

    const selectRow = {
      mode: "radio",
      clickToSelect: true,
      onSelect: (row) => {
        this.setState({
          isContinuePortal: true,
          customerId: row.customer_Id,
          magixassociateId: "",
          table_select: { type: "exist_application" },
        });
        localStorage.setItem("magix_account_no", row.magix_Id);
        localStorage.setItem("selectedUserName", row.name);
        localStorage.setItem("storeCode", row.magixStoreCode);
        sessionStorage.setItem("magixInstoreData", row.magixStoreCode);
        sessionStorage.setItem(
          "selectedUserPhone",
          row.mobileNo == null ? "" : row.mobileNo
        );
        sessionStorage.setItem(
          "selectedUserEmail",
          row.emailAddress == null ? "" : row.emailAddress
        );
        localStorage.setItem("selectedCustomer", row.customer_Id);
        sessionStorage.setItem("createdDate", row.createdDate);
        sessionStorage.setItem("isDaniel", row.isDaniel);
        sessionStorage.setItem("isSynchrony", row.isSynchrony);
        sessionStorage.setItem("isProgressive", row.isProgressive);
        sessionStorage.setItem("isUown", row.isUown);
        sessionStorage.setItem("isFortiva", row.isFortiva);
        localStorage.setItem("lastName", row.lastName);
        localStorage.setItem("creditappEmailAddr", row.emailAddress);
        sessionStorage.setItem("ssn&zipcode",row.fullSSN+","+row.zipCode);
        sessionStorage.setItem("synchronyStoreCode",row.store_code);
        sessionStorage.setItem("existingSyncApplication",row.isSynchrony);
        let obj = {
          isSynchrony: row.isSynchrony,
          customerId: row.customer_Id,
        };
        //localStorage.setItem("synchronyObj", JSON.stringify(obj));
      },
      onSelectAll: this.handleOnSelectAll,
    };
    const rowEvents = {
      onDoubleClick: (e, row, rowIndex) => {
        this.redirctToPortal(row);
      },
    };
    const magixselectRow = {
      mode: "radio",
      clickToSelect: true,
      onSelect: (row, rowIndex, isSelect, e) => {
        this.setState({
          isContinuePortal: true,
          // magixRowIndex:isSelect,
          table_select: { row_index: isSelect, type: "magix" },
          magixassociateId: row.customer_Id,
          customerId: "",
        });
        sessionStorage.setItem("createdDate", "");
        sessionStorage.setItem(
          "selectedUserPhone",
          row?.mobileNo == null ? "" : row?.mobileNo
        );
        sessionStorage.setItem(
          "selectedUserEmail",
          row?.emailAddress == null ? "" : row?.emailAddress
        );
        sessionStorage.setItem("isDaniel", row.isDaniel);
        sessionStorage.setItem("isSynchrony", row.isSynchrony);
        sessionStorage.setItem("isProgressive", row.isProgressive);
        sessionStorage.setItem("isUown", row.isUown);
        sessionStorage.setItem("isFortiva", row.isFortiva);
      },
      onSelectAll: this.handleOnSelectAll,
    };

    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (
        row.customer_Id === this.state.customerId &&
        this.state.table_select.type === "exist_application"
      ) {
        style.backgroundColor = "#5C068C";
        style.color = "white";
      }

      return style;
    };
    const magixrowStyle = (row, rowIndex, isSelect) => {
      const style = {};
      if (
        this.state.table_select.row_index === rowIndex &&
        this.state.table_select.type === "magix"
      ) {
        style.backgroundColor = "#5C068C";
        style.color = "white";
        localStorage.setItem("magix_account_no", row.customer_Id);
        localStorage.setItem("selectedUserName", row.name);
        localStorage.setItem("storeCode", row.store_code);
        localStorage.setItem("selectedCustomer", row.customer_Id);
      }

      return style;
    };

    const onValueChange = (e) => {
      if (e.target.value === "asc") {
        this.setState({ order: "asc", pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      } else if (e.target.value === "desc") {
        this.setState({ order: "desc", pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      } else {
        this.setState({ dataField: e.target.value, pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      }
    };
    const magixonValueChange = (e) => {
      if (e.target.value === "asc") {
        this.setState({ magixorder: "asc", magixpageNo: 1 }, () => {
          magixsortingOnCards(this.state.magixorder, this.state.magixdataField);
        });
      } else if (e.target.value === "desc") {
        this.setState({ magixorder: "desc", magixpageNo: 1 }, () => {
          magixsortingOnCards(this.state.magixorder, this.state.magixdataField);
        });
      } else {
        this.setState(
          { magixdataField: e.target.value, magixpageNo: 1 },
          () => {
            magixsortingOnCards(
              this.state.magixorder,
              this.state.magixdataField
            );
          }
        );
      }
    };

    return (
      <>
        <IdleTimeModal />
        <div className={`app ${this.state.toggled ? "" : "toggled"}`}>
          <HamburgerMenu
            toggled={this.state.toggled}
            handleToggleSidebar={() => {
              this.handleToggleSidebar();
            }}
            hasAdminAccess={this.state.hasAdminAccess}
            handleModal={() => {
              this.handleModal();
            }}
            onAdmin={(event) => {
              this.onAdmin(event);
            }}
          />
          <main>
            <nav className="navbar bg-white justify-content-start">
              <button
                onClick={() => this.handleToggleSidebar()}
                className="btn border-0 p-0 m-0 text-secondary ml-md-3"
              >
                <span>
                  <FontAwesomeIcon icon={faBars} size="lg" />
                </span>
              </button>
              <a className="navbar-brand ml-4" href="/">
                <img
                  src={danielsLogo}
                  alt="danielsLogo"
                  className="img-fluid"
                  width="100"
                ></img>
              </a>
              <ConfirmDialog
                show={this.state.show}
                handleModalPopup={this.handleModal}
                message={"<p>Are you sure you want to logout?</p>"}
                button1_name="Cancel"
                button1={this.handleModal}
                button2_name="Logout"
                button2={this.logOut}
              />
            </nav>
            <div className="container-fluid pr-4 pl-4">
              <div className="row">
                <div className="col-12 mt-4">
                  <p className="h4 ml-2 ml-md-0 text-left">
                    <b>Customer Look Up</b>
                  </p>
                  <form onSubmit={this.handleSubmit}>
                    <div className="row m-1 m-md-0">
                      <div className="col-12 col-md-4 col-lg-1 bg-secondary searchColumn searchColStart">
                        <div className="form-group text-white mt-3">
                          <label>
                            Phone
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  className="tooltip"
                                  id="tooltip-disabled"
                                >
                                  Phone search by area code and number, Phone
                                  should not start with 0 or 1. For example
                                  5595864173 or 559-586-4173.
                                </Tooltip>
                              }
                              placement="top"
                            >
                              <span className="d-inline-block">
                                <FontAwesomeIcon
                                  className="pl-1"
                                  icon={faQuestion}
                                  style={{ pointerEvents: "none" }}
                                />
                              </span>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="text"
                            className="form-control bg-secondary text-white"
                            name="phone"
                            id="phone"
                            placeholder="Phone"
                            onChange={(event) => this.onChange(event)}
                            value={this.state.searchObj.phone}
                          ></input>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-1 bg-secondary searchColumn ">
                        <div className="form-group text-white mt-3">
                          <label>
                            SSN/ITIN#
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  className="tooltip"
                                  id="tooltip-disabled"
                                >
                                  SSN/ITIN search by entering numerical digits.
                                  For example 147323456.
                                </Tooltip>
                              }
                              placement="top"
                            >
                              <span className="d-inline-block">
                                <FontAwesomeIcon
                                  className="pl-1"
                                  icon={faQuestion}
                                  style={{ pointerEvents: "none" }}
                                />
                              </span>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="text"
                            className="form-control bg-secondary text-white"
                            name="ssn"
                            id="ssn"
                            placeholder="SSN/ITIN"
                            onChange={(event) => this.onChange(event)}
                            value={this.state.searchObj.ssn}
                          ></input>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-1 bg-secondary searchColumn searchColLastName">
                        <div className="form-group text-white mt-3">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control bg-secondary text-white"
                            name="lastName"
                            id="lastName"
                            placeholder="Last Name"
                            onChange={(event) => this.onChange(event)}
                            value={this.state.searchObj.name}
                          ></input>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-1 bg-secondary searchColumn">
                        <div className="form-group text-white mt-3">
                          <label>Date of Birth</label>
                          <DatePicker
                            className="form-control bg-secondary text-white"
                            name="txtDob"
                            id="txtDob"
                            placeholderText="MM/DD/YYYY"
                            // selected={
                            //   this.state.searchObj.birthdate === ""
                            //     ? null
                            //     : new Date(this.state.searchObj.birthdate)
                            // }
                            selected={
                              this.state.searchObj.birthdate === ""
                                ? null
                                : new Date(this.state.searchObj.birthdate)
                            }
                            onChange={(event) => this.onDOBChange(event)}
                            dateFormat="MM/dd/yyyy"
                            peekNextMonth
                            maxDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={<IMaskInput mask="00/00/0000" />}
                          ></DatePicker>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-1 bg-secondary searchColumn">
                        <div className="form-group text-white mt-3">
                          <label>
                            ID#
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  className="tooltip"
                                  id="tooltip-disabled"
                                >
                                  ID# search by entering id starts with alphabet
                                  followed by number. For example E3292813.
                                </Tooltip>
                              }
                              placement="top"
                            >
                              <span className="d-inline-block">
                                <FontAwesomeIcon
                                  className="pl-1"
                                  icon={faQuestion}
                                  style={{ pointerEvents: "none" }}
                                />
                              </span>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="text"
                            className="form-control bg-secondary text-white"
                            name="id"
                            id="id"
                            placeholder="ID"
                            onChange={(event) => this.onChange(event)}
                            value={this.state.searchObj.id}
                          ></input>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-1 bg-secondary searchColumn">
                        <div className="form-group text-white mt-3">
                          <label>
                            Account ID
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  className="tooltip"
                                  id="tooltip-disabled"
                                >
                                  Acc# search by entering account number without
                                  a store. For example 3015456.
                                </Tooltip>
                              }
                              placement="top"
                            >
                              <span className="d-inline-block">
                                <FontAwesomeIcon
                                  className="pl-1"
                                  icon={faQuestion}
                                  style={{ pointerEvents: "none" }}
                                />
                              </span>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="text"
                            className="form-control bg-secondary text-white"
                            name="accountId"
                            id="accountId"
                            placeholder="Account ID"
                            onChange={(event) => this.onChange(event)}
                            value={this.state.searchObj.accountId}
                          ></input>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-1 bg-secondary searchColumn">
                        <div className="form-group text-white mt-3">
                          <label>Zip Code</label>
                          <input
                            type="text"
                            className="form-control bg-secondary text-white"
                            name="zipCode"
                            id="zipCode"
                            placeholder="Zip Code"
                            onChange={(event) => this.onChange(event)}
                            value={this.state.searchObj.zipCode}
                            maxLength={5}
                          ></input>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-1 bg-secondary searchColumn searchColEnd">
                        <div className="form-group text-white mt-3">
                          <label>
                            Daniel's Account
                          </label>
                          <input
                            type="text"
                            className="form-control bg-secondary text-white"
                            name="danielsAccountId"
                            id="danielsAccountId"
                            placeholder="Daniel's Account"
                            onChange={(event) => this.onChange(event)}
                            value={this.state.searchObj.danielsAccountId}
                          ></input>
                        </div>
                      </div>
                       <div className="col-sm-12 bg-secondary searchBtnColumn">
                        <div className="row mt-3 mb-3 mt-lg-0">
                          <div className="col-12 offset-md-4 col-md-4 offset-lg-9 col-lg-1 searchColumn">
                          <button
                        type="submit"
                        className="btn text-dark bg-white w-100"
                        disabled={this.validate()}
                      >
                        Search
                      </button>
                          </div>
                          <div className="col-12 col-md-4 col-lg-1 searchColumn mt-3 mt-md-0 mt-lg-0">
                          <button
                      type="button"
                      className="btn text-dark bg-white w-100 "
                      disabled={
                        this.validate() &&
                        this.state.lookupResults?.length <= 0
                      }
                      onClick={() => this.clearSearch()}
                    >
                      Clear Search
                    </button>
                          </div>
                        </div>
                  </div>
                    </div>
                  </form>
                </div>
                <div className="col-12">
              <p className="h4 m-0 p-3 bg-white magix">
                {this.state.magixGrid === true ? (
                  <span>
                    [
                    <FontAwesomeIcon
                      icon={faMinus}
                      size="1x"
                      onClick={() =>
                        this.setState({ magixGrid: !this.state.magixGrid })
                      }
                      aria-controls="magixGrid"
                      aria-expanded={this.state.magixGrid}
                      className="pl-1 pr-1"
                    />
                    ]
                  </span>
                ) : (
                  <span>
                    [
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="1x"
                      onClick={() =>
                        this.setState({ magixGrid: !this.state.magixGrid })
                      }
                      aria-controls="magixGrid"
                      aria-expanded={this.state.magixGrid}
                      className="pl-1 pr-1"
                    />
                    ]
                  </span>
                )}
                <b>Magix</b>
              </p>
              <Collapse in={this.state.magixGrid}>
                <div id="magixGrid">
                  {this.state.magixlookupResults.length > 0 &&
                  this.state.magixlookupResults ? (
                    <>
                      <div id="cardrow">
                        <MagixCardView
                          magixData={this.state.magixlookupResults}
                          magixhandleCardSelect={this.magixhandleCardSelect}
                          activeAccount={this.state.magixassociateId}
                          selectedStore={this.state.magixRowStore}
                          magixdataField={this.state.magixdataField}
                          magixorder={this.state.magixorder}
                          magixonValueChange={magixonValueChange}
                          table_select={this.state.table_select}
                        />
                      </div>
                      <RemotePagination
                        data={this.state.magixlookupResults}
                        page={this.state.magixpageNo}
                        from={this.state.magixfrom}
                        to={this.state.magixto}
                        sizePerPage={5}
                        firstPageText="<<"
                        totalSize={this.state.magixtotalCustomers}
                        onTableChange={magixhandleTableChange}
                        dataField={this.state.magixdataField}
                        order={this.state.magixorder}
                        columns={magixcolumns}
                        selectRow={magixselectRow}
                        rowStyle={magixrowStyle}
                        customTotal={magixcustomTotal}
                        rowEvents={rowEvents}
                      />
                    </>
                  ) : (
                    <>
                      <table
                        id="resulttable"
                        className="w-100 bg-white mb-3"
                        style={{
                          borderBottomLeftRadius: "15px",
                          borderBottomRightRadius: "15px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th className="pt-2 pb-2 pl-4" scope="col">
                              Name <FontAwesomeIcon icon={faSortDown} />
                            </th>
                            <th scope="col">
                              Address <FontAwesomeIcon icon={faSortDown} />
                            </th>
                            <th scope="col">
                              Phone <FontAwesomeIcon icon={faSortDown} />
                            </th>
                            <th scope="col">
                              ID# <FontAwesomeIcon icon={faSortDown} />
                            </th>
                            <th scope="col">
                              SSN/ITIN <FontAwesomeIcon icon={faSortDown} />
                            </th>
                            <th scope="col">
                              Home Store <FontAwesomeIcon icon={faSortDown} />
                            </th>
                            <th scope="col">
                              Account ID <FontAwesomeIcon icon={faSortDown} />
                            </th>
                            {/* <th scope="col">Status <FontAwesomeIcon icon={faSortDown} /></th> */}
                            <th scope="col">
                              Balance Due <FontAwesomeIcon icon={faSortDown} />
                            </th>
                            <th scope="col">
                              Credit Rating{" "}
                              <FontAwesomeIcon icon={faSortDown} />
                            </th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                      <div
                        id="nomatchfound"
                        className="text-center mw-100 d-flex justify-content-center align-items-center"
                      >
                        {this.state.magixnoDataFound &&
                        !this.state.magixisPaginate ? (
                          <p className="mb-0 pt-0 text-secondary">
                            No magix data found
                          </p>
                        ) : this.state.magixisPaginate ? (
                          <p className="mb-0 pt-0 text-secondary"></p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="card mt-3 mb-3" id="cardnotfound">
                        <div className="card-body d-flex justify-content-center align-items-center">
                          {this.state.magixnoDataFound &&
                          !this.state.magixisPaginate ? (
                            <p className="text-secondary">
                              No magix data found
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Collapse>
              <p
                className={`h4 m-0 p-3 bg-white ${
                  this.state.magixGrid === false &&
                  this.state.lookupResultGrid === false
                    ? " roundedStyle3"
                    : this.state.magixGrid === true &&
                      this.state.lookupResultGrid === false
                    ? " roundedStyle1"
                    : this.state.magixGrid === true &&
                      this.state.lookupResultGrid === true
                    ? "roundedStyle2"
                    : ""
                }`}
              >
                {this.state.lookupResultGrid === true ? (
                  <span>
                    [
                    <FontAwesomeIcon
                      icon={faMinus}
                      size="1x"
                      onClick={() =>
                        this.setState({
                          lookupResultGrid: !this.state.lookupResultGrid,
                        })
                      }
                      aria-controls="lookupResultGrid"
                      aria-expanded={this.state.lookupResultGrid}
                      className="pl-1 pr-1"
                    />
                    ]
                  </span>
                ) : (
                  <span>
                    [
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="1x"
                      onClick={() =>
                        this.setState({
                          lookupResultGrid: !this.state.lookupResultGrid,
                        })
                      }
                      aria-controls="lookupResultGrid"
                      aria-expanded={this.state.lookupResultGrid}
                      className="pl-1 pr-1"
                    />
                    ]
                  </span>
                )}
                <b>Existing Customers</b>
              </p>
              <Collapse in={this.state.lookupResultGrid}>
              <div id="lookupResultGrid">
                    {this.state.lookupResults.length > 0 &&
                    this.state.lookupResults ? (
                      <>
                        <div id="cardrow">
                          <CardView
                            data={this.state.lookupResults}
                            handleCardSelect={this.handleCardSelect}
                            activeID={this.state.customerId}
                            onValueChange={onValueChange}
                            dataField={this.state.dataField}
                            order={this.state.order}
                            table_select={this.state.table_select}
                          />
                        </div>
                        <RemotePagination
                          data={this.state.lookupResults}
                          page={this.state.pageNo}
                          from={this.state.from}
                          to={this.state.to}
                          sizePerPage={Constants.LookUpPageSize}
                          firstPageText="<<"
                          totalSize={this.state.totalCustomers}
                          onTableChange={handleTableChange}
                          dataField={this.state.dataField}
                          order={this.state.order}
                          columns={columns}
                          selectRow={selectRow}
                          rowStyle={rowStyle}
                          customTotal={customTotal}
                          rowEvents={rowEvents}
                        />
                      </>
                    ) : (
                      <>
                        <table
                          id="resulttable"
                          className="w-100 bg-white text-center"
                          style={{
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                          }}
                        >
                          <thead>
                            <tr>
                              <th scope="col" className="pt-4 pb-4 colHeader">
                                Customer ID{" "}
                                <FontAwesomeIcon icon={faSortDown} />
                              </th>
                              <th scope="col" className="colHeader">
                                Name <FontAwesomeIcon icon={faSortDown} />
                              </th>
                              <th scope="col" className="colHeader">
                                Address <FontAwesomeIcon icon={faSortDown} />
                              </th>
                              <th scope="col" className="colHeader">
                                Phone <FontAwesomeIcon icon={faSortDown} />
                              </th>
                              <th scope="col" className="colHeader">
                                ID# <FontAwesomeIcon icon={faSortDown} />
                              </th>
                              <th scope="col" className="colHeader">
                                SSN/ITIN <FontAwesomeIcon icon={faSortDown} />
                              </th>
                              <th scope="col" className="colHeader">
                                Daniel's Account
                              </th>
                              <th scope="col" className="vendors">
                                D
                              </th>
                              <th scope="row" className="vendors">
                                F
                              </th>
                              <th scope="col" className="vendors">
                                S
                              </th>
                              <th scope="col" className="vendors">
                                P
                              </th>
                              <th scope="col" className="vendors">
                                U
                              </th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                        <div
                          id="nomatchfound"
                          className="text-center mw-100 d-flex justify-content-center align-items-center"
                        >
                          {this.state.noDataFound && !this.state.isPaginate ? (
                            <p className="mb-0 pt-0 text-secondary">
                              {" "}
                              No existing application data found
                            </p>
                          ) : this.state.isPaginate ? (
                            <p className="mb-0 pt-0 text-secondary"></p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="card mt-3 mb-2" id="cardnotfound">
                          <div className="card-body d-flex justify-content-center align-items-center">
                            {this.state.noDataFound &&
                            !this.state.isPaginate ? (
                              <p className="text-secondary">
                                No existing application data found
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
              </Collapse>
            </div>

                <div className="col-12">
                  <hr className="mb-0"></hr>
                  <div className="row mb-2 ml-0 float-right mt-4">
                    <button
                      className="btn text-white float-right mt-3 mt-md-0 mt-lg-0 mr-3 newCreditApplication pt-2 pb-2 pl-5 pr-5 bg-secondary"
                      onClick={() => {
                        this.creditApplication();
                      }}
                      style={{ backgroundColor: "#1B1B1B" }}
                      disabled={
                        this.state.applyNowPermission?.canAdd ? false : true
                      }
                    >
                      Apply for Credit
                    </button>
                  </div>
                </div>
              </div>
              <hr className="mb-0"></hr>
              <EmailApplyLink />
            </div>
          </main>
        </div>

        <ConfirmDialog
          show={this.state.isModal}
          handleModalPopup={this.handlePortalAccess}
          message={
            "<p class='mb-1'>LoggedIn associate don't have access to the Portal page.</p><p class='mb-0'>Contact your Administrator.</p>"
          }
          header_name="Warning!!"
        />

        <ConfirmDialog
          show={this.state.creditModal}
          handleModalPopup={this.creditApplication}
          message={"<p class='mb-1'>This is for security purposes.</p>"}
          button1_name="Stay In &amp; continue"
          button1={this.stayInAndContinue}
          button2_name="Logout &amp; continue"
          button2={this.logOutAndContinue}
        />

        <ConfirmDialog
          show={this.state.popupShow}
          handleModalPopup={this.handleModalPopup}
          message={
            "<p>If you click OK, you will lose all the data. Are you sure you want to go back to login page?</p>"
          }
          button1_name="Cancel"
          button1={this.handleModalPopup}
          button2_name="OK"
          button2={this.redirectToLogin}
        />
         <Modal show={this.state.validateMagix}>  
            <Modal.Header className="pb-0"><b className="text-secondary h4">Warning</b></Modal.Header>
            <Modal.Body className="pb-0">
                <div>
                <p className="text-danger"><b>The SSN and/or DOB needs to be corrected in Magix to proceed.</b></p>
                </div>
            </Modal.Body> 
            <Modal.Footer className="border-top-0 mt-1">
                <button className="btn mr-1 ml-1 mb-0 reLogout" style={{backgroundColor:"#5c068c",color:"white"}} onClick={(event)=>{ this.setState({validateMagix:false})}}>Ok</button>
            </Modal.Footer>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    userListactions: bindActionCreators(adminUserListActions, dispatch),
    actions: bindActionCreators(customerLookupActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    userRightsactions: bindActionCreators(userRightsActions, dispatch),
    applyNowActions: bindActionCreators(applyNowActions, dispatch),
    adminActions: bindActionCreators(adminActions, dispatch),
  };
};
export default connect(null, mapDispatchToProps)(CustomerLookup);
