import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import store from './redux/store';
import "./web.config";

const options = {
  "info": {
    "applicationID": window.env.NEW_RELIC_APPLICATION_ID,
    "beacon": "bam.nr-data.net",
    "errorBeacon": "bam.nr-data.net",
    "licenseKey": window.env.NEW_RELIC_LICENSE_KEY,
    "sa": 1
  },
  "init": {
    "ajax": {
      "deny_list": [
        "bam.nr-data.net"
      ]
    },
    "distributed_tracing": {
      "enabled": true
    },
    "privacy": {
      "cookies_enabled": true
    },
    "session_replay": {
      "autoStart": true,
      "block_selector": "",
      "collect_fonts": true,
      "enabled": true,
      "error_sampling_rate": 100,
      "fix_stylesheets": true,
      "inline_images": false,
      "inline_stylesheet": true,
      "mask_all_inputs": true,
      "mask_input_options": {},
      "mask_text_selector": "*",
      "sampling_rate": 10
    }
  },
  "loader_config": {
    "accountID": window.env.NEW_RELIC_ACCOUNT_ID,
    "agentID": window.env.NEW_RELIC_APPLICATION_ID,
    "applicationID": window.env.NEW_RELIC_APPLICATION_ID,
    "licenseKey": window.env.NEW_RELIC_LICENSE_KEY,
    "trustKey": window.env.NEW_RELIC_ACCOUNT_ID
  }
}
new BrowserAgent(options)


ReactDOM.render(
 <Provider store={store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

