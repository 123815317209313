import React, { Component } from "react";
import IdleTimer from 'react-idle-timer';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authActions from '../redux/actions/authActions';
import * as adminActions from '../redux/actions/admin.actions';
import APIFailedMessage from "./component.apiFailedMessage";
import ConfirmDialog from "./component.confirmDialog";

class IdleTimeModal extends Component{

    constructor(props){
        super(props);
        this.state={
            timeOutModel: false,
            signOutTime: JSON.parse(localStorage.getItem("signOutInMins")) || 1
        }
        this.idleTimer = null
        this.sessionTimeOut = null
        this.onIdle = this.onIdle.bind(this)
        this.onAction = this.onAction.bind(this)
        this.onActive = this.onActive.bind(this)
    }
    componentDidMount(){
        let signOutInMins=localStorage.getItem("signOutInMins");
        if(!signOutInMins){
            this.props.adminActions.getSignOutTime()
            .then(res=>{
                if(res){
                    this.setState({signOutTime:res.signOutInMins});
                    localStorage.setItem("signOutInMins",res.signOutInMins);
                }
            })
            .catch(error=>{
                APIFailedMessage.displayError(error);
            })
        }
    }

    handleTimeModal = () => {  
        this.setState({timeOutModel:!this.state.timeOutModel});
        clearTimeout(this.sessionTimeOut.current);
    }

    logOut = async() => {
        try{
          await this.props.authActions.logout();
          }
        catch(error){
            APIFailedMessage.displayError(error);
        }
    }
    
    onIdle(e) {
        this.setState({timeOutModel: true});
        this.sessionTimeOut.current = setTimeout(() => {
          this.logOut();
        }, 20000);
    }
    onAction(e) {
        clearTimeout(this.sessionTimeOut.current);
    }
    onActive(e) {
        clearTimeout(this.sessionTimeOut.current);
    }

    render(){
        return(
            <>
            <IdleTimer
                ref={ref => { this.idleTimer = ref; this.sessionTimeOut = ref }}
                onIdle={this.onIdle}
                onAction={this.onAction}
                onActive={this.onActive}
                timeout={60000 * this.state.signOutTime}
            />

            <ConfirmDialog
                show={this.state.timeOutModel}
                handleModalPopup={this.handleTimeModal}
                message={"<p className='mb-0'>Your session is about to expire due to inactivity, please choose to stay signed in or logout.</p><p className='mb-0'>Otherwise, you will logout automatically.</p>"}
                header_name = "Warning!!"
                button1_name="Stay In"
                button1={this.handleTimeModal}
                button2_name="Logout"
                button2={this.logOut}
            />
            </>
        );
    };
}

const mapDispatchToProps=(dispatch)=>{
    return {
     authActions:bindActionCreators(authActions,dispatch),
     adminActions:bindActionCreators(adminActions,dispatch)
    } 
}
export default connect(null,mapDispatchToProps)(IdleTimeModal);