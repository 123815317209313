import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export const CardView = ({
  data,
  handleCardSelect,
  activeID,
  onValueChange,
  dataField,
  order,
  table_select,
}) => {
  return (
    <>
      <p className="h4 text-secondary mb-0 mt-3 ml-2">Sort By</p>
      <div className="row pl-1 pr-3">
        <div className="col-6 mt-3 mb-2">
          <select
            value={dataField}
            className="form-select pt-2 pb-2"
            onChange={(e) => {
              onValueChange(e);
            }}
          >
            <option value="account">Customer ID</option>
            <option value="firstName">Name</option>
            <option value="address">Address</option>
            <option value="mobileNo">Phone</option>
            <option value="idNumber">ID#</option>
            <option value="ssn">SSN/ITIN</option>
          </select>
        </div>
        <div className="col-3 mt-3 mb-2">
          <select
            value={order}
            className="form-select pt-2 pb-2"
            onChange={(e) => {
              onValueChange(e);
            }}
          >
            <option value="asc">ASC</option>
            <option value="desc">DESC</option>
          </select>
        </div>
      </div>
      <hr className="mt-1 mb-1"></hr>
      <div className="row" id="outerCoverForLookUpCards">
        {data &&
          data.map((val) => (
            <div className="col-sm-12 col-md-6 pt-2 pb-2 d-flex" key={val.customer_Id}>
              <Card
                className="text-left shadow"
                onClick={() =>
                  handleCardSelect(val)
                }
              >
                <Card.Body
                  className={`m-3 pt-0 ${
                    val.customer_Id === activeID &&
                    table_select?.type === "exist_application"
                      ? "bg-secondary text-primary"
                      : ""
                  }`}
                  style={{ border: "2px solid #1B1B1B" }}
                >
                  <p className="h3 ml-0 mt-2 mb-0">{val.name}</p>
                  <hr
                    style={
                      val.customer_Id === activeID
                        ? { backgroundColor: "#f8f6fa" }
                        : { backgroundColor: "#1B1B1B" }
                    }
                  ></hr>
                  <div className="row">
                    <div className="col-6">Customer ID :</div>
                    <div className="col-6">{val.customer_Id}</div>
                    <div className="col-6">Address :</div>
                    <div className="col-6">{val.address}</div>
                    <div className="col-6">Phone :</div>
                    <div className="col-6">{val.mobileNo}</div>
                    <div className="col-6">ID# :</div>
                    <div className="col-6">{val.idNumber}</div>
                    <div className="col-6">SSN/ITIN :</div>
                    <div className="col-6">{val.ssn}</div>
                    <div className="col-6">Daniel's Account :</div>
                    <div className="col-6">{val.magix_Id?.length > 0 && val.magix_Id != "null" ? "Y" : "N"}</div>
                  </div>
                  <table className="text-center w-100 mt-2 mb-2 h-25">
                    <thead className="border-dark border-top">
                      <tr className={val.id === activeID ? "text-white" : ""}>
                        <th>D</th>
                        <th>F</th>
                        <th>S</th>
                        <th>P</th>
                        <th>U</th>
                      </tr>
                    </thead>
                    <tbody className="border-dark border-bottom">
                      <tr>
                        <td>
                          {val.isDaniel ? (
                            <FontAwesomeIcon
                              icon={faCircle}
                              className={
                                val.customer_Id === activeID
                                  ? "text-white"
                                  : "text-secondary"
                              }
                              style={{ opacity: "0.6" }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {val.isFortiva ? (
                            <FontAwesomeIcon
                              icon={faCircle}
                              className={
                                val.customer_Id === activeID
                                  ? "text-white"
                                  : "text-secondary"
                              }
                              style={{ opacity: "0.6" }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {val.isSynchrony ? (
                            <FontAwesomeIcon
                              icon={faCircle}
                              className={
                                val.customer_Id === activeID
                                  ? "text-white"
                                  : "text-secondary"
                              }
                              style={{ opacity: "0.6" }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {val.isProgressive ? (
                            <FontAwesomeIcon
                              icon={faCircle}
                              className={
                                val.customer_Id === activeID
                                  ? "text-white"
                                  : "text-secondary"
                              }
                              style={{ opacity: "0.6" }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {val.isUown ? (
                            <FontAwesomeIcon
                              icon={faCircle}
                              className={
                                val.customer_Id === activeID
                                  ? "text-white"
                                  : "text-secondary"
                              }
                              style={{ opacity: "0.6" }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </div>
          ))}
      </div>
    </>
  );
};

export const MagixCardView = ({
  magixData,
  magixhandleCardSelect,
  activeAccount,
  selectedStore,
  magixdataField,
  magixorder,
  magixonValueChange,
  table_select,
}) => {
  return (
    <>
      <p className="h4 text-secondary mb-0 mt-3 ml-2">Sort By</p>
      <div className="row pl-1 pr-3">
        <div className="col-6 mt-3 mb-2">
          <select
            value={magixdataField}
            className="form-select pt-2 pb-2"
            onChange={(e) => magixonValueChange(e)}
          >
            <option value="firstName">Name</option>
            <option value="address">Address</option>
            <option value="mobileNo">Phone</option>
            <option value="idNumber">ID#</option>
            <option value="ssn">SSN/ITIN</option>
            <option value="store_code">Home Store</option>
            <option value="account">Account</option>
            {/* <option value="portalStatus">Status</option> */}
            <option value="balanceDue">Balance Due</option>
            <option value="maxCustBal">Credit Rating</option>
          </select>
        </div>
        <div className="col-3 mt-3 mb-2">
          <select
            value={magixorder}
            className="form-select pt-2 pb-2"
            onChange={(e) => magixonValueChange(e)}
          >
            <option value="asc">ASC</option>
            <option value="desc">DESC</option>
          </select>
        </div>
      </div>
      <hr className="mt-1 mb-1"></hr>
      <div className="row">
        {magixData &&
          magixData.map((val, key) => (
            <div className="col-sm-12 col-md-6 pt-2 pb-2 d-flex" key={val.id}>
              <Card
                className="text-left shadow"
                onClick={(account, name, storeCode) =>
                  magixhandleCardSelect(
                    val,
                    key
                  )
                }
              >
                <Card.Body
                  className={`m-3 pt-0 ${
                    val.application_id === activeAccount &&
                    val.store_code === selectedStore &&
                    table_select?.type === "magix"
                      ? "bg-secondary text-primary"
                      : ""
                  }`}
                  style={{ border: "2px solid #1B1B1B" }}
                >
                  <p className="h3 ml-0 mt-2 mb-0">{val.name}</p>
                  <hr
                    style={
                      val.applicaiton_id === activeAccount &&
                      val.store_code === selectedStore
                        ? { backgroundColor: "#f8f6fa" }
                        : { backgroundColor: "#1B1B1B" }
                    }
                  ></hr>
                  <div className="row">
                    <div className="col-6">Address :</div>
                    <div className="col-6">{val.address}</div>
                    <div className="col-6">Phone :</div>
                    <div className="col-6">{val.mobileNo}</div>
                    <div className="col-6">ID#:</div>
                    <div className="col-6">{val.idNumber}</div>
                    <div className="col-6">SSN/ITIN :</div>
                    <div className="col-6">{val.ssn}</div>
                    <div className="col-6">Home Store :</div>
                    <div className="col-6">{val.store_code}</div>
                    <div className="col-6">Account :</div>
                    <div className="col-6">{val.customer_Id}</div>
                    <div className="col-6">Balance Due :</div>
                    <div className="col-6">{val.strbalanceDue}</div>
                    <div className="col-6">Credit Rating :</div>
                    <div className="col-6">{(val.custAcctStat === null ? "" : val.custAcctStat)+" "+(val.maxCustBal === null ? "" : val.maxCustBal)}</div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </div>
    </>
  );
};
