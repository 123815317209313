import React from "react";
import { Modal } from "react-bootstrap";


export default class NoFortivaDialog extends React.Component{
  render(){
    return(
      <Modal show={this.props.show} >  
        <Modal.Header className="pb-0"><b className="text-secondary h4">{this.props.header_name ? this.props.header_name : "Confirmation" }</b></Modal.Header>
          <Modal.Body className="pb-0">
              <div dangerouslySetInnerHTML={{__html : this.props.message }}></div>
          </Modal.Body> 
          <Modal.Footer className="border-top-0 mt-1">
              <button className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}} onClick={(event)=>{this.props.handleFortivaModal(event)}}>{this.props.btn1_name}</button>
              <button className="btn mr-1 ml-1 mb-0 reLogout" style={{backgroundColor:"#5c068c",color:"white"}} onClick={(event)=>{this.props.submitFortiva(event,false)}}>{this.props.btn2_name}</button>
          </Modal.Footer>
      </Modal>
    )
  }
}