import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCircleXmark,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import "./component.insuranceMatrix.css";
import * as InsuranceActions from "../../redux/actions/insurance.actions";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import ConfirmDialog from "../../components/component.confirmDialog";
import * as applyNowActions from "../../redux/actions/applyNow.actions";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";

class InsuranceMatrix extends Component {
  constructor() {
    super();
    this.state = {
      toggled: false,
      addOrEditTab: false,
      roles: [],
      site_page_details: [],
      sitePages: [],
      userRights: [],
      permission_status: "",
      newPlan: false,
      goldPlan: false,
      silverPlan: false,
      bronzePlan: false,
      allState: [],
      newInsuranceState: [],
      existingInsuranceState: [],
      allInsurancePlans: [],
      insurancePlans: [],
      finicial_partners: [],
      selectedStateAndPlan: "",
      activeTab: "Daniels",
      edit: false,
      step1: false,
      step2: false,
      step3: false,
      coverage: {
        state: "",
        life: false,
        disability: false,
        unemployment: false,
        property: false,
        lifeValue: "",
        disabilityValue: "",
        unemploymentValue: "",
        propertyValue: "",
        totalValue: ""
      },
      bothDaniels : false,
      bothFortiva : false,
      showDaniels : true,
      showFortiva : true,
      hideGoldPlan : false,
      hideSilverPlan : false,
      hideBronzePlan : false,
    };
  }

  async componentDidMount() {
    if (sessionStorage.getItem("isLoggedIn") === "true") {
      let obj = {
        type: "State"
      };
      await trackPromise(
        this.props.applyNowAction
          .getCodeMaster(obj)
          .then((response) => {
            if (response) {
              this.setState({ allState: response.data });
              obj = response.data;
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          }
          ));
      await trackPromise(
        this.props.actions
          .getInsurancePlans()
          .then((response) => {
            if (response) {
              let partnerType = response?.getInsuranceResponses?.map((item) => {
                return item.insurance_partner;
              });
              partnerType = [...new Set(partnerType)];
              partnerType = partnerType?.sort();
              let customResponse = response?.getInsuranceResponses?.filter(item => {
                return item.insurance_partner === partnerType[0];
              });
              let stateList = [];
              let finalResponse = [];
              customResponse.map((item) => {
                stateList.push(item.state);
              });
              const count = {};
              for (const element of stateList) {
                if (count[element]) count[element] += 1;
                else count[element] = 1;
              }
              stateList = [...new Set(stateList)];
              stateList = stateList.sort();
              finalResponse = stateList.map((item) => {
                let val = obj?.find((val) => {
                  return val.code === item;
                });
                let result = {
                  state: val?.description,
                  isActive : customResponse.find((val) => {
                    return val.state === item;
                  })?.isActive,
                  data: customResponse.filter((val) => {
                    return val.state === item;
                  })
                }
                return result;
              });
              stateList=stateList.filter((item) => {
                return count[item] === 3;
              })
              let newInsuranceState = obj.filter((item) => {
                return !stateList.includes(item.code);
              });
              let existingInsuranceState = obj.filter((item) => {
                return stateList.includes(item.code);
              });
              this.setState({
                insurancePlans: finalResponse,
                allInsurancePlans: response,
                isLoad: true,
                isFirstTime: true,
                newInsuranceState: newInsuranceState,
                existingInsuranceState: existingInsuranceState,
                finicial_partners: partnerType,
                activeTab : partnerType[0],
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          }));
      await trackPromise(
        this.props.userRightsactions
          .getUserRights()
          .then((response) => {
            if (response) {
              this.setState({
                roles: response.roles,
                site_page_details: response.sitePageDetails,
                sitePages: response.sitePages,
                userRights: response.userRights,
              });
              this.getPermissions();
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    } else this.props.history.push("/");
  }

  getPermissions() {
    let role_name = localStorage.getItem("role_name"),
      page_name = "Insurance Matrix";
    const getsitepages = this.state.sitePages.filter((site_pages) => {
      return site_pages.name === "Admin Page";
    });
    const getSitepagedetails = this.state.site_page_details.filter(
      (site_page_details) => {
        return (
          site_page_details.sitePage_id === getsitepages[0].id &&
          site_page_details.name === page_name
        );
      }
    );
    const getRole = this.state.roles.filter((roles) => {
      return roles.name.toLowerCase() === role_name.toLowerCase();
    });
    let role_id = getRole[0].id;
    const role_permission_check = this.state.userRights.filter(
      (user_rights) => {
        return (
          user_rights.sitePageDetail_id === getSitepagedetails[0].id &&
          user_rights.role_id === role_id
        );
      }
    );
    this.setState({ permission_status: role_permission_check[0] });
  }

  triggerAddTab = () => {
    this.setState({ addOrEditTab: true, selectedStateAndPlan: "", edit: false, bothDaniels : this.state.activeTab==="Daniels" , bothFortiva : this.state.activeTab==="Fortiva" ,
            showDaniels : this.state.activeTab === "Daniels", showFortiva : this.state.activeTab==="Fortiva" });
  };
  triggerEditTab = () => {
    let obj = this.state.selectedStateAndPlan;
    let result = this.state.allInsurancePlans?.getInsuranceResponses?.find((item) => {
      return (item.insurance_partner !== obj.insurance_partner &&
             item.state === obj.state && 
             item.insurance_Plan === obj.insurance_Plan);
    });
    this.selectPlan(obj.insurance_Plan?.split("_")[0]);
    let total = 0;
    if (obj.insurance_life !== "no")
      total += parseFloat(obj.insurance_life?.replace('$', ""))
    if (obj.insurance_disability !== "no")
      total += parseFloat(obj.insurance_disability?.replace('$', ""))
    if (obj.insurance_unemployment !== "no")
      total += parseFloat(obj.insurance_unemployment?.replace('$', ""))
    if (obj.insurance_property !== "no")
      total += parseFloat(obj.insurance_property?.replace('$', ""))
    total = "$" + total?.toFixed(3);
    this.setState({
      addOrEditTab: true, edit: true,
      coverage: {
        ...this.state.coverage,
        state: obj.state,
        life: obj?.insurance_life !== "no" ? true : false,
        disability: obj?.insurance_disability !== "no" ? true : false,
        unemployment: obj?.insurance_unemployment !== "no" ? true : false,
        property: obj?.insurance_property !== "no" ? true : false,
        lifeValue: obj?.insurance_life !== "no" ? obj.insurance_life?.replace("$", "") : "",
        disabilityValue: obj?.insurance_disability !== "no" ? obj.insurance_disability?.replace("$", "") : "",
        unemploymentValue: obj?.insurance_unemployment !== "no" ? obj.insurance_unemployment?.replace("$", "") : "",
        propertyValue: obj?.insurance_property !== "no" ? obj.insurance_property?.replace("$", "") : "",
        totalValue: total,
      },
      step1: true,
      step3: true,
      bothDaniels : this.state.activeTab==="Daniels",
      bothFortiva : this.state.activeTab==="Fortiva",
      showDaniels : this.state.finicial_partners.includes(this.state.activeTab) ? (this.state.activeTab==="Daniels" ? true : result !== undefined ? true : false) : false,
      showFortiva : this.state.finicial_partners.includes(this.state.activeTab) ? (this.state.activeTab==="Fortiva" ? true : result !== undefined ? true : false) : false,
    });
  };
  triggerDeleteTab = () => {
    this.setState({ addOrEditTab: true });
  };

  onChange = (type) => {
    if (type === "life") {
      this.setState({ coverage: { ...this.state.coverage, life: !this.state.coverage.life } }, () => {
        this.calculateTotal();
      });
    }
    else if (type === "disability") {
      this.setState({ coverage: { ...this.state.coverage, disability: !this.state.coverage.disability } }, () => {
        this.calculateTotal();
      });
    }
    else if (type === "unemployment") {
      this.setState({ coverage: { ...this.state.coverage, unemployment: !this.state.coverage.unemployment } }, () => {
        this.calculateTotal();
      });
    }
    else if (type === "property") {
      this.setState({ coverage: { ...this.state.coverage, property: !this.state.coverage.property } }, () => {
        this.calculateTotal();
      });
    }
  };

  onInputChange = (e) => {
    e.preventDefault();
    const re = /^[.0-9\b]+$/;
    let controlValue = e.target.value?.replace("$", "");
    if (controlValue === '' || re.test(controlValue)) {
      if (e.target.name === "lifeInputBox" && this.state.coverage.life) {
        this.setState({ coverage: { ...this.state.coverage, lifeValue: controlValue } }, () => {
          this.calculateTotal();
        });
      }
      else if (e.target.name === "disabilityInputBox" && this.state.coverage.disability) {
        this.setState({ coverage: { ...this.state.coverage, disabilityValue: controlValue } }, () => {
          this.calculateTotal();
        });
      }
      else if (e.target.name === "unemploymentInputBox" && this.state.coverage.unemployment) {
        this.setState({ coverage: { ...this.state.coverage, unemploymentValue: controlValue } }, () => {
          this.calculateTotal();
        });
      }
      else if (e.target.name === "propertyInputBox" && this.state.coverage.property) {
        this.setState({ coverage: { ...this.state.coverage, propertyValue: controlValue } }, () => {
          this.calculateTotal();
        });
      }
    }
  };

  calculateTotal() {
    let obj = this.state.coverage;
    let total = 0;
    if (obj.life && obj.lifeValue !== "")
      total += parseFloat(obj.lifeValue)
    if (obj.disability && obj.disabilityValue !== "")
      total += parseFloat(obj.disabilityValue)
    if (obj.unemployment && obj.unemploymentValue !== "")
      total += parseFloat(obj.unemploymentValue)
    if (obj.property && obj.propertyValue !== "")
      total += parseFloat(obj.propertyValue)
    total = "$" + total?.toFixed(3);
    this.setState({ coverage: { ...this.state.coverage, totalValue: total } });
  }

  validateInputValue() {
    let obj = this.state.coverage;
    let disable = false;
    if(!this.state.bothDaniels && !this.state.bothFortiva)
      return !disable;
    else if (obj?.state === "0" || obj?.state === "")
      return !disable;
    else if (!obj.life && !obj.disability && !obj.unemployment && !obj.property)
      return !disable;
    else if (obj.life && obj.lifeValue === "")
      return !disable;
    else if (obj.disability && obj.disabilityValue === "")
      return !disable;
    else if (obj.unemployment && obj.unemploymentValue === "")
      return !disable;
    else if (obj.property && obj.propertyValue === "")
      return !disable;
    return disable;
  }

  handleToggleSidebar() {
    this.setState({ toggled: !this.state.toggled });
  }

  newPlanEnroll() {
    this.setState({ newPlan: !this.state.newPlan });
  }

  chooseState = (e) => {
    e.preventDefault();
    this.setState({
      coverage: { ...this.state.coverage, state: e.target.value },
    });
    if(!this.state.edit) {
      let result = this.state.allInsurancePlans.getInsuranceResponses.filter((item) => {
        return item.state === e.target.value && 
        item.insurance_partner === this.state.activeTab;
      })
      let plans = [];
      if(result!== undefined) {
          result.map((item) => {
            plans.push(item.insurance_Plan);
          })
          this.setState({hideGoldPlan : plans.includes('gold_plan'),
        hideSilverPlan : plans.includes('silver_plan'),
      hideBronzePlan : plans.includes('bronze_plan')})
      }
    }
    if (!this.state.step1)
      this.setState({ step1: true });
  }

  onPlanSelectToEdit(item) {
    this.setState({ selectedStateAndPlan: item });
  }

  selectPlan(type) {
    if (type === "gold")
      this.setState({ goldPlan: true, silverPlan: false, bronzePlan: false, step2: true });
    else if (type === "silver")
      this.setState({ goldPlan: false, silverPlan: true, bronzePlan: false, step2: true });
    else if(type === "bronze")
      this.setState({ goldPlan: false, silverPlan: false, bronzePlan: true, step2: true });
    else if(type === "bothDaniels" && this.state.showFortiva)
      this.setState({bothDaniels : !this.state.bothDaniels});
    else if(type === "bothFortiva" && this.state.showDaniels)
      this.setState({bothFortiva : !this.state.bothFortiva});
  };

  revertChanges() {
    this.setState({
      addOrEditTab: false,
      errorWarning: "",
      goldPlan: false,
      silverPlan: false,
      bronzePlan: false,
      edit: false,
      step1: false,
      step2: false,
      step3: false,
      coverage: {
        life: false,
        disability: false,
        unemployment: false,
        property: false,
        lifeValue: "",
        disabilityValue: "",
        unemploymentValue: "",
        propertyValue: ""
      }
    });
  }

  handleSave() {
    let data = this.state.coverage;
    let obj = {
      state: data.state,
      insurance_Plan: this.state.goldPlan ? "gold_plan" : this.state.silverPlan ? "silver_plan" : "bronze_plan",
      insurance_life: data.life ? data.lifeValue === "" ? "no" : "$" + parseFloat(data.lifeValue).toFixed(3) : "no",
      insurance_disability: data.disability ? data.disabilityValue === "" ? "no" : "$" + parseFloat(data.disabilityValue).toFixed(3) : "no",
      insurance_unemployment: data.unemployment ? data.unemploymentValue === "" ? "no" : "$" + parseFloat(data.unemploymentValue).toFixed(3) : "no",
      insurance_property: data.property ? data.propertyValue === "" ? "no" : "$" + parseFloat(data.propertyValue).toFixed(3) : "no",
      insurance_partner: (this.state.bothDaniels && this.state.bothFortiva) ? "Both" : this.state.bothDaniels ? "Daniels" : "Fortiva",
      modified_By: localStorage.getItem("loggedUserEmail"),
    }
    trackPromise(
      this.props.actions.updateInsurancePlans(obj).then((response) => {
        if (response) {
          if (response === true) {
            toast.success("Plan value updated successfully.");
            this.reRenderData();
            this.setState({ selectedStateAndPlan: "" });
          }
          else {
            toast.error("Error! please try again.")
          }
          this.revertChanges();
        }
      })
    );
  }

  reRenderData() {
    trackPromise(
      this.props.actions
        .getInsurancePlans()
        .then((response) => {
          if (response) {
            let customResponse = response?.getInsuranceResponses?.filter(item => {
              return item.insurance_partner === this.state.activeTab;
            });
            let obj = this.state.allState;
            let stateList = [];
            let finalResponse = [];
            customResponse.map((item) => {
              stateList.push(item.state);
            })
            const count = {};
              for (const element of stateList) {
                if (count[element]) count[element] += 1;
                else count[element] = 1;
              }
            stateList = [...new Set(stateList)];
            stateList = stateList.sort();
            finalResponse = stateList.map((item) => {
              let val = obj?.find((val) => {
                return val.code === item;
              });
              let result = {
                state: val?.description,
                isActive : customResponse.find((val) => {
                  return val.state === item;
                })?.isActive,
                data: customResponse.filter((val) => {
                  return val.state === item;
                })
              }
              return result;
            });
            stateList=stateList.filter((item) => {
              return count[item] === 3;
            })
            let newInsuranceState = obj.filter((item) => {
              return !stateList.includes(item.code);
            });
            this.setState({
              insurancePlans: finalResponse,
              allInsurancePlans: response,
              isLoad: true,
              newInsuranceState: newInsuranceState,
            });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        }));
  }

  async changeTab(currentTab) {
    if (currentTab !== "" && this.state.activeTab != currentTab) {
      this.setState({ activeTab: currentTab, selectedStateAndPlan: "" });
      let obj = this.state.allState;
      await trackPromise(
        this.props.actions
          .getInsurancePlans()
          .then((response) => {
            if (response) {
              let customResponse = response?.getInsuranceResponses?.filter(item => {
                return item.insurance_partner === currentTab;
              });
              let stateList = [];
              let finalResponse = [];
              customResponse.map((item) => {
                stateList.push(item.state);
              })
              const count = {};
              for (const element of stateList) {
                if (count[element]) count[element] += 1;
                else count[element] = 1;
              }
              stateList = [...new Set(stateList)];
              stateList = stateList.sort();
              finalResponse = stateList.map((item) => {
                let val = obj?.find((val) => {
                  return val.code === item;
                });
                let result = {
                  state: val?.description,
                  isActive : customResponse.find((val) => {
                    return val.state === item;
                  })?.isActive,
                  data: customResponse.filter((val) => {
                    return val.state === item;
                  })
                }
                return result;
              });
              stateList=stateList.filter((item) => {
                return count[item] === 3;
              })
              let newInsuranceState = obj.filter((item) => {
                return !stateList.includes(item.code);
              });
              let existingInsuranceState = obj.filter((item) => {
                return stateList.includes(item.code);
              });
              this.setState({
                insurancePlans: finalResponse,
                allInsurancePlans: response,
                isLoad: true,
                newInsuranceState: newInsuranceState,
                existingInsuranceState: existingInsuranceState
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          }));
    }
  }

  onActiveStateChange(val) {
    let obj = {
      state : val?.data[0]?.state,
      partnerType : this.state.activeTab,
      isActive : !val.isActive
    }
      this.props.actions.enableDisableInsurancestate(obj).then((res) => {
        if(res === true) {
          let plans = this.state.insurancePlans;
          plans = plans.map((item) => {
            if(item.state == val.state) {
              item.isActive = !item.isActive;
            }
            return item;
          });
          this.setState({insurancePlans : plans});
          let allPlans = this.state.allInsurancePlans.getInsuranceResponses;
          allPlans = allPlans.map((item) => {
            if(item.state == val?.data[0]?.state && item.insurance_partner == this.state.activeTab) {
              item.isActive = !item.isActive;
            }
            return item;
          });
          let homeStore = JSON.parse(localStorage.getItem('associateStores'));
          var storeList = JSON.parse(sessionStorage.getItem("storeList"));
          storeList = storeList.find((item) => {
            return item.code == homeStore
          });
          let stateCode = storeList?.address2?.split(",")[1]?.trim();
          allPlans = allPlans?.find(item => {
            return item.insurance_partner === this.state.activeTab && item.isActive === true && item.state == stateCode
          });
          if(this.state.activeTab == "Fortiva") {
            if(allPlans !== undefined)
              sessionStorage.setItem("havingFortivaInsurance", true)
            else sessionStorage.setItem("havingFortivaInsurance", false) 
          }
          else {
            if(allPlans !== undefined)
              sessionStorage.setItem("havingDanielInsurance", true)
            else sessionStorage.setItem("havingDanielInsurance", false)
          }     
        }
        else {
          toast.error("Error: please try after sometime.")
        }
      })
  }

  render() {
    let count = 0;
    let adminMenus = [];
    adminMenus = localStorage.getItem("sitePageDetails");
    adminMenus = JSON.parse(adminMenus);
    return (
      <>
        <div
          className={this.state.addOrEditTab
            ? " RolePanelShow"
            : "RolePanelHide "
          }
        >
          {this.state.addOrEditTab && (
            <div className="RolePanel">
              <div className="container-fluid pt-4">
                <div className="row">
                  <div className="col-12 pl-4 pr-4">
                    <p>
                      <span className="h5 m-0">
                        <b>{this.state.edit ? "Edit plan value" : "Add New Insurance" }</b>
                      </span>
                      <span
                        className="btn float-right p-0"
                        onClick={() =>
                          this.revertChanges()
                        }
                      >
                        <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                      </span>
                    </p>
                    <div className="row mt-4 mb-3">
                      {this.state.showDaniels ? 
                          <div className={"col-12 col-md-4"}>
                            <div className="form-group mb-0 d-flex">
                              <input
                                type="checkbox"
                                name="danielsPartner"
                                id="danielsPartner"
                                value={this.state.bothDaniels}
                                checked={this.state.bothDaniels}
                                onClick={() => this.selectPlan("bothDaniels")}
                              />
                              <label htmlFor="danielsPartner" className="mt-2 ml-2">Daniel's</label>
                            </div>
                          </div>
                       : null}
                      {this.state.showFortiva ? 
                          <div className={"col-12 col-md-4"}>
                            <div className="form-group mb-0 d-flex">
                              <input
                                type="checkbox"
                                name="foertivaPartner"
                                id="foertivaPartner"
                                value={this.state.bothFortiva}
                                checked={this.state.bothFortiva}
                                onClick={() => this.selectPlan("bothFortiva")}
                              />
                              <label htmlFor="foertivaPartner" className="mt-2 ml-2">Fortiva</label>
                            </div>
                          </div>
                          : null }
                        </div>
                    <hr></hr>
                    <div className="row mb-3">
                      <div className="col-6 col-md-4 col-lg-5 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                        <label>State</label>
                      </div>
                      <div className="form-group col-6 col-md-4 col-lg-5 pt-2">
                        {this.state.edit ?
                          this.state.allState?.find((item) => {
                            return this.state.selectedStateAndPlan?.state === item.code;
                          })?.description
                          :
                          <select value={this.state.coverage?.state} className="form-control" onChange={this.chooseState}>
                            <option value="0"></option>
                            {this.state.newInsuranceState?.map((item) => {
                              return <option value={item.code}>{item.description}</option>
                            })
                            }
                          </select>
                        }
                      </div>
                    </div>
                    {this.state.step1 ?
                      <>
                        <hr></hr>
                        <div className="row mb-4">
                          <div className="col-12 d-flex align-items-center">
                            <h5 className="mb-0"><b>Insurance Plan</b></h5>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className={"col-12 col-md-4 " + ((this.state.edit && !this.state.goldPlan) || this.state.hideGoldPlan ? "d-none" : "")}>
                            <div className="form-group mb-0">
                              <input
                                type="radio"
                                name="insuranePlan"
                                id="insuraneGoldPlan"
                                value={this.state.goldPlan}
                                checked={this.state.goldPlan}
                                onClick={() => this.selectPlan("gold")}
                              />
                              <label htmlFor="insuraneGoldPlan" className="ml-2">GOLD</label>
                            </div>
                          </div>
                          <div className={"col-12 col-md-4 " + ((this.state.edit && !this.state.silverPlan) || this.state.hideSilverPlan ? "d-none" : "")}>
                            <div className="form-group mb-0">
                              <input
                                type="radio"
                                name="insuranePlan"
                                id="insuraneSilverPlan"
                                value={this.state.silverPlan}
                                checked={this.state.silverPlan}
                                onClick={() => this.selectPlan("silver")}
                              />
                              <label htmlFor="insuraneSilverPlan" className="ml-2">SILVER</label>
                            </div>
                          </div>
                          <div className={"col-12 col-md-4 " + ((this.state.edit && !this.state.bronzePlan) || this.state.hideBronzePlan ? "d-none" : "")}>
                            <div className="form-group mb-0">
                              <input
                                type="radio"
                                name="insuranePlan"
                                id="insuraneBronzePlan"
                                value={this.state.bronzePlan}
                                checked={this.state.bronzePlan}
                                onClick={() => this.selectPlan("bronze")}
                              />
                              <label htmlFor="insuraneBronzePlan" className="ml-2">BRONZE</label>
                            </div>
                          </div>
                        </div>
                      </>
                      : null}
                    {this.state.step2 ?
                      <>
                        <hr></hr>
                        <div className="row mb-4">
                          <div className="col-12 d-flex align-items-center">
                            <h5 className="mb-0"><b>Insurance Coverages</b></h5>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-12">
                            <div className="form-group d-flex border">
                              <div className="d-flex p-2 border-right w-50">
                                <input
                                  type="checkbox"
                                  name="lifeCheckBox"
                                  id="lifeCheckBox"
                                  checked={this.state.coverage.life === true ? "checked" : ""}
                                  value={this.state.coverage.life}
                                  onClick={() => this.onChange("life")}
                                />
                                <label htmlFor="lifeCheckBox" className="mb-0 mt-1 pl-2">Life</label>
                              </div>
                              <input
                                className="form-control m-1"
                                name="lifeInputBox"
                                id="lifeInputBox"
                                value={"$" + this.state.coverage.lifeValue}
                                onChange={(e) => this.onInputChange(e)}
                                disabled={!this.state.coverage.life}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group d-flex border">
                              <div className="d-flex p-2 border-right w-50">
                                <input
                                  type="checkbox"
                                  name="disabilityCheckbox"
                                  id="disabilityCheckbox"
                                  checked={this.state.coverage.disability === true ? "checked" : ""}
                                  value={this.state.coverage.disability}
                                  onClick={() => this.onChange("disability")}
                                />
                                <label htmlFor="disabilityCheckbox" className="mb-0 mt-1 pl-2">Total Disability</label>
                              </div>
                              <input
                                className="form-control m-1"
                                name="disabilityInputBox"
                                id="disabilityInputBox"
                                value={"$" + this.state.coverage.disabilityValue}
                                onChange={(e) => this.onInputChange(e)}
                                disabled={!this.state.coverage.disability}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group d-flex border">
                              <div className="d-flex p-2 border-right w-50">
                                <input
                                  type="checkbox"
                                  name="unemploymentCheckBox"
                                  id="unemploymentCheckBox"
                                  checked={this.state.coverage.unemployment === true ? "checked" : ""}
                                  value={this.state.coverage.unemployment}
                                  onClick={() => this.onChange("unemployment")}
                                />
                                <label htmlFor="unemploymentCheckBox" className="mb-0 mt-1 pl-2">Involuntary Unemployment</label>
                              </div>
                              <input
                                className="form-control m-1"
                                name="unemploymentInputBox"
                                id="unemploymentInputBox"
                                value={"$" + this.state.coverage.unemploymentValue}
                                onChange={(e) => this.onInputChange(e)}
                                disabled={!this.state.coverage.unemployment}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group d-flex border">
                              <div className="d-flex p-2 border-right w-50">
                                <input
                                  type="checkbox"
                                  name="propertyCheckBox"
                                  id="propertyCheckBox"
                                  checked={this.state.coverage.property === true ? "checked" : ""}
                                  value={this.state.coverage.property}
                                  onClick={() => this.onChange("property")}
                                />
                                <label htmlFor="propertyCheckBox" className="mb-0 mt-1 pl-2">Property</label>
                              </div>
                              <input
                                className="form-control m-1"
                                name="propertyInputBox"
                                id="propertyInputBox"
                                value={"$" + this.state.coverage.propertyValue}
                                onChange={(e) => this.onInputChange(e)}
                                disabled={!this.state.coverage.property}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group d-flex">
                              <div className="d-flex p-2 border-right w-50">
                                <p className="mb-0 mt-1 pl-2 h5"><b>Total</b></p>
                              </div>
                              <input
                                className="form-control m-1 h5 font-weight-bold"
                                value={this.state.coverage.totalValue}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 mb-4">
                            <div className="row">
                              <div className="col-12 col-md-4 col-lg-5">
                                <button
                                  className="btn bg-primary text-secondary w-100 mb-3 mb-md-0 mb-lg-0"
                                  onClick={() =>
                                    this.revertChanges()
                                  }
                                >
                                  Cancel
                                </button>
                              </div>
                              <div className="col-12 col-md-4 col-lg-5">
                                <button
                                  className="btn bg-secondary text-primary w-100"
                                  onClick={() => this.handleSave()}
                                  disabled={this.validateInputValue()}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={`app ${this.state.toggled ? "toggled" : ""}`}>
          <Sidebar
            toggled={this.state.toggled}
            handleToggleSidebar={() => {
              this.handleToggleSidebar();
            }}
            isAdmin={true}
            adminMenus={adminMenus}
          />
          <main>
            <CustomNavbar
              handleToggleSidebar={() => {
                this.handleToggleSidebar();
              }}
              isAdmin={true}
            />
            <div>
              <div className="row mr-3 ml-3 mb-4">
                <div className="col-12 mt-4 pl-0 pr-0 pl-md-3 pr-md-3">
                  <div
                    className="row ml-0 mr-0 ml-md-2 mr-md-3"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="h4 text-left">
                      <b>Insurance Matrix</b>
                    </p>
                  </div>
                  <div className="row pl-3 pr-3 mb-2">
                    {!this.state.permission_status.canEdit && 
                     !this.state.permission_status.canEdit ? null : (
                      <div className="col-12 mt-3 command_bar">
                        {this.state.permission_status?.canAdd ? (
                          <button
                            className="add_button"
                            onClick={() => this.triggerAddTab()}
                          >
                            <FontAwesomeIcon icon={faAdd} className="mr-1" />
                            Add
                          </button>
                        ) : null}
                        {this.state.permission_status?.canEdit ? (
                          <button
                            className="btn add_button ml-3"
                            onClick={() => this.triggerEditTab()}
                            disabled={!(this.state.selectedStateAndPlan !== "")}
                          >
                            <FontAwesomeIcon icon={faEdit} className="mr-1" />
                            Edit
                          </button>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <ul className="nav nav-tabs pl-3 pr-3">
                    {this.state.finicial_partners?.map((val) => {
                      return (
                        <>
                          <li className="nav-item pr-4">
                            <button
                              id={val}
                              className={"nav-link p-3 pl-md-5 pr-md-5 " + (this.state.activeTab == val ? "active shadow" : "")}
                              aria-current={this.state.activeTab == val ? "page" : ""}
                              onClick={() => this.changeTab(val)}
                            >
                              <b className="h5">{val}</b>
                            </button>
                          </li>
                        </>
                      )
                    })}
                  </ul>
                </div>
                {this.state.insurancePlans?.map((val) => {
                  ++count;
                  return <div className={"col-12 p-0 pl-md-3 pr-md-3 " + (count === 1 ? "" : "mt-3 mt-md-0 mt-lg-0")}>
                    <Card className="shadow rounded-0">
                      <Card.Body className="pt-1 pb-0">
                        <div className="row text-center">
                          <div className="col-12 col-md-3 p-0 d-flex">
                              <div className="col-6 p-0 d-md-flex flex-column">
                              <p className={"mb-0 bg-secondary text-primary insuDiv1 pt-2 pb-2 mb-3 stateHeading " + (count === 1 ? "" : "d-md-none d-lg-none")}>State</p>
                            <h5 className="my-auto"><b>{val.state}</b></h5>
                              </div>
                              <div className="col-6 p-0 d-md-flex flex-column">
                              <p className={"mb-0 bg-secondary text-primary pt-2 pb-2 mb-3 stateHeading " + (count === 1 ? "" : "d-md-none d-lg-none")}>Active</p>
                            <input
                                type="checkbox"
                                className="my-auto"
                                name="danielsPartner"
                                id="danielsPartner"
                                value={val.isActive}
                                checked={val.isActive}
                                onClick={() => this.onActiveStateChange(val)}
                              />
                              </div>                            
                          </div>
                          <div className="col-12 col-md-9 mt-3 mt-md-0">
                            <div className={"row border-bottom " + (count === 1 ? "" : "d-md-none d-lg-none")}>
                              <div className="col-2 bg-secondary text-primary stateHeading insuDiv1 pt-2 pb-2 pl-1 pr-1">
                                Plan Type
                              </div>
                              <div className="col-2 bg-secondary text-primary stateHeading insuDiv1 pt-2 pb-2 pl-1 pr-1">
                                Life
                              </div>
                              <div className="col-2 bg-secondary text-primary stateHeading insuDiv1 pt-2 pb-2 pl-1 pr-1">
                                Total Disability
                              </div>
                              <div className="col-2 bg-secondary text-primary stateHeading insuDiv1 pt-2 pb-2 pl-1 pr-1">
                                Involuntary Unemployment
                              </div>
                              <div className="col-2 bg-secondary text-primary stateHeading insuDiv1 pt-2 pb-2 pl-1 pr-1">
                                Property
                              </div>
                            </div>
                            {val.data.map((item) => {
                              return <>
                                <div className={"row " + (this.state.selectedStateAndPlan.state === item.state && this.state.selectedStateAndPlan.insurance_Plan === item.insurance_Plan ? "bg-secondary text-primary" : "")} onClick={() => this.onPlanSelectToEdit(item)}>
                                  <div className="col-2 insuDiv1 pt-2 pb-2 pl-1 pr-1 border-left border-bottom">
                                    {item.insurance_Plan?.split("_")[0].toUpperCase()}
                                  </div>
                                  <div className="col-2 insuDiv1 pt-2 pb-2 pl-1 pr-1 border-left border-bottom">
                                    {item.insurance_life === "no" ? "-" : item.insurance_life}
                                  </div>
                                  <div className="col-2 insuDiv1 pt-2 pb-2 pl-1 pr-1 border-left border-bottom">
                                    {item.insurance_disability === "no" ? "-" : item.insurance_disability}
                                  </div>
                                  <div className="col-2 insuDiv1 pt-2 pb-2 pl-1 pr-1 border-left border-bottom">
                                    {item.insurance_unemployment === "no" ? "-" : item.insurance_unemployment}
                                  </div>
                                  <div className="col-2 insuDiv1 pt-2 pb-2 pl-1 pr-1 border-left border-bottom">
                                    {item.insurance_property === "no" ? "-" : item.insurance_property}
                                  </div>
                                </div>
                              </>
                            })}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                    ;
                })}

              </div>
            </div>
          </main>
        </div>

        <ConfirmDialog
          show={this.state.isModalDeleteOpen}
          handleModalPopup={this.handleModalExit}
          message={"<p>Are you sure you want to delete?</p>"}
          button1_name="Cancel"
          button1={this.handleModalExit}
          button2_name="Delete"
          button2={this.handleDelete}
        />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(InsuranceActions, dispatch),
    userRightsactions: bindActionCreators(userRightsActions, dispatch),
    applyNowAction: bindActionCreators(applyNowActions, dispatch),
  };
};
export default connect(null, mapDispatchToProps)(InsuranceMatrix);
