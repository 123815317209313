import { useState,useRef } from 'react';
import CSVPopup from "./component.csvPopup";

const CsvReader=(props)=>{
    const {onFileUpload}=props;
    const [csvFile, setCsvFile] = useState();
    const [csvArray, setCsvArray] = useState([]);
    const [show,setShow]=useState(false);
    const inputRef = useRef(null);
   

    const processCSV = (str, delim=',') => {
        const headers = str.slice(0,str.indexOf('\n'))?.trimEnd()?.split(delim)
        const rows = str.slice(str.indexOf('\n')+1).split('\n')?.map(item=>item.trimEnd())

        const newArray = rows.map( row => {
            const values = row.split(delim);
            const eachObject = headers.reduce((obj, header, i) => {
                obj[header] = values[i];
                return obj;
            }, {})
            return eachObject;
        })
        let ipsList=[];
        if(newArray){
            newArray.forEach(item=>{
                if(item.fromIP){
                    ipsList.push(item);
                }
            })
        }
        setCsvArray(ipsList)
    }

    const submit = () => {
        const file = csvFile;
        const reader = new FileReader();
        
        reader.onload = function(e) {
            const text = e.target.result;
            processCSV(text)
        }
        setShow(true);

        reader.readAsText(file);
    }
    const handleCSVPopup=()=>{
        setShow(false);
        setCsvFile();
        inputRef.current.value="";
    }
    const onFileUploadCSV= async()=>{
        setShow(false);
        inputRef.current.value="";
        await onFileUpload(csvArray, csvFile);
        setCsvFile();
      
    }

    return(
        <>
        <form id='csv-form'>
        <div className="form-group d-inline-block w-40 pl-3 mt-1 mb-1" > 
            <input type='file'
                accept='.csv,.xls,.xlsx'
                id='csvFile'
                onChange={(e) => {
                    setCsvFile(e.target.files[0])
                }}
                ref={inputRef} />
        </div>
            <button
             className="btn bg-secondary float-right text-primary pl-4 pr-4 mr-3"
                onClick={(e) => {
                    e.preventDefault()
                    if(csvFile)submit()
                }}
                disabled={csvFile ?false:true}
            >
                Import File
            </button>
        </form>
        <div className="col-12">
        <CSVPopup 
    show={show} 
        data={csvArray} 
        handleCSVPopup={handleCSVPopup}
        onFileUpload={onFileUploadCSV}
        />
      </div>
      </>
    );

}
export default CsvReader;