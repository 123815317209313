import { ApiWrapper } from "../../api/thunk.helper";
import HttpMethods from "../_http.methods";

export function getCustomerDetails(CustomerId) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        Resource: CustomerId,
        ResourceUrl:
          "v1/customers/"+CustomerId,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }
 
  export function getCustomerDetails_new(CustomerId) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        Resource: CustomerId,
        ResourceUrl:
          "v1/customers/GetCustomerDetails_ById?Customer_Id="+CustomerId,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }
  export function getApplicationDetails(applicationId) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        Resource: applicationId,
        ResourceUrl:
          "v1/customers/"+applicationId,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }
  export function getMagixCustomerDetails(storeCode) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        Resource: storeCode?.associateId,
        ResourceUrl:
          `v1/magix/0?magixId=${storeCode?.associateId}&storecode=${storeCode?.storecode}`,
        isMagix : true
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

export function getCustomerViewOffers(CustomerId) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Get(),
            Resource: CustomerId,
            ResourceUrl:
                "v1/customers/view-offers?CustomerId=" + CustomerId,
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function getSynchronyViewOffers(cust_Id,synchronyAppId,homeStore) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Get(),
          Resource: synchronyAppId,
          ResourceUrl:
              "v1/customers/viewoffers_synchrony?CustomerId="+cust_Id+"&Application_Id="+synchronyAppId+"&homeStore="+homeStore,
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}
export function printToCreditDepartment(obj) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: obj,
          ResourceUrl:
              "v1/customers/apply-now/print/attachment",
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function printToMagixStoreCode(obj) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: obj,
          ResourceUrl:
              "v1/customers/search-customers/sendEmail",
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function submitPrintSummaryData(obj) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: obj,
          ResourceUrl:
              "v1/associates/save-customer-printsummary",
          isAssociate: true
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}
// export function trackWaterfall_check(obj) {
//   return function (dispatch) {
//       const data = {
//           MethodType: HttpMethods.Post(),
//           Resource: obj,
//           ResourceUrl:
//           "v1/customers/Update-waterfall-check",
//       };
//       return ApiWrapper.LoadData(data)
//           .then((response) => {
//               if (response) {
//                   return response;
//               } else {
//                   return response;
//               }
//           })
//           .catch((error) => {
//               throw error;
//           });
//   };
// }

export function getAllMagixAccountNumbers(obj) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: obj,
          ResourceUrl:
              "v1/magix/GetAllMagixAccountNumbers?ssn="+obj.ssn+"&dob="+encodeURIComponent(obj.dob),
          isMagix : true
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}
export function isNewAccoutRequired(acc_stat_code) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: acc_stat_code,
          ResourceUrl:
          "v1/customers/isNewAccoutRequired?cust_acct_stat_code="+acc_stat_code
          
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function getDPayOffes(magixId) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: magixId,
          ResourceUrl:
              "v1/magix/Get-DPayOffer?magixId="+magixId,
          isMagix : true
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function getAllMagixTransactionDates(obj) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: obj,
          ResourceUrl:
              "v1/magix/GetAllMagixTransactionDates?ssn="+obj.ssn+"&dob="+obj.dob,
          isMagix : true
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function getViewOfferFortiva(cust_Id) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: cust_Id,
      ResourceUrl: "v1/customers/view-offers-fortiva?CustomerId="+cust_Id,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getAutopaynEstateStatus(cust_Id,store_code) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: cust_Id,
      ResourceUrl: "v1/customers/GetAutoPayEStatementStatus?siteId="+store_code+"&custId="+cust_Id,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
