import React, {useRef}  from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from 'react-to-print';

const DanielsDisclosureForm=(props)=>{
    const {onDanielsDisclosureClicked,isAgreedRevort,show,isAgreed,onElectronicAgree}=props;
    const componentRef = useRef(null);

    return(
        <Modal id="customModel" show={show} onHide={(event) => onDanielsDisclosureClicked(event)}>
                            <Modal.Header className="pb-0"><b className="text-secondary h4">ELECTRONIC RECORDS DISCLOSURE AND CONSENT AGREEMENT</b><FontAwesomeIcon icon={faClose} className="btn" onClick={(event) => { onDanielsDisclosureClicked(event); isAgreedRevort() }} /></Modal.Header>
                            <Modal.Body className="pb-0">
                                <div className={!isAgreed ? " ShowContent" : " HideContent"}>
                                    <p>Please read this Electronic Records Disclosure and Consent Agreement ("Agreement") carefully and download or print a copy foryour records. You must click the "I Accept" button at the end of this Agreement to continue your application.</p>
                                    <p>In this Agreement, the words "we," "us," "our" and "the Creditor" mean Daniel's Jewelers.</p>
                                    <p>Electronic Application and Related Disclosures. Federal laws and regulations, including the federal Truth-in-Lending Act,Consumer Financial Protection Bureau and Regulation Z, require us to give you certain important disclosures when you apply for and are approved for credit.These disclosures are included with our online credit application, and our credit agreement. With your consent, we will give you these disclosures electronically.</p>
                                    <p>Requesting Paper Copy of Disclosures. At your request, we will provide a copy of your disclosure and agreement in paper-based media. To request a paper copy of the disclosure, contact us by telephone at 310-665-2110 or by email at <a style={{ color: "rgb(0, 82, 204)" }} href="mailto:customer_service@danielsjewelers.com" title="mailto:customer_service@danielsjewelers.com">customer_service@danielsjewelers.com</a>. We will not charge you any fees for providing a paper copy.</p>
                                    <p>Your Consent is Required. You must consent to receiving the disclosuresand credit agreementelectronically in orderto apply online for credit. Your consent will only apply to the application, credit agreement, and you will not be consenting to receiving other electronic records or disclosures at this time.</p>
                                    <p>If you do not want to receive the credit agreement and related disclosures electronically, you should exit the online credit application area of our website and your on-line application will be discontinued.</p>
                                    <p>System Requirements to Access Application, Disclosures and Credit Agreement. To apply electronically and to receive an electronic copy of thedisclosures and credit agreement, you must have the following:</p>
                                    <ul>
                                        <li>a personal computer or other access device which is capable of accessing the Internet (e.g., you must have a modem, cable Internet connection or some other means of access to the Internet, and you must have an active account with an Internet service provider), and which can receive HTML files;</li>
                                        <li>an Internet web browser which is capable of supporting 128-bit SSL encrypted communications</li>
                                    </ul>
                                    <p>Your access to this page verifies that your system, browser and encryption software meet these requirements.</p>
                                    <p>System Requirements to Retain the Disclosures and Credit Agreement. To retain the disclosures and credit agreement, your system must have the ability to either download (to your hard disk drive or other storage device) or print both web pages (screens) as well as embedded HTML files.</p>
                                    <p>Updating Your Information. You should keep us informed of any change in your telephone number or your mailing address. You may contact us to tell us about these changesby e-mail at <a style={{ color: "rgb(0, 82, 204)" }} href="mailto:customer_service@danielsjewelers.com" title="mailto:customer_service@danielsjewelers.com">customer_service@danielsjewelers.com</a>.</p>
                                    <p>Acceptance of Agreement and Consent to Receive Electronic Disclosures and Credit Agreement. Clicking the "I Accept" button (below) constitutes your written signature and approval of, and agreement to be bound bythe terms of this Agreement. If you do not Accept just exit the online credit application area of our website and your on-line application will be discontinued..</p>
                                    <p>By clicking "I Accept," you hereby confirm that the Internet access device(s) you will use to completeyour on-line application and to receive the disclosures and credit agreement meet(s) the system requirements described above and consent to receiving the disclosures and credit agreement electronically.</p>
                                </div>
                                <div className={"container_terms" + (isAgreed ? " ShowContent" : " HideContent")} ref={componentRef}>
                                    <h2 className="text-secondary" id="disclosureHeading"><b>ELECTRONIC RECORDS DISCLOSURE AND CONSENT AGREEMENT</b></h2>
                                    <p className="printThisPage">
                                        <span>Please</span>
                                        <ReactToPrint
                                            trigger={() =>
                                                <span className="ml-0" style={{ color: "rgb(0, 174, 239)", cursor: "pointer" }}><u>print this page</u></span>
                                            }
                                            content={() => componentRef.current}
                                            pageStyle="@page { size: 210mm 297mm; margin: 20mm 20mm; }"
                                            documentTitle="Daniel’s Jewelers disclosures"
                                        />
                                        <span className="ml-0"> for your record.</span>
                                    </p>
                                    <div className="apply_table">
                                        <center><h4><b>DANIEL'S PREFERRED CHARGE RETAIL INSTALLMENT CREDIT AGREEMENT</b></h4></center>
                                        <div className="left-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "22%", backgroundColor: "white" }}>
                                                            <p className="mb-0">Annual</p>
                                                            <p className="mb-0">Percentage</p>
                                                            <p className="mb-0">Rate</p>
                                                        </td>
                                                        <td style={{ width: "78%", backgroundColor: "white" }}>
                                                            <p className="mb-0">35.88% in AZ, CA, NV, NM, PA, SC, and VA</p>
                                                            <p className="mb-0">18.00% in FL and NC</p>
                                                            <p className="hide_if_need mb-0">21.00% in GA and TX<br /></p>
                                                            24.99% in <span className="hide_if_need m-0">NY, MI, and </span>OH<br />

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "22%", backgroundColor: "white" }}>
                                                            <p className="mb-0">Periodic</p>
                                                            <p>Rate</p></td>
                                                        <td style={{ width: "78%", backgroundColor: "white"  }}>
                                                           2.99% in AZ, CA, NV, NM, PA, SC, and VA<br />
                                                           1.50% in FL and NC<br />
                                                            <span className="hide_if_need ml-0">1.50% in FL and NC<br /></span>
                                                            2.0825% in <span className="hide_if_need">NY, MI, and </span>OH<br />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "22%", backgroundColor: "white" }}>
                                                            Grace period for repayment of balances for purchases
                                                        </td>
                                                        <td style={{ width: "78%", backgroundColor: "white"  }}>There is no <b>FINANCE CHARGE</b> on new charges to your account when there is no balance outstanding at the start of the billing cycle and if you pay the balance of your account in full by the due date shown on your statement. </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "22%", backgroundColor: "white" }}>Minimum FINANCE CHARGE</td>
                                                        <td style={{ width: "78%", backgroundColor: "white"  }}>None, if paid during grace period, otherwise, $0.50 in AZ, CA, NV, and SC; <span className="hide_if_need">$1.00 in FL, GA, OH, and PA;</span> and $0.75  MI, NY, and TX. There is no minimum finance charge in NC, NM and VA.</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="right-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "22%", backgroundColor: "white" }}>
                                                            Method of<br />computing<br />the periodic<br />FINANCE<br />CHARGE
                                                        </td>
                                                        <td style={{ width: "78%", backgroundColor: "white"  }}>
                                                            We calculate the finance charge using the Average DailyBalance Method. To get the “Average Daily Balance” we take the balance of your account at the beginning of the billing cycle, not including any unpaid finance charges that are included in your account balance and add any new purchases and subtract payments or credits each day (we do not addin any new purchases or subtract payments or creditsin NM). This gives us the daily balance.
                                                            Then, we add up all the daily balancesfor the billing cycle and divide the total by the number of days in the billing cycle. This gives us the Average Daily Balance.
                                                            <br /><br />
                                                            We figure the FINANCE CHARGE on your account by multiplying the Average Daily Balance of your account by the Periodic Rate.
                                                        </td>
                                                        
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="fee_box">
                                        <center><h4 className="mt-3 mb-2"><b>FEES</b></h4></center>
                                        <div className="left-col lap_box">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "22%", backgroundColor: "white" ,padding: '5px'}}>Annual Fee</td>
                                                        <td style={{ width: "78%", backgroundColor: "white" ,padding: '5px' }}>
                                                            CA and NV: $15.00<br />
                                                            AZ, <span className="hide_if_need">FL, GA, MI, NM, NY, NC, OH, PA, SC, TX </span>and VA: $0.0
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "22%", backgroundColor: "white", verticalAlign: "middle", padding: "5px" }}>Late Payment Fee</td>
                                                        <td style={{ width: "78%", backgroundColor: "white"  }}>
                                                        <p  style={{padding: '5px'  }}>If we do not receive your minimum payment by its due date <span className="hide_if_need"> (or within seven (7) days in VA, within ten (10) days in AZ, CA, GA, FL, MI, NM, NV, NY, and SC, within twenty-one (21) days in TX, and within thirty (30) days in NC, we</span> will charge a late payment fee which will generally be the highest amount permitted by law.</p>
                                                        <table className="table_box">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none" }}>
                                                                            <b>State</b>
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                                            <b>Maximum Returned Payment Fee</b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                                           AZ, CA, NV, TX, FL
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                                            $ 10.00
                                                                        </td>
                                                                    </tr>
                                                                    <tr>    
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                                           GA,NY
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                                            $ 25.00
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                                           NC
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                                            $ 10.00 ( $5.00 if balance under $100)
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                                           SC
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                                            5% of amount past due, but not more than $21.00 and not less than $8.40
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                                           MI,OH,PA
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                                            $ 39.00
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                                           VA
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                                            5% of installement ( max of $39.00)
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                                           NM
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                                            None
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <p  style={{padding: '5px'  }}>We will add any late payment fee to your Account's regular balance</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="right-col lap_box">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "22%", backgroundColor: "white",padding: '5px', verticalAlign: "middle" }}>
                                                            Returned Payment Fee
                                                        </td>
                                                        <td style={{ width: "78%", backgroundColor: "white"  }}>
                                                            <p style={{ padding: '5px' }}>If any check, instrument, or electronic authorization used to pay us is not honored by your financial institution, a returned payment fee (max. of $39) which will generally be the highest amount permitted by law may be assessed. This fee generally will not exceed the amount of the required minimum payment due immediately prior to the date on which the payment is returned.</p>
                                                            <table className="table_box">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: " 1px solid transparent" }}>
                                                                            <b>State</b>
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: " 1px solid transparent" }}>
                                                                            <b>Maximum Returned Payment Fee</b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: " 1px solid transparent"  }}>
                                                                           AZ
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: " 1px solid transparent" }}>
                                                                            $ 10.00
                                                                        </td>
                                                                    </tr>
                                                                    <tr>    
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: " 1px solid transparent"  }}>
                                                                          CA
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: " 1px solid transparent" }}>
                                                                            $ 15.00
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: " 1px solid transparent"  }}>
                                                                          FL,NV
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: " 1px solid transparent" }}>
                                                                            $ 25.00
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: " 1px solid transparent"  }}>
                                                                           GA
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: " 1px solid transparent"  }}>
                                                                            $30.00
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: " 1px solid transparent"  }}>
                                                                           NC
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: " 1px solid transparent" }}>
                                                                            $ 25.00($0.00 for electronic payments)
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: " 1px solid transparent"  }}>
                                                                           NY
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: " 1px solid transparent" }}>
                                                                            $ 20.00($0.00 for electronic payments)
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: " 1px solid transparent"  }}>
                                                                           OH
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: " 1px solid transparent" }}>
                                                                            $ 39.00($0.00 for electronic payments)
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: " 1px solid transparent"  }}>
                                                                           SC,TX
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: " 1px solid transparent" }}>
                                                                            $ 30.00
                                                                        </td>
                                                                    </tr>
                                                                    <tr>  
                                                                        <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: " 1px solid transparent"  }}>
                                                                           MI,NM,PA,VA
                                                                        </td>
                                                                        <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: " 1px solid transparent" }}>
                                                                            None
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <p  style={{padding: '5px'  }}>We will add any late payment fee to your Account's regular balance</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row mt-2 text-justify">
                                        <div className="col-6">
                                            <p>The type of merchandise and your credit report may impact the required down payment or repayment term or both. The new Minimum Payment schedule will be based on the highest New Balance on your account. This will change only if the New Balance is paid in full, added on with subsequent purchases to a higher New Balance, subsequently refinanced by written agreement between you and us or in default</p>
                                        </div>
                                        <div className="col-6">
                                            <p>under these terms, in which case, the entire New Balance will be immediately due and payable.</p>
                                            <p>Payments to your account are applied in the following sequence: 1) to any unpaid late charge, 2) to any unpaid credit insurance, 3) to any unpaid finance charge, 4) to the balance of your account</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <center><h4><b>YOUR BILLING RIGHTS</b></h4></center>
                                    <hr />
                                    <div className="row text-justify">
                                        <div className="col-6">
                                            <p>Notify us in case of errors or questions about your bill. If you think your bill is wrong, or you need more information about a transaction on your bill, write us as soon as possible on a separate piece of paper and send it to Daniel’s Jewelers, Customer Service, P.O. Box 3750, Culver City, CA 90231-3750. We must hear from you no later than 60 days after we sent you the first bill on which the error or problem appeared. You can telephone us or contact the store where you made the purchase, but doing so will not preserve your rights. In your letter, please give us the following information:</p>
                                            <ol>
                                                <li>
                                                    Your name, address and account number. Please include your telephone number so that we can call you if we have a question about your letter.
                                                </li>
                                                <li>
                                                    The dollar amount of the suspected error or item in question and an explanation of why you believe there is an error. If you need more information, describe the transaction you are not sure about.
                                                </li>
                                            </ol>
                                            <p>We must acknowledge your letter within 30 days, unless we have corrected the error by then. Within 90 days, we must either correct the error or explain why we believe the bill was correct. After we receive your  letter,  we  cannot try  to collect  any  amount  you  question or report you as delinquent.</p>
                                        </div>
                                        <div className="col-6">
                                            <p>We can continue to bill you for the amount in question, including finance charges, and we can apply any unpaid amount against your credit limit. You do not have to pay any questioned amount while we are investigating, but you are still obligated to pay the parts of your bill that are not in question.</p>
                                            <p>If we find that we made a mistake on your bill, you will not have to pay any finance charge related to any questioned amount. If we did not make a mistake, you have to pay the finance charges, and applicable late charges, and you will have to make up any missed payments on the questioned amount. In either case, we will send you a statement of the amount you owe and the date it is due.</p>
                                            <p>If you fail to pay the amount we think you owe, we may report you as delinquent. However, if our explanation does not satisfy you and you write us within ten days telling us that you still refuse to pay, we must tell anyone we report you to that you have a question about your bill, and we must tell you the name of anyone we report you to. We must tell anyone we reported you to that the matter has been settled between us when it finally is. If we don’t follow these rules, we can’t collect the first $50.00 of the questioned amount even if your bill was correct.</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <center><h4><b>NOTICES</b></h4></center>
                                    <hr />
                                    <div className="row text-justify">
                                        <div className="col-6">
                                            <p><strong>Notice:</strong> The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the basis of race, sex or marital status. The Federal Agency which administers compliance with the law concerning this creditor is the Equal Credit Opportunity Section, Federal Trade Commission, Washington, D.C. 20550.</p>
                                            <p><strong>STATE NOTICES:</strong></p>
                                            <p><strong>CALIFORNIA RESIDENTS:</strong> As required by California State law you are hereby notified that a negative credit report, reflecting on your credit record, may be submitted to a credit reporting agency if you fail to pay your scheduled payments on time and keep your account current. If you pay your scheduled payments on time and keep your account current, a positive credit report, reflecting on your credit record, may be submitted to a credit reporting agency. If you are married, you may apply for a separate account.</p>
                                            <p><strong>MICHIGAN RESIDENTS: The seller retains a security interest in the subject matter of this agreement.</strong></p>                                         
                                            <p><strong>NOTICE TO THE BUYER: 1. DO NOT SIGN THIS AGREEMENT BEFORE YOU READ IT OR IF IT CONTAINS BLANK SPACES. 2. YOU ARE ENTITLED TO A COPY OF THE AGREEMENT YOU SIGN. 3. KEEP THIS AGREEMENT TO PROTECT YOUR LEGAL RIGHTS. 3. YOU ARE ENTITLED TO A COMPLETELY FILLED IN COPY OF THIS CREDIT AGREEMENT. 4. YOU MAY PAY YOUR ENTIRE BALANCE IN FULL OR MORE THAN THE MINIMUM MONTHLY PAYMENT AT ANY TIME WITHOUT INCURRING ANY ADDITIONAL CHARGE. 5. YOU HAVE THE RIGHT TO PAY IN ADVANCE THE FULL AMOUNT DUE. 6. THE SELLER HAS NO AUTHORITY TO ENTER YOUR PREMISES UNLAWFULLY OR COMMIT ANY BREACH OF THE PEACE IN ORDER TO REPOSSESS GOODS PURCHASED UNDER THIS AGREEMENT.</strong> </p>
                                            <p><strong>YOU ACKNOWLEDGE RECEIPT OF A COPY OF THIS RETAIL INSTALLMENT CREDIT AGREEMENT. YOUR SIGNATURE ON THE APPLICATION OR SALES SLIP (OR ONLINE SCREEN) FOR THE INITIAL PURCHASE ON THIS ACCOUNT FOR THE INITIAL PURCHASE ON THIS ACCOUNT HAVE SIGNED THIS AGREEMENT AS FOLLOWS:</strong></p>
                                            <p className="container_terms bottom_table_data" style={{ height: "auto" }}>
                                                <table >
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "50%", backgroundColor: "white", padding: '5px' }}>
                                                                Buyer
                                                            </td>
                                                            <td style={{ width: "50%", backgroundColor: "white", padding: '5px' }}>
                                                                Signature
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "30%", backgroundColor: "white", padding: '5px' }}>
                                                                Seller-Creditor
                                                            </td>
                                                            <td style={{ width: "70%", backgroundColor: "white", padding: '5px' }}>
                                                            <img src="https://ww2.danielsjewelers.com/images/onlinecreditapps/signature.jpg" height='50%' width='100%' alt="signature not found"></img>
                                                    
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                        <td style={{width: "100%", backgroundColor: "white", padding: '5px'}} colspan="2">
                                                            {/* <td style={{ width: "100%", backgroundColor: "white", padding: '5px' }}> */}
                                                                <p>Reddy Pakanati, Senior Vice President</p>
                                                                <p>Sherwood Management Company Inc., dba Daniel's Jewelers</p>
                                                                <p>P.O. Box 3750, Culver City, CA 90231-3750</p>
                                                            {/* </td> */}
                                                        </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </p>
                                            <p><strong>Annual Fee:</strong> If you open and maintain an account balance in California or Nevada, we will charge a non-refundable $15.00 annual fee to your account when it is opened and once a year thereafter if you have an account balance of $25.00 or more on the date the fee is to be charged to your account. Your annual fee will be added to your purchase balance.</p>
                                            <p><strong>Renewal and Replacement Cards:</strong> Buyer hereby requests the periodic mailing of account cards. You agree to promptly notify us, orally, or in writing, of the loss, theft, or actual or anticipated unauthorized use of any account card issued by us. If notice is given orally, you agree to confirm such conversations in writing. Your liability for unauthorized use of your Daniel’s Account Card will not exceed $50.00 providing you have notified us of such loss or theft.</p>
                                            <p><strong>Termination of Credit Privileges:</strong> You agree not to purchase on your account while it is past due or exceed your credit limit, or while you are otherwise in default under this agreement. Your account card is not transferrable and only you are authorized to you use it. Your account will be subject to the seller’s approval of each individual sale. We reserve the right to require down-payments prior to delivery of any merchandise. We reserve the right to cancel credit privileges at any time.</p>
                                            <p><strong>Use of Card/Liability for Charges:</strong> Only the persons signing the application are permitted to use the card issued to them. We will look to you for payment on all charges made with the card issued to you, even if you let someone else use it. If you voluntarily relinquish possession of the card to another person, you will be liable for all charges incurred by that person to the full extent of applicable law. If you are married and sign the application, you agree that your separate property shall be liable for unpaid sums due.</p>
                                            <p><strong>Security Interest:</strong> You grant us a Purchase Money security interest in the goods purchased on your account to secure its unpaid purchase price (except in NY (on any purchase under $200) until such good is paid for in full (in NY, not to exceed five (5) years from date the purchase is posted to your account). Further, items purchased previously on your account may secure later purchases. You also grant us a security interest in the proceeds of any credit insurance purchased on your account. NOTICE: BY GIVING US A SECURITY INTEREST IN THE PROPERTY DESCRIBED, YOU WAIVE ALL RIGHTS PROVIDED BY LAW TO CLAIM THE PROPERTY EXEMPT FROM LEGAL PROCESS.</p>
                                            <p><strong>Minimum Payment and Default/Collection Costs:</strong> You agree to pay at least the minimum payment shown on your billing statement on or before its due date. If you fail to pay any minimum payment due or if the prospect of payment is   significantly   impaired,   to the extent permitted by applicable law it will be a default. We may also repossess any merchandize in which we retain a security interest, but we will do so only in the manner and to the extent permitted by state law. If we refer the account to an attorney who is not our  salaried employee and we bring a suit against you to collect the  amount you owe, in addition to the full amount owed and any court costs, you agree to pay our reasonable attorney’s fees to the extent permitted by applicable law.</p>
                                            <p><strong>Governing Law:</strong> This agreement and your Account and any claim, dispute, controversy arising from or relating to this agreement or your account are governed, to the extent applicable, by U.S. Federal law and the laws of the state where the account was opened.</p>
                                            <p><strong>Change of Address or Other Contact Information:</strong> You agree to notify us promptly in writing of any changes to your billing address, phone number, email address, and other contact information.</p>
                                            <p><strong>Contacts, Monitoring and Recording:</strong> You authorize Daniel’s or any third party acting on behalf of Daniel’s to contact you via mail, email, telephone, cellular telephone, text messaging (SMS or MMS), and any other electronic means, including, but not limited to through automatic telephone dialing systems and/or with artificial or prerecorded voices at, including, but not limited to, those contact methods noted above, for account service, collections, sales promotion including marketing, and other consumer business related matters. You assume the risk that our electronic communications to you (text, email, voice message) may be viewed or accessed by others with access to your devices. You acknowledge that you may receive up to ten (10) calls and ten (10) text messages per month. You acknowledge that, to opt out now or at any time from receiving calls or texts for any purpose other than accounts servicing or collection, you may call (310) 846-5630 or text “STOP” in response to any text message. You acknowledge that standard text messaging and call use rates may apply and will be accepted by you with no reimbursement or payment by Daniel’s; calls you make to or receive from Daniel’s may be monitored or recorded  by Daniel’s for supervisory purposes; you understand that consent for   marketing   is   NOT   a   condition   of   purchasing    or receiving service and you can opt out at any time, and understand that in order to discontinue receiving contact for any purpose other than accounts servicing or collection, you can call (310) 846-5630 during regular business hours.</p>
                                        </div>
                                        <div className="col-6">
                                            <p><strong>NEW YORK RESIDENTS:</strong> A consumer credit report may be obtained in connection with evaluating your application and subsequently in connection with updates, renewals, or extensions of credit for which this application is made. Upon your request you will be informed whether a report was obtained, and if so, of the name and address of the consumer reporting agency.</p>
                                            <p><strong>OHIO RESIDENTS:</strong> The Ohio laws against discrimination require that all creditors make credit equally available to all credit worthy customers. The Ohio Civil Rights Commission administers compliance with this law.</p>
                                            <p><strong>PENNSYLVANIA RESIDENTS:</strong> This is a Security Agreement.</p>
                                            <p><strong>NOTICE: ANY HOLDER OF THIS CONSUMER CREDIT CONTRACT IS SUBJECT TO ALL CLAIMS AND DEFENSES WHICH THE DEBTOR COULD ASSERT AGAINST THE SELLER OF GOODS OR SERVICES OBTAINED PURSUANT HERETO OR WITH THE PROCEEDS HEREOF. RECOVERY HEREUNDER BY THE DEBTOR SHALL NOT EXCEED AMOUNTS PAID BY THE DEBTOR HEREUNDER.</strong></p>
                                                                                        <p><strong>Customer Financial Condition:</strong> You represent and warrant that you will not make purchases on credit or on account at any time during which your financial net worth (available assets less debts owed) and financial condition (available net income) are materially less than what you disclosed to us in writing at the time you applied for credit with us or updated with us your credit and financial information. You  further agree to promptly notify Daniel’s Jewelers of any adverse change in your financial net worth and/or financial condition prior to attempting to make any purchase to be charged in whole or in part to your account. If you fail to notify us of such adverse change, we will consider this your affirmative representation that no such change has occurred. We will rely upon your current and future representations in granting future credit or in allowing you to make future purchases on account.</p>
                                            <p><strong>Posting of Additional Charges:</strong> For any reason we may stop the posting to your account of Finance Charges, Customer Insurance Premiums, Late Charge Fees and other additional charges to which we are entitled. We reserve the right to calculate and post to your account all charges which have become due and have not been previously posted or paid and these additional charges shall become immediately due and payable.</p>
                                            <p><strong>Default:</strong> You shall be in default under this agreement if you fail to make any payment as required, or if you are insolvent or cannot pay your debts. If you are in default, we reserve the right to cancel the Daniel’s card and we may require immediate payment of any amounts due. You agree to pay reasonable costs for collecting amounts due, including attorney’s fees and court costs to the extent permitted by applicable law.</p>
                                            <p><strong>Changing this Agreement:</strong> We   have   the   right   to   change this Agreement at any time, including any Annual Percentage Rates  or fees, as well as any terms. If we need your consent for any change, we will inform you of the change in advance, as required by law.  If your consent is not required, notice will be provided at the implementation of the change in this Agreement.</p>
                                            <p><strong>Assignment:</strong> We may assign any or all of our rights under this agreement to another person or organization at any time, without your consent. We may periodically allow other companies access to our mailing list, and to a list of our account card holders so that they can provide you with special offers. If you do not wish to receive the offers, please write to us at the address at the end of this document, and we will remove your name prior to allowing the list to be used. See our privacy statement for additional details.</p>
                                            <p><strong>Credit Investigation and Reporting:</strong> You authorize Daniel’s and affiliates to verify all identity, credit, employment, references, or banking information, obtain current balances on any such accounts, and request information from credit bureau reporting agencies, and, if approved for this account, whether a purchase is charged to this  account or not, authorize Daniel’s to re-verify, obtain updates on such accounts and to obtain information from   and   report   the   payment history and account status of this account to and from credit bureau reporting agencies without my (our) specific authorization or a specific request for purchase approval or credit line increase, (A) if a joint account is requested by you providing information about and the signature of a second individual, agree that the primary and joint accountholder each can individually sign and incur  obligations  on behalf of the other, (B) understand and agree that you are submitting this application and all of the information on this application to both the designated entities that you have signed for as well as their affiliates and designees for their use in marketing products and services to me as permitted by applicable law. We may report information about you to   credit bureaus including late payments, missed payments, or other defaults which may be reflected on your credit report. If you believe that we have reported inaccurate information to a credit reporting agency, write to us at the address shown at the beginning of   this document and we will investigate the matter.</p>
                                            <p><strong>Legal Notices:</strong> All notices, including notices relating to legal actions, bankruptcy notices, and any notice to us that you are represented by counsel with your debt to us, must be sent to us at Daniel’s Jewelers, Customer Service, P.O. Box 3750, Culver City, CA 90231-3750. If these notices are sent to any other address, it will not satisfy any notice requirement under this agreement or any other legal requirement that you provide notice to us.</p>
                                            <p><strong>Notice to Texas Residents:</strong> For questions or complaints about this contract, contact Daniel’s Jewelers at (800) 819-8000. The Office of Consumer Credit Commissioner (OCCC) is a state agency, and it enforces certain laws that apply to this contract. If a complaint    or question cannot be resolved by contacting the creditor, consumers can contact the OCCC to file a complaint or ask a general credit-related question.</p>
                                            <p className="mb-0">OCCC address: 2601 N. Lamar Blvd., Austin, Texas 78705.</p>
                                            <p className="mb-0">Phone: (800) 538-1579. Fax: (512) 936-7610. Website: occc.texas.gov.</p>
                                            <p className="mb-0">E-mail: <a href="#" style={{ color: "rgb(0, 82, 204)" }} > consumer.complaints@occc.texas.gov.</a></p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p><b>Notice to buyers: The disclosures above are accurate as of the date of publishing on September 1, 2022 and are effective September 1, 2022. These terms are subject to change after this date. Write to Daniel’s Jewelers, P.O. Box 3750, Culver City, CA 90231-3750 or call (310) 665-2110 for the most recent version of this agreement.</b></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col">
                                        <p>For the Daniel's Privacy Policy please go to: <a style={{ color: "rgb(0, 82, 204)" }} rel="noreferrer" className="text-decoration-none" target="_blank" href="https://www.danielsjewelers.com/pages/privacy-policy">https://www.danielsjewelers.com/pages/privacy-policy</a>.</p>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="d-block border-0 pt-0">
                                <form className={` ${!isAgreed ? "" : "d-none"}`}>
                                    <button className="btn bg-secondary text-primary w-100" id="btnElectronicAgree" name="btnElectronicAgree" style={{ backgroundColor: "#5c068c", color: "white" }} onClick={(event) => { onElectronicAgree(event); }} >
                                        I ACCEPT
                                    </button>
                                    <p className="mt-3 text-center">(Electronic Application, Credit Agreementand Related Disclosuresand Consent Agreement, version 1.0)</p>
                                </form>
                            </Modal.Footer>
                        </Modal>

     )
}
export default DanielsDisclosureForm;