import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class APIFailedMessage {
  static displayError(error) {
    if(error?.response?.data.errorMessage === "Connection request timed out" ||
      error?.response?.data.errorMessage === "ORA-12541: TNS: No listener") {
      toast.error("API call failed : "+error.response.data.errorMessage+", please try again.");
      toast.clearWaitingQueue();
    }
    else {
      toast.error("Something went wrong, Please contact Administrator");
      toast.clearWaitingQueue();
    }
  }
}
