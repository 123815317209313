import { ApiWrapper } from "../../api/thunk.helper";
import HttpMethods from "../../redux/_http.methods";

export function getDanielsDownPayment(obj) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Post(),
        Resource: obj,
        ResourceUrl: "v1/customers/AutoPay_EStatement_Enroll_Daniels",
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

export function getTenderFortivaUrl(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: obj,
      ResourceUrl: "v1/customers/Tender_Fortiva",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getTenderSynchronyUrl(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: obj,
      ResourceUrl: "v1/customers/Tender_Synchrony",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}