import React, { Component } from "react";
import { Card,Modal,OverlayTrigger,Tooltip} from "react-bootstrap";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import IdleTimeModal from "../../components/component.idleTimeModal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
import * as customerDetailsActions from "../../redux/actions/customerDetails.actions";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import "./component.viewOffers.css";
import Moment from 'moment';
import ConfirmDialog from "../../components/component.confirmDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose,faQuestion} from "@fortawesome/free-solid-svg-icons";
import * as synchronyActions from "../../redux/actions/synchronyLookup.actions";
import * as applyNowActions from '../../redux/actions/applyNow.actions';
import * as viewOfferActions from "../../redux/actions/viewOffers.actions";
import { Toast, ToastContainer } from "react-bootstrap";
import {faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import FortivaDisclosures from "../../components/component.fortivaDisclosures";
//import ReactToPrint from "react-to-print";
import FortivaAcceptOfferDialog from "./component.fortivaActOfferDialog";
//import FortivaDialog from "../../components/component.noFortivaDialog";
//import FortivaErrorDialog from "../../components/component.fortivaErrorDialog";
import GetInfo from "../../components/component.getInfo";
import Insurance from "../insurance/component.insurance";
import DanielsCreditOffer from "./component.danielsCreditoffers";
import * as adminActions from '../../redux/actions/admin.actions';

class ViewOffers extends Component{
    constructor(){
        super();
        this.state={
            toggled: false,
            offers : [],
            fortivaOffers : [],
            synchronyOffers: {
                accountNumber: "",
                applicationId: "",
                merchantCustomerId: "",
                approvalLimit: "",
                status: "",
                message: "",
                errorMessage: "",
                providerURL: "",
                authorizationNumber: "",
                fieldInError1: "",
                fieldInError2: "",
                fieldInError3: "",
                fieldInError4: "",
                fieldInError5: "",
                createdDate: "",
                fpType: ""
            },
            showAlert:false,
            showincrLimit:false,
            formObj:{
                income: localStorage.getItem("monthlyIncome")!=="null"?localStorage.getItem("monthlyIncome"):"",
                requestedLmt:""
            },
            increaseLimitmsg:"",
            increaseLmtErr:"",
            summaryDetails: {
                accountNumber:"",
                creditLine:"",
                balance:"",
                opentobuy:"",
                statusMessage:null,
                failedCredit:"",
                previousCredit:'',
                approvedDate:"",
            },
            isElectronicDisclosure:false,
            isSummaryCreditTerms:false,
            isFortivaAppDisclosure:false,
            summaryCreditTerms:"",
            fortivaApplicationDisMsg:"",
            encryptedBytes : "",
            //showFortiva:false,
            fortivaObj:{},
            offerModal: false,
            successMsg:"",
            isAgreeEelectronicDis:false,
            isAgreeSummaryCreditTerms:false,
            //printText : "",
            isCardholderAgreement:false,
            cardholderAggrement:"",
            isAcceptOffer:false,
            cardAgreementChecked:false,
            isAcceptOrRejectOffer:false,
            fortivaAppDisclosureAgreed:false,
            fortivaAPPDisclosureErr:"",
            fortivaOfferConfirm:false,
            //synchronyPending:false,
            associateId:localStorage.getItem('loggedUserEmail') !== "null"?localStorage.getItem('loggedUserEmail'):"",
            store_Id:localStorage.getItem("associateStores") !== "null"?JSON.parse(localStorage.getItem("associateStores")):"",
            //fortivaAppError:false,
            //fortivaAppErrMsg:"",
            isInsuranceEnable : false,
            isDanielsInsurance:false,
            insurance : false,
            isCSSEnable:false,
            danielsOffers:null,
            insuranceDaniels:false
        };
    }

    handleToggleSidebar() {
        this.setState({
            toggled: !this.state.toggled
        });
      };

    componentDidMount() {
        if(sessionStorage.getItem("isLoggedIn") === "true") {
            let fortivaObj= this.props.data;
            this.setState({fortivaObj:fortivaObj});
             this.loadData();
             this.loadAdminSettings();
             if(sessionStorage.getItem("havingFortivaInsurance") === "true" || sessionStorage.getItem("havingDanielInsurance") === "true" ) {
                trackPromise(
                    this.props.adminActions?.getSignOutTime().then(response => {
                        if(response) {
                            this.setState({isInsuranceEnable : response.isInsurance_FortivaEnabled,isDanielsInsurance:response.isInsurance_DanielsEnabled});
                        }
                    })
                );
            }
         }
         else 
           this.props.history.push( "/");
     }
     loadAdminSettings() {
        trackPromise(
            this.props.adminActions.getSignOutTime().then(
              response=>{
                if(response){
                  this.setState({ 
                    isCSSEnable:response.isCSSEnable
                 });
                }
              }
            ).catch(err=>{
               APIFailedMessage.displayError(err);
            })
        )
    }
    loadData() {
        let obj = localStorage.getItem("customerId");
        let synchronyAppId = localStorage.getItem("synchronyAppId");

        if(obj !== "null") {
            trackPromise(
                this.props.customerDetailsActions.getCustomerViewOffers(GetInfo.getCustomerId(),obj).then(
                    response => {
                        if(response){
                            this.setState({offers : response});
                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
            trackPromise(
                this.props.customerDetailsActions.getViewOfferFortiva(obj).then(
                    response => {
                        if(response){
                            this.setState({fortivaOffers : response});
                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
            if (synchronyAppId !== "null" && synchronyAppId !== "undefined" && synchronyAppId !== "") {
                this.loadSynchronyOffers(synchronyAppId);
            }
        }
    }

    loadSynchronyOffers(synchronyAppId) {
        let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
        trackPromise(
            this.props.customerDetailsActions.getSynchronyViewOffers(GetInfo.getCustomerId(),synchronyAppId,homeStore).then(
                response => {
                    if(response.accountNumber){
                        this.getActSummary(response,synchronyAppId);
                    }
                    else{
                        this.setState({ synchronyOffers: response ,previousCreditLimit:response.previousCredit});
                    }
                }).catch(err => {
                    APIFailedMessage.displayError(err);
                })
        );
    }
       
    getActSummary(offersResponse,obj) {
        let applicationId = obj;
        let phoneNo=sessionStorage.getItem("selectedUserPhone")!=="null"?sessionStorage.getItem("selectedUserPhone"):"";
        let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
        if(phoneNo.includes("-")){
            phoneNo = phoneNo.slice(0,3)+phoneNo.slice(4,7)+phoneNo.slice(8,12);
        }
         //let isSynchrony=sessionStorage.getItem("isSynchrony");
        //let isFortiva=sessionStorage.getItem("isFortiva");
        if(applicationId!=="null"){
            trackPromise(
                this.props.syncActions.getsynchronyActSummmary(GetInfo.getCustomerId(),applicationId,phoneNo,homeStore).then(
                    response => {
                        if(response.accountNumber){
                            offersResponse.approvalLimit = response.creditLine;
                            response.approvedDate=Moment(response.approvedDate).format("MM/DD/YYYY");
                            this.setState({ synchronyOffers: offersResponse ,summaryDetails:response });
                        }
                        // else{
                        //     let synchronyStatus=localStorage.getItem("synchronyStatus");
                        //     if(synchronyStatus?.toLowerCase()=="declined")
                        //         response.errorMessage="Synchrony application status is Declined";
                        //     else if(synchronyStatus?.toLowerCase()=="pending")
                        //         response.errorMessage="Synchrony application status is Pending";

                        // this.setState({ increaseLimitmsg: response.errorMessage,showAlert:!this.state.showAlert,synchronyOffers: offersResponse });     
                        // }

                        // let submitforFortiva= localStorage.getItem('submitforFortiva');
                        // if(offersResponse.status?.toLowerCase() === "approved" && submitforFortiva=="true") {
                        //     localStorage.setItem('submitforFortiva', false);
                        //     this.setState({ showFortiva: true });
                        // }
                        // else  if(offersResponse.status?.toLowerCase() === "declined" && submitforFortiva=="true") {
                        //     localStorage.setItem('submitforFortiva', false);
                        //     let declined=true;
                        //     this.submitFortiva("",declined);
                        // }
                        // else  if(offersResponse.status?.toLowerCase() === "pending" && submitforFortiva=="true" && isSynchrony=="true" && isFortiva=="true") {
                        //     localStorage.setItem('submitforFortiva', false);
                        //     let pending=true;
                        //     this.rejectFortiva(pending);
                        //     this.setState({synchronyPending:!this.state.synchronyPending});
                        // }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            );
        }
    }
    loadDaniels() {
        let danielAppId= localStorage.getItem("danielAppId");
        if (danielAppId !== "null" && danielAppId !== "undefined" && danielAppId !== "") {
            return (
                <>
                   <div className="col-12 col-lg-6">
                    <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                        <Card.Body>
                            <div className="row h-100">
                                <div className="col-12 col-md-6 pr-md-0">
                                    <Card className="h-100">
                                        <Card.Body className="bg-light pl-3 pt-4 pr-3 pb-4 bg-primary subCard">
                                        <p className="h5"><b>DANIEL'S CREDIT</b></p>
                                        <p className="h5 text-secondary my-auto">Applied</p>                                     
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="row mt-3 mt-md-0">
                                        <div className="col-12 pr-md-3 pl-md-4 pt-2">
                                                    <p><b>Application Date</b></p>
                                                    <p className="mb-2">{ Moment(sessionStorage.getItem("createdDate")).format("MM/DD/YYYY")}</p>
                                                </div>
                                        <div className="col-12 pr-md-3 pl-md-4 pt-2 pb-2">
                                            
                                        <hr className="mt-0"></hr>
                                            <p><b>Status Message</b></p>
                                            <p className="mb-1">Your application has been submitted to Daniel's credit department for review.</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div> 
                <hr className="mt-4 mr-4 mb-0 ml-4"></hr>
                </>
            );
        }
        else if(localStorage.getItem("magix_account_no") !== "null" && localStorage.getItem("magix_account_no") !== ""){
            return (
                <>
                <div className="col-12 col-lg-6">
                    <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                        <Card.Body>
                            <div className="row h-100">
                                <div className="col-12 col-md-6 pr-md-0">
                                    <Card className="h-100">
                                        <Card.Body className="bg-light pl-3 pt-4 pr-3 pb-4 bg-primary subCard">
                                            <p className="h5"><b>DANIEL’s CREDIT</b></p>
                                            <p className="h5 my-auto">Check Magix F2 or F8</p>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div> 
                <hr className="mt-4 mr-4 mb-0 ml-4"></hr>
               </>
            )
        }
    }


    loadProgressive() {
        let progressiveOffer=this.state.offers.data?.filter((val) => { return val.fpType?.toLowerCase() === "progressive" });
        if (progressiveOffer?.length > 0) {
            for (let i = 0; i < progressiveOffer?.length; i++) {
                return (
                    <>
                           <div className="col-12 col-lg-6 customAlignment">
                                    <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pr-md-0">
                                                    <Card className="h-100">
                                                        <Card.Body className="bg-light pl-3 pt-4 pr-3 pb-4 bg-primary subCard">
                                                            {/* <div className="row">
                                                                <div className="col-8"> */}
                                                                    <p className="h5"><b>PROGRESSIVE LEASING</b></p>
                                                                {/* </div> */}
                                                                {/* <div className="col-4 pr-lg-2">
                                                                    {
                                                                        (progressiveOffer[i].status?.toLowerCase()) == 'approved' ?
                                                                            <span className="border float-right h6 pt-1 pr-2 pb-1 pl-2 acceptNotation mr-0 ml-0">Accepted</span>
                                                                        :
                                                                        ((progressiveOffer[i].status?.toLowerCase()) == 'declined' || (progressiveOffer[i].status?.toLowerCase()) == 'denied') ?
                                                                            <span className="border float-right h6 pt-1 pr-2 pb-1 pl-2 declinedNotation mr-0 ml-0">Declined</span>
                                                                        :
                                                                        ((progressiveOffer[i].status?.toLowerCase()) == 'pending' || (progressiveOffer[i].status?.toLowerCase()) == 'pre-approved') ?
                                                                            <span className="border float-right h6 pt-1 pr-2 pb-1 pl-2 pendingNotation">Pending</span>
                                                                        :
                                                                        ((progressiveOffer[i].status?.toLowerCase()) == 'in process') ?
                                                                        <span className="border float-right h6 pt-1 pr-2 pb-1 pl-2 pendingNotation">In Process</span>
                                                                        :
                                                                        (progressiveOffer[i].errorMessage.length > 0) ?
                                                                        <span className="border float-right h6 pt-1 pr-2 pb-1 pl-2 declinedNotation mr-0 ml-0">Error</span>
                                                                        :
                                                                            ""
                                                                    }
                                                                </div> */}
                                                          {/* </div> */}
                                                            {
                                                            (progressiveOffer[i].status?.toLowerCase()) === 'approved' ?
                                                                <>
                                                                <p className="h5 text-secondary">Approved For  ${progressiveOffer[i].approvalLimit}</p>
                                                                <p className="mb-0"><b>Lease Id : </b>{progressiveOffer[i].accountNumber}</p>
                                                                </>
                                                            :
                                                            ((progressiveOffer[i].status?.toLowerCase()) === 'declined' || (progressiveOffer[i].status?.toLowerCase()) === 'denied') ?
                                                                <>
                                                                <p className="h5 text-danger">Declined</p>
                                                                <p className="mb-0"><b>Lease Id : </b>{progressiveOffer[i].accountNumber}</p>
                                                                </>
                                                            :
                                                            ((progressiveOffer[i].status?.toLowerCase()) === 'pending' || (progressiveOffer[i].status?.toLowerCase()) === 'pre-approved') ?
                                                                <>
                                                                <p className="h5">Pending</p>
                                                                <p className="mb-0"><b>Lease Id : </b>{progressiveOffer[i].accountNumber}</p>
                                                                </>
                                                            :
                                                            ((progressiveOffer[i].status?.toLowerCase()) === 'in process') ?
                                                                <>
                                                                <p className="h5">In Process</p>
                                                                <p className="mb-0"><b>Lease Id : </b>{progressiveOffer[i].accountNumber}</p>
                                                                </>
                                                            :
                                                            (progressiveOffer[i].errorMessage?.length > 0) ?
                                                            <p className="h5 text-danger">Rejected</p>
                                                            :
                                                                ""
                                                            }                                                            
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="row mt-3 mt-md-0">
                                                        {/* <div className="col-6 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                            <p><b>Account Id</b></p>
                                                            <p></p>
                                                        </div> */}
                                                        <div className="col-12 pr-md-3 pl-md-4 pt-2">
                                                                    <p><b>Application Date</b></p>
                                                                    <p className="mb-1">{Moment(progressiveOffer[i].createdDate).format("MM/DD/YYYY")}</p>
                                                                </div>
                                                        <div className="col-12 pr-md-3 pl-md-4 pt-2 pb-2">
                                                            
                                                        <hr className="mt-0"></hr>
                                                            <p><b>Status Message</b></p>
                                                            {
                                                                (progressiveOffer[i].status?.toLowerCase()) === 'approved' ?
                                                                    <p className="mb-1">The standard lease-to-own agreement offers 12 months to ownership and could cost more than double the retail price.</p>
                                                                :
                                                                ((progressiveOffer[i].status?.toLowerCase()) === 'declined' || (progressiveOffer[i].status?.toLowerCase()) === 'denied') ?
                                                                    <>
                                                                    <p className="mb-0">We're sorry, we were unabe to approve your lease at this time. We'll send you a letter with additional information within 30 days.</p>
                                                                    <p className="mb-1">Progressive Leasing can reassess eligibility after 30 days. Please call 877-898-1970 if you have any questions.</p>
                                                                    </>
                                                                :
                                                                ((progressiveOffer[i].status?.toLowerCase()) === 'pending' || (progressiveOffer[i].status?.toLowerCase()) === 'pre-approved') ?
                                                                    <p className="mb-1">After reviewing your application, we still need to verify some information. Please call 877-898-1970.</p>
                                                                :
                                                                ((progressiveOffer[i].status?.toLowerCase()) === 'in process') ?
                                                                    ""
                                                                :
                                                                (progressiveOffer[i].errorMessage?.length > 0) ?
                                                                    <p className="mb-1">An error has occured when processing your credit application. Please see a Daniel's Jewelers sales associate for further assistance.</p>
                                                                :
                                                                    ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-center mt-3 mb-0" style={{fontSize:"12px"}}>Log into the customer portal to finalize the transaction. <a target="_blank" rel="noreferrer" style={{color:"#0056b3"}} href="https://www.progressivelp.com/retaildashboardui/login">Progressive | ProgCentral</a></p>
                                        </Card.Body>
                                    </Card>
                                </div> 
                    <hr className="mt-4 mr-4 mb-0 ml-4"></hr>
                    </>
                );
            }
        }
        else {
            return null;
        }
    }
    loadFortiva() {
        let fortivaOffer=this.state.fortivaOffers.data?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" })[0];
        let isFortiva=sessionStorage.getItem("isFortiva");
        if(fortivaOffer && (fortivaOffer?.applicationStatus !== "null" && fortivaOffer?.applicationStatus !== null)) {
            let amt="$ ";
            let per="%";
            fortivaOffer.downPaymentPct=0;
            var Difference_In_Days=0
            if(fortivaOffer.offerAcceptDate){
                let offerAcceptDate=Moment(fortivaOffer.offerAcceptDate).format("MM/DD/YYYY")
                let acceptedDate = new Date(offerAcceptDate);
                let today = new Date();
                let Difference_In_Time = Math.abs(today.getTime() - acceptedDate.getTime());
                Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
            }
            //existing customer(fortiva application is approved and has accepted or applied for offer)
            if(fortivaOffer.transactionResult?.toLowerCase() === "a" && fortivaOffer.color!=null && Difference_In_Days>90  && fortivaOffer?.lastPaymentDate!==null){
                if(fortivaOffer.color.toLowerCase()==="tier 1a" || fortivaOffer.color==="tier 2a" ||fortivaOffer.color.toLowerCase()==="tier 3a" || fortivaOffer.color.toLowerCase()==="tier 4a"){
                    fortivaOffer.downPaymentPct=0;
                }
                if(fortivaOffer.color.toLowerCase()==="tier 5a"){
                    fortivaOffer.downPaymentPct=25;
                }
                if(fortivaOffer.color.toLowerCase()==="tier 6a"){
                    fortivaOffer.downPaymentPct=40;
                }
            }
            // new customer.
            else if(fortivaOffer.color!=null){
                if(fortivaOffer.color.toLowerCase()==="tier 1a" || fortivaOffer.color.toLowerCase()==="tier 2a" || fortivaOffer.color.toLowerCase()==="tier 4a"){
                    fortivaOffer.downPaymentPct=0;
                }
                if(fortivaOffer.color.toLowerCase()==="tier 3a"){
                    fortivaOffer.downPaymentPct=10;
                }
                if(fortivaOffer.color.toLowerCase()==="tier 5a"){
                    fortivaOffer.downPaymentPct=25;
                }
                if(fortivaOffer.color.toLowerCase()==="tier 6a"){
                    fortivaOffer.downPaymentPct=40;
                }
            }
            if(fortivaOffer.applicationStatus?.toLowerCase()=== "a" && (fortivaOffer.responseType===null && fortivaOffer.responseTypeOutcome===null)){
                return (
                    <>
                    <div className="col-12">
                        <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                            <Card.Body>
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-3 pr-md-0">
                                <Card className="h-100">
                                    <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center card-body">
                                    <p className="h5 my-auto"><b>FORTIVA RETAIL CREDIT</b></p>
                                    <div className="my-auto text-secondary">
                                        <p className="mb-0"><b>CONGRATULATIONS!</b></p>
                                        <p><b>You have been approved for ${fortivaOffer.approvalLimit.toLocaleString("en-US")} by Fortiva Retail Credit</b></p>
                                    </div>
                                    <div className="my-auto text-secondary">
                                        <p className="mb-0"><b>Required Down Payment {fortivaOffer.downPaymentPct}%</b></p>
                                        
                                        <p>
                                        Please note that down payment may be required on this
                                        transaction with the balance of the transaction financed.
                                        See store associate for details.
                                        </p>
                                    </div>
                                    {(fortivaOffer.introDuration!=="0" && fortivaOffer.introDuration!=null)?
                                    <div className="my-auto text-secondary">
                                        <p className="my-auto"><b>Promotional Term</b></p>
                                        <p className="my-auto">
                                            
                                            No interest if paid in full within {fortivaOffer.introDuration} months.
                                            <OverlayTrigger
                                            overlay={
                                                <Tooltip className="customTooltip" id="tooltip-disabled"><span style={{fontSize:"16px",textAlign:"left"}}>
                                                Interest will be charged to your account from the purchase date if the promotional purchase
                                                is not paid in full within the promotional period. Minimum Monthly Payments are required.
                                                Required monthly payments may or may not pay off your purchase before the end
                                                of the promotional period. To avoid paying the Deferred Interest Charges, you must pay the
                                                promotional purchase in full within the promotional period.</span>
                                                </Tooltip>
                                            }
                                            placement="auto"
                                            >
                                            <span className="d-inline-block">
                                                <FontAwesomeIcon
                                                className="pl-2 pr-2 pt-1 pb-1 border border-dark rounded-circle"
                                                icon={faQuestion}
                                                style={{ pointerEvents: "none"}}
                                                />
                                            </span>
                                            </OverlayTrigger>
                                        </p>
                                    </div>
                                   :""}
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col-12 col-md-8 col-lg-9 pl-lg-4 pt-4 pt-md-0 pt-lg-0">
                                <div className="row">
                                    <div className="col-6 col-md-3 border-right textColor">
                                    <p><b>APR</b></p>
                                    <p>{Number(fortivaOffer.apr)?.toFixed(2)+per}</p>
                                    </div>
                                    <div className="col-6 col-md-3 border-right textColor">
                                        <p><b>Annual Fee</b></p>
                                        <p>{amt+fortivaOffer.annualFee}</p>
                                    <p></p>
                                    </div>
                                    <div className={(isFortiva==="true" && (fortivaOffer.saveCustResponseFortiva?.toLowerCase()==="automatic" || fortivaOffer.saveCustResponseFortiva?.toLowerCase()==="yes") ) ? "form-group form-check ml-3":"d-none"}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="fortivaAppDisclosureAgreed"
                                                id="chkfortivaAppDisclosureAgreed"
                                                //onChange={(event) => this.onChange(event)}
                                                onClick={(event)=>this.onFortivaDisclosure(event)}
                                                //value={this.state.fortivaAppDisclosureAgreed}
                                                checked={this.state.fortivaAppDisclosureAgreed ? "checked" : ""}
                                            />
                                            <label htmlFor="chkFortivaDisclosure" className="mt-1 ml-2"><u style={{ color: "#5C068C", cursor: "pointer",fontWeight:"bold" }} onClick={(event) => this.onApplicationDis(event)}>Application Disclosures</u>: By checking the box at the left you certify that you have read and agree to the Application Disclosures.</label>
                                        
                                            {this.state.fortivaAPPDisclosureErr &&
                                                <div style={{ color: "red", paddingBottom: 10 }}>{this.state.fortivaAPPDisclosureErr}</div>
                                            }
                                        </div>
                                    <p className="pl-3 pr-3 mt-2">
                                    Before selecting your offer, please review the <u style={{ color: "#5C068C", cursor: "pointer",fontWeight:"bold" }} onClick={(event) => this.onsummaryCreditTerms(event)}>Summary of Credit Terms</u> which summarizes the key terms of the Cardholder Agreement that
                                    will govern your Fortiva Retail Credit revolving account. Then,
                                    click Apply for Offer.
                                    </p>
                                    <p className="pl-3 pr-3">
                                    By clicking Apply for Offer, you hereby certify that you are at
                                    least 18 years of age and that everything you have stated above is
                                    true and accurate. You certify that you do not presently have a
                                    petition filed under federal bankruptcy laws (whether voluntary or
                                    involuntarily) and do not anticipate filing a petition. If
                                    approved for credit, you agree to be bound by the terms of the
                                    Cardholder Agreement provided to you. You authorize The Bank of
                                    Missouri, its successors, assigns and agents (the “Lender”) to
                                    investigate your creditworthiness and to obtain a credit report on
                                    you for any lawful purpose, including any update, extension of
                                    credit, review or collection of your account, and you specifically
                                    authorize the release of such information to creditor. If you
                                    request, the Lender will tell you whether any credit report was
                                    requested, and if so, the name and address of the consumer
                                    reporting agency furnishing the report. ​
                                    </p>
                                    <div className="pl-3 pr-3 col-12">
                                        <button className="btn bg-secondary text-primary p-3 applyOfferBtn" disabled={this.state.isAcceptOrRejectOffer || ((fortivaOffer.responseType?.toLowerCase()==="acceptoffer" || fortivaOffer.responseType?.toLowerCase()==="rejectoffer") && (fortivaOffer.responseTypeOutcome?.toLowerCase()==="s" || fortivaOffer.responseTypeOutcome?.toLowerCase()==="p" || fortivaOffer.responseTypeOutcome?.toLowerCase()==="d" ))?true:false} onClick={()=>{this.acceptOfferButton()}}>
                                            <span className="ml-3 mr-3">Apply for Offer</span>
                                        </button>
                                        <button
                                            disabled={this.state.isAcceptOrRejectOffer || ((fortivaOffer.responseType?.toLowerCase()==="acceptoffer" || fortivaOffer.responseType?.toLowerCase()==="rejectoffer") && (fortivaOffer.responseTypeOutcome?.toLowerCase()==="s" || fortivaOffer.responseTypeOutcome?.toLowerCase()==="p" || fortivaOffer.responseTypeOutcome?.toLowerCase()==="d" ))?true:false}
                                            style={{ backgroundColor: "#ebd9f6" }}
                                            className="btn border-dark creditBtn text-secondary pt-3 pb-3 float-right rejectOfferBtn"
                                            onClick={()=>{this.rejectOfferModal()}}
                                        >
                                            <span className="ml-3 mr-3">Reject Offer</span>
                                        </button>
                                    </div>
                                    <div className="mt-3 pl-3 pr-3 w-100 col-12 d-flex">
                                        <div className="ml-auto">
                                            <p className="mb-0">Application Id : {fortivaOffer.applicationId}</p>
                                            <p className="mb-0">Application Date :{Moment(fortivaOffer?.createdDate).format("MM/DD/YYYY")}</p>
                                            <p>Offer Expiration Date :{Moment(fortivaOffer?.offerExpirationDate).format("MM/DD/YYYY")}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-12">
                        <Card className="text-center mt-4 ml-2 mr-2 mb-2 shadow">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-12">
                                        <Card className="h-100">
                                            <Card.Body className="p-1 bg-primary d-flex justify-content-center">
                                                <p className="mb-1 mt-1 text-secondary"><b>FORTIVA CUSTOMER:  NO HARD INQUIRY OR $99 ANNUAL FEE UNLESS THE FORTIVA ACCOUNT IS CHARGED</b></p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    {/*Daniels 2X and 3X */}
                    {fortivaOffer.approvalLimit<10000?
                    <div className="col-12">
                        <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                            <Card.Body>
                            <div className="row">
                                <div className="col-12">
                                <Card className="h-100">
                                    <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center card-body" style={{minHeight:"150px"}}>
                                    <div className="mb-4"><p className="h5 my-auto mb-4"><b>DANIEL'S CREDIT</b></p></div>
                                    <div className="my-auto text-secondary">
                                        <p>If you need additional credit to complete your purchase, you may qualify for Daniel's Credit.  Subject to Credit Approval. See store associate for more details. This offer not associated with Fortiva Retail Credit.</p>
                                    </div>
                                    </Card.Body>
                                </Card>
                                </div>
                            </div>
                            </Card.Body>
                        </Card>
                    </div>:""}
                    <hr className="mt-4 mr-4 mb-0 ml-4"></hr>
                    </>
                )
            }
            else if(fortivaOffer.applicationStatus?.toLowerCase() === "a" &&(fortivaOffer.responseType?.toLowerCase()==="acceptoffer" && fortivaOffer.responseTypeOutcome?.toLowerCase()==="s")){
                return (
                    <>
                    <div className="col-12 col-lg-8">
                        <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                            <Card.Body>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 pr-md-0">
                                <Card className="h-100">
                                    <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center">
                                        <div className="mb-4"><p className="h5 my-auto mb-4"><b>FORTIVA RETAIL CREDIT</b></p></div>
                                        <div className="my-auto mb-4 text-secondary">
                                        <p className="h5 my-auto mb-4">Congratulations! Please see a sales associate for further assistance.</p>
                                        </div>
                                        <div className="my-auto mt-2 text-secondary" >
                                            <p className="mb-0 mt-4"> Credit Limit: ${fortivaOffer.approvalLimit.toLocaleString("en-US")}</p>
                                            <p className="mb-0">Required Down Payment: {fortivaOffer.downPaymentPct}%</p>
                                            {(fortivaOffer.introDuration!=="0" && fortivaOffer.introDuration!=null)?
                                            <p className="mb-0">No Interest, Promotional Period:  {fortivaOffer.introDuration} months</p>:""}
                                        </div>
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 pl-lg-4 pt-4 pt-md-0 pt-lg-0 d-flex flex-column">
                                    <div className="mt-auto">
                                        <p className="mb-0">Application Id : {fortivaOffer.applicationId}</p>
                                        <p className="mb-0">Application Date :{Moment(fortivaOffer?.createdDate).format("MM/DD/YYYY")}</p>
                                    </div>
                                </div>
                            </div>
                            </Card.Body>
                        </Card>
                        
                    </div>
                    <div className="col-12 col-lg-8">
                        <Card className="text-center mt-4 ml-2 mr-2 mb-2 shadow">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-12">
                                        <Card className="h-100">
                                            <Card.Body className="p-1 bg-primary d-flex justify-content-center">
                                                <p className="mb-1 mt-1 text-secondary"><b>FORTIVA CUSTOMER:  NO HARD INQUIRY OR $99 ANNUAL FEE UNLESS THE FORTIVA ACCOUNT IS CHARGED</b></p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                     {/*Daniels 2X and 3X */}
                      {fortivaOffer.approvalLimit<10000?
                        <div className="col-12 col-lg-8">
                            <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                                <Card.Body>
                                <div className="row">
                                    <div className="col-12">
                                    <Card className="h-100">
                                        <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center" style={{minHeight:"150px"}}>
                                        <div className="mb-4"><p className="h5 my-auto mb-4"><b>DANIEL'S CREDIT</b></p></div>
                                        <div className="my-auto text-secondary">
                                            {/* <p className="mb-0"><b>Up to ${purchaseAmt.toLocaleString("en-US")} total Credit Offer including Daniel's Credit When You Maximize Your Fortiva Retail Credit Approval.</b></p>
                                            <p >Subject to Daniel's Credit approval. See store associate for more details. This offer is not associated with Fortiva Retail Credit.</p> */}
                                             <p>If you need additional credit to complete your purchase, you may qualify for Daniel's Credit.  Subject to Credit Approval. See store associate for more details. This offer not associated with Fortiva Retail Credit.</p>
                                        </div>
                                        </Card.Body>
                                    </Card>
                                    </div>
                                </div>
                                </Card.Body>
                            </Card>
                        </div>:""}
                        <hr className="mt-4 mr-4 mb-0 ml-4"></hr>
                        </>
                )
            }
            else if(fortivaOffer.applicationStatus?.toLowerCase() === "a" && (fortivaOffer.responseType?.toLowerCase()==="rejectoffer" && fortivaOffer.responseTypeOutcome?.toLowerCase()==="s")){
                return (
                    <>
                    <div className="col-7">
                        <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                            <Card.Body>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 pr-md-0">
                                <Card className="h-100">
                                    <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center">
                                        <div className="mb-4"><p className="h5 my-auto mb-4"><b>FORTIVA RETAIL CREDIT</b></p></div>
                                        <div className="my-auto text-secondary text-center">
                                            <p> We are sorry that you have chosen not to accept this offer.  You can reapply in 30 days.</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 pl-lg-4 pt-4 pt-md-0 pt-lg-0">
                                    <div className="row">
                                        <div className=" col-12 mt-4 pl-3 pr-3 w-100 text-left fortivaOffers">
                                            <p className="mb-0">Application Id : {fortivaOffer.applicationId}</p>
                                            <p className="mb-0">Application Date :{Moment(fortivaOffer?.createdDate).format("MM/DD/YYYY")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Card.Body>
                        </Card>
                        
                    </div>
                    <div className="col-7">
                        <Card className="text-center mt-4 ml-2 mr-2 mb-2 shadow">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-12">
                                        <Card className="h-100">
                                            <Card.Body className="p-1 bg-primary d-flex justify-content-center">
                                                <p className="mb-1 mt-1 text-secondary"><b>FORTIVA CUSTOMER:  NO HARD INQUIRY OR $99 ANNUAL FEE UNLESS THE FORTIVA ACCOUNT IS CHARGED</b></p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <hr className="mt-4 mr-4 mb-0 ml-4"></hr>
                    </>
                )
            }
            else{
                return (
                    <>
                    <div className="col-6">
                        <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                            <Card.Body>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 pr-md-0">
                                <Card className="h-100">
                                    <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center">
                                    <div className="mb-4"><p className="h5 my-auto mb-4"><b>FORTIVA RETAIL CREDIT</b></p></div>
                                    {fortivaOffer.applicationStatus?.toLowerCase() === "ec"?
                                    <div className="my-auto text-secondary">
                                        <p>Existing cardholder, please see account summary.</p>
                                    </div>:
                                    fortivaOffer.applicationStatus?.toLowerCase() === "d"?
                                    <>
                                    <div className="my-auto text-secondary mt-4 mb-4">
                                        <p className="h5 mb-4"><b>Sorry, there are no offers available</b></p>
                                    </div>
                                    <div className="my-auto text-secondary mt-4 mb-4">
                                        <p>
                                        Thank you for applying for a Fortiva Retail Credit Account.  Unfortunately, your application cannot be approved at this time.  You will receive an email or letter within the next 30 days with more details.
                                        </p>
                                    </div></>:
                                    fortivaOffer.applicationStatus?.toLowerCase()==="cr"?
                                    <>
                                    <div className="my-auto text-secondary mt-4 mb-4">
                                        <p className="h5 mb-4"><b>Sorry, there are no offers available</b></p>
                                    </div>
                                    <div className="my-auto text-secondary mt-4 mb-4">
                                        <p>
                                        Thank you for applying for a Fortiva Retail Credit Account.  Unfortunately, your application cannot be approved at this time. 
                                        </p>
                                    </div></>:
                                    fortivaOffer.applicationStatus?.toLowerCase()==="r"?
                                    <>
                                    <div className="my-auto text-secondary mt-4 mb-4">
                                        <p className="h5 mb-4"><b>Sorry, there are no offers available.</b></p>
                                    </div>
                                    <div className="my-auto text-secondary mt-4 mb-4">
                                        <p>
                                        Thank you for applying for a Fortiva Retail Credit Account.  Unfortunately, your application cannot be approved at this time.  You will receive an email or letter within the next 30 days with more details. 
                                        </p>
                                    </div></>:
                                    fortivaOffer.applicationStatus?.toLowerCase()==="p"?
                                    <div className="my-auto text-secondary mt-4 mb-4">
                                    <p className="h5 mb-4 text-danger"><b>Error 1</b></p>
                                </div>:
                                fortivaOffer.applicationStatus?.toLowerCase()==="ca"?
                                <div className="my-auto text-secondary mt-4 mb-4">
                                <p className="h5 mb-4 text-danger"><b>Error 2</b></p>
                                </div>:
                                fortivaOffer.applicationStatus?.toLowerCase()==="an"?
                                <div className="my-auto text-secondary mt-4 mb-4">
                                <p className="h5 mb-4 text-danger"><b>Error 3</b></p>
                                </div>:
                                    ""}
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 pl-lg-4 pt-4 pt-md-0 pt-lg-0">
                                <div className="row">
                                    <div className=" col-12 mt-4 pl-3 pr-3 w-100 text-left fortivaOffers">
                                        <p className="mb-0">Application Id : {fortivaOffer.applicationId}</p>
                                        <p className="mb-0">Application Date :{Moment(fortivaOffer?.createdDate).format("MM/DD/YYYY")}</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <hr className="mt-4 mr-4 mb-0 ml-4"></hr>
                    </>
                )
            }
        }
        else {
            return null;
        }
    }
    onFortivaDisclosure=(event)=> {
        this.setState({ [event.target.name]: event.target.checked });
    }
    onChange=(event)=> {
        this.setState({ [event.target.name]: event.target.checked });
    }

    loadUOwn() {
        let uownOffer=this.state.offers.data?.filter((val) => { return val.fpType?.toLowerCase() === "uown" });
        if (uownOffer?.length > 0) {
            for (let i = 0; i < uownOffer?.length; i++) {
                return (
                    <>
                    <div className="col-12 col-lg-6 customAlignment">
                                    <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pr-md-0">
                                                    <Card className="h-100">
                                                        <Card.Body className="bg-light pl-3 pt-4 pr-3 pb-4 bg-primary subCard">
                                                            <div className="row">
                                                                <div className="col-8">
                                                                    <p className="h5"><b>UOWN LEASING</b></p>
                                                                </div>
                                                                {/* <div className="col-4 pr-lg-2">
                                                                {
                                                                        (uownOffer[i].status?.toLowerCase()) == 'e0' ?
                                                                            <span className="border float-right h6 pt-1 pr-2 pb-1 pl-2 acceptNotation mr-0 ml-0">Accepted</span>
                                                                        :
                                                                        ((uownOffer[i].status?.toLowerCase()) == 'e4') ?
                                                                            <span className="border float-right h6 pt-1 pr-2 pb-1 pl-2 declinedNotation mr-0 ml-0">Declined</span>
                                                                        :
                                                                        ((uownOffer[i].status?.toLowerCase()) == 'e1') ?
                                                                            <span className="border float-right h6 pt-1 pr-2 pb-1 pl-2 pendingNotation">Pending</span>
                                                                        :
                                                                        ((uownOffer[i].status?.toLowerCase()) == 'e3') ?
                                                                            <span className="border float-right h6 pt-1 pr-2 pb-1 pl-2 declinedNotation mr-0 ml-0">Error</span>
                                                                        :
                                                                            ""
                                                                    }
                                                                </div> */}
                                                            </div>
                                                            {
                                                            (uownOffer[i].status?.toLowerCase()) === 'e0' ?
                                                                <>
                                                                <p className="h5 text-secondary">Approved For  ${uownOffer[i].approvalLimit}</p>
                                                                <p className="mb-0"><b>Lease Id : </b>{uownOffer[i].authorizationNumber}</p>
                                                                </>
                                                            :
                                                            ((uownOffer[i].status?.toLowerCase()) === 'e4') ?
                                                                <>
                                                                <p className="h5 text-danger">Declined</p>
                                                                <p className="mb-0"><b>Lease Id : </b>{uownOffer[i].authorizationNumber}</p>
                                                                </>
                                                            :
                                                            ((uownOffer[i].status?.toLowerCase()) === 'e1') ?
                                                                <>
                                                                <p className="h5">Pending</p>
                                                                <p className="mb-0"><b>Lease Id : </b>{uownOffer[i].authorizationNumber}</p>
                                                                </>
                                                            :
                                                            ((uownOffer[i].status?.toLowerCase()) === 'e3') ?
                                                                <>
                                                                <p className="h5 text-danger">Error</p>
                                                                </>
                                                            :
                                                                ""
                                                            }
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="row mt-3 mt-md-0">
                                                        {/* <div className="col-6 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                            <p><b>Account Id</b></p>
                                                            <p></p>
                                                        </div> */}
                                                        <div className="col-12 pr-md-3 pl-md-4 pt-2">
                                                                    <p><b>Application Date</b></p>
                                                                    <p className="mb-1">{Moment(uownOffer[i].createdDate).format("MM/DD/YYYY")}</p>
                                                                </div>
                                                        <div className="col-12 pr-md-3 pl-md-4 pt-2 pb-2">
                                                            
                                                        <hr className="mt-0"></hr>
                                                            <p><b>Status Message</b></p>
                                                            {
                                                                (uownOffer[i].status?.toLowerCase()) === 'e0' ?
                                                                    <p className="mb-1">Your Uown Leasing Application has been Approved for a spending limit of <b>${ uownOffer[i].approvalLimit }</b>.</p>
                                                                :
                                                                ((uownOffer[i].status?.toLowerCase()) === 'e4') ?
                                                                    <>
                                                  <p className="mb-0">Thank you for contacting Daniel's about a payment plan for your purchase. Unfortunately, after careful review of your application, we must decline your request at this time.</p>
                                                                    <p className="mb-1">Your Uown Leasing Application has been denied.</p>
                                                                    </>
                                                                :
                                                                ((uownOffer[i].status?.toLowerCase()) === 'e1') ?
                                                                    <>
                                                                    <p className="mb-1">Thank you for contacting Daniel's about your credit needs. Additional documentation is required to process your credit application. Please see a Daniel's Jewelers sales associate for further details.</p>
                                                                    <p className="mb-1">{uownOffer[i].message}</p>
                                                                    </>
                                                                :
                                                                ((uownOffer[i].status?.toLowerCase()) === 'e3') ?
                                                                    (uownOffer[i].fieldInError1.length > 0) ?
                                                                    <>
                                                                        <p className="mb-0">An error has occured when processing your credit application. Please correct and resubmit.</p>
                                                                        <p className="mb-1">{uownOffer[i].errorMessage}</p>
                                                                        <p className="mb-0">Please check the following field(s):</p>
                                                                        <p className="mb-0">{uownOffer[i].fieldInError1}</p>
                                                                            {uownOffer[i].fieldInError2.length > 0 ? <p className="mb-0">{uownOffer[i].fieldInError2}</p> : null}
                                                                            {uownOffer[i].fieldInError3.length > 0 ? <p className="mb-0">{uownOffer[i].fieldInError3}</p> : null}
                                                                            {uownOffer[i].fieldInError4.length > 0 ? <p className="mb-0">{uownOffer[i].fieldInError4}</p> : null}
                                                                            {uownOffer[i].fieldInError5.length > 0 ? <p className="mb-0">{uownOffer[i].fieldInError5}</p> : null}
                                                                        </>
                                                                    :
                                                                        (uownOffer[i].message.length > 0) ? 
                                                                            <>
                                                                            <p className="mb-1">An error has occured when processing your credit application. Please see a Daniel's Jewelers sales associate for assistance.</p>
                                                                            <p className="mb-1">{uownOffer[i].errorMessage}</p>
                                                                            <p className="mb-1">{uownOffer[i].message}</p>
                                                                            </>
                                                                        :
                                                                            ""
                                                                :
                                                                    ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-center mt-3 mb-0" style={{fontSize:"12px"}}>Log into the customer portal to finalize the transaction. <a target="_blank" rel="noreferrer" style={{color:"#0056b3"}} href="https://merchant.uownleasing.com/">Uown | Merchant</a></p>
                                        </Card.Body>
                                    </Card>
                                </div>
                    </>
                );
            }
        }
        else {
            return null;
        }
    }

    loadSynchrony() {
        let synchronyOffer=this.state.synchronyOffers;
        //let synchronyObj = JSON.parse(localStorage.getItem("synchronyObj"));
        //let obj = localStorage.getItem("applicationId");
        //if(synchronyObj?.isSynchrony==true && synchronyObj?.customerId==obj){
        if(localStorage.getItem("synchronyAppId") !== "null" && localStorage.getItem("synchronyAppId") !== "undefined") {
        if(synchronyOffer.applicationId) {
                return (
                    <>
                    <div className="col-12">
                        <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-12 col-md-3 pr-md-1">
                                        <Card className="h-100">
                                            <Card.Body className="bg-light pl-3 pt-4 pr-3 pb-4 bg-primary subCard">
                                                    {/* <div className="row">
                                                        <div className="col-8"> */}
                                                            <p className="h5"><b>Synchrony Bank</b></p>
                                                        {/* </div>
                                                    </div> */}
                                                {
                                                (synchronyOffer.status?.toLowerCase()) === 'approved' ?
                                                    <>
                                                    <p className="h5 text-secondary">Approved For  ${synchronyOffer.approvalLimit}</p>
                                                    </>
                                                :
                                                ((synchronyOffer.status?.toLowerCase()) === 'declined' || (synchronyOffer.status?.toLowerCase()) === 'denied') ?
                                                    <>
                                                    <p className="h5 text-danger">Declined</p>
                                                    </>
                                                :
                                                ((synchronyOffer.status?.toLowerCase()) === 'pending' || (synchronyOffer.status?.toLowerCase()) === 'pre-approved') ?
                                                    <>
                                                    <p className="h5 text-danger">Pending</p>
                                                    </>
                                                :
                                                ((synchronyOffer.status?.toLowerCase()) === 'in process') ?
                                                    <>
                                                    <p className="h5">In Process</p>
                                                    </>
                                                :
                                                ((synchronyOffer.status?.toLowerCase()) === 'non fraud referral') ?
                                                    <>
                                                    {/* <p className="h5">NON FRAUD REFERRAL</p> */}
                                                    </>
                                                :
                                                (synchronyOffer.errorMessage?.length > 0) ?
                                                    <p className="h5 text-danger">Error</p>
                                                :
                                                    ""
                                                }
                                                <button className={"btn border-dark float-center w-100 text-primary pt-2 pb-2"+(this.state.synchronyOffers.status?.toLowerCase() !== 'approved' || (this.state.summaryDetails.opentobuy === "" ||  this.state.summaryDetails.opentobuy==null)? " creditBtn":" bg-secondary")} onClick={(event) => this.onIncreaseLimit(event)}
                                                disabled={this.state.synchronyOffers.status?.toLowerCase() !== 'approved' || (this.state.summaryDetails.opentobuy === "" ||  this.state.summaryDetails.opentobuy == null)?true:false}>Request Credit Line Increase</button>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <div className="row mt-3 mt-md-0">
                                        <div className={"col-6 col-md-3 border-right pr-md-3 pl-md-3 pt-2 pb-2 " + ((synchronyOffer.status?.toLowerCase()) === 'approved' ? "" : "d-none")}>
                                                <p><b>Account Id</b></p>
                                                <p>{synchronyOffer.accountNumber}</p>
                                            </div>
                                            <div className="col-6 col-md-3 pr-md-3 pl-md-3 pt-2 pb-2 border-right">
                                                <p><b>Application Id</b></p>
                                                <p>{synchronyOffer.applicationId}</p>
                                            </div>
                                            <div className="col-6 col-md-3 pr-md-3 pl-md-3 pt-2 pb-2 border-right">
                                                <p><b>Application Date</b></p>
                                                <p>{Moment(synchronyOffer.createdDate).format("MM/DD/YYYY")}</p>
                                            </div>
                                            <div className="col-6 col-md-3 pr-md-3 pl-md-3 pt-2 pb-2">
                                                <p><b>Open to Buy</b></p>
                                                <p>{"$"+this.state.summaryDetails.opentobuy}</p>
                                            </div>
                                        </div>
                                        <hr className="mt-0"></hr>
                                        <div className="row">
                                            {(this.state.summaryDetails.statusMessage!=="Approved" && this.state.summaryDetails.statusMessage!=="Declined" && this.state.increaseLimitmsg!=="Declined"  && (this.state.summaryDetails.previousCredit === "0" || this.state.summaryDetails.previousCredit === "")) &&
                                                <div className="col-12 pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Status Message</b></p>
                                                        <p>{synchronyOffer.message}</p>
                                                </div>}
                                            {(this.state.summaryDetails.previousCredit!=="0" && this.state.summaryDetails.previousCredit!=="" && this.state.synchronyOffers.accountNumber && this.state.summaryDetails.statusMessage==="Approved")  &&  
                                                <div className="col-12 pr-md-3 pl-md-4 pt-2 pb-2">
                                                    <p><b>Credit Line increase Status</b></p>
                                                    <p>{`Your request for credit line increase of  ${'$'+this.state.summaryDetails.previousCredit} was approved on ${this.state.summaryDetails.approvedDate}`}</p>
                                                </div>
                                            }
                                            {(this.state.summaryDetails.statusMessage === "Declined" || this.state.increaseLimitmsg==="Declined")  &&  
                                                <div className="col-12 pr-md-3 pl-md-4 pt-2 pb-2">
                                                    <p><b>Credit Line increase Status</b></p>
                                                    <p>{`Your request for credit line increase was declined on ${this.state.summaryDetails.approvedDate}`}</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <hr className="mt-4 mr-4 mb-0 ml-4"></hr>
                    </>
                    );
            }
            else  {
                return (
                    <>
                    <div className="col-6 pr-0">
                        <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-12 col-md-6 pr-md-1">
                                    <Card className="d-flex">
                                        <Card.Body className="bg-light pl-3 pt-4 pr-3 pb-4 bg-primary subCard">
                                        <p className="h5"><b>Synchrony Bank</b></p>
                                         {/* <p className="h5">{(localStorage.getItem("isSynchronyCD") == "true" ? "" : "Applied")}</p> */}
                                         <p className="h5">Applied</p>
                                        <p></p>
                                        </Card.Body>
                                    </Card>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <hr className="mt-4 mr-4 mb-0 ml-4"></hr>
                    </>
                    );
            }
        }
        //}
        
    }

    onIncreaseLimit = ()=>{
        this.setState({showincrLimit:!this.state.showincrLimit,increaseLmtErr:"",
           formObj:{...this.state.formObj,requestedLmt:""}
        });
    }
    onChangeSynchrony(event){
    //    let reg=/^[0-9]+$/;
    //    if(reg.test(event.target.value)){
            this.setState({formObj:{...this.state.formObj,[event.target.name]:event.target.value}})
        //}
    }

    submitIncCreditLimit(){
        if(Number(this.state.formObj.requestedLmt)<Number(this.state.summaryDetails.opentobuy)){
            this.setState({increaseLmtErr:"Requested amount cannot be less than the open to buy amount."});  
           return 
        }
        let synchronyAppId = localStorage.getItem("synchronyAppId") === "null" ? "" : localStorage.getItem("synchronyAppId");
        let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
        let obj={
            customerId: GetInfo.getCustomerId(),
            applicationId : synchronyAppId,
            requestedLimit:this.state.formObj.requestedLmt,
            income:this.state.formObj.income,
            homeStore:homeStore
        }
        this.setState({showincrLimit:false,increaseLmtErr:""});
        trackPromise(
            this.props.syncActions.increaseSynLmt(obj)
            .then(response=>{
                if(response.fullApproval){
                    let currentDate=Moment(new Date()).format("MM/DD/YYYY");
                    let msg=`Your request for credit line increase ${'$'+response.creditLimit} was approved on ${currentDate}`;
                    let previousApprDate=Moment(response.approvedDate).format("MM/DD/YYYY");
                    this.setState({showAlert:true,increaseLimitmsg:msg,
                        summaryDetails:{...this.state.summaryDetails,previousCredit:response.previousCredit,approvedDate:previousApprDate},
                        synchronyOffers:{...this.state.synchronyOffers,approvalLimit:response.creditLimit}});
                }
                else {
                    if(response.errorMessage==="Declined"){
                        let previousApprDate=Moment(response.approvedDate).format("MM/DD/YYYY");
                        this.setState({summaryDetails:{...this.state.summaryDetails,approvedDate:previousApprDate}});
                    }
                    this.setState({showAlert:true,increaseLimitmsg:response.errorMessage});
                }
                this.getActSummaryData(synchronyAppId);
            }).catch(err => {
                APIFailedMessage.displayError(err);
            })
        );
    }
    getActSummaryData(synchronyAppId) {
        let phoneNo=sessionStorage.getItem("selectedUserPhone")!=="null"?sessionStorage.getItem("selectedUserPhone"):"";
        let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
        if(phoneNo.includes("-")){
            phoneNo = phoneNo.slice(0,3)+phoneNo.slice(4,7)+phoneNo.slice(8,12);
        }
        trackPromise(
            this.props.syncActions.getsynchronyActSummmary(GetInfo.getCustomerId(),synchronyAppId,phoneNo,homeStore).then(
                response => {
                    if (response.applicationId || response.accountNumber)
                    this.setState({summaryDetails:{...this.state.summaryDetails,opentobuy:response.opentobuy}});

                    else
                        this.setState({ showAlert: true, increaseLimitmsg: response.errorMessage });
                }
            ).catch(err => {
                APIFailedMessage.displayError(err);
            })
        );
    }

    handleAlertModal = () => {
        let showAlert=!this.state.showAlert;
        this.setState({showAlert:showAlert});
    }
    
    handleAcceptOfferModal = () => {
        this.setState({isAcceptOffer:!this.state.isAcceptOffer});
    }
    
    camelize(str) {
        let camelStr="";
        if(str){
            camelStr= str.split(' ').map(a => a.trim()).map(a => a[0].toUpperCase() + a.substring(1).toLowerCase()).join(" ");
        }
        return camelStr;
    }
    
    acceptOfferButton=()=>{
        let isFortiva=sessionStorage.getItem("isFortiva");
        let fortivaOffer=this.state.fortivaOffers.data?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" })[0];
        if(fortivaOffer){
            if(isFortiva === "true" && (fortivaOffer.saveCustResponseFortiva?.toLowerCase() === "automatic" || fortivaOffer.saveCustResponseFortiva?.toLowerCase() === "yes")){
                if(!this.state.fortivaAppDisclosureAgreed){
                    this.setState({fortivaAPPDisclosureErr:"Application Disclosures agreement is required."});
                    return;
                }
            }
        }
        let fortivaAppId = localStorage.getItem("fortivaAppId");
        if (fortivaAppId !== "null" && fortivaAppId !== "undefined" && fortivaAppId !== "") {
             // //Cardholder Agrement
        let cardholderAgrement={
                    documentCode: "6",  
                    fortivaDocResponse: "",
                    applicationId:fortivaAppId,
                    isSubmitApp:false
                };
            trackPromise(
            this.props.applyNowActions.fortivaDisclosures(cardholderAgrement)
                .then((res) => {
                        this.setState({cardholderAggrement:res.documentsImageHtml,isCardholderAgreement:!this.state.isCardholderAgreement});
                })
                .catch((err) => {
                    APIFailedMessage.displayError(err);
                })
            )
        }
    }
    acceptOffer=(event)=>{
        event.preventDefault();
        this.setState({isAcceptOffer:!this.state.isAcceptOffer})
        let fortivaOffer=this.state.fortivaOffers.data?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" });
        if(fortivaOffer?.length>0) {
            let offerObj={
                customerId: GetInfo.getCustomerId(),
                applicationId: fortivaOffer[0].applicationId,
                lastName: localStorage.getItem('lastName') !== "null"? localStorage.getItem('lastName'):"",
                acceptedOfferId: fortivaOffer[0].offerId,
                acceptedOfferUId: fortivaOffer[0].offerUId,
                emailAddress: localStorage.getItem("creditappEmailAddr") !== "null"?localStorage.getItem("creditappEmailAddr"):"",
                btAccepted: "N",
                isFortivaAcceptOfferDisclosure:this.state.cardAgreementChecked,
                isFortivaDisclosures:this.state.fortivaAppDisclosureAgreed,
                store_Id:JSON.parse(localStorage.getItem("associateStores")),
             }
            trackPromise(
                this.props.actions.acceptOffer(offerObj).then(
                    response => {
                        if (response) {
                            if (response.errorMessage) {
                                this.setState({successMsg:response.errorMessage,offerModal:!this.state.offerModal});
                            }
                            else {
                                if(response.applicationOutcome.toLowerCase()==="s"){
                                    this.setState({successMsg:'Offer accepted successfully',isAcceptOrRejectOffer:!this.state.isAcceptOrRejectOffer});
                               }
                               else if(response.applicationOutcome.toLowerCase()==="p"){
                                 this.setState({successMsg:'Offer accepted is pending',offerModal:!this.state.offerModal,isAcceptOrRejectOffer:!this.state.isAcceptOrRejectOffer});
                                }
                                this.getOffers(response.applicationOutcome.toLowerCase());
                            }
                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
        }
    }
    rejectOfferModal=()=>{
        this.setState({showrejectOfferModal:!this.state.showrejectOfferModal});
    }
    OfferConfirmModal=()=>{
        this.setState({fortivaOfferConfirm:!this.state.fortivaOfferConfirm});
        if(this.state.isInsuranceEnable && sessionStorage.getItem("havingFortivaInsurance") === "true") {
            this.setState({insurance : !this.state.insurance});
        }
    }
    // synchronPendingModal=()=>{
    //     this.setState({synchronyPending:!this.state.synchronyPending});
    // }
    rejectOffer=(event)=>{
        event.preventDefault();
        this.setState({showrejectOfferModal:!this.state.showrejectOfferModal});
        let fortivaOffer=this.state.fortivaOffers.data?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" });
        if(fortivaOffer?.length>0) {
            let offerObj={
                customerId: GetInfo.getCustomerId(),
                applicationId: fortivaOffer[0].applicationId,
                lastName: localStorage.getItem('lastName') !== "null"? localStorage.getItem('lastName'):"",
                store_Id:this.state.store_Id
             }
            trackPromise(
                this.props.actions.rejectOffer(offerObj).then(
                    response => {
                        if (response) {
                            if (response.errorMessage) {
                                this.setState({successMsg:response.errorMessage});
                            }
                            else {
                                this.getOffers();
                                if(response.applicationOutcome.toLowerCase()==="s"){
                                    this.setState({successMsg:'Offer rejected successfully',isAcceptOrRejectOffer:!this.state.isAcceptOrRejectOffer});
                                }
                                if(response.applicationOutcome.toLowerCase()==="d"){
                                    this.setState({successMsg:'Reject offer is denied',isAcceptOrRejectOffer:!this.state.isAcceptOrRejectOffer});
                                }
                            }
                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
        }
    }

    async getOffers(status) {
        let obj = localStorage.getItem("customerId");
        let cust_Id = localStorage.getItem("customerId");
        if(obj !== "null"){
            await trackPromise(
                this.props.customerDetailsActions.getViewOfferFortiva(cust_Id,obj).then(
                    response => {
                        if (response) {
                            this.setState({ fortivaOffers: response });
                            sessionStorage.setItem("fortivaAccountNo", response.data[0]?.accountNumber);
                            sessionStorage.setItem("updated_fortiva_response", JSON.stringify(response));
                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            );
            if(status === "s" ) {
                this.setState({fortivaOfferConfirm:true});
            }
        }
    }
    onCardholder=(event)=>{
        event.preventDefault();
        this.setState({isCardholderAgreement:!this.state.isCardholderAgreement});
    }

    onsummaryCreditTerms=(event)=>{
        event.preventDefault();
        let fortivaOffer=this.state.fortivaOffers.data?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" });
        let fortivaAppId = localStorage.getItem("fortivaAppId");
        if(fortivaOffer?.length>0) {
            if(fortivaOffer[0].applicationStatus?.toLowerCase() === "a"){
                if (fortivaAppId !== "null" && fortivaAppId !== "undefined" && fortivaAppId !== "") {
                //summary of credit terms
                    let summaryofCreditTerms={
                        documentCode: "4",  
                        fortivaDocResponse: "",
                        applicationId:fortivaAppId,
                        isSubmitApp:false
                    };
                    trackPromise(
                    this.props.applyNowActions.fortivaDisclosures(summaryofCreditTerms)
                        .then((res) => {
                            this.setState({ summaryCreditTerms: res.documentsImageHtml,isSummaryCreditTerms:!this.state.isSummaryCreditTerms });
                           
                        })
                        .catch((err) => {
                            APIFailedMessage.displayError(err);
                        })
                    )
                }
            }
        }

    }
    
    triggerInsurance = () => {
        this.setState({insurance : !this.state.insurance});
    }

    onApplicationDis=(event)=>{
        event.preventDefault();
         //fortiva application disclosures
         let fortivaAppId = localStorage.getItem("fortivaAppId");
         if (fortivaAppId !== "null" && fortivaAppId !== "undefined" && fortivaAppId !== "") {
            let applicationDisclosure={
                documentCode: "9",
                fortivaDocResponse: "",
                applicationId:fortivaAppId,
                isSubmitApp:false
            };
            trackPromise(
            this.props.applyNowActions.fortivaDisclosures(applicationDisclosure)
                .then((res) => {
                    this.setState({ fortivaApplicationDisMsg: res.documentsImageHtml,isFortivaAppDisclosure:!this.state.isFortivaAppDisclosure });
                })
                .catch((err) => {
                    APIFailedMessage.displayError(err);
                })
            )
        }
    }

    render()
    {

        let synchronyAppId = localStorage.getItem("synchronyAppId");

        return(
            <>
            <IdleTimeModal />
            <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
                <Sidebar
                toggled={this.state.toggled}
                handleToggleSidebar={() => {this.handleToggleSidebar()}}
                />
                <main>
                    <CustomNavbar handleToggleSidebar={() => {this.handleToggleSidebar()}}/>
                    <ToastContainer className="p-3" position="top-end">
                        <Toast bg="success" onClose={() => {this.setState({offerModal : !this.state.offerModal})}} show={this.state.offerModal} delay={3000} animation autohide>
                            <Toast.Body className="text-white ml-2 mr-2"><FontAwesomeIcon icon={faCircleCheck} className="pr-2"/>{this.state.successMsg}</Toast.Body>
                        </Toast>
                    </ToastContainer>
                        <div id="main" className="container-fluid">
                            <div className="row mb-3">
                                {(this.state.offers.data?.length > 0 || this.state.fortivaOffers.data?.length > 0 || 
                                    (synchronyAppId !== "null" && synchronyAppId !== "undefined" && synchronyAppId !== ""))?
                                <>
                                <div className="col-12 p-0">
                                    {this.state.isCSSEnable? <DanielsCreditOffer   isDanielsInsuranceEnable={this.state.isDanielsInsurance}/>:this.loadDaniels()}
                                </div>
                                <div className="col-12 p-0">
                                    {this.loadFortiva()}
                                </div>
                                <div className="col-12 p-0">
                                    {this.loadSynchrony()}                                    
                                </div>
                                <div className="col-12 p-0">
                                    {this.loadProgressive()} 
                                </div>
                                <div className="col-12 p-0">
                                    {this.loadUOwn()}
                                </div>
                                </>
                                :
                                <>
                                    {this.state.isCSSEnable? <DanielsCreditOffer   isDanielsInsuranceEnable={this.state.isDanielsInsurance}/>:this.loadDaniels()}
                                </>}                              
                            </div>
                            
                            {/* <div id="customerInfo" ref={el => (this.printToStore = el)}>
                                <div className="ml-5 mb-3 mr-5 mt-4">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.printText }} />
                                    <span className="float-right">{sessionStorage.getItem("createdDate") == "" ? "" : new Date(sessionStorage.getItem("createdDate")).toLocaleString()}</span>
                                </div>
                            </div>

                            <ReactToPrint
                                trigger={() =>
                                    <span id="printToStore" className="printToStoreLabel mt-3 ml-3 mr-2 mb-0 pr-1 d-none" style={{ cursor: "pointer" }} onClick={() => this.setState({ show: !this.state.show })}></span>
                                }
                                content={() => this.printToStore}
                                pageStyle="@page { size: A4; margin : 0 0 0 0 !important;}"
                                documentTitle={`${this.state.accountNo}-Information`}
                            /> */}

                            <Modal show={this.state.showincrLimit} onHide={this.state.showincrLimit}>  
                                <Modal.Header className="pb-0"><b className="text-secondary h4">Credit Limit Increase</b><FontAwesomeIcon icon={faClose} className="btn" onClick={() => this.onIncreaseLimit()} /></Modal.Header>
                                <Modal.Body className="pb-0">
                                    <div className="row">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2">
                                        <label>Requested Total Purchase Amount</label>
                                    </div>
                                    <div
                                        className="col-12 col-md-6 col-lg-6 pt-2"
                                        style={{ marginRight: "-30px" }}
                                    >
                                        <input
                                        type="number"
                                        className="form-control border-dark "
                                        name="requestedLmt"
                                        id="requestedLmt"
                                        placeholder="Requested Limit"
                                        value={this.state.formObj.requestedLmt}
                                        onChange={(event) => this.onChangeSynchrony(event)}
                                        ></input>
                                          <span className="text-danger ml-0">{this.state.increaseLmtErr}</span>
                                    </div>
                                    <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                                    
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2">
                                        <label>Monthly Income</label>
                                    </div>
                                    <div
                                        className="col-12 col-md-6 col-lg-6 pt-2"
                                        style={{ marginRight: "-30px" }}
                                    >
                                        <input
                                        type="number"
                                        className="form-control border-dark "
                                        name="income"
                                        id="income"
                                        placeholder="Income"
                                        value={this.state.formObj.income}
                                        onChange={(event) => this.onChange(event)}
                                        ></input>
                                    </div>
                                    <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                                    </div>
                                    </div>

                                </Modal.Body> 
                                <Modal.Footer className="border-top-0 mt-1">
                                    <p className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}}onClick={()=>this.onIncreaseLimit()}>Cancel</p>
                                     <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary" disabled={(this.state.formObj.requestedLmt!=="" && this.state.formObj.income!=="")?false:true} onClick={()=>{this.submitIncCreditLimit()}}>Request</button>
                                </Modal.Footer>
                            </Modal>

                            {/* <FortivaDialog
                                show={this.state.showFortiva}
                                handleModalPopup={this.handleFortivaModal}
                                message={`<span class=\"mb-0 \">You have been approved for Synchrony. Do you still want to send the application to Fortiva?</span>`}
                                handleFortivaModal={this.handleFortivaModal}
                                submitFortiva={this.submitFortiva}
                                btn1_name="No"
                                btn2_name="Yes"
                            /> */}
                            <ConfirmDialog
                                show={this.state.showAlert}
                                handleModalPopup={this.handleAlertModal}
                                message={`<span class="mb-0">${this.state.increaseLimitmsg}</span>`}
                                header_name = "Alert!!"
                                button2_name="OK"
                                button2={this.handleAlertModal}
                            />
                            <FortivaDisclosures
                                title={"APPLICATION DISCLOSURES"}
                                onDisclosure={this.onApplicationDis}
                                onClose={(event)=>{this.setState({isFortivaAppDisclosure:!this.state.isFortivaAppDisclosure})}}
                                show={this.state.isFortivaAppDisclosure}
                                disclosuresMsg={this.state.fortivaApplicationDisMsg}
                            />
                                <FortivaDisclosures
                                title={"SUMMARY OF CREDIT TERMS"}
                                onDisclosure={this.onsummaryCreditTerms}
                                onClose={(event)=>{this.setState({isSummaryCreditTerms:!this.state.isSummaryCreditTerms})}}
                                show={this.state.isSummaryCreditTerms}
                                disclosuresMsg={this.state.summaryCreditTerms}
                            />
                            <FortivaDisclosures
                                title={"CARDHOLDER AGREEMENT"}
                                onDisclosure={this.onCardholder}
                                onClose={(event)=>{this.setState({isCardholderAgreement:!this.state.isCardholderAgreement,isAcceptOffer:true})}}
                                show={this.state.isCardholderAgreement}
                                disclosuresMsg={this.state.cardholderAggrement}
                            />
                            <FortivaAcceptOfferDialog
                                show={this.state.isAcceptOffer}
                                cardAgreementChecked={this.state.cardAgreementChecked}
                                handleModalPopup={this.handleAcceptOfferModal}
                                message={`<span class="mb-0">By checking the box above, you agree that you have (i) read and understand your Cardholder Agreement including the terms of any deferred interest offer and agree to
                                be bound by its terms, and (ii) have provided the correct email adress to receive electronic copy of your Cardholder Agreement that you will print, download or otherwise retain for your records. </span>`}
                                onClose={(event)=>{this.setState({isAcceptOffer:!this.state.isAcceptOffer})}}
                                acceptOffer={this.acceptOffer}
                                onAgreement={(event)=>{this.setState({cardAgreementChecked:event.target.checked})}}
                            />
                            <ConfirmDialog
                                show={this.state.showrejectOfferModal}
                                handleModalPopup={this.rejectOfferModal}
                                message={`<span class="mb-0">Are you sure you want to reject the offer from Fortiva Retail Credit? You will not be able to reapply for 30 days.</span>`}
                                header_name = "Confirmation"
                                button1_name="No"
                                button1={this.rejectOfferModal}
                                button2_name="Yes"
                                button2={this.rejectOffer}
                            
                            />
                            <ConfirmDialog
                                show={this.state.fortivaOfferConfirm}
                                handleModalPopup={this.OfferConfirmModal}
                                message={`<span class="mb-0">Your offer acceptance has been confirmed. Please see a sales associate to make a purchase.</span>`}
                                header_name = "Confirmation"
                                button2_name="OK"
                                button2={this.OfferConfirmModal}
                            />
                    </div>

                    {this.state.insurance ? 
                        <Insurance 
                            from="offers" 
                            insurance={this.state.insurance}
                            triggerInsurance={this.triggerInsurance}
                            partnerType = "Fortiva"
                            offerAcceptedState={sessionStorage.getItem("AssociateState")}
                        />
                    : null}

                </main>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        data: state.fortiva.fortivaObj
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        customerDetailsActions: bindActionCreators(customerDetailsActions, dispatch),
        syncActions: bindActionCreators(synchronyActions, dispatch),
        applyNowActions: bindActionCreators(applyNowActions, dispatch),
        actions :bindActionCreators(viewOfferActions,dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
        }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewOffers);
