import HttpMethods from "../redux/_http.methods";
import axios from "axios";
import Auth from "../redux/_http.services.js";
import APIFailedMessage from "../components/component.apiFailedMessage";

export class ApiWrapper {
  static LoadData(data) {
    let storePortalApiBaseUrl = window.env.LINKS_STORE_PORTAL_API;
    let storePortalApiUrl = storePortalApiBaseUrl + data.ResourceUrl;
    let autoFillBaseUrl = window.env.LINKS_ADDRESS_AUTOFILLURL;
    let autoFillUrl = autoFillBaseUrl + data.ResourceUrl;

    switch (data.MethodType) {
      case HttpMethods.Get():
        if (data) {
          if (data.anonymouseApi && data.isAssociate) {
            return axios
              .get(`${storePortalApiUrl}`)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
          if (data.anonymouseApi && data.isCustomer) {
            return axios
              .get(`${storePortalApiUrl}`)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
          if (data.thirdPartyApi) {
            return axios
              .get(`${data.ResourceUrl}`)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
          if (data.isAssociate) {
            return Auth.axiosMethod()
              .get(storePortalApiUrl)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
          if (data.isMagix) {
            return Auth.axiosMethod()
              .get(storePortalApiUrl)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
          if (data.isAutoFill) {
            return axios
              .get(`${autoFillUrl}`)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          } else {
            return Auth.axiosMethod()
              .get(storePortalApiUrl)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
        }
        break;
      case HttpMethods.Post():
        if (data.Resource) {
          if (data.isAssociate) {
            return Auth.axiosMethod()
              .post(storePortalApiUrl, data.Resource)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
          if (data.isMagix) {
            return Auth.axiosMethod()
              .post(storePortalApiUrl, data.Resource)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
          if (data.isCSV) {
            return Auth.axiosMethod()
              .post(storePortalApiUrl, data.Resource, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          } else {
            return Auth.axiosMethod()
              .post(storePortalApiUrl, data.Resource)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
        }
        break;
      case HttpMethods.Put():
        if (data.Resource) {
          if (data.isAssociate) {
            return Auth.axiosMethod()
              .put(storePortalApiUrl, data.Resource)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          } else {
            return Auth.axiosMethod()
              .put(storePortalApiUrl, data.Resource)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
        }
        break;
      case HttpMethods.Delete():
        if (data) {
          if (data.isAssociate) {
            return Auth.axiosMethod()
              .delete(storePortalApiUrl, data.Resource)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          } else {
            return Auth.axiosMethod()
              .delete(storePortalApiUrl, data.Resource)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
        }
        break;
      default:
        break;
    }
  }
}
