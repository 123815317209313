import { ADDFORTIVA } from "../actions/actionTypes";
import { initialState } from "../initialState";


const reducer = (state = {}, action) => {
  switch (action.type) {
    case ADDFORTIVA:
      return {...state, fortivaObj: action.payload.obj};
    default:
      return state;
  }
};
export default reducer;