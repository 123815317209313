import React, { Component } from "react";
import { Card, Modal } from "react-bootstrap";
// import CustomNavbar from "../../components/component.customNavbar";
// import Sidebar from "../../components/component.customSideBar";
// import IdleTimeModal from "../../components/component.idleTimeModal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
// import "./component.danielsCreditOffer.css";
import * as viewOfferActions from "../../redux/actions/viewOffers.actions";
import Moment from 'moment';
import { faClose} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetInfo from "../../components/component.getInfo";
import Insurance from "../insurance/component.insurance";
import * as InsuranceActions from "../../redux/actions/insurance.actions";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import * as billingCycleActions from "../../redux/actions/billingCycle.actions";
import moment from "moment";

class DanielsCreditOffers extends Component{
    constructor(){
        super();
        this.state={
            isDanielsOffer:false,
            isContinue:false,
            isMatch:false,
            ratingCode:'',
            isMatchFound:false,
            isAccept:false,
            approvalId:"",
            insuranceDaniels:false,
            insurance:false,
            isCSSaccountCreated:false,
            isBillingCycle:false,
            billingCycleId:"",
            associateId:localStorage.getItem('loggedUserEmail')!="null"?localStorage.getItem('loggedUserEmail'):"",
            store_Id:localStorage.getItem("associateStores")!="null"?JSON.parse(localStorage.getItem("associateStores")):"",
            // danielAppId:localStorage.getItem("danielAppId"),
            selectedAccount : "",
            danielsOffersList : [],      
            OfferStatusOrFaxbackInfo : false,
            creditDirectorResponse : [],  
            ApprovedcreditRating : ['15','17','19','21'],    
        };
    }

    componentDidMount() {
        this.loadDanielsOfferGrid();
    }

    loadDanielsOfferGrid() {
        let customerId = localStorage.getItem("customerId");
        trackPromise(
            this.props.actions.getGridCustomerData(customerId).then(response => {
                if(response) {
                    this.setState({danielsOffersList : response?.getGridCustomers, selectedAccount : ""});
                }
            }).catch(err => APIFailedMessage.displayError(err))
        )
    }

    setSelectedAccount(item) {
        if(item?.application_id === this.state.selectedAccount?.application_id) this.setState({selectedAccount : ""});
        else this.setState({selectedAccount : item});
    }
   
    loadDanielsCredit() {
        let createdDate=sessionStorage.getItem("createdDate");
        if(createdDate){
            createdDate=Moment(sessionStorage.getItem("createdDate")).format("MM/DD/YYYY");
        }
        let danielAppId= this.state.danielAppId
        // if (danielAppId != "null" && danielAppId != "undefined" && danielAppId != "") {
        if(this.state.danielsOffersList?.length !== 0) {
            return (
                (!this.state.isCSSaccountCreated)?
                <div className="col-12">
                    <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                    
                        <Card.Body>
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-3 pr-md-0">
                            <Card className="h-100">
                                <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center card-body">
                                <p className="h5 my-auto"><b>DANIEL'S CREDIT</b></p>
                                <div className="my-auto text-secondary">
                                    <p className="mb-0"><b>Your application has been submitted to Credit for review.</b></p>
                                </div>
                                </Card.Body>
                            </Card>
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 pl-lg-4 pt-4 pt-md-0 pt-lg-0">
                                <div className="row">
                                    <p className="pl-3 pr-3 mt-2">
                                        Your application has been submitted to the Credit department for review. Once you receive your approval on the roll printer and your customer would like to make a purchase , press the <span className="text-secondary font-weight-bold">Accept Offer </span>button below to create the customer account. 
                                    </p>
                                    <p className="pl-3 pr-3 mt-2"> Terms and Conditions placeholder.</p>
                                </div>
                                <p> Please select a Daniel’s Application to review the status.</p>
                                <div className="pl-3 pr-3">
                                <div className="row text-center">
                                        <div className="col-3 bg-secondary text-white pt-2 pb-2"></div>
                                        <div className="col-3 bg-secondary text-white pt-2 pb-2"><span className="d-none d-md-linine d-lg-inline">Date</span></div>
                                        <div className="col-3 bg-secondary text-white pt-2 pb-2"><span className="d-none d-md-linine d-lg-inline">Application Number</span></div>
                                        <div className="col-3 bg-secondary text-white pt-2 pb-2"><span className="d-none d-md-linine d-lg-inline">Application Type</span></div>
                                    </div>
                                    {this.state.danielsOffersList?.map(item => {
                                        return (
                                            <div className="row text-center border-bottom">
                                                <div className="col-3 pt-2 pb-1 border-right d-flex">
                                                    <input className="mx-auto my-auto" type="checkbox" id={item.application_id} checked={item.application_id === this.state.selectedAccount.application_id} onClick={() => this.setSelectedAccount(item)} />
                                                </div>
                                                <div className="col-9">
                                                    <div className="row">
                                                        <div className="col-12 col-md-4 pt-2 pb-1 border-right">
                                                            <span className="d-inline d-md-none d-lg-none">Date : </span>
                                                            {Moment(item.date).format("MM/DD/YYYY")}
                                                        </div>
                                                        <div className="col-12 col-md-4 pt-2 pb-1 border-right">
                                                            <span className="d-inline d-md-none d-lg-none">Application ID : </span>
                                                            {item.application_id}
                                                        </div>
                                                        <div className="col-12 col-md-4 pt-2 pb-1">
                                                            <span className="d-inline d-md-none d-lg-none">Application Type : </span>
                                                            {item.appType === "instore application" ? item.store_code : item.appType}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    </div>
                                    {/* <button className="btn bg-secondary text-white mt-3 float-right" onClick={()=>{this.handleAcceptOffer()}} disabled={this.state.selectedAccount === ""}>Continue</button> */}
                                    <button className="btn bg-secondary text-white mt-3 float-right" onClick={()=>this.getOfferStatusOrFaxbackInfo()} disabled={this.state.selectedAccount === ""}>Continue</button>
                                </div>
                        </div>
                        </Card.Body>
                    </Card>
                </div>:
                // <div className="col-6">
                //     <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                    
                //         <Card.Body>
                //         <div className="row">
                //             <div className="col-12">
                //                 <Card className="h-100">
                //                     <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center card-body">
                //                     <p className="h5 my-auto text-secondary"><b>DANIEL'S CREDIT</b></p>
                //                     <div className="my-auto text-danger">
                //                         <p className="mb-0"><b>Application has not been submitted to Daniel's Credit.</b></p>
                //                     </div>
                //                     </Card.Body>
                //                 </Card>
                //             </div>
                //         </div>
                //         </Card.Body>
                //     </Card>
                // </div>
                // (this.state.isCSSaccountCreated) ?
                // <div className="col-12 col-lg-8">
                //             <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                //                 <Card.Body>
                //                 <div className="row">
                //                     <div className="col-12 col-md-6 col-lg-6 pr-md-0">
                //                     <Card className="h-100">
                //                         <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center">
                //                             <div className="mb-4"><p className="h5 my-auto mb-4"><b>DANIEL'S CREDIT</b></p></div>
                //                             <div className="my-auto mb-4 text-secondary">
                //                             <p className="h5 my-auto mb-4">Congratulations! Please see a sales associate for further assistance.</p>
                //                             </div>
                //                             {/* <div className="my-auto mt-2 text-secondary" >
                //                                 <p className="mb-0 mt-4"> Credit Limit: ${fortivaOffer.approvalLimit.toLocaleString("en-US")}</p>
                //                                 <p className="mb-0">Required Down Payment: {fortivaOffer.downPaymentPct}%</p>
                //                                 {(fortivaOffer.introDuration!=="0" && fortivaOffer.introDuration!=null)?
                //                                 <p className="mb-0">No Interest, Promotional Period:  {fortivaOffer.introDuration} months</p>:""}
                //                             </div> */}
                //                         </Card.Body>
                //                     </Card>
                //                     </div>
                //                     <div className="col-12 col-md-6 col-lg-6 pl-lg-4 pt-4 pt-md-0 pt-lg-0 d-flex flex-column">
                //                         <div className="mt-auto">
                //                             <p className="mb-0">Application Id : {this.state.danielAppId}</p>
                //                             <p className="mb-0">Application Date :{ createdDate}</p>
                //                         </div>
                //                     </div>
                //                 </div>
                //                 </Card.Body>
                //             </Card>
                            
                //         </div>:
                ""
            )
        }
    }
    handleAcceptOffer=()=>{
        this.setState({isDanielsOffer:!this.state.isDanielsOffer});
    }

    async getOfferStatusOrFaxbackInfo() {
        this.setState({OfferStatusOrFaxbackInfo : false});
        let customerId = localStorage.getItem("customerId");
        let appId = this.state.selectedAccount?.application_id;
        let result = undefined;
        await trackPromise(
            this.props.actions.getOfferStatusOrFaxbackInfo(customerId, appId).then(res => {
                if(res) {
                    result = res;
                }
            })
        )
        if(result === undefined) {
            this.setState({OfferStatusOrFaxbackInfo : true });
        }
        else {
            //For testing approved application
            // result.cD_STATUS = "15";
            // result.cD_CREDIT_RATING = "V";
            this.setState({creditDirectorResponse : result, OfferStatusOrFaxbackInfo : true });
        }
    }

    onContinueOffer= async()=>{
        this.setState({OfferStatusOrFaxbackInfo : false});
        // let obj=await this.getRatingCode();
        // if(obj){
        //     if(obj.isMatchFound){
        //         if((obj.ratingCode?.toLowerCase()=="z" || obj.ratingCode=="null" || obj.ratingCode=="" )){
        //             this.setState({isMatch:true,isDanielsOffer:false});
        //         }
        //         else{
                    this.setState({isContinue:!this.state.isContinue,isDanielsOffer:false});
        //         }
        //     }
        //     else
                // this.setState({isMatch:true,isDanielsOffer:false});
        // }
    }
    getRatingCode=async()=>{
        this.setState({isDanielsOffer:false});
        let applicationId=this.state.selectedAccount.application_id;
        if((applicationId !== "null" && applicationId !== "0" && applicationId !== "")){
            let response=await trackPromise(this.props.actions.GetRating(GetInfo.getCustomerId(),applicationId));
            if (response) {
                if (response.rating_Code) {
                    let saveRatingCode={
                        ratingCode:response.rating_Code,
                        approvalCode:this.state.approvalId,
                        customerId:GetInfo.getCustomerId(),
                        applicationid:applicationId,
                        store_id: JSON.parse(localStorage.getItem("associateStores"))
                    }
                    await trackPromise(this.props.actions.saveApprovalAndRatingCode(saveRatingCode));
                    this.setState({ratingCode:response.rating_Code, isMatchFound:true,approvalId:""});
                    return {ratingCode:response.rating_Code,isMatchFound:true }
                }
                else {
                    this.setState({isMatchFound:false,approvalId:"",ratingCode:""});
                    return {ratingCode:"",isMatchFound:false }
                }
            }
            else {
                this.setState({isMatchFound:false,approvalId:"",ratingCode:""});
                return {ratingCode:"",isMatchFound:false }
            }
        }
    }
    onCancelOffer = ()=>{
        this.setState({isDanielsOffer:false,approvalId:""});
    }
    onBillingcycle=()=>{
        //let createdDate=sessionStorage.getItem("createdDate");
        let createdDate=Moment(new Date()).format('YYYY-MM-DD h:mm:ss');
        let cycleObj={
            createdDate: this.state.selectedAccount?.date,
            application_id: this.state.selectedAccount?.application_id
        }
        this.setState({isContinue:!this.state.isContinue});
        trackPromise(
        this.props.billingCycleActions.getBillingCycle(cycleObj)
        .then(resp=>{
             this.setState({billingCycleId:resp.cycle,isBillingCycle:!this.state.isBillingCycle});
        }).catch(err=>{
            APIFailedMessage.displayError();
        })
        )
    }

    postOfferAcceptedStatus() {
        let obj = {
            "cusID" : localStorage.getItem("customerId"),
            "appId" : this.state.selectedAccount?.application_id,
            "status" : true
        }
        this.props.actions.postDanielsAcceptedStatus(obj).then(res => {
        }).catch(err => {
            APIFailedMessage.displayError(err);
        })
    }

    onAccept=()=>{
        this.postOfferAcceptedStatus();
        if(this.props.isDanielsInsuranceEnable && sessionStorage.getItem("havingDanielInsurance") == "true" && this.state.selectedAccount?.appType !== "Shopify-Cart"){
            this.setState({insuranceDaniels:!this.state.insuranceDaniels,isBillingCycle:!this.state.isBillingCycle,insurance:!this.state.insurance});
        }
        else{
            this.setState({isBillingCycle:!this.state.isBillingCycle});
            if(this.state.selectedAccount.danielAccNo == "" || this.state.selectedAccount.danielAccNo == null) {
                this.createCSSAccount();
            }
        }
    }
    createCSSAccount() {
        let obj = {
          storeCode: JSON.parse(localStorage.getItem("associateStores")),
          customerId: localStorage.getItem("customerId"),
          applicationId: this.state.selectedAccount?.application_id
        };
        trackPromise(
        this.props.Insuranceactions.createDanielsAccountNumber(obj)
          .then(async resp => {
            if (resp) {
              this.setState({ danielsAcctNumber: resp });
              let cssObj = {
                "customerId": JSON.parse(localStorage.getItem("customerId")),
                "application_id": this.state.selectedAccount?.application_id,
                // "danielAccountNo": resp,
                // "credit_director": false,
                // "isInsurance":false
              };
              let creditData=await trackPromise(this.props.Insuranceactions.getCreditDirectorData(cssObj));
             if(creditData){
                    let creditSaveObj={
                     "crediT_LIMIT": creditData.cD_CREDIT_LIMIT,
                    "experiaN_SCORE": creditData.experiaN_SCORE == undefined ? "" : creditData.experiaN_SCORE,//
                    "tranS_UNION_SCORE": creditData.cD_TRANS_UNION_SCORE,
                    "equifaX_SCORE": creditData.cD_EQUIFAX_SCORE,
                    "tranS_UNION_SCORE_1": creditData.cD_TRANS_UNION_SCORE_2,
                    "id": creditData.cD_CD_APP_ID+"",
                    "crediT_BUREAU_USED": creditData.cD_CREDIT_BUREAU_USED,
                    "totaL_RATIO": creditData.totaL_RATIO == undefined ? "" : creditData.totaL_RATIO,//
                    "trades": creditData.trades == undefined ? "" : creditData.trades,//
                    "neT_INCOME": creditData.neT_INCOME == undefined ? "" : creditData.experneT_INCOMEiaN_SCORE,//
                    "generiC_STRING_FIELD_8": creditData.generiC_STRING_FIELD_8 == undefined ? "" : creditData.generiC_STRING_FIELD_8,//
                    "applicatioN_TYPE": creditData.cD_SOURCE,
                    "totaL_SCORE": creditData.totaL_SCORE == undefined ? "" : creditData.totaL_SCORE,//
                    "applicatioN_SCORE": creditData.applicatioN_SCORE == undefined ? "" : creditData.applicatioN_SCORE,//
                    "reV_RATIO": creditData.reV_RATIO == undefined ? "" : creditData.reV_RATIO,//
                    "tradeS_DEROGS": creditData.tradeS_DEROGS == undefined ? "" : creditData.tradeS_DEROGS,//
                    "in_File_YYMM": creditData.in_File_YYMM == undefined ? "" : creditData.in_File_YYMM,//
                    "experiaN_SCORE_1": creditData.cD_EXPERIAN_SCORE,
                    "experiaN_SCORE_2": creditData.cD_EXPERIAN_SCORE_2,
                    "tranS_UNION_SCORE_2":  creditData.cD_TRANS_UNION_SCORE_2,
                    "equifaX_SCORE_1": creditData.cD_EQUIFAX_SCORE,
                    "equifaX_SCORE_2": creditData.cD_EQUIFAX_SCORE_2,
                    "crediT_RATING": creditData.cD_CREDIT_RATING,
                    "ratinG_CODE": creditData.cD_RATING_CODE,
                    //"lasT_BUREAU_DATE": creditData.cD_CREDIT_BUREAU_USED,
                    "maintaineD_DATE_TIME": (creditData.cD_MAINTAINED_DATE_TIME!= null && creditData.cD_MAINTAINED_DATE_TIME!="") ? moment(creditData.cD_MAINTAINED_DATE_TIME) : "",
                    "client_acnt_number2": creditData.cD_ACCOUNT_NUMBER,
                    "fnd_rcpt_id": creditData.fnd_rcpt_id == undefined ? "" : creditData.fnd_rcpt_id,//
                    application_Id:this.state.selectedAccount.application_id,
                    customer_Id:JSON.parse(localStorage.getItem("customerId"))
                  }
                  cssObj = {
                    "customerId": JSON.parse(localStorage.getItem("customerId")),
                    "application_id": this.state.selectedAccount.application_id,
                    "danielAccountNo": resp,
                    "credit_director": true,
                    "isInsurance":false
                  };
                  await this.props.Insuranceactions.CreditDirectorSaveData(creditSaveObj)
                  .then(async resp => {
                    if (resp==true) {
                        let response=await trackPromise(this.props.Insuranceactions.createCSSAccount(cssObj));
                        if (response.success == "true") {
                            
                            // this.setState({isCSSaccountCreated:!this.state.isCSSaccountCreated});
                        }
                        this.loadDanielsOfferGrid();
                    }
                  });
                }
            }
          }).catch((err) => {
            APIFailedMessage.displayError(err);
          })
          )
      }
    onAcceptCancel = ()=>{
        this.setState({isContinue:false});
    }
   
    onMatch=()=>{
        this.setState({isMatch:false});
    }
    onTryAgain=()=>{
        this.setState({isDanielsOffer:!this.state.isDanielsOffer,isMatch:false});
    }
    handleMatchnotFound=()=>{
        this.setState({isMatch:!this.state.isMatch});
    }
    onChange(event){
        //    let reg=/^[0-9]+$/;
        //    if(reg.test(event.target.value)){
                this.setState({approvalId:event.target.value})
            //}
    }
    triggerInsurance = (status) => {
        if(status=="Declined"){
            // this.setState({isCSSaccountCreated:!this.state.isCSSaccountCreated});
        }
        else if(status=="Completed"){
            // this.setState({insurance : !this.state.insurance,isCSSaccountCreated:!this.state.isCSSaccountCreated});
            this.setState({insurance : !this.state.insurance, insuranceDaniels : false});
        }
        else{
            this.setState({insurance : !this.state.insurance, insuranceDaniels : false});
        }
        this.loadDanielsOfferGrid();
    }
  
    render(){
        let message="";
        if(this.state.isMatchFound){
            if(this.state.ratingCode?.toLowerCase()=="z")
            message="Please contact the Credit Department regarding this application.";
            else if(this.state.ratingCode=="null" || this.state.ratingCode=="")
                message="This application is under review. Try again once the approval is sent via the roll printer.";
        }
        else 
            message="Approval number not found, please enter in your approval number again.";

        let result = this.state.danielsOffersList?.find((item) => item.danieloffer_Accepted == true);
        let isDanielAccountCreated = false
        if(result != undefined) {
            isDanielAccountCreated = true;
        }

        return(
            <>
            {/* <IdleTimeModal /> */}
            <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
                {/* <Sidebar
                toggled={this.state.toggled}
                handleToggleSidebar={() => {this.handleToggleSidebar()}}
                /> */}
                <main>
                    {/* <CustomNavbar handleToggleSidebar={() => {this.handleToggleSidebar()}}/> */}
                        <div id="main" className="container-fluid">
                            <div className="row mb-3">
                                <div className="col-12 p-0">
                                    {this.loadDanielsCredit()}
                                </div>
                            </div>
                    </div>
                </main>
                <Modal show={this.state.isDanielsOffer}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Daniel's Offer Acceptance</b><FontAwesomeIcon icon={faClose} className="btn" onClick={() => this.onCancelOffer()} /></Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="row">
                                <p className="ml-3 w-100"><b>Customer :</b> {this.state.selectedAccount?.applicant_name}</p>
                                <p className="ml-3 w-100"><b>Application ID : </b>{this.state.selectedAccount?.application_id}</p>
                            <div className="col-12 col-md-5 col-lg-5 font-weight-bold pt-2 pr-0">
                                <label>Enter Application ID :</label>
                            </div>
                            <div className="col-12 col-md-7 col-lg-7 pl-lg-0">
                                <input
                                type="number"
                                className="form-control border-dark "
                                name="approvalId"
                                id="approvalId"
                                placeholder="Approval ID"
                                value={this.state.approvalId}
                                onChange={(event) => this.onChange(event)}
                                ></input>
                            </div>
                        </div>
                    </Modal.Body> 
                    <Modal.Footer className="border-top-0">
                    <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onContinueOffer()}} disabled={this.state.approvalId?false:true}>Continue</button>
                        <p className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}}onClick={()=>this.onCancelOffer()}>Cancel</p>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isContinue}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Daniel's Offer Acceptance</b>
                    {/* <FontAwesomeIcon icon={faClose} className="btn" onClick={() => this.onAcceptCancel()} /> */}
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                            <div className="row">
                                <div className="col-12 pt-2">
                                    <p>Congratulations!  Your customer is approved for Daniel’s Credit. Click “Continue” to create a customer account.</p>
                                </div>
                            </div>
                    </Modal.Body> 
                    <Modal.Footer className="border-top-0 mt-1">
                    <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onBillingcycle()}}>Continue</button>
                        <p className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}}onClick={()=>this.onAcceptCancel()}>Cancel</p>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isBillingCycle}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Billing Cycle</b></Modal.Header>
                    <Modal.Body className="pb-0">
                            <div className="row">
                                <div className="col-12 pt-2">
                                    <p>Billing Cycle <b>{this.state.billingCycleId}</b> has been assigned to this customer account.</p>
                                </div>
                            </div>
                    </Modal.Body> 
                    <Modal.Footer className="border-top-0 mt-1">
                        <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onAccept()}}>OK</button>
                    </Modal.Footer>
                </Modal>
                {/* Back up modal */}
                <Modal show={this.state.isAccept}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Daniel's Offer Acceptance</b><FontAwesomeIcon icon={faClose} className="btn" onClick={() => this.onContinueCancel()} /></Modal.Header>
                    <Modal.Body className="pb-0">
                            <div className="row">
                                <div className="col-12 font-weight-bold pt-2">
                                    <p>Congratulations! you have been approved for Daniel's Credit! Would you like to accept the offer and create a customer account?</p>
                                </div>
                            </div>
                    </Modal.Body> 
                    <Modal.Footer className="border-top-0 mt-1">
                    <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onContinueOffer()}}>Accept</button>
                        <p className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}}onClick={()=>this.onContinueCancel()}>Cancel</p>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isMatch}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Daniel's Offer Acceptance</b><FontAwesomeIcon icon={faClose} className="btn" 
                    onClick={() => ((this.state.ratingCode?.toLowerCase()=="z" || this.state.ratingCode=="null" || this.state.ratingCode=="") && this.state.isMatchFound)?this.onMatch():this.handleMatchnotFound()} /></Modal.Header>
                    <Modal.Body className="pb-0">
                            <div className="row">
                                <div className="col-12 pt-2">
                                    <p>{message}</p>
                                </div>
                            </div>
                    </Modal.Body> 
                    <Modal.Footer className="border-top-0 mt-1">
                     {((this.state.ratingCode?.toLowerCase()=="z" || this.state.ratingCode=="null" || this.state.ratingCode=="") && this.state.isMatchFound)?
                      <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onMatch()}}>OK</button>:
                     <>
                       <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onTryAgain()}}>Try Again</button>
                       <p className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}}onClick={()=>this.handleMatchnotFound()}>Cancel</p>
                       </>
                     }
                    </Modal.Footer>
                </Modal>
                {this.state.insuranceDaniels ? 
                        <Insurance 
                            from="offers" 
                            insurance={this.state.insurance}
                            triggerInsurance={this.triggerInsurance}
                            partnerType = "Daniels"
                            offerAcceptedState={sessionStorage.getItem("AssociateState")}
                            selectedAppId = {this.state.selectedAccount?.application_id}
                            isDanielAccountCreated = {isDanielAccountCreated}
                            
                        />
                    : null}

                <Modal show={this.state.OfferStatusOrFaxbackInfo}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Daniel's Offer</b><FontAwesomeIcon icon={faClose} className="btn" onClick={() => this.setState({OfferStatusOrFaxbackInfo : false})} /></Modal.Header>
                    <Modal.Body className="pb-0">
                            <div className="row">
                                <div className="col-12 pt-2">
                                    {this.state.creditDirectorResponse == [] ? "No record found" :
                                        <p>{!this.state.selectedAccount?.danieloffer_Accepted ? this.state.creditDirectorResponse?.cD_FAXBACK : (this.state.creditDirectorResponse?.cD_FAXBACK != "" && this.state.creditDirectorResponse?.cD_FAXBACK != " ") ? this.state.creditDirectorResponse?.cD_FAXBACK : "The offer has been accepted."}</p>
                                    }
                                </div>
                            </div>
                    </Modal.Body> 
                    <Modal.Footer className="border-top-0 mt-1">
                    {this.state.selectedAccount.danieloffer_Accepted ? null :
                    <>
                        {/* <button className="btn ml-1 mr-1 mb-0 reStay bg-primary text-secondary border"  onClick={()=>{this.getOfferStatusOrFaxbackInfo()}}>Refresh</button> */}
                        <button className="btn ml-1 mr-1 mb-0 reStay bg-primary text-secondary border"  onClick={() => this.setState({OfferStatusOrFaxbackInfo : false})}>Refresh</button>
                        {(this.state.ApprovedcreditRating.includes(this.state.creditDirectorResponse?.cD_STATUS) && (this.state.creditDirectorResponse?.cD_CREDIT_RATING != 'H' && this.state.creditDirectorResponse?.cD_CREDIT_RATING != '_')) &&
                        <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary border"  onClick={() => this.onContinueOffer()}>Accept</button>
                        }
                    </>
                    }
                    </Modal.Footer>
                </Modal>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    
    return {
        data: state.fortiva.fortivaObj
    };
  };

const mapDispatchToProps = (dispatch) => {
    return {
        actions :bindActionCreators(viewOfferActions,dispatch),
        Insuranceactions: bindActionCreators(InsuranceActions, dispatch),
        billingCycleActions: bindActionCreators(billingCycleActions, dispatch),
        }
}
export default connect(mapStateToProps, mapDispatchToProps)(DanielsCreditOffers);
