import React, { Component } from "react";
import IdleTimeModal from "../../components/component.idleTimeModal";
import Sidebar from "../../components/component.customSideBar";
import CustomNavbar from "../../components/component.customNavbar";
import { Card, Modal } from "react-bootstrap";
import Moment from 'moment';
import TitleCard from "../../components/component.titleCard";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as accountSummaryAction from "../../redux/actions/accountSummary.actions";
import { trackPromise } from 'react-promise-tracker';
import APIFailedMessage from '../../components/component.apiFailedMessage';
import TenderModal from '../../components/component.tenderModal';
import FortivaErrorDialog from "../../components/component.fortivaErrorDialog";
import GetInfo from "../../components/component.getInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Insurance from "../insurance/component.insurance";
import * as adminActions from '../../redux/actions/admin.actions';
import * as insuranceActions from "../../redux/actions/insurance.actions";
import {ClaimAndCancel} from "../../components/component.insuranceClaimAndCancel";
import * as customerLooUpAction from "../../redux/actions/customerLookup.actions";
import { toast } from "react-toastify";
import * as userRightsaction from "../../redux/actions/adminUserRights.actions";
import moment from "moment";

class FortivaSummary extends Component {

    constructor() {
        super();
        this.state = {
            toggled: false,
            summaryDetails: {
                accountId: null,
                openToBuy: 0,
                transactionOutcome: "",
                transactionResult: "",
                sorPortfolioId: null,
                goodStandingInd: null,
                firstName: null,
                lastName: null,
                ssn: null,
                accountNumber: null,
                addressLine1: null,
                addressLine2: null,
                city: null,
                state: null,
                zipFive: null,
                birthday: null,
                homePhoneNumber: null,
                lastPaymentAmount: null,
                paymentDueAmount: null,
                totalAmountDue: null,
                emailAddress: null,
                applicationId: null,
                customerServiceNumber: null,
                merchantServiceNumber: null,
                applicationUID: null,
                expirationDate: null,
                webSiteURL: null,
                language: "en",
                referenceNumber: "",
                leadGenLocationId: "",
                leadGenCompanyId: "",
                leadGenName: "",
                lineOfBusiness: "",
                offerId: null,
                apr: 0,
                aprString: "",
                periodicRate: null,
                creditLimit: 0,
                approvedAmountString: "",
                annualFee: null,
                latePaymentFee: null,
                returnedPaymentFee: null,
                minPaymentAmt: 0,
                minPaymentAmountString: "",
                minPaymentPct: null,
                duration: null,
                manualUnderwriting: null,
                offerUID: null,
                introDuration: 0,
                transactionFee: 0,
                color: null,
                budgetEquityPay: null,
                penaltyAPR: 0,
                minFirstPurchaseAmt: null,
                documentCode_4: null,
                documentsImageHtml_4: null,
                documentCode_8: null,
                documentsImageHtml_8: null,
                documentCode_7: null,
                documentsImageHtml_7: null,
                documentCode_9: null,
                documentsImageHtml_9: null,
                documentCode_6: null,
                documentsImageHtml_6: null,
                documentOfferId: 0,
                error: null,
                errorMessage: null,
                offerAcceptDate: "",
                accountBalance: 0,
                lastPaymentDate: ""
            },
            showTender: false,
            currentTender: "",
            isSummary: false,
            isInsuranceEnabled: false,
            isFortivaFileAClaim:false,
            isFortivaCancelEnrollment:false,
            optForInsurance: "",
            insurance: false,
            showCancelAndClaimPopup : false,
            claimOrCancelContent : "",
            reason : "",
            permission_status : null,
            offerAcceptedState : "",
            alredayInsuranceEnrolled : false,
            offerAcceptedStateHavingInsurnace : false,
            insuranceCancelPopUp: false,
        }
    }
    handleToggleSidebar() {
        this.setState({
            toggled: !this.state.toggled
        });
    };

    componentDidMount() {
        if (JSON.parse(sessionStorage.getItem('isLoggedIn'))) {
            let fortivaAppId = localStorage.getItem("fortivaAppId");
            if (fortivaAppId != "null" && fortivaAppId != "undefined" && fortivaAppId !== "") {
                trackPromise(
                    this.props.actions.getFortivaActSummmary(GetInfo.getCustomerId(), fortivaAppId).then(
                        response => {
                            this.setState({ summaryDetails: response });
                            this.getState(response?.store_Id);
                        }
                    ).catch(err => {
                        APIFailedMessage.displayError(err);
                    })
                )
            }
            else {
                // this.setState({ isSummary: !this.state.isSummary });
            }
            trackPromise(
                this.props.adminActions?.getSignOutTime().then(response => {
                    if (response) {
                        this.setState({ isInsuranceEnable: response.isInsurance_FortivaEnabled,isFortivaCancelEnrollment:response.isFortivaCancelEnrollment,
                            isFortivaFileAClaim:response.isFortivaFileAClaim });
                    }
                    if (response?.isInsurance_FortivaEnabled === true) {
                        this.triggerInsurance("Completed");
                    }
                })
            )
            // trackPromise(
            //     this.props.userRightsactions.getUserRights()
            //       .then((response) => {
            //         if (response) {
            //           let role_name = localStorage.getItem("role_name"),
            //             page_name = "Insurance Cancellation Access";
            //           const getsitepages = response.sitePages.filter((site_pages) => {
            //             return site_pages.name === "Portal Page";
            //           });
            //           const getSitepagedetails = response.sitePageDetails.filter(
            //             (site_page_details) => {
            //               return (
            //                 site_page_details.sitePage_id === getsitepages[0].id &&
            //                 site_page_details.name === page_name
            //               );
            //             }
            //           );
            //           const getRole = response.roles.filter((roles) => {
            //             return roles.name.toLowerCase() === role_name.toLowerCase();
            //           });
            //           let role_id = getRole[0].id;
            //           const role_permission_check = response.userRights.filter(
            //             (user_rights) => {
            //               return (
            //                 user_rights.sitePageDetail_id === getSitepagedetails[0].id &&
            //                 user_rights.role_id === role_id
            //               );
            //             }
            //           );
            //           this.setState({permission_status :  role_permission_check[0]});
            //         }
            //       })
            //       .catch((err) => {
            //         APIFailedMessage.displayError(err);
            //       })
            //   );           
        }
        else
            this.props.history.push("/");
    }

    getState(store_Id) {
        var storeList = JSON.parse(sessionStorage.getItem("storeList"));
        let offerAcceptedState = "";
        storeList = storeList?.find((item) => {
            return item.code == store_Id
        });
        offerAcceptedState = storeList?.address2?.split(",")[1]?.trim()
        this.setState({offerAcceptedState : offerAcceptedState});
        trackPromise(
        this.props.insuranceAction.getInsurancePlans().then((res) => {
            if(res) {
                let havingPlan = res.getInsuranceResponses?.find(item => {
                    return item.insurance_partner === "Fortiva" && item.isActive === true && item.state == offerAcceptedState;
                });
                if(havingPlan !== undefined)
                  this.setState({offerAcceptedStateHavingInsurnace : true});
                else this.setState({offerAcceptedStateHavingInsurnace : false});
            }
        }).catch((err) => {
            APIFailedMessage.displayError(err);
        })
        )
    }

    triggerTender = (curTen) => {
        this.setState({ showTender: !this.state.showTender, currentTender: curTen });
    }

    triggerInsurance = (type) => {
        if (type === "Completed") {
            let obj = {
                cust_Id: localStorage.getItem("customerId"),
                partner_type: "Fortiva"
            }
            trackPromise(
                this.props.insuranceAction.getInsurancePlanValue(obj).then(res => {
                    if (res) {
                        if (res.getInsuranceplan_values?.length === 0) {
                            this.setState({optForInsurance: "Enroll" });
                        }
                        else {
                            let acceptStatus = res.getInsuranceplan_values?.filter(item => {
                                return item.isInsurance_Status?.toLowerCase() === "accept"
                            })
                            let claimStatus = res.getInsuranceplan_values?.filter(item => {
                                return item.isInsurance_Status?.toLowerCase() === "claimreqraised" && item.plan_type !== "None selected"
                            })
                            let cancelStatus = res.getInsuranceplan_values?.filter(item => {
                                return item.isInsurance_Status?.toLowerCase() === "cancelreqraised" && item.plan_type !== "None selected"
                            })
                            let notEnrollStatus = res.getInsuranceplan_values?.filter(item => {
                                return item.isInsurance_Status?.toLowerCase() === "cancelreqfinalized" && item.plan_type !== "None selected"
                            })
                            if (acceptStatus !== undefined && acceptStatus?.length !== 0) {
                                this.setState({ planValue: acceptStatus, optForInsurance: "Enrolled", alredayInsuranceEnrolled : true });
                            }
                            else if (claimStatus !== undefined && claimStatus?.length !== 0) {
                                this.setState({  planValue: claimStatus, optForInsurance: "claimReqRaised", alredayInsuranceEnrolled : true });
                            }
                            else if (cancelStatus !== undefined && cancelStatus?.length !== 0) {
                                this.setState({  planValue: cancelStatus, optForInsurance: "cancelReqRaised", alredayInsuranceEnrolled : true });
                            }
                            else if (notEnrollStatus !== undefined && notEnrollStatus?.length !== 0) {
                                this.setState({  optForInsurance: "Not Enrolled", alredayInsuranceEnrolled : false });
                            }
                            else {
                                this.setState({ optForInsurance: "Declined", alredayInsuranceEnrolled : false });
                            }
                        }
                    }
                }).catch((err) => {
                    APIFailedMessage.displayError(err);
                })
            );
            this.setState({ insurance: false });
        }
        else
            this.setState({ insurance: !this.state.insurance });
    }
    
    handleClaimAndCancel =(type) => {
        if(type === "claimReqRaised") {
            this.setState({claimOrCancelContent : "claimReqRaised"});
        }
        else if(type === "cancelReqRaised") {
            this.setState({claimOrCancelContent : "cancelReqRaised"});
        }
        else if(type==="actionConfirm" && this.state.claimOrCancelContent==="cancelReqRaised") {
            this.fileAClaimOrCancelEnroll();
        }
        else if(type==="actionConfirm" && this.state.claimOrCancelContent==="claimReqRaised" && this.state.reason !== "") {
            this.fileAClaimOrCancelEnroll();
        }
        else if(type === "revert") {
            this.setState({reason : ""});
        }
        this.setState({showCancelAndClaimPopup : !this.state.showCancelAndClaimPopup});
    }

    onChange=(e)=> {
        this.setState({reason : e.target.value});
    }

    async fileAClaimOrCancelEnroll(){
        let insuranceStatus = this.state.claimOrCancelContent;
        let reason = this.state.reason?.split("\n")?.map(val => {
            return (val +
                '<br>'
            );
        });
        reason = String(reason)?.replace(/,/g, '');
        let date = new Date();
        date = date.toLocaleString("en-US", {timeZone: "America/Los_Angeles"});
        let resStatus = null;
        let obj = {
            "credit_id": sessionStorage.getItem("credit_Id"),
            "application_id": localStorage.getItem("applicationId"),
            "isInsurance_Status": insuranceStatus,
            "state": sessionStorage.getItem("AssociateState"),
            "plan_type": this.state.planValue?.plan_type,
            "plan_value": this.state.planValue?.plan_value,
            "createdBy": "",
            "modifiedBy": localStorage.getItem("loggedUserEmail"),
            "insurance_life": "",
            "insurance_unemployment": "",
            "insurance_disability": "",
            "insurance_property": "",
            "financial_partner": "Fortiva",
            "account_number": this.state.summaryDetails?.accountNumber,
            "customer_id": localStorage.getItem("customerId"),
            "finalize_cancellationDate": insuranceStatus === "cancelReqFinalized" ? moment(date).format("MM/DD/YYYY") : "",
        };
        await trackPromise(
            this.props.insuranceAction.enrollInsurace(obj).then((res) => {
                resStatus = res;
                if(res === false)
                    toast.error("Failed to raise a request, please try again");
                else if(insuranceStatus === "cancelReqRaised") {
                    this.setState({insuranceCancelPopUp : true});
                }
            })
        );
        if(resStatus === true && insuranceStatus !== "cancelReqFinalized") {
            let htmlContent = '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '</head>' +
            '<body>' +
            (insuranceStatus==="claimReqRaised" ? '<h2>FORTIVA CREDIT INSURANCE - FILE A CLAIM REQUEST</h2>' : '<h2>FORTIVA CREDIT INSURANCE - CANCEL ENROLLMENT REQUEST</h2>') +
            '<br>' +
            '<table>' +
            '<tr>' +
            '<td>' +
            '<b>Associate Name : </b>' + (localStorage.getItem("loggedUserName")) +
            '<br>' +
            '<b>Apply Store : </b>' + (JSON.parse(localStorage.getItem("associateStores"))) +
            '<br>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<br>' +
            '<table>' +
            '<tr>' +
            '<td>' +
            '<b><u>Customer Info</u></b>' +
            '<br>' +
            '<b>Fortiva Account Number : </b>' + (this.state.summaryDetails?.accountNumber) +
            '<br>' +
            '<b>Customer Name : </b>' + (this.state.summaryDetails?.firstName+" "+this.state.summaryDetails?.lastName) +
            '<br>' +
            '<b>Address : </b>' + (this.state.summaryDetails?.addressLine1+", "+this.state.summaryDetails?.city+", "+this.state.summaryDetails?.state+", "+this.state.summaryDetails?.zipFive) +
            '<br>' +
            '<b>Phone : </b>' + (this.state.summaryDetails?.homePhoneNumber.slice(0,3)+"-"+this.state.summaryDetails?.homePhoneNumber.slice(3,6)+"-"+this.state.summaryDetails?.homePhoneNumber.slice(6)) +
            '<br>' +
            '<b>Email : </b>' + (this.state.summaryDetails?.emailAddress?.toLowerCase()) +
            '<br>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<br>' +
            (insuranceStatus==="claimReqRaised" ? '<p><b>Briefly explain reason for filing a claim</b></p>' : '') +
            (insuranceStatus==="claimReqRaised" ? reason : '') +
            '</body>' +
            '</html>';
            obj = {
                toEmail: this.state.summaryDetails?.emailAddress?.toLowerCase(),
                cc: "",
                subject: "Fortiva Credit Insurance - " + (insuranceStatus==="claimReqRaised" ? "File a claim request" : "Cancel enrollment request"),
                emailContent: " ",                
                attachmentContent: htmlContent,
                is_insurance: insuranceStatus==="claimReqRaised" ? "Claim" : "Cancel",
                financial_partner : "Fortiva",
            }
            await trackPromise(
                this.props.customerLooUpActions.sendApplyEmailLink(obj).then(
                    response => {
                        if (response.requestStatus?.toLowerCase() === "success") {
                            toast.success("Request raised successfully.")
                            this.triggerInsurance("Completed");
                            this.setState({claimOrCancelContent : "", reason : ""});
                        }
                        else {
                            toast.error("Failed to raise a request, please try again")
                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
        }
        else if(resStatus === true && insuranceStatus === "cancelReqFinalized") {
            this.triggerInsurance("Completed");
            toast.success("Cancellation has been done.")
        }
    }

    render() {
        let amt = "$ ";
        let per = "%";
        let summaryDetails = this.state.summaryDetails;
        summaryDetails.downPaymentPct = 0;
        if (this.state.summaryDetails) {
            var Difference_In_Days = 0
            if (this.state.summaryDetails.offerAcceptDate) {
                let offerAcceptDate = Moment(this.state.summaryDetails.offerAcceptDate).format("MM/DD/YYYY")
                let acceptedDate = new Date(offerAcceptDate);
                let today = new Date();
                let Difference_In_Time = Math.abs(today.getTime() - acceptedDate.getTime());
                Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
                //console.log(Difference_In_Days);
            }
            //existing customer(fortiva application is approved and has accept or apply for offer)
            if (summaryDetails.transactionResult?.toLowerCase() === "a" && summaryDetails.color != null && Difference_In_Days > 90 && summaryDetails?.lastPaymentDate !== null) {
                if (summaryDetails.color.toLowerCase() === "tier 1a" || summaryDetails.color.toLowerCase() === "tier 2a" || summaryDetails.color.toLowerCase() === "tier 3a" || summaryDetails.color.toLowerCase() === "tier 4a") {
                    summaryDetails.downPaymentPct = 0;
                }
                if (summaryDetails.color.toLowerCase() === "tier 5a") {
                    summaryDetails.downPaymentPct = 25;
                }
                if (summaryDetails.color.toLowerCase() === "tier 6a") {
                    summaryDetails.downPaymentPct = 40;
                }
            }
            // new customer.
            else if (summaryDetails.color != null) {
                if (summaryDetails.color.toLowerCase() === "tier 1a" || summaryDetails.color.toLowerCase() === "tier 2a" || summaryDetails.color.toLowerCase() === "tier 4a") {
                    summaryDetails.downPaymentPct = 0;
                }
                if (summaryDetails.color.toLowerCase() === "tier 3a") {
                    summaryDetails.downPaymentPct = 10;
                }
                if (summaryDetails.color.toLowerCase() === "tier 5a") {
                    summaryDetails.downPaymentPct = 25;
                }
                if (summaryDetails.color.toLowerCase() === "tier 6a") {
                    summaryDetails.downPaymentPct = 40;
                }
            }
        }
        return (
            <>
                <IdleTimeModal />
                <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
                    <Sidebar
                        toggled={this.state.toggled}
                        handleToggleSidebar={() => this.handleToggleSidebar()}
                    />
                    <main>
                        <CustomNavbar handleToggleSidebar={() => this.handleToggleSidebar()} />
                        <div id="main" className="container-fluid mt-4 pl-4 pr-4">
                            <div className="row mb-3">
                                <TitleCard accType={"Fortiva"} />
                                <div className="col-12 d-flex">
                                    <Card className="text-left mt-3 mb-2 shadow w-100 ">
                                        <Card.Body>
                                            <div>
                                                <div className="row mb-3">
                                                    <div className="col-7">
                                                        <p className="h5 mt-2 ml-2"><b>Customer Info</b></p>
                                                    </div>
                                                    <div className="col-5 text-right">
                                                        {(sessionStorage.getItem("tenderFortivaAccess") == "true") ?
                                                            <button className={"btn " + (sessionStorage.getItem("tenderFortivaEnable") == "true" ? "text-primary bg-secondary" : "text-muted border")}
                                                                onClick={(sessionStorage.getItem("tenderFortivaEnable") == "true" ? () => this.triggerTender("Fortiva") : null)}>
                                                                Tender Fortiva</button>
                                                            : null}
                                                    </div>
                                                </div>
                                                <div className="row border-top ">
                                                    <div className="col-6 col-md-2 mt-3 border-right pl-md-4">
                                                        <p><b>Account Number</b></p>
                                                        <p>{summaryDetails.accountNumber}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3  mt-3 border-right">
                                                        <p><b>Address</b></p>
                                                        <p>{summaryDetails.addressLine1}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3  mt-3 border-right">
                                                        <p><b>City</b></p>
                                                        <p>{summaryDetails.city}</p>
                                                    </div>
                                                    <div className="col-6 col-md-2  mt-3 border-right">
                                                        <p><b>State</b></p>
                                                        <p>{summaryDetails.state}</p>
                                                    </div>
                                                    <div className="col-6 col-md-2  mt-3">
                                                        <p><b>Zip Code</b></p>
                                                        <p>{summaryDetails.zipFive}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-12 d-flex">
                                    <Card className="text-left mt-3 mb-2 shadow w-100 ">
                                        <Card.Body>
                                            <div>
                                                <div className="row">
                                                    <div className="col-md-9 col-12">
                                                        <p className="h5 mt-2 mb-4 ml-2"><b>Transaction Info</b></p>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-6 col-md-2 mt-3 border-right pl-md-4">
                                                        <p><b>Credit Limit</b></p>
                                                        <p>{amt + summaryDetails.creditLimit}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3  mt-3 border-right">
                                                        <p><b>Open to Buy</b></p>
                                                        <p>{amt + (summaryDetails.openToBuy != null ? summaryDetails.openToBuy : 0)}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3  mt-3 border-right">
                                                        <p><b>Down Payment Percentage</b></p>
                                                        <p>{summaryDetails.downPaymentPct + per}</p>
                                                    </div>
                                                    {/* <div className="col-6 col-md-3  mt-3">
                                                    <p><b>Account Balance</b></p>
                                                    <p>{amt+(summaryDetails.accountBalance!=null?summaryDetails.accountBalance:0)}</p>
                                                </div> */}
                                                </div>
                                                <div className="row border-top mt-3">
                                                    <div className="col-md-9 col-12">
                                                        <p className="h5 mt-4 mb-3 ml-2"><b>Account Info</b></p>
                                                    </div>
                                                </div>

                                                <div className="row ">
                                                    <div className="col-6 col-md-2 mt-3 border-right pl-md-4">
                                                        <p><b>Payment Due Amount</b></p>
                                                        <p>{amt + (summaryDetails.paymentDueAmount != null ? summaryDetails.paymentDueAmount : 0)}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3  mt-3 border-right">
                                                        <p><b>Total Amount Due</b></p>
                                                        <p>{amt + (summaryDetails.totalAmountDue != null ? summaryDetails.totalAmountDue : 0)}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3 border-right  mt-3">
                                                        <p><b>Payment Due Date</b></p>
                                                        <p>{summaryDetails.paymentDueDate}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4 mt-3 pl-md-4 text-secondary">
                                                        {summaryDetails.transactionResult?.toLowerCase() === "o" ?
                                                            <p><b>Application with offer found. Go to the view offers page to "Apply for Offer".</b></p> :
                                                            summaryDetails.transactionResult?.toLowerCase() === "cr" ? <p><b>Customer Rejected Offer.</b></p> : ""}
                                                    </div>
                                                </div>
                                                {/* <div className="row mt-2">
                                                <div className="col-12 mt-3 border-right pl-md-4">
                                                    {this.state.summaryDetails.transactionResult.toLocaleLowerCase()==="o"?
                                                    <p><b>Application offer found. Go to the view offers page to "apply for offer"</b></p> :
                                                    this.state.summaryDetails.transactionResult.toLocaleLowerCase()==="cr"?<p><b>Customer Rejected Offer</b></p> :"" }                                                 
                                                </div>
                                                
                                            </div> */}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            {this.state.offerAcceptedStateHavingInsurnace || this.state.alredayInsuranceEnrolled ?
                            <div className="row mb-4">
                                {this.state.isInsuranceEnable && this.state.optForInsurance !== "" && 
                                summaryDetails.transactionResult?.toLowerCase() !== undefined &&
                                summaryDetails.transactionResult !== null && summaryDetails.transactionResult !== "" ?
                                    <div className="col-12">
                                        <Card className="text-left shadow">
                                            <Card.Body>
                                                <div className="d-flex align-items-center">
                                                    <FontAwesomeIcon icon={faShieldAlt} className="text-secondary" size="2x" />
                                                    <p className="h4 mb-0 pl-3 text-secondary">Credit Insurance</p>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12 col-md-8 col-lg-10">
                                                        {this.state.optForInsurance === "Enroll" ?
                                                            <p className="mb-0">Your current credit approval has an option to add <b>Credit Insurance</b> to protect your purchase.</p>
                                                            :
                                                            (this.state.optForInsurance === "Declined" || this.state.optForInsurance === "Not Enrolled") ?
                                                                <>
                                                                    <p className="mb-0">Your current credit approval has an option to add <b>Credit Insurance</b> to protect your purchase.</p>
                                                                    <div className="row ">
                                                                        <div className="col-6 col-md-4 col-lg-3 mt-3 border-right">
                                                                            <p><b>Enrollment Status</b></p>
                                                                            <p>{this.state.optForInsurance}</p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.optForInsurance === "claimReqRaised" ? <p className="mb-0">File a cliam request has been raised.</p> : this.state.optForInsurance === "cancelReqRaised" ? <p className="mb-0">Insurance cancellation request has been raised.</p> : <p className="mb-0">Your current Fortiva offer is protected by Credit Insurance.</p>}
                                                                    <div className="row ">
                                                                        <div className="col-6 col-md-4 col-lg-3 mt-3 border-right">
                                                                            <p><b>Enrollment Status</b></p>
                                                                            <p>Enrolled</p>
                                                                        </div>
                                                                        <div className="col-6 col-md-4 col-lg-3 mt-3 border-right pl-md-4">
                                                                            <p><b>Plan</b></p>
                                                                            {this.state.planValue?.map((item) => {
                                                                                return (
                                                                                    <>
                                                                                        <p>{item.plan_type}</p>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                    {summaryDetails.transactionResult?.toLowerCase() === "o" ? null : summaryDetails.transactionResult?.toLowerCase() === "a" ?
                                                        <div className="col-12 col-md-4 col-lg-2 d-flex flex-column mt-3 mt-md-0">
                                                            {this.state.optForInsurance === "Enroll" || this.state.optForInsurance === "Declined" || this.state.optForInsurance === "Not Enrolled" ?
                                                                (this.state.offerAcceptedStateHavingInsurnace ?
                                                                <button
                                                                    className="btn bg-secondary text-primary w-100 my-auto"
                                                                    onClick={() => this.triggerInsurance()}
                                                                >
                                                                    ENROLL
                                                                </button>
                                                                : null )
                                                            : 
                                                            <>
                                                                {/* {this.state.permission_status.canView === true && this.state.optForInsurance === "cancelReqRaised" ?
                                                                    <button 
                                                                        className="btn bg-secondary text-primary w-100 my-auto"
                                                                        onClick={() => this.setState({claimOrCancelContent : "cancelReqFinalized"},() => {
                                                                            if(this.state.permission_status.canAdd === true || this.state.permission_status.canEdit === true) {
                                                                                this.fileAClaimOrCancelEnroll()
                                                                            }
                                                                        })}
                                                                        disabled={!(this.state.permission_status.canAdd === true || this.state.permission_status.canEdit === true)}
                                                                    >
                                                                        Finalize Cancellation
                                                                    </button>
                                                                : */}
                                                                <>
                                                                {this.state.isFortivaCancelEnrollment?
                                                                    <button 
                                                                        className="btn bg-secondary text-primary w-100 my-auto" 
                                                                        // style={{ backgroundColor: "#E5D7EC" }}
                                                                        onClick={() => this.handleClaimAndCancel("cancelReqRaised")}
                                                                        // disabled={this.state.optForInsurance === "claimReqRaised" || this.state.optForInsurance === "cancelReqRaised"}
                                                                    >
                                                                        Cancel Enrollment
                                                                    </button>
                                                                :   ""}
                                                                {this.state.offerAcceptedStateHavingInsurnace && this.state.alredayInsuranceEnrolled ?
                                                                    (this.state.isFortivaFileAClaim?
                                                                        <>
                                                                        <p></p>
                                                                        <button 
                                                                            className="btn bg-secondary text-primary w-100 my-auto" 
                                                                            onClick={() => this.handleClaimAndCancel("claimReqRaised")}
                                                                            disabled={this.state.optForInsurance === "claimReqRaised" || this.state.optForInsurance === "cancelReqRaised"}
                                                                        >
                                                                            File a claim
                                                                        </button>
                                                                        </> 
                                                                    :   "" ) 
                                                                :   null }
                                                                </>
                                                                {/* } */}
                                                            </>
                                                            }
                                                        </div>
                                                    : null}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    : null}
                            </div>
                            : null }
                        </div>

                        <FortivaErrorDialog
                            show={this.state.isSummary}
                            handleModalPopup={(event) => this.setState({ isSummary: !this.state.isSummary })}
                            message={`<span class=\"mb-0 \">No Account found</span>`}
                            submitFortiva={(event) => this.setState({ isSummary: !this.state.isSummary })}
                        />

                        {this.state.insurance ?
                            <Insurance
                                from="summary"
                                insurance={this.state.insurance}
                                triggerInsurance={this.triggerInsurance}
                                partnerType="Fortiva" 
                                offerAcceptedState={this.state.offerAcceptedState} />
                            : null}

                        <ClaimAndCancel 
                            showCancelAndClaimPopup={this.state.showCancelAndClaimPopup}
                            content={this.state.claimOrCancelContent}
                            handleClaimAndCancel={this.handleClaimAndCancel}
                            reason= {this.state.reason}
                            onChange={this.onChange}
                        />

                        <Modal show={this.state.insuranceCancelPopUp}>
                            <Modal.Header className="align-items-center">
                                <b className="mr-auto text-secondary h4">Info</b><FontAwesomeIcon icon={faTimesCircle} className="ml-auto" style={{cursor : "pointer"}} size="2x" onClick={() => {this.setState({insuranceCancelPopUp : false})}}/>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Your request to cancel credit insurance enrollment has been sent to customer service. The enrollment status will be updated when it is completed.</p>
                                <button className="float-right btn bg-secondary text-primary" onClick={() => {this.setState({insuranceCancelPopUp : false})}}>Ok</button>
                            </Modal.Body>
                        </Modal>

                    </main>
                </div>
                <TenderModal
                    showTender={this.state.showTender}
                    triggerTender={this.triggerTender}
                    currentTender={this.state.currentTender}
                />
            </>
        );

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(accountSummaryAction, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
        insuranceAction: bindActionCreators(insuranceActions, dispatch),
        customerLooUpActions: bindActionCreators(customerLooUpAction, dispatch),
        userRightsactions : bindActionCreators(userRightsaction, dispatch),
    }
}
export default connect(null, mapDispatchToProps)(FortivaSummary);
