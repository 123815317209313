export default class HttpMethods{
    static Get(){
        return "GET";
    }
    static Put(){
        return 'PUT';
    }
    static Post(){
        return 'POST';
    }
    static Delete(){
        return 'DELETE';
    }
}