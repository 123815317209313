import { faAngleLeft, faAngleRight, faClose, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Card, Modal } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import { bindActionCreators } from "redux";
import * as StoreNumberActions from "../../redux/actions/storeNumber.actions";
import danielsLogo from "../../images/DanielBlack.png";
import JSZip from "jszip";
// import * as pdfjsLib from "pdfjs-dist";
// import 'pdfjs-dist/build/pdf.worker';
import pdfToText from "react-pdftotext";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment/moment";
import { IMaskInput } from "react-imask";

class DailyNotes extends Component {

    constructor() {
        super();
        this.state = {
            dailynotepage: false,
            checked: "",
            dailyImg: "",
            imgFileName : "",
            dailyNote: "",
            textFileName : "",
            imgFile: undefined,
            textFile: undefined,
            previewPopup : false,
            cutOffTime : "",
        };
    }

    onChange(event) {
        if (event.target.type == "radio")
            this.setState({ checked: event.target.name });
        else
            this.setState({ dailyNote: event.target.value });
    }

    openCoverPageEdit(type) {
        if(type === "loadData")
            this.loadData();
        else {
            this.setState({
                dailynotepage: false,
                checked: "",
                dailyImg: "",
                imgFileName : "",
                dailyNote: "",
                textFileName : "",
                imgFile: undefined,
                textFile: undefined,
                cutOffTime : "",
            });
        }
        this.setState({ dailynotepage: !this.state.dailynotepage });
    }

    readDataFromTextFile() {
        const file = this.state.textFile;
        const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{FE00}-\u{FE0F}\u{1F000}-\u{1F02F}\u{1F0A0}-\u{1F0FF}\u{1F1E6}-\u{1F1FF}\u{1F201}-\u{1F251}\u{1F004}-\u{1F0CF}]/gu;
        if(file !== undefined) {
            this.setState({textFileName : file.name});
            if(file.type === "text/plain") {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.setState({ dailyNote: e.target.result });
                };
                reader.readAsText(file);
            }
            // else if(file.type === "application/pdf") {
            //     // const reader = new FileReader();
            //     // reader.onload = async (e) => {
            //     //     const arrayBuffer = e.target.result;
            //     //     const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
            //     //     let fullText = '';
            //     //     for (let i = 1; i <= pdf.numPages; i++) {
            //     //         const page = await pdf.getPage(i);
            //     //         const textContent = await page.getTextContent();
            //     //         const textItems = textContent.items.map(item => item.str + "\n").join('')
            //     //         fullText += textItems;
            //     //     }
            //     //     this.setState({dailyNote : fullText});
            //     // };
            //     // reader.readAsArrayBuffer(file);
            //     pdfToText(file)
            //         .then((text) => this.setState({dailyNote : text?.replace(emojiRegex, '')}))
            //         .catch(err => {
            //             APIFailedMessage.displayError(err);
            //         })
            //     }
            // else {
            //     const reader = new FileReader();
            //     reader.onload = async(e) => {
            //         const arrayBuffer = e.target.result;
            //         const zip = await JSZip.loadAsync(arrayBuffer);
            //         const documentXml = await zip.file('word/document.xml').async("string");
            //         const parser = new DOMParser();
            //         const xmlDoc = parser.parseFromString(documentXml, "application/xml");
            //         const paragraphs = xmlDoc.getElementsByTagName("w:t");
            //         let docText = "";
            //         //Regular expression to match emoji characters
            //         for (let i = 0; i < paragraphs.length; i++) {
            //             docText += paragraphs[i].textContent?.replace(emojiRegex, '') + "\n";
            //             // docText += paragraphs[i].textContent + "\n";
            //         }
            //         this.setState({dailyNote : docText});
            //     }
            //     reader.readAsArrayBuffer(file);
            // }
        }        
    }

    loadImg(imgFileName) {
        trackPromise(
            this.props.action.getImageFileFromBlob(imgFileName).then(res => {
                if(res.blobUrl !== undefined) {
                    this.setState({dailyImg : res.blobUrl});
                }
            })
            .catch(err => {
                APIFailedMessage.displayError(err);
            })
        )
    }

    loadData() {
        trackPromise(
            this.props.action.getText_Img().then(res => {
                if(res) {
                    res.dailyNote = res.dailyNote?.replaceAll("*^*","'");
                    if(res.cutOffTime!== "" && res.cutOffTime != null && res.cutOffTime != "null") {
                        this.setState({cutOffTime : new Date(res.cutOffTime)});
                    }
                    if(res.isImage && res.isText) {
                        this.setState({checked : "imageAndText", dailyNote : res.dailyNote, imgFileName : res.imgFileName});
                        this.loadImg(res.imgFileName);
                    }
                    else if(res.isText) {
                        this.setState({checked : "onlyText", dailyNote : res.dailyNote, imgFileName : res.imgFileName});
                    }
                    else if(res.isImage) {
                        this.setState({checked : "onlyImage", imgFileName : res.imgFileName});
                        this.loadImg(res.imgFileName);
                    }
                }
            })
            .catch(err => {
                APIFailedMessage.displayError(err);
            })
        );
    }


    readDataFromImgFile() {
        const file = this.state.imgFile;
        if(file !== undefined) {
            this.setState({imgFileName : file.name});
            const reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ dailyImg: e.target.result });
            };
            reader.readAsDataURL(file);
        }
    }

    handleImgTextDrop(ev) {
        ev.preventDefault();
        if (ev.dataTransfer.items) {
            [...ev.dataTransfer.items].forEach((item, i) => {
                if (item.kind === "file" && (item.type === "text/plain")) {
                    const file = item.getAsFile();
                    this.setState({ textFile: file }, () => {
                        this.readDataFromTextFile();
                    });
                }
                else {
                    toast.error("Please select a .txt file only.")
                }
            });
        } else {
            [...ev.dataTransfer.files].forEach((file, i) => {
                if(file.type === "text/plain") {
                    this.setState({ textFile: file }, () => {
                        this.readDataFromTextFile();
                    });
                }
                else {
                    toast.error("Please select a .txt file only.")
                }
            });
        }
    }

    handleImgDrop(ev) {
        ev.preventDefault();
        if (ev.dataTransfer.items) {
            [...ev.dataTransfer.items].forEach((item, i) => {
                if (item.kind === "file" && (item.type === "image/png" ||  item.type === "image/jpeg" || item.type === "image/jpg")) {
                    const file = item.getAsFile();
                    this.setState({ imgFile: file, imgFileName : file.name }, () => {
                        this.readDataFromImgFile();
                    });
                }
                else {
                    toast.error("Please select a .jpg, .jpeg, .png file only.")
                }
            });
        } else {
            [...ev.dataTransfer.files].forEach((file, i) => {
                if(file.type === "image/png" ||  file.type === "image/jpeg" || file.type === "image/jpg") {
                    this.setState({ imgFile: file, imgFileName : file.name }, () => {
                        this.readDataFromImgFile();
                    });
                }
                else {
                    toast.error("Please select a .jpg, .jpeg, .png file only.")
                }
            });
        }
    }

    allowDrop(event) {
        event.preventDefault();
    }

    saveDailyImgText() {
        let obj = {
            isImage : (this.state.checked === "onlyImage" || this.state.checked === "imageAndText"),
            isText : (this.state.checked === "onlyText" || this.state.checked === "imageAndText"),
            dailyNote : this.state.dailyNote?.replaceAll("'","*^*"),
            imgFileName : this.state.imgFileName,
            cutOffTime : this.state.cutOffTime.toLocaleString("en-US", {timeZone: "America/Los_Angeles"})
        }
        let by = localStorage.getItem("loggedUserEmail");
        trackPromise(
            this.props.action.postDailyNote(by,obj).then(res => {
                if(res) {
                    toast.success("Daily note updated successfully.")
                    this.setState({
                        dailynotepage: false,
                        checked: "",
                        dailyImg: "",
                        imgFileName : "",
                        dailyNote: "",
                        textFileName : "",
                        imgFile: undefined,
                        textFile: undefined,
                        cutOffTime : "",
                    });
                }
                else toast.error("Failed to update the daily note. Please make sure there is no emoji in the daily note and try again.");
            })
            .catch(err => APIFailedMessage.displayError(err))
        )
        if((this.state.checked === "onlyImage" || this.state.checked === "imageAndText") && this.state.imgFile !== undefined) {
            let formData = new FormData();
            formData.append('file', this.state.imgFile);
            trackPromise(
                this.props.action.postDailyImage(formData).then(res => {
                    if(res) {
                        toast.success("Image uploaded successfully.")
                        this.setState({
                            dailynotepage: false,
                            checked: "",
                            dailyImg: "",
                            imgFileName : "",
                            dailyNote: "",
                            textFileName : "",
                            imgFile: undefined,
                            textFile: undefined,
                            cutOffTime : "",
                        });
                    }
                    else toast.error("Failed to upload the image. Please try again.");
                })
                .catch(err => APIFailedMessage.displayError(err))
            )
        }
    }

    saveResetImgText() {
        let obj = {
            isImage : false,
            isText : false,
            dailyNote : "",
            imgFileName : "",
            cutOffTime : ""
        }
        let by = localStorage.getItem("loggedUserEmail");
        trackPromise(
            this.props.action.postDailyNote(by,obj).then(res => {
                if(res) {
                    toast.success("Rested successfully.")
                    this.setState({
                        dailynotepage: false,
                        checked: "",
                        dailyImg: "",
                        imgFileName : "",
                        dailyNote: "",
                        textFileName : "",
                        imgFile: undefined,
                        textFile: undefined,
                        cutOffTime : "",
                    });
                }
                else toast.error("Failed to reset, try again.");
            })
            .catch(err => APIFailedMessage.displayError(err))
        )
    }

    changecutOffTime(event) {
        if(event === null) {
            this.setState({cutOffTime : null});
        }
        else {
            let selDate = moment(event).format("MM/DD/yyyy");
            let curDate = moment(new Date()).format("MM/DD/yyyy");
            if(selDate == curDate) {
                if(new Date(event).getHours() == 0 && new Date(event).getMinutes() == 0) {
                    selDate = new Date(event).setHours(new Date().getHours(), new Date().getMinutes()+1);
                    this.setState({cutOffTime: new Date(selDate) });
                }
                else {
                    this.setState({cutOffTime: new Date(event) });
                }
            }
            else {
                this.setState({cutOffTime: new Date(event) });
            }
        }
    }

    render() {

        let style = "";
        let dailyNoteStyle = "";
        if(this.state.checked === "imageAndText") {
            if(this.state.dailyNote?.split("\n")?.length <=4 || this.state.dailyNote?.split("\n")?.length === undefined) {
                if(this.state.dailyNote?.split(" ")?.length <= 50) {
                    style = "Illustration3";
                    dailyNoteStyle = "dailyStyle3";
                }
                else {
                    style = "Illustration2";
                    dailyNoteStyle = "dailyStyle2";
                }
            }
            else {
                style = "Illustration2";
                dailyNoteStyle = "dailyStyle2";
            }
        }
        else {
            style = "Illustration1";
            dailyNoteStyle = "dailyStyle1";
        }

        const saveButton = () => {
            return !(((this.state.checked === "onlyImage" && this.state.dailyImg !== "") || (this.state.checked === "onlyText" && this.state.dailyNote !== "") || (this.state.checked === "imageAndText" && this.state.dailyImg !== "" && this.state.dailyNote !== "")) && (this.state.cutOffTime !== "" && this.state.cutOffTime > new Date()));
        }

        const previewButton = () => {
            return !(((this.state.checked === "onlyImage" && this.state.dailyImg !== "") || (this.state.checked === "onlyText" && this.state.dailyNote !== "") || (this.state.checked === "imageAndText" && this.state.dailyImg !== "" && this.state.dailyNote !== "")));
        }

        let minTime = "";
        let cutOffDate = (this.state.cutOffTime !== "" && this.state.cutOffTime !== null) ? moment(this.state.cutOffTime).format("MM/DD/yyyy") : "";
        if(cutOffDate > moment(new Date()).format("MM/DD/yyyy")) {
            minTime = new Date().setHours(0,0);
        }
        else {
            minTime = new Date().setHours(new Date().getHours(), new Date().getMinutes());
        }

        return (         
            <>
                <Modal show={this.state.previewPopup} size="lg">
                    <Modal.Header>
                        <h4>Preview</h4> <FontAwesomeIcon icon={faTimesCircle} className="float-right" size="2x" onClick={() => {this.setState({previewPopup : false})}}/>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-6 pr-0">
                                <div className="d-flex flex-column h-100" id="dailyNote">
                                    {(this.state.checked === "onlyImage" || this.state.checked === "imageAndText") && 
                                        <img
                                            id={style}
                                            src={this.state.dailyImg}
                                            alt="Illustration"
                                            className={"img-fluid my-auto "+((this.state.checked === "imageAndText" && (this.state.dailyNote?.split(" ")?.length <= 50 && this.state.dailyNote?.split("\n")?.length <=4)) ? "h-100" : (this.state.checked === "imageAndText") ? "h-50" : "")}
                                        />
                                    }
                                    {(this.state.checked === "onlyText" || this.state.checked === "imageAndText") &&
                                        <div id={dailyNoteStyle} className="overflow-auto">
                                            <p className="m-0 mx-auto my-auto" style={{whiteSpace: "pre-wrap"}}>{this.state.dailyNote}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-6 d-flex flex-column">
                            <div className="text-center">
                          <div className="mt-5 mt-md-0 mt-sm-0 mt-lg-0">
                              <img src={danielsLogo} alt="Daniels Logo" width="172px"></img>
                          </div>
                      </div>
                      <div className="flex-fill fill d-flex justify-content-center align-items-center mb-5">
                          <div id="associateLoginBtn" className="mb-5 mt-5">
                              <button
                                  className="btn text-white w-100 pt-2 pb-2"
                              >
                                  Associate Login
                              </button>
                          </div>

                      </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className="row">
                    <Card className="w-100 signOutSection mt-0">
                        <Card.Body>
                            <h5 className="mb-0">
                                {this.state.dailynotepage ? <div className="d-inline" onClick={() => this.openCoverPageEdit()} style={{cursor:"pointer"}}><FontAwesomeIcon icon={faAngleLeft} className="mr-3"/>Login Page</div> : null }
                                {!this.state.dailynotepage ? <div className="d-inline" onClick={() => this.openCoverPageEdit("loadData")} style={{cursor:"pointer"}}>Login Page<FontAwesomeIcon icon={faAngleRight} className="float-right mt-1"/></div> : 
                                <div className="d-block d-md-inline d-lg-inline">
                                <button className="btn float-right pt-1 pb-1 bg-secondary text-primary" onClick={() => this.saveDailyImgText()} disabled={saveButton()}>SAVE</button>
                                <button className="btn float-right pt-1 pb-1 mr-1 mr-md-3 mr-lg-3 bg-secondary text-primary" onClick={() => this.setState({previewPopup : true})} disabled={previewButton()}>PREVIEW</button>
                                <button className="btn float-right pt-1 pb-1 mr-1 mr-md-3 mr-lg-3 bg-secondary text-primary" onClick={() => this.saveResetImgText()}>Reset</button>
                                </div> }
                            </h5>
                        </Card.Body>
                    </Card>
                    {this.state.dailynotepage ?
                        <Card className="w-100 signOutSection mt-0">
                            <Card.Body>
                                <div className="form-group">
                                    <input type="radio"
                                        id="onlyImage"
                                        name="onlyImage"
                                        className="mr-2"
                                        style={{ scale: "1.5" }}
                                        onChange={(event) => this.onChange(event)}
                                        value={this.checked}
                                        checked={this.state.checked === "onlyImage"} />
                                    <label htmlFor="onlyImage">Display Image Only with Login</label>
                                </div>
                                <div className="form-group">
                                    <input type="radio"
                                        id="imageAndText"
                                        name="imageAndText"
                                        className="mr-2"
                                        style={{ scale: "1.5" }}
                                        onChange={(event) => this.onChange(event)}
                                        value={this.checked}
                                        checked={this.state.checked === "imageAndText"} />
                                    <label htmlFor="imageAndText">Display Image and Text with Login</label>
                                </div>
                                <div className="form-group mb-0">
                                    <input type="radio"
                                        id="onlyText"
                                        name="onlyText"
                                        className="mr-2"
                                        style={{ scale: "1.5" }}
                                        onChange={(event) => this.onChange(event)}
                                        value={this.checked}
                                        checked={this.state.checked === "onlyText"} />
                                    <label htmlFor="onlyText">Display Text Only with Login</label>
                                </div>
                                {this.state.checked !== "" &&
                                    <>
                                    <hr></hr>
                                    <div className="form-group mb-0">
                                        <label htmlFor="onlyText">Show Until : (MM/DD/YYYY HH:MM AM|PM)</label>
                                        <DatePicker
                                            selected={this.state.cutOffTime === "" || this.state.cutOffTime === null ? null : new Date(this.state.cutOffTime)}
                                            onChange={(event) => this.changecutOffTime(event)}
                                            showTimeSelect
                                            showFullMonthYearPicker
                                            minTime={minTime}
                                            maxTime={new Date().setHours(23, 30)}
                                            dateFormat="MM/dd/yyyy hh:mm aa"
                                            minDate={new Date()}
                                            className={"form-control w-25 "+(this.state.cutOffTime !== "" && this.state.cutOffTime < new Date() ? "border-danger text-danger" : "border-dark")}
                                            customInput ={
                                                <IMaskInput mask ="00/00/0000 00:00 aa" />
                                            }
                                        /> 
                                        {(this.state.cutOffTime === null || (this.state.cutOffTime !== "" && this.state.cutOffTime < new Date()))  && <p className="text-danger">Please correct your date and/or time.</p>}                                                  
                                    </div>
                                        {(this.state.checked == "imageAndText" || this.state.checked == "onlyImage") &&
                                        // <div className="row mt-4">
                                        // <div className="col-12 col-md-6 col-lg-6">
                                        <div className="mt-3" style={{border : "dashed"}} onDrop={(event) => this.handleImgDrop(event)} onDragOver={(event) => this.allowDrop(event)}>
                                                <p className="pl-3 pt-4 pr-3 pb-1 mb-0 text-center">drag and drop .jpg, .jpeg, .png</p>
                                                <p className="mb-0 text-center text-secondary">{this.state.imgFileName}</p>
                                                <div className="d-flex w-100 mb-3 mt-2">
                                                <label htmlFor="imageUpload" className="btn pl-5 pr-5 pt-2 pb-2 rounded mx-auto bg-secondary text-primary">Select a file</label>
                                                <input type='file'
                                                    accept='.jpg,.jpeg,.png'
                                                    id='imageUpload'
                                                    style={{display: "none"}}
                                                    onChange={(e) => {
                                                        this.setState({ imgFile: e.target.files[0] },
                                                        () => {
                                                            this.readDataFromImgFile();
                                                        });
                                                    }}
                                                    />
                                                </div>
                                        </div>
                                        //     </div>
                                        //     <div className="col-12 col-md-6 col-lg-6">
                                        //         {this.state.dailyImg && 
                                        //             <img className="border border-dark" id="dailyImg" src={this.state.dailyImg} alt="dailyImg" height="100%" width="100"/> 
                                        //         }                                           
                                        //     </div>
                                        // </div>
                                        }
                                        {(this.state.checked == "imageAndText" || this.state.checked == "onlyText") &&
                                            <div className="row mt-4">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div style={{ border: "dashed" }}
                                                        onDrop={(e) => { this.handleImgTextDrop(e); }}
                                                        onDragOver={(event) => this.allowDrop(event)}
                                                    >
                                                        <p className="pl-3 pt-4 pr-3 pb-1 mb-0 text-center">drag and drop .txt</p>
                                                        <p className="mb-0 text-center text-secondary">{this.state.textFileName}</p>
                                                        <div className="d-flex w-100 mb-3 mt-2">
                                                            <label htmlFor="textUpload" className="btn pl-5 pr-5 pt-2 pb-2 rounded mx-auto bg-secondary text-primary">Select a file</label>
                                                            <input type='file'
                                                                accept='.txt'
                                                                id='textUpload'
                                                                style={{ display: "none" }}
                                                                onChange={(e) => {
                                                                    this.setState({ textFile: e.target.files[0] },
                                                                        (e) => {
                                                                            this.readDataFromTextFile(e);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 d-flex">
                                                    <textarea className="form-control border" rows={5} value={this.state.dailyNote} onChange={(event) => this.onChange(event)} />
                                                </div>
                                            </div>
                                        }
                                    </>}
                            </Card.Body>
                        </Card>
                        : null}
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        action : bindActionCreators(StoreNumberActions,dispatch)
    }
};

export default connect(null, mapDispatchToProps )(DailyNotes);