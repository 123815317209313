import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCircleChevronLeft, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authActions from '../redux/actions/authActions';
import danielsLogo from "../images/DanielBlack.png";
import APIFailedMessage from "./component.apiFailedMessage";
import ConfirmDialog from "../components/component.confirmDialog";

class CustomNavbar extends Component {
    constructor(){  
        super();
        this.state={  
          show:false,
          popupShow:false
        }  
      }  
    handleModal = () => {  
        this.setState({show:!this.state.show})  
    }  
    redirectToLookup=(event)=>{
        event.preventDefault();
        this.setState({popupShow:false});
        window.location.pathname = '/customer-lookup'
       //this.props.history.push("/customer-lookup");
    }
    logOut = async() => {
        try{
          await this.props.authActions.logout();
          }
        catch(error){
          APIFailedMessage.displayError(error);
        }
      }
    handleModalPopup = () => {
    this.setState({ popupShow: !this.state.popupShow});
    }

    actionHandler=() => {
        window.history.pushState(
            null,
            document.title,
            window.location.href
        )
        this.setState({popupShow:true});
    }

    componentDidMount(){
    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener("popstate", this.actionHandler)
    }

    render() {
        let selectedUserName= localStorage.getItem("selectedUserName");
        let userStoreCode= localStorage.getItem("storeCode");
        let selectedCustomer = localStorage.getItem("selectedCustomer");
        return(
            <>
            {sessionStorage.getItem("thirdPartyLink") === "false" 
            ?
                sessionStorage.getItem("logout/continue") == "true" ? 
                <nav className="navbar bg-white" style={{padding: ".8rem"}} >
                    <img src={danielsLogo} alt="logo" width="80" className="ml-2"></img>
                </nav>
                :
                <nav className="navbar bg-white" style={{padding: ".8rem"}} >
                    
                    {sessionStorage.getItem("isNewApplication") == "true"
                        ?
                            <img src={danielsLogo} alt="logo" width="80" className="ml-2"></img>
                        :
                            <button onClick={this.props.handleToggleSidebar} className="btn border-0 p-0 m-0 text-secondary ml-md-3 toggleBtn"><span><FontAwesomeIcon icon={faBars} size="lg"/></span></button>
                    }
                    {!this.props.isApplyNow ?
                        <a href="/customer-lookup" className="text-dark pr-3 ml-3 text-decoration-none backToCustomerLookUp"><FontAwesomeIcon icon={faCircleChevronLeft} className="pr-1 text-secondary"/><small className="d-none d-md-inline d-lg-inline d-xl-inline">Back to customer lookup</small></a>
                    : null }
                    {/* {( !this.props.isAdmin && !this.props.isActSummary) &&
                    <div className="h-100 ml-auto pr-2 border-right">
                            {
                                sessionStorage.getItem("isNewApplication") == "false" ? (<p className="mb-0 text-secondary text-right"><b>{selectedUserName}</b></p>
                                ) : ("")
                            }
                            {
                            
                            sessionStorage.getItem("isNewApplication") == "false" ? (<><div className="mb-0 row text-right d-md-block d-lg-block"><small className="pr-3 pr-md-2 pr-lg-2 col-12 col-md-6 p-0 border-0 border-md-right"><b>Home Store : </b>{userStoreCode === "null" || userStoreCode === undefined || userStoreCode === "" ? "-" : userStoreCode}</small><span className="ml-0 d-none d-md-inline d-lg-inline">|</span><small className="pr-3 col-12 col-md-6 p-0"><b>Account ID : </b> {localStorage.getItem("magixassociateId") !== "null" ? selectedUserAccount : sessionStorage.getItem("magixInstoreData") !== "" && sessionStorage.getItem("magixInstoreData") !== "null" && sessionStorage.getItem("magixInstoreData") !== undefined ? selectedUserAccount : "-"}</small></div>
                            <p className="mb-0 text-right"><small><b>Application ID : </b> {localStorage.getItem("associateId") === "null" ? "-" : localStorage.getItem("associateId")}</small></p></>) : ("")
                            }
                    </div>} */}
                    {/* { this.props.isActSummary && */}
                    {!this.props.isAdmin&&
                    <div className="h-100 ml-auto pr-2 border-right mr-3">
                            {
                                sessionStorage.getItem("isNewApplication") == "false" ? <><p className="mb-0 text-secondary text-right mr-3"><b>{selectedUserName}</b></p><p className="mb-0 text-right mr-3"><b>Customer ID : {localStorage.getItem("customerId") === "null" ? "-" : localStorage.getItem("customerId")}</b></p></>
                                 : null
                            }
                    </div>}
                    <button
                        className="text-dark btn border-0 mr-md-4 d-flex"
                        onClick={()=>this.handleModal()}
                    >
                        <FontAwesomeIcon icon={faPowerOff} className="pr-2 text-secondary" style={{marginTop:"4px"}}/>Logout
                    </button>
                    <ConfirmDialog
                        show={this.state.show}
                        handleModalPopup={this.handleModal}
                        message={"<p>Are you sure you want to logout?</p>"}
                        button1_name="Cancel"
                        button1={this.handleModal}
                        button2_name="Logout"
                        button2={this.logOut}
                    />
                </nav>
            :
                <nav className="navbar bg-white" style={{padding: ".8rem"}} >
                    <img src={danielsLogo} alt="logo" width="80" className="ml-2"></img>
                </nav>
            }
            
            <ConfirmDialog
                show={this.state.popupShow}
                handleModalPopup={this.handleModalPopup}
                message={"<p>If you click OK, you will lose all the data. Are you sure you want to go back to customer lookup?</p>"}
                button1_name="Cancel"
                button1={this.handleModalPopup}
                button2_name="OK"
                button2={this.redirectToLookup}
            />
            </>
        );
    }
}
const mapDispatchToProps=(dispatch)=>{
    return {
     authActions:bindActionCreators(authActions,dispatch)
    }
}
export default connect(null,mapDispatchToProps)(CustomNavbar);