import { ApiWrapper } from "../../api/thunk.helper";
import HttpMethods from "../_http.methods";

export function getProgressiveActSummmary(cust_Id,progressiveAppId) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: progressiveAppId,
      ResourceUrl: "v1/customers/account-summary-progressive?CustomerId="+cust_Id+"&Application_Id="+progressiveAppId,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getUownActSummmary(cust_Id,uownAppId) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        Resource: uownAppId,
        ResourceUrl: "v1/customers/account-summary-uown?CustomerId="+cust_Id+"&Application_Id="+uownAppId,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

  export function getFortivaActSummmary(cust_Id,fortivaAppId) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        Resource: fortivaAppId,
        ResourceUrl: "v1/customers/account-summary-fortiva?CustomerId="+cust_Id+"&Application_Id="+fortivaAppId,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }
