import * as Msal from 'msal'; //services.authentication


export default class AuthService {
  constructor() {
    let redirectUri = window.location.protocol + '//' + window.location.hostname;
    redirectUri = window.location.port !== '' ? redirectUri + ':' + window.location.port : redirectUri;

    this.applicationConfig = {
      clientId: window.env.AZURE_AD_CLIENT_ID,
      scopes: window.env.AZURE_AD_SCOPES,
      tenantId: window.env.AZURE_AD_TENANT_ID,
      graphScopes: ["User.Read"],
    };

    const config = {
      
      auth: {
        clientId: this.applicationConfig.clientId,
        authority: 'https://login.microsoftonline.com/'+ this.applicationConfig.tenantId,
        redirectUri: redirectUri,
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: redirectUri,
      },
      cache: {
        cacheLocation: 'sessionStorage'
      },
      system: {
        tokenRenewalOffsetSeconds: 60
      }
    }

    this.app = new Msal.UserAgentApplication(config);
  }

  checkLogin = () => {
    const account = this.app.getAccount();
    if(account) {
      return this.onUserLoggedIn(account);
    }
  }

  onUserLoggedIn = (account) => {
    let loginUser = {
      'accountIdentifier': account.accountIdentifier,
      'userName': account.userName,
      'name': account.name,
      'homeAccountIdentifier': account.homeAccountIdentifier
    };
    // To store the logged in user details
    localStorage.setItem('loginUser', JSON.stringify(loginUser));
    localStorage.setItem('loginUserEmail', loginUser.userName);
    return {account, loginUser};
  }

  login = async () => {
    //const login= await this.app.loginRedirect(this.applicationConfig.graphScopes);
    // var response= await this.app.loginPopup(this.applicationConfig.graphScopes);
    var response= await this.app.loginPopup({
      // this.applicationConfig.graphScopes
      prompt: "login"
    });
    return response;
  };

  logout = () => {
    localStorage.removeItem("loginUser");
    localStorage.removeItem("token");
    this.app.logout();
  };

  getToken = () => {
    return this.app.acquireTokenSilent(this.applicationConfig.graphScopes).then(
      (accessToken) => {
        return accessToken;
      },
      (error) => {
        console.error(error);
        return this.app
        .acquireTokenPopup(this.applicationConfig.graphScopes)
        .then(
          (accessToken) => {
            return accessToken;
          },
          (err) => {
            console.error(err);
            throw new Error('Unable to get access token');
          }
        );
      }
    );
  };
}
