import React,{Component} from 'react';
import IdleTimeModal from "../../components/component.idleTimeModal";
import Sidebar from "../../components/component.customSideBar";
import CustomNavbar from "../../components/component.customNavbar";
import { Card } from "react-bootstrap";
import { trackPromise } from 'react-promise-tracker';
import APIFailedMessage from '../../components/component.apiFailedMessage';
import TitleCard from "../../components/component.titleCard";
import Moment from 'moment';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as accountSummaryAction from "../../redux/actions/accountSummary.actions" ;
import FortivaErrorDialog from "../../components/component.fortivaErrorDialog";
import GetInfo from '../../components/component.getInfo';

class UownActSummary extends Component{

    constructor(){
        super();
        this.state={
         toggled:false,
         summaryDetails : [],
         isSummary:false
        }
    }
    handleToggleSidebar() {
        this.setState({
            toggled: !this.state.toggled
        });
    };

    componentDidMount() {
        if (JSON.parse(sessionStorage.getItem('isLoggedIn'))) {
            let uownAppId = localStorage.getItem("uownAppId");
            if (uownAppId!="null" && uownAppId!="undefined" && uownAppId!=="") {
                trackPromise(
                    this.props.actions.getUownActSummmary(GetInfo.getCustomerId(),uownAppId).then(
                        response => {
                            this.setState({ summaryDetails: response });
                        }
                    ).catch(err => {
                        APIFailedMessage.displayError(err);
                    })
                )
            }
            else{
                // this.setState({isSummary:!this.state.isSummary});
            }
        }
        else
            this.props.history.push("/");
    }
   
    render() {
        return (
            <>
                <IdleTimeModal />
                <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
                    <Sidebar
                        toggled={this.state.toggled}
                        handleToggleSidebar={() => this.handleToggleSidebar()}
                    />
                    <main>
                        <CustomNavbar handleToggleSidebar={() => this.handleToggleSidebar()} />
                        <div id="main" className="container-fluid mt-4 pl-4 pr-4" ref={el => (this.accountPrintOut = el)}>
                            <div className="row">
                            <TitleCard accType={"Uown"} />
                                <div className="col-12 d-flex">
                                <Card className="text-left mb-2 shadow w-100 ">
                                    <Card.Body>
                                        <div>
                                            <div className="row">
                                                <div className="col-12 pr-md-3 pl-md-4 pt-2 pb-2">
                                                    <div className='row'>
                                                        <div className='col-5 col-md-2'>
                                                        <p><b>Submitted Date :</b></p>
                                                        </div>
                                                        <div className='col-7 col-md-4'>
                                                        <p>{this.state.summaryDetails?.createdDate ? Moment(this.state.summaryDetails?.createdDate).format("MM/DD/YYYY") : ""}</p>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-5 col-md-2'>
                                                        <p><b>Lease ID:</b></p>
                                                        </div>
                                                        <div className='col-7 col-md-6'>
                                                        <p>{(this.state.summaryDetails?.authorizationNumber !== "") ? this.state.summaryDetails?.authorizationNumber : ""}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row border-top ">
                                                <div className="col-12 col-md-3 col-lg-3 border-right pr-md-3 pl-md-4 pt-2 pb-2 mt-2">
                                                    <p><b>Approval Status</b></p>
                                                    <p>{this.state.summaryDetails?.approval_status}</p>                                                   
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-3 border-right pr-md-3 pl-md-4 pt-2 pb-2 mt-2">
                                                    <p><b>Approval Limit</b></p>
                                                    <p>{this.state.summaryDetails?.approval_status == "UW_DENIED" ?  "0" : this.state.summaryDetails?.approvalLimit}</p>                                                   
                                                </div>
                                                {/* <div className="col-12 col-md-3 col-lg-3 border-right pr-md-3 pl-md-4 pt-2 pb-2 mt-2">
                                                    <p><b>Account Balance</b></p>
                                                    <p>{this.state.summaryDetails?.account_Balance}</p>                                                   
                                                </div> */}
                                                <div className="col-12 col-md-3 col-lg-3 border-right pr-md-3 pl-md-4 pt-2 pb-2 mt-2">
                                                    <p><b>Status Description</b></p>
                                                    <p>{this.state.summaryDetails?.statusDescription}</p>                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            {/* <div className="col-12 d-flex">
                            <Card className="text-left mt-3 ml-2 mr-2 mb-2 shadow w-100 ">
                                    <Card.Body>
                                        <div>
                                            <div className="row">
                                                <div className="col-12 col-md-3 col-lg-2 pr-md-3 pl-md-4 pt-2 pb-2">
                                                    <p><b>Submitted Date:</b> <span>{this.state.summaryDetails?.strbalanceDue0}</span></p>
                                                    <p><b>Lease ID:</b> <span>${(this.state.summaryDetails?.schedPymtDate !== null && this.state.summaryDetails?.schedPymtDate !== undefined) ? Moment(this.state.summaryDetails?.schedPymtDate).format("MM/DD/YYYY") : ""}</span></p>
                                                    <p><b>Expiry Date:</b><span>{this.state.summaryDetails?.strschedPymtAmt}</span></p>
                                                </div>
                                            </div>
                                            <div className="row border-top ">
                                                <div className="col-12 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2 mt-2">
                                                    <p><b>Approval Status:</b> <span>{this.state.summaryDetails?.strbalanceDue0}</span></p>
                                                    <p>{this.state.summaryDetails?.strbalanceDue0}</p>
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2 mt-2">
                                                    <p><b>Approval Limit:</b> <span>{this.state.summaryDetails?.strbalanceDue0}</span></p>
                                                    <p>${this.state.summaryDetails?.strbalanceDue0}</p>
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2 mt-2">
                                                    <p><b>Balance:</b> <span>{this.state.summaryDetails?.strbalanceDue0}</span></p>
                                                    <p>{this.state.summaryDetails?.strbalanceDue0}</p>
                                                   
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2 mt-2">
                                                    <p><b>Status Reason:</b> <span>{this.state.summaryDetails?.strbalanceDue0}</span></p>
                                                    <p>{this.state.summaryDetails?.strbalanceDue0}</p>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div> */}
                            </div>
                        </div>
                        <FortivaErrorDialog
                            show={this.state.isSummary}
                            handleModalPopup={(event)=>this.setState({isSummary:!this.state.isSummary})}
                            message={`<span class=\"mb-0 \">No Account found</span>`}
                            submitFortiva={(event)=>this.setState({isSummary:!this.state.isSummary})}
                        />
                    </main>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(accountSummaryAction, dispatch),
    }
}
export default connect(null, mapDispatchToProps)(UownActSummary);