import { Modal, ModalBody } from "react-bootstrap"
import ModalHeader from "react-bootstrap/esm/ModalHeader"

export const ClaimAndCancel = (props) => {
  const { showCancelAndClaimPopup, content, handleClaimAndCancel, reason, onChange } = props;
  return (
    <>
      <Modal show={showCancelAndClaimPopup}>
        <ModalHeader>
          <span className="h4 mb-0">Credit Insurance</span>
        </ModalHeader>
        <ModalBody>
          {content === "cancelReqRaised" ?
            <>
              <div className="form-group d-flex">
                <input
                  type="checkbox"
                  id="declineCheckBox"
                  value={true}
                  className="mr-2 mb-auto"
                  checked={true}
                />
                <label htmlFor="declineCheckBox">
                  By submitting this application, I acknowledge that my
                  electronic signature below indicates that I am NOT enrolling
                  in any of the available insurance programs and decline all
                  coverage benefits offered at this time.
                </label>
              </div>
            </>
            :
            <>
              <p className="mb-1">Are you sure you want to file a claim?</p>
              <div>
                <p>Provide a brief explanation and confirm to submit.</p>
                <textarea
                  className="form-control border"
                  rows={5}
                  placeholder="Please enter here"
                  value={reason}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </>
          }
          <div className="row mt-3">
            <div className="col-12 col-md-3 offset-md-6">
              <button
                className="btn pt-2 pb-2 border customtext-secondary w-100"
                style={{ backgroundColor: "#E5D7EC" }}
                onClick={() => handleClaimAndCancel("revert")}
              >
                CANCEL
              </button>
            </div>
            <div className="col-12 col-md-3 pl-md-1">
              <button
                className="btn pt-2 pb-2 border bg-secondary text-primary w-100 pl-1 pr-1 mt-3 mt-md-0 mt-lg-0"
                style={{ backgroundColor: "#E5D7EC" }}
                onClick={() => handleClaimAndCancel("actionConfirm")}
                disabled={content === "cancelReqRaised" ? false : reason === ""}
              >
                CONFIRM
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}