import {Card} from 'react-bootstrap';
  

const TitleCard = ({accType}) =>
 {
    return(
        <div className="col-12 mb-4">
        <div className="row">
          <div className="col-12 d-flex">
          <div className="pl-0 w-100">
            <Card>
            <Card.Body className="pl-lg-0 pr-lg-0">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-3 d-lg-flex justify-content-center pr-0">
                <p className="mb-0 d-flex"><span className="text-secondary mr-0 font-weight-bold">Customer Name :</span><span className="mr-0">{localStorage.getItem("selectedUserName")}</span></p>
                </div>
                <div className="col-12 col-md-6 col-lg-3 d-lg-flex justify-content-center border-left">
                <p className="mb-0 d-flex"><span className="text-secondary mr-0 font-weight-bold">Account :</span><span>{accType}</span></p>
                </div>
                <div className="col-12 col-md-6 col-lg-3 d-lg-flex justify-content-center border-left">
                <p className="mb-0 d-flex"><span className="text-secondary mr-0 font-weight-bold">Phone No. :</span><span>{sessionStorage.getItem("selectedUserPhone")}</span></p>
                </div>
                <div className="col-12 col-md-6 col-lg-3 d-lg-flex justify-content-center border-left pl-lg-0">
                <p className="mb-0 d-flex"><span className="text-secondary mr-0 font-weight-bold">Email :</span><span>{sessionStorage.getItem("selectedUserEmail") == "(null)" ? "-" : sessionStorage.getItem("selectedUserEmail")}</span></p>
                </div>
              </div>
            </Card.Body>
           </Card>
          </div>
          </div>
        </div>
  </div>

    )
 };
  export default TitleCard;