import React, { Component } from "react";
import { Card,ToastContainer, Toast } from "react-bootstrap";
import "./component.applyNow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as applyNowActions from '../../redux/actions/applyNow.actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'moment';
import { trackPromise } from 'react-promise-tracker';
import * as customerDetailsActions from "../../redux/actions/customerDetails.actions";
import { Modal } from "react-bootstrap"
import { faAngleDown, faAngleUp, faClose } from "@fortawesome/free-solid-svg-icons";
import * as authActions from '../../redux/actions/authActions';
import * as storeactions from '../../redux/actions/storeNumber.actions';
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import * as adminUserListActions from '../../redux/actions/adminUserList.actions';
import { IMaskInput } from 'react-imask';
import APIFailedMessage from "../../components/component.apiFailedMessage";
import GetDateFromMMYYYY from "../../components/component.GetDateFromMMYYYY";
import GetDateFromMMDDYYYY from "../../components/component.GetDateFromMMDDYYYY";
import formatCC from "../../components/component.formatCC";
import * as adminActions from '../../redux/actions/admin.actions';


class ProgUownForm extends Component {
    constructor() {
        super();
        this.state = {
            step1: true,
            isStep1Complete: false,
            step2: false,
            toggled: false,
            isStep2Complete: false,
            step3: false,
            isStep3Complete: false,
            step4: false,
            isStep4Complete: false,
            step5: false,
            isStep5Complete: false,
            step6: false,
            isStep6Complete: false,
            danielsCredit: false,
            synchronyCredit: false,
            fortivaCredit:false,
            progressiveLeasing: false,
            UOwnLeasing: false,
            progressiveDisclosure: false,
            progressiveSMS: false,
            UOwnDisclosure: false,
            showSubmitConfirmation: false,
            waterfallSubmittedAlert: false,
            formObj: {
                applicationId:"",
                credit_Id: 0,
                account:"",
                storeCode: "0",
                suffix: "0",
                firstName: "",
                middleName: "",
                lastName: "",
                mailingAdr: "",
                emailAdr: "",
                aptUnit: "",
                dob: "",
                city: "",
                mobileNo: "",
                state: "0",
                secPhone: "",
                zipCode: "",
                empName: "",
                empStartDate: "",
                empPhoneNo: "",
                netIncome: "",
                duties: "",
                housingInfo: "0",
                ssn: "",
                primaryIdType: "0",
                primaryIdNo: "",
                primaryIdState: "0",
                primaryIdExpiryDate: "",
                secondIdType: "0",
                secondIdIssuedBy: "",
                secondIdExpiryDate: "",
                relativeName: "",
                relativePhone: "",
                relativeRelation: "",
                verificationCode: "",
                oftenPaid: "0",
                cc: "",
                lastPayDay: "",
                ccExpiryDate: "",
                nextPayDay: "",
                ccName: "",
                primarySourceIncome: "0",
                creditCardBillingAddress: false,
                ccmailingAdr: "",
                ccaptUnit: "",
                cccity: "",
                ccstate: "0",
                cczipCode: "",
                bankRouteNo: "",
                checkAccNo: "",
                associateEmployeeNo: "",
                lstState: [],
                lstCCState: [],
                lstPrimaryIdState: [],
                lstOftenPaid: [],
                lstStoreCode: []
            },
            limitedState : [],
            formErrors: {},
            permission_status: '',
            show : false,
            errorInEmployment : false,
            errorInIdentification : false,
            erronInProgressive : false,
            errorInUown : false,
            errorInDisclosure : false,
            verificationBox : false,
            djoSubmitted: false,
            ABAError : undefined,
            ABAChanged : false,
            isAlldisclosuresChecked:false,
            showAppInProgress:false,
        };
    }
    validateABA=async()=> {
        if(this.state.progressiveLeasing && this.state.ABAChanged) {
            let result=false;
            let obj=this.state.formObj.bankRouteNo;
            result= await this.props.actions.validateABANumber(this.props.formObj.account,obj);
                if(result){
                    if(!result.isValid)
                        this.setState({ ABAError: "Please enter a valid Bank Routing Number." });
                    else
                        this.setState({ ABAError: undefined, ABAChanged : false });
                    return result.isValid;
                }
        }
        else 
            return true;
    }
    handleFormValidation(currStep) {

        let formErrors = {};
        let formIsValid = true;
        formErrors["empNameErr"] = "";
        formErrors["netIncomeErr"] = "";
        formErrors["primaryIdNoErr"] = "";
        formErrors["primaryIdStateErr"] = "";
        formErrors["progressiveDisclosureErr"] = "";
        formErrors["UOwnDisclosureErr"] = "";
        formErrors["oftenPaidErr"] = "";
        formErrors["ccErr"] = "";
        formErrors["lastPayDayErr"] = "";
        formErrors["ccExpiryDateErr"] = "";
        formErrors["nextPayDayErr"] = "";
        formErrors["ccNameErr"] = "";
        formErrors["primarySourceIncomeErr"] = "";
        formErrors["creditCardBillingAddressErr"] = "";
        formErrors["ccmailingAdrErr"] = "";
        formErrors["ccaptUnitErr"] = "";
        formErrors["cccityErr"] = "";
        formErrors["ccstateErr"] = "";
        formErrors["cczipCodeErr"] = "";
        formErrors["bankRouteNoErr"] = "";
        formErrors["checkAccNoErr"] = "";
        formErrors["secPhoneErr"] = "";
        formErrors["uOwnOftenPaidErr"] = "";
        formErrors["uOwnLastPayDayErr"] = "";           
        formErrors["uOwnNextPayDayErr"] = "" ;
      

        if (currStep === "Step 1") {
            if (this.state.UOwnLeasing) {
                if (this.state.formObj.empName.length === 0) {
                    formIsValid = false;
                    formErrors["empNameErr"] = "Employer's name is required.";
                }
            }
            if (this.state.formObj.netIncome?.length === 0) {
                formIsValid = false;
                formErrors["netIncomeErr"] = "Net income is required.";
            }
            else if(this.state.formObj.netIncome < 100) {
                formIsValid = false;
                formErrors["netIncomeErr"] = "Net income must be greater than $100.";
            }
            else if(this.state.formObj.netIncome > 99999) {
                formIsValid = false;
                formErrors["netIncomeErr"] = "Net income must be lesser than $99999.";
            }
        }
        if (currStep === "Step 2") {
            if(this.state.progressiveLeasing) {
                if (this.state.formObj.primaryIdNo.length === 0) {
                    formIsValid = false;
                    formErrors["primaryIdNoErr"] = "Primary ID number is required.";
                }
                else if (this.state.formObj.primaryIdNo.length < 3) {
                    formIsValid = false;
                    formErrors["primaryIdNoErr"] = "Primary ID number should contain at least 3 characters.";
                }
                if (parseInt(this.state.formObj.primaryIdState) === 93) {
                    formIsValid = false;
                    formErrors["primaryIdStateErr"] = "State should not be Mexico if applying for progressive.";
                }
            }
           
        }
      
        
        if (currStep === "Step 3") {
            if (this.state.progressiveLeasing) {
                if (this.state.formObj.bankRouteNo.length === 0) {
                    formIsValid = false;
                    formErrors["bankRouteNoErr"] = "Field is required if applying for Progressive.";
                }
                else if (this.state.formObj.bankRouteNo.length < 9) {
                    formIsValid = false;
                    formErrors["bankRouteNoErr"] = "Bank routing number must be 9 digits.";
                }
                if (this.state.formObj.checkAccNo.length === 0) {
                    formIsValid = false;
                    formErrors["checkAccNoErr"] = "Field is required if applying for Progressive.";
                }
                if (parseInt(this.state.formObj.oftenPaid) === 0) {
                        formIsValid = false;
                        formErrors["oftenPaidErr"] = "Field is required if applying for Progressive.";
                }

                if (this.state.formObj.lastPayDay.length === 0) {
                            formIsValid = false;
                        formErrors["lastPayDayErr"] = "Field is required if applying for Progressive.";   
                        formErrors["lastPayDayErr"] = "Field is required if applying for Progressive.";           
                        formErrors["lastPayDayErr"] = "Field is required if applying for Progressive.";        
                }
                else {
                    let time = new Date().getTime() - (new Date(this.state.formObj.lastPayDay)).getTime();
                    let days = time / (1000 * 3600 * 24);
                    days = Math.floor(days);
                    if (days > 35) {
                        formIsValid = false;
                        formErrors["lastPayDayErr"] = "Last pay date must be within 35 days.";   
                    }
                } 
                if (this.state.formObj.nextPayDay.length === 0) {
                    formIsValid = false;
                    formErrors["nextPayDayErr"] = "Field is required if applying for Progressive.";
                }
                if (this.state.formObj.cc.length === 0) {
                    formIsValid = false;
                    formErrors["ccErr"] = "Field is required if applying for Progressive.";
                }
                else if (this.state.formObj.cc.length < 7) {
                    formIsValid = false;
                    formErrors["ccErr"] = "Credit card number must be first 6 digit number";
                }

                if (this.state.formObj.ccExpiryDate.length === 0) {
                    formIsValid = false;
                    formErrors["ccExpiryDateErr"] = "Field is required if applying for Progressive.";
                }

                if (this.state.formObj.ccName.length === 0) {
                    formIsValid = false;
                    formErrors["ccNameErr"] = "Field is required if applying for Progressive.";
                }

                if (!this.state.formObj.creditCardBillingAddress) {
                    if (this.state.formObj.ccmailingAdr.length === 0) {
                        formIsValid = false;
                        formErrors["ccmailingAdrErr"] = "Field is required if applying for Progressive.";
                    }
                    else {
                        let mailaddr1 = this.state.formObj.ccmailingAdr?.toLowerCase()?.includes("p.o box");
                        let mailaddr2 = this.state.formObj.ccmailingAdr?.toLowerCase()?.includes("p.obox");
                        let mailaddr3 = this.state.formObj.ccmailingAdr?.toLowerCase()?.includes("po box");
                        let mailaddr4 = this.state.formObj.ccmailingAdr?.toLowerCase()?.includes("pobox");
                        let mailaddr5 = this.state.formObj.mailingAdr?.toLowerCase()?.includes("p.o. box");
                        let mailaddr6 = this.state.formObj.mailingAdr?.toLowerCase()?.includes("po. box");
                        if (mailaddr1 || mailaddr2 || mailaddr3 || mailaddr4 || mailaddr5 || mailaddr6) {
                            formIsValid = false;
                            formErrors["ccmailingAdrErr"] = "P.O Box is not accepted by Progressive.";
                        }
                    }
                    if (this.state.formObj.cccity.length === 0) {
                        formIsValid = false;
                        formErrors["cccityErr"] = "Field is required if applying for Progressive.";
                    }

                    if (parseInt(this.state.formObj.ccstate) === 0) {
                        formIsValid = false;
                        formErrors["ccstateErr"] = "Field is required if applying for Progressive.";
                    }
                    if (this.state.progressiveLeasing) {
                        if (parseInt(this.state.formObj.ccstate) === 93) {
                            formIsValid = false;
                            formErrors["ccstateErr"] = "State should not be Mexico if applying for progressive.";
                        }
                    }
                    if (this.state.formObj.cczipCode.length === 0) {
                        formIsValid = false;
                        formErrors["cczipCodeErr"] = "Field is required if applying for Progressive.";
                    }
                }
            }
        }

        if(currStep === "Step 4") {
            if (this.state.UOwnLeasing) {
                if (parseInt(this.state.formObj.oftenPaid) === 0) {
                    formIsValid = false;
                    formErrors["uOwnOftenPaidErr"] = "Field is required if applying for Uown.";
                }
                if (this.state.formObj.lastPayDay.length === 0) {
                    formIsValid = false;
                    formErrors["uOwnLastPayDayErr"] = "Field is required if applying for Uown.";           
                }
                else {
                    let time = new Date().getTime() - (new Date(this.state.formObj.lastPayDay)).getTime();
                    let days = time / (1000 * 3600 * 24);
                    days=Math.floor(days);
                    if(days > 36) {
                        formIsValid = false;
                        formErrors["uOwnLastPayDayErr"] = "Last pay date must be within 35 days.";  
                    }
                }
                if (this.state.formObj.nextPayDay.length === 0) {
                    formIsValid = false;
                    formErrors["uOwnNextPayDayErr"] = "Field is required if applying for Uown.";
                }
            }
        }


    if (currStep === "Step 5") {
           
            if (this.state.progressiveLeasing === true) {
                if (!this.state.progressiveDisclosure) {
                    formIsValid = false;
                    formErrors["progressiveDisclosureErr"] = "Disclosure agreement is required.";
                }
            }
            if (this.state.UOwnLeasing === true) {
                if (!this.state.UOwnDisclosure) {
                    formIsValid = false;
                    formErrors["UOwnDisclosureErr"] = "Disclosure agreement is required.";
                }
            }
        }
        this.setState({ formErrors: formErrors });
        return formIsValid;
    }
    disclosureValidation(){
        let formErrors = {};
        formErrors["progressiveDisErr"] = "";
        formErrors["uownDisErr"] = "";
        if(this.state.progressiveLeasing){
            if(this.state.progressiveDisclosure){
                formErrors["progressiveDisErr"]="";
            }
            else{
                formErrors["progressiveDisErr"] ="progressive disclsoures not checked";
            }
        }
        if(this.state.UOwnLeasing ){
            if(this.state.UOwnDisclosure){
                formErrors["uownDisErr"]="";
            }
            else{
                formErrors["uownDisErr"] ="uown disclsoures not checked";
            }
        }
        this.setState({ formErrors: formErrors });
        return formErrors;  
    }
    grayOutSubmit(formErrors) {
        let { progressiveDisErr, uownDisErr } = formErrors;
        if ( !progressiveDisErr && !uownDisErr) {
            this.setState({ isAlldisclosuresChecked: true });
        }
        else
            this.setState({ isAlldisclosuresChecked: false });
    }

   
    handleSubmittedAlert(event) {
        event.preventDefault();
        this.setState({ waterfallSubmittedAlert: !this.state.waterfallSubmittedAlert });
        
    }
    onProgressiveLeasingChecked() {
        this.setState({ progressiveLeasing: !this.state.progressiveLeasing} ,()=>{
            let formErrors= this.disclosureValidation();
            this.grayOutSubmit(formErrors);
        });
    }
    onCreditCardBillingAddressChecked() {
        this.setState({
            formObj: { ...this.state.formObj, creditCardBillingAddress: !this.state.formObj.creditCardBillingAddress },
        });
    }
    onProgressiveDisclosureChecked() {
        this.setState({ progressiveDisclosure: !this.state.progressiveDisclosure }, ()=>{
            let formErrors= this.disclosureValidation();
            this.grayOutSubmit(formErrors);
        });
    }
    onProgressiveSMSChecked() {
        this.setState({ progressiveSMS: !this.state.progressiveSMS });
    }
    onUOwnLeasingChecked() {
        this.setState({ UOwnLeasing: !this.state.UOwnLeasing }, ()=>{
            let formErrors= this.disclosureValidation();
            this.grayOutSubmit(formErrors);
        });
    }
    onUOwnDisclosureChecked() {
        this.setState({ UOwnDisclosure: !this.state.UOwnDisclosure}, ()=>{
            let formErrors= this.disclosureValidation();
            this.grayOutSubmit(formErrors);
        });
    }
    goStep1(event) {
        event.preventDefault();
        if (this.state.isStep1Complete) {
            this.setState({ step1: !this.state.step1 });
        }
    }

    goStep2(event) {
        event.preventDefault();
        if (this.state.isStep2Complete) {
            this.setState({ step2: !this.state.step2 });
        }
    }

    goStep3(event) {
        event.preventDefault();
        if (this.state.isStep3Complete) {
            this.setState({ step3: !this.state.step3 });
        }
    }

    goStep4(event) {
        event.preventDefault();
        if (this.state.isStep4Complete) {
            this.setState({ step4: !this.state.step4 });
        }
    }

    goStep5(event) {
        event.preventDefault();
        if (this.state.isStep5Complete) {
            this.setState({ step5: !this.state.step5 });
        }
    }
    async submitStep1(event) {
        event.preventDefault();
        if (this.handleFormValidation("Step 1"))  {
            this.setState({ step1: true, isStep1Complete: true, step2: true });
        }
    }
    submitStep2(event) {
        event.preventDefault();
        
        if (!this.handleFormValidation("Step 1") ) {
            this.setState({errorInEmployment : true});
        }
        if (this.handleFormValidation("Step 1") && this.handleFormValidation("Step 2")) {
            this.setState({ step2: true, isStep2Complete: true, step3: true });
        }
    }
    async submitStep3(event) {
        event.preventDefault();
        if (!this.handleFormValidation("Step 1") ) {
            this.setState({errorInEmployment : true});
        }
        if (!this.handleFormValidation("Step 2") ) {
            this.setState({errorInIdentification : true});
        }
        if (this.handleFormValidation("Step 1") && this.handleFormValidation("Step 2") && await this.validateABA() && this.handleFormValidation("Step 3") ) {
                this.setState({ step3: true, isStep3Complete: true, step4: true });
        }
    }
    async submitStep4(event) {
        event.preventDefault();
        if (!this.handleFormValidation("Step 1") ) {
            this.setState({errorInEmployment : true});
        }
        if (!this.handleFormValidation("Step 2") ) {
            this.setState({errorInIdentification : true});
        }
        if (!this.handleFormValidation("Step 3")  || (await this.validateABA() === false) ) {
            this.setState({erronInProgressive : true});
        }
        if (this.handleFormValidation("Step 1") && this.handleFormValidation("Step 2") && this.handleFormValidation("Step 3") && await this.validateABA() && this.handleFormValidation("Step 4") ) {
            this.setState({ step4: true, isStep4Complete: true, step5: true });
        }
    }

    async submitStep5(event) {
        event.preventDefault();
        this.setState({ showSubmitConfirmation: !this.state.showSubmitConfirmation });
        this.setState({showAppInProgress:!this.state.showAppInProgress});

        if (this.handleFormValidation("Step 1") && this.handleFormValidation("Step 2")
            && this.handleFormValidation("Step 3") && await this.validateABA() && this.handleFormValidation("Step 4")
            && this.handleFormValidation("Step 5")) {
            let obj1={
                "store_Id":this.props.formObj.storeCode,
                "customerId":  this.props.waterfallObj.customerId,
                "firstName":  this.props.formObj.firstName,
                "middleName": this.props.formObj.middleName,
                "lastName": this.props.formObj.lastName,
                 "suffix_Id": null,
                "address": this.props.formObj.mailingAdr,
                "city": this.props.formObj.city,
                "state_Id": (parseInt(this.props.formObj.state) > 0) ? parseInt(this.props.formObj.state) : null,
                "state_Id_Code": (parseInt(this.props.formObj.state) > 0) ? this.state.formObj.lstState.find(obj => obj.id == this.props.formObj.state)?.code : "",
                "zipCode":  this.props.formObj.zipCode,
                "apt":null,
                "emailAddress": this.props.formObj.emailAdr,
                "birthDate": GetDateFromMMDDYYYY(this.props.formObj.dob),
                "mobileNo": this.props.formObj.mobileNo,
                "secondaryPhone": null,
                "empName": (this.props.formObj.empName || this.state.UOwnLeasing)?this.state.formObj.empName:null,
                "empPhone": null,
                "howLong":  null,
                "monthlyIncome": this.props.formObj.netIncome,
                "monthlyIncomeIndicator": "",
                "workJob": null,
                "housingInfo_Id": null,
                "createdBy_Associate":  localStorage.getItem('loggedUserEmail'),
                "ssn": this.props.formObj.ssn,
                "primaryTypeId": null,
                "primaryTypeId_Code":"",
                "idNumber":  (this.props.formObj.primaryIdNo || this.state.progressiveLeasing)?this.state.formObj.primaryIdNo:null,
                "primaryStateId": null,
                "primaryStateId_Code": "",
                "relativeName": "string",
                "relativePhone": "string",
                "relativeRelationship": null,
                "isMobileNoVerified": false,
                "isSMSCodeSent": false,
                "isDanielDisclosures": false,
                "dateLived": GetDateFromMMYYYY(Moment().format("MM/YYYY")),
                "monthlyPayment": false,
                "bankC": "",
                "bankS": "",
                "initialSale": 0,
                "isUown": this.state.UOwnLeasing,
                "isProgressive": this.state.progressiveLeasing,
                "isUownDisclosures": this.state.UOwnLeasing?this.state.UOwnDisclosure:false,
                "isProgressiveDisclosures":  this.state.progressiveLeasing?this.state.progressiveDisclosure:false,
                "howOftenPaidId": (this.state.progressiveLeasing || this.state.UOwnLeasing) ? (parseInt(this.state.formObj.oftenPaid) > 0) ? parseInt(this.state.formObj.oftenPaid) : null : null,
                "howOftenPaidId_Code":  (this.state.progressiveLeasing || this.state.UOwnLeasing) ? (parseInt(this.state.formObj.oftenPaid) > 0) ? this.state.formObj.lstOftenPaid.find(obj => obj.id == this.state.formObj.oftenPaid)?.code : "" : "",
                "lastPayDay": (this.state.progressiveLeasing || this.state.UOwnLeasing) ? (parseInt(this.state.formObj.oftenPaid) > 0) ? GetDateFromMMDDYYYY(this.state.formObj.lastPayDay) : null : null,
                "nextPayDay": (this.state.progressiveLeasing || this.state.UOwnLeasing) ? GetDateFromMMDDYYYY(this.state.formObj.nextPayDay) : null,
                "primaryIncomeId": (this.state.progressiveLeasing) ? parseInt(this.state.formObj.primarySourceIncome) : null,
                "bankRoutingNo": (this.state.progressiveLeasing) ? this.state.formObj.bankRouteNo : null,
                "checkingAccountNo":  (this.state.progressiveLeasing) ? this.state.formObj.checkAccNo : null,
                "creditCardBin":(this.state.progressiveLeasing) ? this.state.formObj.cc : null,
                "creditCardExpDate": (this.state.progressiveLeasing) ? GetDateFromMMYYYY(this.state.formObj.ccExpiryDate) : null,
                "nameOnCreditCard":(this.state.progressiveLeasing) ? this.state.formObj.ccName : null,
                "creditCardHomeAddress": (this.state.progressiveLeasing) ? (this.state.formObj.creditCardBillingAddress ? true : false) : null,
                "progressiveMarketInOptin": this.state.progressiveLeasing?(this.state.progressiveSMS) ? true : false:false,
                "ccAddress":(this.state.progressiveLeasing && !this.state.formObj.creditCardBillingAddress) ? this.state.formObj.ccmailingAdr : "",
                "ccApt":(this.state.progressiveLeasing && !this.state.formObj.creditCardBillingAddress) ? this.state.formObj.ccaptUnit === "" ? null : this.state.formObj.ccaptUnit : null,
                "ccCity": (this.state.progressiveLeasing && !this.state.formObj.creditCardBillingAddress) ? this.state.formObj.cccity : "",
                "ccStateId":  (this.state.progressiveLeasing && !this.state.formObj.creditCardBillingAddress) ? (parseInt(this.state.formObj.ccstate) > 0) ? parseInt(this.state.formObj.ccstate) : null : null,
                "ccStateId_Code": (this.state.progressiveLeasing && !this.state.formObj.creditCardBillingAddress) ? (parseInt(this.state.formObj.ccstate) > 0) ? this.state.formObj.lstState.find(obj => obj.id == this.state.formObj.ccstate)?.code : "" : "",
                "ccZipCode":  (this.state.progressiveLeasing && !this.state.formObj.creditCardBillingAddress) ? this.state.formObj.cczipCode : "",
                "modifiedBy_Associate":  localStorage.getItem('loggedUserEmail'),
                "isSynchronyDisclosures": false,
                "associateEmpNo":  this.props.associateEmployeeNo,
                "isAssociateVerified": false,
                "primaryIdExpDate": null,
                "secondIdType_Id": null,
                "secondIdIssuedBy_Id": null,
                "secondIdExpDate":null,
                "applicantIdMatch": false,
                "isSynchronyAgreement": false,
                "isSynchrony": false,
                "isDaniel": this.props.waterfallObj.isDaniel, // if its applied for daniels for first time.
                "isSMSOverride": false,
                "magix_Id": null,
                "synchronyResponse": "",
                "uOwnResponse": "",
                "progressiveResponse": "",
                "fortivaResponse": "",
                "url": "string",
                "ipAddress":sessionStorage.getItem('ipAddress'),
                "applicationVersion": "application.Version.0.0.1",
                "isAutoPay": false,
                "custIdEnt": null,
                "autoPayStatus": "",
                "type": "all",
                "emailContent": "",
                "magixStoreCode": null,
                "poBox": "",
                "bankAccountTypeId": 0,
                "bankAccountTypeId_Code": "",
                "debitCardBin": "",
                "debitCardExpDate": "",
                "isSynchronyCD": false,
                "homeStore": this.props.formObj.storeCode,
                "isFortiva": false,
                "isFortivaDisclosures": false,
                "transactionAmount": "",
                "internetChannel": "",
                "isElectronicDisclosure": false,
                "isWrittenInstruction": false,
                "saveCustResponseFortiva": null,
                "isReapply": false,
                "associateId": localStorage.getItem('loggedUserEmail'),
                "fortAppDisclosure": "",
                "isSendEmail_Synchrony": false,
                "isNewApplication": false,
                "isDanielEStatementDisclosure": false,
                "danielEStatementMailDisclosure": ""
              }
            let obj2 = {
                applicationId: this.props.waterfallObj.applicationId,
                creditId:this.props.waterfallObj.creditId
            }
           
            trackPromise(
                this.props.actions.creditSubmitProgUown(obj1, obj2).then(
                    response => {
                        if (response) {
                            if (response.errorMessage) {
                                this.setState({ showAppInProgress: !this.state.showAppInProgress });
                                this.setState({isStep4Complete:true,step5:true});
                                this.props.handlesubmitProgUown(response.errorMessage);
                            }
                            else {
                                localStorage.setItem("applicationId", response.customer.application_id);
                                localStorage.setItem("customerId",response.customer.customer_Id?.split("-")[0]);
                                if(this.state.progressiveLeasing){
                                    localStorage.setItem("progressiveAppId",response.customer.application_id);
                                }
                               if(this.state.UOwnLeasing){
                                localStorage.setItem("uownAppId",response.customer.application_id);
                               }
                               this.setState({ showAppInProgress: !this.state.showAppInProgress });
                               this.setState({ waterfallSubmittedAlert: !this.state.waterfallSubmittedAlert });
                                var date=new Date(response.customer.createdDate);
                                sessionStorage.setItem("isProgressive",this.state.progressiveLeasing);
                                sessionStorage.setItem("isUown",this.state.UOwnLeasing);
                                date=new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
                                sessionStorage.setItem("createdDate",date.toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));

                                this.props.handlesubmitProgUown();


                            }

                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
        }
    }
    async ErrorInSections() {
        if (!this.handleFormValidation("Step 1") ) {
            this.setState({errorInEmployment : true});
        }
        if (!this.handleFormValidation("Step 2") ) {
            this.setState({errorInIdentification : true});
        }
        if (!this.handleFormValidation("Step 3")) {
            this.setState({erronInProgressive : true});
        }     
        if (!this.handleFormValidation("Step 4") ) {
            this.setState({errorInUown : true});
        }
        if (!this.handleFormValidation("Step 5") ) {
            this.setState({errorInDisclosure : true});
        }
    }

    async handleFormValidationOnSubmit(event) {
        event.preventDefault();
        if (this.state.step1 === false) {
            this.setState({ step1: true });
        }
        if (this.state.step2 === false) {
            this.setState({ step2: true });
        }
        if (this.state.step3 === false) {
            this.setState({ step3: true });
        }
        if (this.state.step4 === false) {
            this.setState({ step4: true });
        }
        if (this.state.step5 === false) {
            this.setState({ step5: true });
        }
        this.setState({isStep4Complete:false,step5:false});
        this.setState({showAppInProgress:!this.state.showAppInProgress},()=>{
            setTimeout(() => {
                this.handleValidation(event);
                }, this.props.setTimerForAppDelay); 
        });
    }
    
    handleValidation(event) {
        this.ErrorInSections();
        if (this.handleFormValidation("Step 1") && this.handleFormValidation("Step 2")
            && this.handleFormValidation("Step 3") && this.handleFormValidation("Step 4")
            && this.handleFormValidation("Step 5")) {
            this.setState({ showAppInProgress: !this.state.showAppInProgress });
            this.handleSubmitConfirmationModal(event);
        }
        else{
            this.setState({ showAppInProgress: !this.state.showAppInProgress });
            this.setState({isStep4Complete:true,step5:true});
        }

    }
    handleSubmitConfirmationModal(event) {
        event.preventDefault();
        this.setState({ showSubmitConfirmation: !this.state.showSubmitConfirmation });
    }
    handleSubmitModalCancel(event) {
        event.preventDefault();
        this.setState({ showSubmitConfirmation: !this.state.showSubmitConfirmation });
        this.setState({isStep4Complete:true,step5:true});
    }
   
    onLastPayDayChange(event) {
        if(event === null) {
            this.setState({
                formObj: { ...this.state.formObj, lastPayDay: "" },
            });
        }
        else {
            this.setState({
                formObj: { ...this.state.formObj, lastPayDay: Moment(event).format("MM/DD/YYYY") },
            });
        }
    }
    onCCExpiryDateChange(event) {
        if(event === null) {
            this.setState({
                formObj: { ...this.state.formObj, ccExpiryDate: "" },
            });
        }
        else {
            this.setState({
                formObj: { ...this.state.formObj, ccExpiryDate: Moment(event).format("MM/YYYY") },
            });
        }
    }
    onNextPayDayChange(event) {
        if(event === null) {
            this.setState({
                formObj: { ...this.state.formObj, nextPayDay: "" },
            });
        }
        else {
            this.setState({
                formObj: { ...this.state.formObj, nextPayDay: Moment(event).format("MM/DD/YYYY") },
            });
        }
    }
   
    updateState(controlId, controlValue) {
         if (controlId === "txtEmpName") {
            this.setState({
                formObj: { ...this.state.formObj, empName: controlValue },
            });
        }
        else if (controlId === "txtNetIncome") {
            const re = /^[0-9\b]+$/;
            if (controlValue === '' || re.test(controlValue)) {
                this.setState({
                    formObj: { ...this.state.formObj, netIncome: controlValue },
                });
            }
        }
        else if (controlId === "txtPrimaryIdNo") {
            this.setState({
                formObj: { ...this.state.formObj, primaryIdNo: controlValue },
            });
        }
        else if (controlId === "cmbOftenPaid") {
            this.setState({
                formObj: { ...this.state.formObj, oftenPaid: controlValue },
            });
        }
        else if (controlId === "txtCC") {
            this.setState({
                formObj: { ...this.state.formObj, cc: formatCC(controlValue) },
            });
        }
        else if (controlId === "txtLastPayDay") {
            this.setState({
                formObj: { ...this.state.formObj, lastPayDay: controlValue },
            });
        }
        else if (controlId === "txtCCExpiryDate") {
            this.setState({
                formObj: { ...this.state.formObj, ccExpiryDate: controlValue },
            });
        }
        else if (controlId === "txtNextPayDay") {
            this.setState({
                formObj: { ...this.state.formObj, nextPayDay: controlValue },
            });
        }
        else if (controlId === "txtCCName") {
            controlValue = controlValue.replace(",","");
            const re = /^(?!\s)[-a-zA-Z ]*$/;
            if (controlValue === '' || re.test(controlValue)) {
                this.setState({
                    formObj: { ...this.state.formObj, ccName: controlValue },
                });
            }
        }
        else if (controlId === "cmbCreditCardBillingAddress") {
           this.setState({
               formObj: { ...this.state.formObj, creditCardBillingAddress: controlValue },
           });
        }
        else if (controlId === "txtCCMailingAdr") {
            this.setState({
                formObj: { ...this.state.formObj, ccmailingAdr: controlValue },
            });
        }
        else if (controlId === "txtCCAptUnit") {
            this.setState({
                formObj: { ...this.state.formObj, ccaptUnit: controlValue },
            });
        }
        else if (controlId === "txtCCCity") {
            controlValue = controlValue.replace(",","");
            const re = /^(?!\s)[-a-zA-Z ]*$/;
            if (controlValue === '' || re.test(controlValue)) {
                this.setState({
                    formObj: { ...this.state.formObj, cccity: controlValue },
                });
            }
        }
        else if (controlId === "cmbCCState") {
            this.setState({
                formObj: { ...this.state.formObj, ccstate: controlValue },
            });
        }
        else if (controlId === "txtCCZipCode") {
            const re = /^[0-9\b]+$/;
            if (controlValue === '' || re.test(controlValue)) {
                this.setState({
                    formObj: { ...this.state.formObj, cczipCode: controlValue },
                });
            }
        }
        else if (controlId === "txtBankRouteNo") {
            const re = /^[0-9\b]+$/;
            if (controlValue === '' || re.test(controlValue)) {
                this.setState({
                    formObj: { ...this.state.formObj, bankRouteNo: controlValue }, ABAError : undefined, ABAChanged : true
                });
            }
        }
        else if (controlId === "txtCheckAccNo") {
            this.setState({
                formObj: { ...this.state.formObj, checkAccNo: controlValue },
            });
        }
       
    }
   
    onChange(event) {
        this.updateState(event.target.id, event.target.value);
    }

    updateDropDowns() {
        this.loadDropDowns("State_Credit");
        this.loadDropDowns("State");
        this.loadDropDowns("OftenPaid");
    }

    async componentDidMount() {
        this.updateDropDowns();
        let progUownApplications=JSON.parse(sessionStorage.getItem("progUownApplications"));
        if(progUownApplications){
            if(progUownApplications==true){
              this.setState({danielsCredit:false,synchronyCredit:false,fortivaCredit:false});
              this.setState({
                progressiveLeasing: this.props.progressiveApp ? this.props.waterfallObj.isProgChecked : false,
                UOwnLeasing: this.props.uownApp ? this.props.waterfallObj.isUownChecked : false});
                this.loadCustomer();
            }
        }
    }
    loadCustomer() {
        if (localStorage.getItem("applicationId") !== "null" && localStorage.getItem("applicationId") !== "0" && localStorage.getItem("applicationId") !== "") {
            let obj = localStorage.getItem("applicationId");
            if (parseInt(obj) > 0) {
                trackPromise(
                    this.props.customerDetailsActions.getApplicationDetails(obj).then(
                        response => {
                            if(response){
                                this.props.formObj.netIncome=response[0].monthlyIncome;
                                this.props.formObj.primaryIdNo=response[0].idNumber;
                                this.props.formObj.empName=response[0].empName;
                                if(response[0].monthlyIncome){
                                    this.setState({formObj:{...this.state.formObj,netIncome:response[0].monthlyIncome}});
                                }
                                if(response[0].idNumber){
                                    this.setState({formObj:{...this.state.formObj,primaryIdNo:response[0].idNumber}});
                                }
                                if(response[0].empName){
                                    this.setState({formObj:{...this.state.formObj,empName:response[0].empName}});
                                }
                            }
                        }
                    ).catch(err => {
                        APIFailedMessage.displayError(err);
                    })
                )
            }
        }
    }
    componentWillUnmount(){
        localStorage.removeItem("fortivaAPIErrorMsg");
        sessionStorage.removeItem("progUownApplications");
    }
    loadDropDowns(type) {
        let obj = {
            "type": type
        }
        trackPromise(
            this.props.actions.getCodeMaster(obj).then(
                response => {
                    if (response) {
                         if (type === "State_Credit") {
                            this.setState({limitedState : response.data});
                            if((sessionStorage.getItem("SMSLink") == "true") || (this.state.thirdPartySessionLink ? (this.state.cartId == "" || this.state.cartId == "0") : false)) {
                                let limitedState = ["georgia","new york","north carolina","south carolina","virginia","ohio","pennsylvania","michigan"];
                                let data = response?.data?.filter((val) => {return !limitedState?.includes(val?.description?.toLowerCase())});
                                this.setState({
                                    formObj: { ...this.state.formObj, lstState: data },
                                });
                            }
                            else if(this.state.thirdPartySessionLink) {
                                    this.setState({
                                        formObj: { ...this.state.formObj, lstState: response.data },
                                    });
                            }
                        }
                        else if (type === "State") {
                            this.setState({
                                formObj: { ...this.state.formObj, lstPrimaryIdState: response.data },
                            });
                            if(!JSON.parse(sessionStorage.getItem("thirdPartyLink"))) {
                                this.setState({
                                    formObj: { ...this.state.formObj, lstState: response.data },
                                });
                            }
                            this.setState({
                                formObj: { ...this.state.formObj, lstCCState: response.data },
                            });
                        }
                        else if (type === "OftenPaid") {
                            this.setState({
                                formObj: { ...this.state.formObj, lstOftenPaid: response.data },
                            });
                        }
                    }
                }
            ).catch(err => {
                APIFailedMessage.displayError(err);
            })
        )
    }
    // waterfall methods

    handleProgUownApplication = (event) => {
        event.preventDefault();
        this.setState({waterfallObj:{...this.state.waterfallObj,showProgUown:false}});
        let customerId=localStorage.getItem("customerId");
        this.props.history.push(`/customer-details/${customerId}`);
    }
  

    render() {
        
        const { empNameErr,netIncomeErr,primaryIdNoErr,  progressiveDisclosureErr, UOwnDisclosureErr,
            oftenPaidErr, ccErr, lastPayDayErr, ccExpiryDateErr, nextPayDayErr, ccNameErr,  creditCardBillingAddressErr,
            ccmailingAdrErr, ccaptUnitErr, cccityErr, ccstateErr, cczipCodeErr,
            bankRouteNoErr, checkAccNoErr, uOwnOftenPaidErr, uOwnLastPayDayErr, uOwnNextPayDayErr} = this.state.formErrors;

        let screenWidth = "";
        let count = [this.props.fortivaApp, this.props.danielsApp, this.props.progressiveApp, this.props.uownApp];
        count = count.filter((val) => {return val});
        count = count?.length;
        if(count === 4){
            screenWidth = "col-md-2.5";
        }
        else if(count === 3){
            screenWidth = "col-md-3";
        }
        else if(count === 2){
            screenWidth = "col-md-4";
        }
        else if(count === 1) {
            screenWidth = "col-md-6"
        }

        return (
            <>
                <ToastContainer className="p-3" position="top-end">
                    <Toast bg="danger" onClose={() => { this.setState({ errorInEmployment: !this.state.errorInEmployment }) }} show={this.state.errorInEmployment} delay={5000} animation autohide>
                        <Toast.Body className="text-white ml-2 mr-2">Invalid data in <b>Employment</b> section.</Toast.Body>
                    </Toast>
                    <Toast bg="danger" onClose={() => { this.setState({ errorInIdentification: !this.state.errorInIdentification }) }} show={this.state.errorInIdentification} delay={5000} animation autohide>
                        <Toast.Body className="text-white ml-2 mr-2">Invalid data in <b>Identification</b> section.</Toast.Body>
                    </Toast>
                    
                    <Toast bg="danger" onClose={() => { this.setState({ errorInUown: !this.state.errorInUown }) }} show={this.state.errorInUown} delay={5000} animation autohide>
                        <Toast.Body className="text-white ml-2 mr-2">Invalid data in <b>Uown Required Fields</b> section.</Toast.Body>
                    </Toast>
                    <Toast bg="danger" onClose={() => { this.setState({ errorInDisclosure: !this.state.errorInDisclosure }) }} show={this.state.errorInDisclosure} delay={5000} animation autohide>
                        <Toast.Body className="text-white ml-2 mr-2">Required data in <b>Disclosure</b> section.</Toast.Body>
                    </Toast>
                </ToastContainer>
                <main>
                    <div id="main" className="container-fluid">
                        <div className="row">
                            <Card
                                className="bg-white m-3 mt-3 mb-3 border-none w-100"
                            >
                                <Card.Title className="ml-3">
                                    <p className="h4 p-0 pl-md-3 pr-md-3 mt-3"><b>Daniel's Jewelers</b></p>
                                    <p className="h4 p-0 pl-md-3 pr-md-3 "><b>Credit Application Form</b></p>
                                </Card.Title>
                                <Card.Body>
                                    <p className="m-0 ml-md-3 mr-md-3 text-center mt-4 pt-2 p-2 text-secondary bg-primary">Please note that you must reside in the State of California, Arizona, Florida, Nevada, New Mexico, Texas and be 18 years or older to apply for Daniel's card.</p>
                                    <p className="m-0 ml-md-3 mr-md-3 text-right mt-4 pt-2 p-2 text-danger">Note : All fields are required unless noted as optional.</p>
                                        <>
                                            <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                                                <div className="col-12 col-sm-4 col-md-4 p-0 pl-md-3 pr-md-3 ">
                                                    <label className="text-secondary">Select credit accounts to apply:</label>
                                                </div>
                                            </div>
                                            <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                                                <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                                                    <div className="row">
                                                    <div className={"col-12  d-flex "+(screenWidth == "col-md-2.5" ?" financialPartner":screenWidth)}>
                                                                <div className={"form-group d-flex align-items-center justify-content-center radiobtn partnerDisable"}>
                                                                <div className="form-check form-check-inline mr-0 mb-0">
                                                                    <input type="checkbox"
                                                                        name="chkSyncOnly"
                                                                        id="chkSyncOnly"
                                                                        value={this.state.synchronyCredit}
                                                                        checked={this.state.synchronyCredit ? "checked" : ""}
                                                                        disabled={true}
                                                                    />
                                                                    <label htmlFor="chkSyncOnly" className="mb-0 ml-2 text-secondary">
                                                                        <label htmlFor="chkSyncOnly" className="mb-0">Synchrony Bank Credit</label>
                                                                        <div htmlFor="chkSyncOnly" className="mb-0">
                                                                            ( &nbsp;<div className="d-inline-block" id="squareIcon" /> Synchrony required fields )
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.props.fortivaApp && 
                                                        <div className={"col-12  d-flex "+(screenWidth == "col-md-2.5" ?" financialPartner":screenWidth)}>
                                                            <div className={"form-group d-flex align-items-center justify-content-center radiobtn partnerDisable"}>
                                                                <div className="form-check form-check-inline mr-0">
                                                                    <input type="checkbox"
                                                                        name="chkFortivaOnly"
                                                                        id="chkFortivaOnly"
                                                                        value={this.state.fortivaCredit}
                                                                        checked={this.state.fortivaCredit ? "checked" : ""}
                                                                        disabled={true}
                                                                    />
                                                                    <label htmlFor="chkFortivaOnly" className="mb-0 ml-2 text-secondary">Fortiva Retail Credit</label>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        {this.props.danielsApp &&
                                                        <div className={"col-12  d-flex "+(screenWidth == "col-md-2.5" ?" financialPartner":screenWidth)}>
                                                        <div className={"form-group d-flex align-items-center justify-content-center radiobtn partnerDisable"}>
                                                                <div className="form-check form-check-inline mr-0">
                                                                    <input type="checkbox"
                                                                        name="chkDanielsOnly"
                                                                        id="chkDanielsOnly"
                                                                        value={this.state.danielsCredit}
                                                                        checked={this.state.danielsCredit ? "checked" : ""}
                                                                        disabled={true}
                                                                    />
                                                                    <label htmlFor="chkDanielsOnly" className="mb-0 ml-2 text-secondary">Daniel's Credit</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        {this.props.progressiveApp && 
                                                        <div className={"col-12  d-flex "+(screenWidth == "col-md-2.5" ?" financialPartner":screenWidth)}>
                                                            <div className={"form-group d-flex align-items-center justify-content-center radiobtn"}>
                                                                <div className="form-check form-check-inline mr-0">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="chkPrgLeasing"
                                                                        id="chkPrgLeasing"
                                                                        onClick={()=>this.onProgressiveLeasingChecked()}
                                                                        value={this.state.progressiveLeasing}
                                                                        checked={this.state.progressiveLeasing ? "checked" : ""}
                                                                    ></input>
                                                                    <label htmlFor="chkPrgLeasing" style={{ margin: "10px" }} className="d-inline text-secondary">
                                                                        Progressive Leasing
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        {this.props.uownApp && 
                                                        <div className={"col-12  d-flex "+(screenWidth == "col-md-2.5" ?" financialPartner":screenWidth)}>
                                                        <div className={"form-group d-flex align-items-center justify-content-center radiobtn"}>
                                                                <div className="form-check form-check-inline mr-0">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="chkUownLeasing"
                                                                        id="chkUownLeasing"
                                                                        onClick={()=>this.onUOwnLeasingChecked()}
                                                                        value={this.state.UOwnLeasing}
                                                                        checked={this.state.UOwnLeasing ? "checked" : ""}
                                                                    />
                                                                    <label htmlFor="chkUownLeasing" style={{ margin: "10px" }} className="d-inline text-secondary">
                                                                        Uown Leasing
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                    {!this.state.danielsCredit && !this.state.fortivaCredit && !this.state.synchronyCredit && !this.state.progressiveLeasing && !this.state.UOwnLeasing &&
                                                        <div style={{ color: "red", paddingBottom: 10 }}>Please select a credit account</div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                                            <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                                                <hr className="bg-secondary mt-5 mb-0" style={{ height: "1px" }}></hr>
                                                <span className="position-relative m-0 pr-3 text-secondary bg-white h5" style={{ top: "-15px" }}><b>Employment</b>
                                                    {this.state.step1 === false ?
                                                        <FontAwesomeIcon icon={faAngleDown} className="float-right bg-white pl-3" size="lg" onClick={(event) => { this.goStep1(event); }} />
                                                        :
                                                        <FontAwesomeIcon icon={faAngleUp} className="float-right bg-white pl-3" size="lg" onClick={(event) => { this.goStep1(event); }} />
                                                    }
                                                </span>
                                                {this.state.step1 === true  ? (
                                                    <div>
                                                        <div className="row">
                                                            <div className={"col-12 col-md-6 col-lg-3" + (this.state.danielsCredit || this.state.UOwnLeasing ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Employer's Name</label>
                                                                    <input
                                                                        type="text"
                                                                        className={empNameErr ? 'form-control border-dark-error' : 'form-control border-dark'}
                                                                        name="txtEmpName"
                                                                        id="txtEmpName"
                                                                        placeholder="Employer's Name"
                                                                        onChange={(event) => this.onChange(event)}
                                                                        value={this.props.formObj.empName?this.props.formObj.empName:this.state.formObj.empName}
                                                                        maxLength="200"
                                                                        disabled={this.props.formObj.empName?true:false}
                                                                    ></input>
                                                                    {empNameErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{empNameErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-6 col-lg-3">
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Monthly Net Income(from all sources)                                   
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className={!this.state.synchronyCredit ? "form-control" + (netIncomeErr ? ' border-dark-error' : ' border-dark')
                                                                            : "form-control-Synchrony" + (netIncomeErr ? ' border-dark-error' : ' border-dark-Synchrony')}
                                                                        name="txtNetIncome"
                                                                        id="txtNetIncome"
                                                                        placeholder="Monthly Net Income(from all sources) **"
                                                                        onChange={(event) => this.onChange(event)}
                                                                        value={this.props.formObj.netIncome}
                                                                        maxLength="5"
                                                                        disabled={true}
                                                                    ></input>

                                                                    {netIncomeErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{netIncomeErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <button
                                                                    className="float-right btn bg-secondary text-primary pl-5 pr-5"
                                                                    name="confirmStep1"
                                                                    id="confirmStep1"
                                                                    onClick={(event) => {
                                                                        this.submitStep1(event);
                                                                    }}
                                                                >
                                                                    Next</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ""}
                                            </div>
                                        </div>

                                        <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                                            <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                                                <hr className="bg-secondary mt-5 mb-0" style={{ height: "1px" }}></hr>
                                                <span className="position-relative m-0 pr-3 text-secondary bg-white h5" style={{ top: "-15px" }}><b>Identification</b>
                                                    {this.state.step2 === false ?
                                                        <FontAwesomeIcon icon={faAngleDown} className="float-right bg-white pl-3" size="lg" onClick={(event) => { this.goStep2(event); }} />
                                                        :
                                                        <FontAwesomeIcon icon={faAngleUp} className="float-right bg-white pl-3" size="lg" onClick={(event) => { this.goStep2(event); }} />
                                                    }
                                                </span>
                                                {this.state.isStep1Complete === true && this.state.step2 === true ? (
                                                    <div>
                                                        <div className="row">
                                                            
                                                        <div className={"col-12 col-md-6 col-lg-3" + (this.state.progressiveLeasing ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Primary ID Number</label>
                                                                    <input
                                                                        type="text"
                                                                        className={primaryIdNoErr ? 'form-control border-dark-error' : 'form-control border-dark'}
                                                                        name="txtPrimaryIdNo"
                                                                        id="txtPrimaryIdNo"
                                                                        placeholder="Primary ID Number"
                                                                        onChange={(event) => this.onChange(event)}
                                                                        value={this.props.formObj.primaryIdNo?this.props.formObj.primaryIdNo:this.state.formObj.primaryIdNo}
                                                                        maxLength="50"
                                                                        disabled={this.props.formObj.primaryIdNo?true:false}
                                                                    ></input>
                                                                    {primaryIdNoErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{primaryIdNoErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <button
                                                                    className="float-right btn bg-secondary text-primary pl-5 pr-5"
                                                                    name="confirmStep2"
                                                                    id="confirmStep2"
                                                                    onClick={(event) => {
                                                                        this.submitStep2(event);
                                                                    }}
                                                                >
                                                                    Next</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ""}
                                            </div>
                                        </div>
                                        
                                        
                                        <div className={"row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4 "}>
                                            <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                                                <hr className="bg-secondary mt-5 mb-0" style={{ height: "1px" }}></hr>
                                                <span className="position-relative m-0 pr-3 text-secondary bg-white h5" style={{ top: "-15px" }}><b>Progressive Required Fields</b>
                                                    {this.state.step3 === false ?
                                                        <FontAwesomeIcon icon={faAngleDown} className="float-right bg-white pl-3" size="lg" onClick={(event) => { this.goStep3(event); }} />
                                                        :
                                                        <FontAwesomeIcon icon={faAngleUp} className="float-right bg-white pl-3" size="lg" onClick={(event) => { this.goStep3(event); }} />
                                                    }
                                                </span>
                                                {this.state.isStep2Complete === true && this.state.step3 === true ? (
                                                    <div>      
                                                        <div className="row">
                                                        <div className={"col-12 col-md-6 col-lg-3" + (this.state.progressiveLeasing === true ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Bank Routing Number (9 digits)</label>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Bank Routing Number (9 digits)"
                                                                        className={bankRouteNoErr ? 'form-control border-dark-error' : 'form-control border-dark'}
                                                                        name="txtBankRouteNo"
                                                                        id="txtBankRouteNo"
                                                                        onChange={(event) => this.onChange(event)}
                                                                        value={this.state.formObj.bankRouteNo}
                                                                        maxLength="9"
                                                                    ></input>
                                                                    {bankRouteNoErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{bankRouteNoErr}</div>
                                                                    }
                                                                    {this.state.ABAError &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{this.state.ABAError}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={"col-12 col-md-6 col-lg-3" + (this.state.progressiveLeasing === true ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Checking Account Number</label>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Checking account number"
                                                                        className={checkAccNoErr ? 'form-control border-dark-error' : 'form-control border-dark'}
                                                                        name="txtCheckAccNo"
                                                                        id="txtCheckAccNo"
                                                                        onChange={(event) => this.onChange(event)}
                                                                        value={this.state.formObj.checkAccNo}
                                                                        maxLength="200"
                                                                    ></input>
                                                                    {checkAccNoErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{checkAccNoErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>                                                      
                                                        <div className="row">
                                                        <div className={"col-12 col-md-6 col-lg-3" + (this.state.progressiveLeasing === true ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">How often are you paid?</label>
                                                                    <select id="cmbOftenPaid" name="cmbOftenPaid"
                                                                        className={"form-select p-2" + (oftenPaidErr ? ' border-dark-error' : ' form-select-border')}
                                                                        onChange={(event) => this.onChange(event)}>
                                                                        <option value="0">-- Select --</option>
                                                                        {this.state.formObj.lstOftenPaid.map((oftenPaid) => <option value={oftenPaid.id} selected={this.state.formObj.oftenPaid == oftenPaid.id ? "selected" : ""}>{oftenPaid.description}</option>)}
                                                                    </select>
                                                                    {oftenPaidErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{oftenPaidErr}</div>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className={"col-12 col-md-6 col-lg-3" + (this.state.progressiveLeasing === true ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Last Pay Day (MM/DD/YYYY)</label>
                                                                    <DatePicker
                                                                        className={"form-control" + (lastPayDayErr ? ' border-dark-error' : ' border-dark')}
                                                                        name="txtLastPayDay"
                                                                        id="txtLastPayDay"
                                                                        placeholderText="Last Pay Day (MM/DD/YYYY)"
                                                                        selected={this.state.formObj.lastPayDay === "" ? null :
                                                                            new Date(this.state.formObj.lastPayDay)}
                                                                        onChange={(event) => this.onLastPayDayChange(event)}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        maxDate={new Date()}
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        customInput={
                                                                            <IMaskInput mask="00/00/0000" />
                                                                        }
                                                                    />
                                                                    {lastPayDayErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{lastPayDayErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                                { <div className={"col-12 col-md-6 col-lg-3" + (this.state.progressiveLeasing === true ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Next Pay Day (MM/DD/YYYY)</label>
                                                                    <DatePicker
                                                                        className={"form-control" + (nextPayDayErr ? ' border-dark-error' : ' border-dark')}
                                                                        name="txtNextPayDay"
                                                                        id="txtNextPayDay"
                                                                        placeholderText="Next Pay Day (MM/DD/YYYY)"
                                                                        selected={this.state.formObj.nextPayDay === "" ? null :
                                                                            new Date(this.state.formObj.nextPayDay)}
                                                                        onChange={(event) => this.onNextPayDayChange(event)}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        minDate={this.state.formObj.lastPayDay === "" ? new Date() : new Date(this.state.formObj.lastPayDay)}
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        customInput={
                                                                            <IMaskInput mask="00/00/0000" />
                                                                        }
                                                                    />
                                                                    {nextPayDayErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{nextPayDayErr}</div>
                                                                    }
                                                                </div>
                                                            </div> 
                                                            }
                                                        </div>
                                                        <div className="row">
                                                            <div className={"col-12 col-md-6 col-lg-3" + (this.state.progressiveLeasing ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Name on Credit Card</label>
                                                                    <input
                                                                        type="text"
                                                                        className={ccNameErr ? 'form-control border-dark-error' : 'form-control border-dark'}
                                                                        name="txtCCName"
                                                                        placeholder="Name on Credit Card"
                                                                        id="txtCCName"
                                                                        onChange={(event) => this.onChange(event)}
                                                                        value={this.state.formObj.ccName}
                                                                        maxLength="200"
                                                                    ></input>
                                                                    {ccNameErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{ccNameErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={"col-12 col-md-6 col-lg-3" + (this.state.progressiveLeasing ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Credit/Debit Card (first 6 digits)</label>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control" + (ccErr ? ' border-dark-error' : ' border-dark')}
                                                                        name="txtCC"
                                                                        placeholder="Credit Card Number"
                                                                        id="txtCC"
                                                                        onChange={(event) => this.onChange(event)}
                                                                        value={this.state.formObj.cc}
                                                                        maxLength="7"
                                                                    ></input>
                                                                    {ccErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{ccErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={"col-12 col-md-6 col-lg-3" + (this.state.progressiveLeasing ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Credit Card Expiration Date (MM/YYYY)</label>

                                                                    <DatePicker
                                                                        className={ccExpiryDateErr ? 'form-control border-dark-error' : 'form-control border-dark'}
                                                                        name="txtCCExpiryDate"
                                                                        id="txtCCExpiryDate"
                                                                        placeholderText="Credit Card Expiration Date (MM/YYYY)"
                                                                        selected={this.state.formObj.ccExpiryDate === "" ? null :
                                                                            new Date(this.state.formObj.ccExpiryDate.split('/')[1], parseInt(this.state.formObj.ccExpiryDate.split('/')[0]) - 1, new Date().getDate())}
                                                                        minDate={new Date(new Date().getFullYear(), new Date().getMonth(), "01")}
                                                                        onChange={(event) => this.onCCExpiryDateChange(event)}
                                                                        dateFormat="MM/yyyy"
                                                                        showMonthYearPicker
                                                                        customInput={
                                                                            <IMaskInput mask="00/0000" />
                                                                        }
                                                                    />
                                                                    {ccExpiryDateErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{ccExpiryDateErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className={"col-12" + (this.state.progressiveLeasing ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group form-check mb-1 mt-1">

                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="chkCreditCardBillingAddress"
                                                                        id="chkCreditCardBillingAddress"
                                                                        onClick={()=>this.onCreditCardBillingAddressChecked()}
                                                                        value={this.state.formObj.creditCardBillingAddress}
                                                                        checked={this.state.formObj.creditCardBillingAddress ? "checked" : ""}
                                                                    />
                                                                    <label htmlFor="chkCreditCardBillingAddress" className="mt-1 ml-2">
                                                                        Credit Card billing address same as residence address (above)
                                                                    </label>
                                                                    <br />
                                                                    {creditCardBillingAddressErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{creditCardBillingAddressErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className={"col-12 col-md-6 col-lg-3" + (this.state.progressiveLeasing && !this.state.formObj.creditCardBillingAddress ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Credit Card Billing Address</label>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control" + (ccmailingAdrErr ? ' border-dark-error' : ' border-dark')}
                                                                        name="txtCCMailingAdr"
                                                                        id="txtCCMailingAdr"
                                                                        placeholder="Mailing Address"
                                                                        onChange={(event) => this.onChange(event)}
                                                                        value={this.state.formObj.ccmailingAdr}
                                                                        maxLength="500"
                                                                    ></input>
                                                                    {ccmailingAdrErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{ccmailingAdrErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={"col-12 col-md-6 col-lg-2" + (this.state.progressiveLeasing && !this.state.formObj.creditCardBillingAddress ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Apt/Unit (As Needed)</label>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control" + (ccaptUnitErr ? ' border-dark-error' : ' border-dark')}
                                                                        name="txtCCAptUnit"
                                                                        id="txtCCAptUnit"
                                                                        placeholder="Apt/Unit (As Needed)"
                                                                        onChange={(event) => this.onChange(event)}
                                                                        value={this.state.formObj.ccaptUnit}
                                                                        maxLength="100"
                                                                    ></input>
                                                                    {ccaptUnitErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{ccaptUnitErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={"col-12 col-md-6 col-lg-2" + (this.state.progressiveLeasing && !this.state.formObj.creditCardBillingAddress ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">City</label>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control" + (cccityErr ? ' border-dark-error' : ' border-dark')}
                                                                        name="txtCCCity"
                                                                        id="txtCCCity"
                                                                        placeholder="City"
                                                                        onChange={(event) => this.onChange(event)}
                                                                        value={this.state.formObj.cccity}
                                                                        maxLength="100"
                                                                    ></input>
                                                                    {cccityErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{cccityErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={"col-12 col-md-6 col-lg-2" + (this.state.progressiveLeasing && !this.state.formObj.creditCardBillingAddress ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">State</label>

                                                                    <select id="cmbCCState" name="cmbCCState"
                                                                        className={"form-select p-2" + (ccstateErr ? ' border-dark-error' : ' form-select-border')}
                                                                        onChange={(event) => this.onChange(event)}>
                                                                        <option value="0">-- Select --</option>
                                                                        {this.state.formObj.lstCCState.map((state) => <option value={state.id} selected={this.state.formObj.ccstate == state.id ? "selected" : ""}>{state.description}</option>)}
                                                                    </select>
                                                                    {ccstateErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{ccstateErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={"col-12 col-md-6 col-lg-2" + (this.state.progressiveLeasing && !this.state.formObj.creditCardBillingAddress ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">Zip Code</label>

                                                                    <input
                                                                        type="text"
                                                                        className={"form-control" + (cczipCodeErr ? ' border-dark-error' : ' border-dark')}
                                                                        name="txtCCZipCode"
                                                                        id="txtCCZipCode"
                                                                        placeholder="Zip Code"
                                                                        onChange={(event) => this.onChange(event)}
                                                                        value={this.state.formObj.cczipCode}
                                                                        maxLength="5"
                                                                    ></input>
                                                                    {cczipCodeErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{cczipCodeErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-12">

                                                                <button
                                                                    className="float-right btn bg-secondary text-primary pl-5 pr-5"
                                                                    name="confirmStep3"
                                                                    id="confirmStep3"
                                                                    onClick={(event) => {
                                                                        this.submitStep3(event);
                                                                    }}
                                                                >
                                                                    Next</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ""}
                                            </div>
                                        </div>
                                        <div className={"row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4 "}>
                                            <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                                                <hr className="bg-secondary mt-5 mb-0" style={{ height: "1px" }}></hr>
                                                <span className="position-relative m-0 pr-3 text-secondary bg-white h5" style={{ top: "-15px" }}><b>Uown Required Fields</b>
                                                    {this.state.step4 === false ?
                                                        <FontAwesomeIcon icon={faAngleDown} className="float-right bg-white pl-3" size="lg" onClick={(event) => { this.goStep4(event); }} />
                                                        :
                                                        <FontAwesomeIcon icon={faAngleUp} className="float-right bg-white pl-3" size="lg" onClick={(event) => { this.goStep4(event); }} />
                                                    }
                                                </span>
                                                {this.state.isStep3Complete === true && this.state.step4 === true ? (
                                                    <div>
                                                        <div className="row">
                                                        <div className={"col-12 col-md-6 col-lg-3" + (this.state.UOwnLeasing === true ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                    <label className="text-secondary">How often are you paid?</label>
                                                                    <select id="cmbOftenPaid" name="UownCmbOftenPaid"
                                                                        className={"UownCmbOftenPaid form-select p-2" + (uOwnOftenPaidErr ? ' border-dark-error' : ' form-select-border')}
                                                                        onChange={(event) => this.onChange(event)}>
                                                                        <option value="0">-- Select --</option>
                                                                        {this.state.formObj.lstOftenPaid.map((oftenPaid) => <option value={oftenPaid.id} selected={this.state.formObj.oftenPaid == oftenPaid.id ? "selected" : ""}>{oftenPaid.description}</option>)}
                                                                    </select>
                                                                    {uOwnOftenPaidErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{uOwnOftenPaidErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={"col-12 col-md-6 col-lg-3" + (this.state.UOwnLeasing === true ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                <label className="text-secondary">Last Day (MM/DD/YYYY) Pay $ Was Available</label>
                                                                    <DatePicker
                                                                        className={"form-control" + (uOwnLastPayDayErr ? ' border-dark-error' : ' border-dark')}
                                                                        name="txtLastPayDay"
                                                                        id="txtLastPayDay"
                                                                        placeholderText="Last Day (MM/DD/YYYY) Pay $ Was Available"
                                                                        selected={this.state.formObj.lastPayDay === "" ? null :
                                                                            new Date(this.state.formObj.lastPayDay)}
                                                                        onChange={(event) => this.onLastPayDayChange(event)}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        maxDate={new Date()}
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        customInput={
                                                                            <IMaskInput mask="00/00/0000" />
                                                                        }
                                                                    />
                                                                    {uOwnLastPayDayErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{uOwnLastPayDayErr}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={"col-12 col-md-6 col-lg-3" + (this.state.UOwnLeasing === true ? " ShowContent" : " HideContent")}>
                                                                <div className="form-group text-left mt-md-2">
                                                                <label className="text-secondary">Next Day (MM/DD/YYYY) Pay $ Will Be Available</label>
                                                                    <DatePicker
                                                                        className={"form-control" + (uOwnNextPayDayErr ? ' border-dark-error' : ' border-dark')}
                                                                        name="txtNextPayDay"
                                                                        id="txtNextPayDay"
                                                                        placeholderText="Next Day (MM/DD/YYYY) Pay $ Will Be Available"
                                                                        selected={this.state.formObj.nextPayDay === "" ? null :
                                                                            new Date(this.state.formObj.nextPayDay)}
                                                                        onChange={(event) => this.onNextPayDayChange(event)}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        minDate={this.state.formObj.lastPayDay === "" ? new Date() : new Date(this.state.formObj.lastPayDay)}
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        customInput={
                                                                            <IMaskInput mask="00/00/0000" />
                                                                        }
                                                                    />
                                                                    {uOwnNextPayDayErr &&
                                                                        <div style={{ color: "red", paddingBottom: 10 }}>{uOwnNextPayDayErr}</div>
                                                                    }
                                                                </div>
                                                        </div>              
                                                        </div>                                                                                             
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <button
                                                                    className="float-right btn bg-secondary text-primary pl-5 pr-5"
                                                                    name="confirmStep4"
                                                                    id="confirmStep4"
                                                                    onClick={(event) => {
                                                                        this.submitStep4(event);
                                                                    }}
                                                                >
                                                                    Next</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ""}
                                            </div>
                                        </div>
                                        <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                                            <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                                                <hr className="bg-secondary mt-5 mb-0" style={{ height: "1px" }}></hr>
                                                <span className="position-relative m-0 pr-3 text-secondary bg-white h5" style={{ top: "-15px" }}><b>Disclosures</b>
                                                    {this.state.step5 === false ?
                                                        <FontAwesomeIcon icon={faAngleDown} className="float-right bg-white pl-3" size="lg" onClick={(event) => { this.goStep5(event); }} />
                                                        :
                                                        <FontAwesomeIcon icon={faAngleUp} className="float-right bg-white pl-3" size="lg" onClick={(event) => { this.goStep5(event); }} />
                                                    }
                                                </span>
                                                {this.state.isStep4Complete === true && this.state.step5 === true ? (
                                                    <div>
                                                            <div className="row">
                                                            <div className={"col-12 customBorder shadow mt-3" + (this.state.progressiveLeasing ? " ShowContent" : " HideContent")}>
                                                                <div className="pl-3 pr-3 pl-md-0 pr-md-0 pl-lg-0 pr-lg-0">
                                                                    <p className="h5 mt-3"><b>PROGRESSIVE LEASING</b></p>
                                                                    <ol>
                                                                        <li> I certify that I provided complete and accurate information on my application.</li>
                                                                        <li> I authorize Prog Leasing, LLC and/or affiliates (“Progressive”) to obtain reports from consumer reporting agencies and verify information provided.  I agree that Progressive may use my consumer reports to any lawful purpose, such as: (1) authenticating my identity; (2) making lease-to-own eligibility decisions; (3) assisting with internal modeling and analysis; (4) serving my account through the life of my lease and (5) verifying information in my application. </li>
                                                                        <li>I authorize Progressive to share information regarding my application, including the decision, with the Retailer.</li>
                                                                    </ol>
                                                                    <div className="form-group form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            name="chkProgressiveDisclosure"
                                                                            id="chkProgressiveDisclosure"
                                                                            onClick={()=>this.onProgressiveDisclosureChecked()}
                                                                            value={this.state.progressiveDisclosure}
                                                                            checked={this.state.progressiveDisclosure ? "checked" : ""}
                                                                        />
                                                                        <label htmlFor="chkProgressiveDisclosure" className="mt-1 ml-2">
                                                                            I have read, and I agree to all of the above statements.  I further agree to the <a href="https://progleasing.com/terms/" target="_blank" rel="noreferrer" className="text-decoration-none" style={{ color: "#00aeef" }}>Terms of Use</a>, <a href="https://progleasing.com/privacy/" target="_blank" rel="noreferrer" style={{ color: "#00aeef" }}>Privacy Policy</a>, <a href="https://progleasing.com/application-disclosure/" target="_blank" rel="noreferrer" style={{ color: "#00aeef" }}>Application Disclosure</a>, <a href="https://progleasing.com/arbitration-clause/" target="_blank" rel="noreferrer" style={{ color: "#00aeef" }}>Arbitration Provision</a> and <a href="https://progleasing.com/e-sign-disclosure/" target="_blank" rel="noreferrer" style={{ color: "#00aeef" }}>E-Sign Disclosure</a>.
                                                                        </label>
                                                                        <br />
                                                                        {progressiveDisclosureErr &&
                                                                            <div style={{ color: "red", paddingBottom: 10 }}>{progressiveDisclosureErr}</div>
                                                                        }
                                                                    </div>
                                                                    <div className="form-group form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            name="chkProgressiveSMS"
                                                                            id="chkProgressiveSMS"
                                                                            onClick={()=>this.onProgressiveSMSChecked()}
                                                                            value={this.state.progressiveSMS}
                                                                            checked={this.state.progressiveSMS ? "checked" : ""}
                                                                        />
                                                                        <label htmlFor="chkProgressiveSMS" className="mt-1 ml-2">Yes! I would like to receive marketing communications at <b>{this.state.formObj.mobileNo}</b> on behalf of Progressive Leasing. These communications may be delivered using an auto dialer or a prerecorded message. Consent is not a condition of application, lease, or purchase. For text messages, message and data rates may apply. Text STOP to cancel future marketing messages. Text HELP for help. Message frequency is recurring. </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"col-12 customBorder shadow mt-3" + (this.state.UOwnLeasing ? " ShowContent" : " HideContent")}>
                                                                <div className="pl-3 pr-3 pl-md-0 pr-md-0 pl-lg-0 pr-lg-0">
                                                                    <p className="h5 mt-3"><b>UOWN LEASING</b></p>
                                                                    <div className="form-group form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            name="chkUOwnDisclosure"
                                                                            id="chkUOwnDisclosure"
                                                                            onClick={()=>this.onUOwnDisclosureChecked()}
                                                                            value={this.state.UOwnDisclosure}
                                                                            checked={this.state.UOwnDisclosure ? "checked" : ""}
                                                                        />
                                                                        <label htmlFor="chkUOwnDisclosure" className="mt-1 ml-2">I acknowledge that I am applying for Uown Leasing and have provided complete and accurate information.</label>
                                                                        <br />
                                                                        {UOwnDisclosureErr &&
                                                                            <div style={{ color: "red", paddingBottom: 10 }}>{UOwnDisclosureErr}</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className={"bg-secondary ml-3 mr-3"+this.state.fortivaCredit?"d-none":""} style={{ height: "1px" }}></hr>
                                                    </div>
                                                ) : ""}
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12">
                                                <div className="float-right">
                                                    <button className={"ml-auto mr-4 mr-md-5 btn bg-primary text-secondary border-secondary pl-4 pr-4 pl-md-5 pr-md-5 " +(this.state.djoSubmitted ? " d-none" : "" )} 
                                                        name="cancelSessionId"
                                                        id="cancelSessionId" onClick={(event)=> this.props.cancelSessionLink(event, "progressUownpage")}
                                                    >
                                                        Cancel
                                                    </button>
                                                    {this.state.isStep4Complete === true && this.state.step5 === true ?
                                                        (this.state.progressiveLeasing || this.state.UOwnLeasing) ?
                                                            <button
                                                            className={"ml-auto btn bg-secondary text-primary pl-4 pr-4 pl-md-5 pr-md-5 "+(this.state.djoSubmitted ? " d-none" : "")}
                                                                name="confirmStep5"
                                                                id="confirmStep5"
                                                                onClick={(event) => this.handleFormValidationOnSubmit(event)}
                                                                disabled={this.state.isAlldisclosuresChecked? false : true}
                                                            >
                                                                Submit
                                                            </button>
                                                        : ""
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <Modal show={this.state.showSubmitConfirmation} onHide={(event) => this.handleSubmitModalCancel(event)}>
                        <Modal.Header className="pb-0"><b className="text-secondary h4">Confirmation</b><FontAwesomeIcon icon={faClose} className="btn" onClick={(event) => this.handleSubmitModalCancel(event)} /></Modal.Header>
                        <Modal.Body>Are you sure you want to submit?</Modal.Body>
                        <Modal.Footer className="border-0">
                            <form>
                                <button className="btn mr-2" style={{ backgroundColor: "#1B1B1B", color: "white" }} onClick={(event) => this.handleSubmitModalCancel(event)}>Cancel</button>
                                <button className="btn ml-2" style={{ backgroundColor: "#5c068c", color: "white" }} onClick={(event) => {
                                    this.submitStep5(event);
                                }}>Submit</button>
                            </form>
                        </Modal.Footer>
                    </Modal> 
                    <Modal show={this.state.waterfallSubmittedAlert} onHide={(event) => this.handleSubmittedAlert(event)}>
                        <Modal.Header className="pb-0"><b className="text-secondary h4">Credit Application</b><FontAwesomeIcon icon={faClose} className="btn" onClick={(event) => this.handleSubmittedAlert(event)} /></Modal.Header>
                        <Modal.Body>We have received application. Thank you.</Modal.Body>
                        <Modal.Footer className="border-0">
                            <form>
                                <button className="btn ml-2" style={{ backgroundColor: "#5c068c", color: "white" }} onClick={(event) => {
                                    this.handleSubmittedAlert(event);
                                }}>Close</button>
                            </form>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.permission_status === '' ? false : this.state.permission_status?.canView ? false : true}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Access Denied</b></Modal.Header>
                        <Modal.Body>You didn't have access to the page</Modal.Body>
                    </Modal>
                    <Modal show={this.state.showAppInProgress} >
                            <Modal.Header className="pb-0"><b className="text-secondary h4">Confirmation</b></Modal.Header>
                            <Modal.Body>Your application is being processed, please wait for some time.</Modal.Body>
                        </Modal> 
                </main>
                  
               </>
         
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(applyNowActions, dispatch),
        customerDetailsActions: bindActionCreators(customerDetailsActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        actionStore: bindActionCreators(storeactions, dispatch),
        userRightsactions: bindActionCreators(userRightsActions, dispatch),
        userListactions:bindActionCreators(adminUserListActions,dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}
export default connect(null, mapDispatchToProps)(ProgUownForm);