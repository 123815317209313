import { ApiWrapper } from "../../api/thunk.helper"; //storeNumber.actions.js
import HttpMethods from "../../redux/_http.methods";

export function saveSignOutTime(signOutTime) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: signOutTime,
      ResourceUrl: "v1/associates/admin-setting",
      isAssociate: true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getSignOutTime() {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        ResourceUrl: "v1/associates/admin-setting",
        isAssociate: true
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
}

export function saveAlertMsg(alertObj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: alertObj,
      ResourceUrl: "v1/associates/AssignAlertMessages",
      isAssociate: true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getAlertMsg() {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: "",
      ResourceUrl: "v1/associates/GetAlertMessages",
      isAssociate: true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getallstoredetails() {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: "",
      ResourceUrl: "v1/associates/getallstoredetails",
      isAssociate: true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getstoredetailsById(storeId) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: "",
      ResourceUrl: "v1/associates/getallstoredetails?store_id="+storeId,
      isAssociate: true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function EnaDis_Financial_partner_and_store(stresList) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: stresList,
      ResourceUrl: "v1/customers/EnaDis_Financial_partner_and_store",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getIPAddress(store_code) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      ResourceUrl: "v1/customers/GetIpAddressDetails?code="+store_code,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function addIP(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: obj,
      ResourceUrl: 
      obj.createdBy === "" ? "v1/customers/Update-IpAddress-store?from_IP="+obj.fromIP+"&to_IP="+obj.toIP+"&store="+obj.store_Id+"&isAdd="+obj.isAdd+"&id="+obj.id+"&isDelete=false&modifiedBy="+obj.modifiedBy
      : "v1/customers/Update-IpAddress-store?from_IP="+obj.fromIP+"&to_IP="+obj.toIP+"&store="+obj.store_Id+"&isAdd="+obj.isAdd+"&id="+obj.id+"&isDelete=false&createdBy="+obj.createdBy,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteIP(id) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: id,
      ResourceUrl: "v1/customers/Update-IpAddress-store?id="+id+"&isDelete=true",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function uploadCSV(formData) {
  let createdBy=localStorage.getItem('loggedUserEmail');
  let modifiedBy="";
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: formData,
      ResourceUrl: "v1/customers/IpAddress_table_data?createdBy="+createdBy+"&modifiedBy="+modifiedBy,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  
}

export function uploadCSVfile(formData) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: formData,
      ResourceUrl: "v1/customers/UploadIpCsvFileToBlob",
      isCSV:true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}