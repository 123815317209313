import { ApiWrapper } from "../../api/thunk.helper";
import HttpMethods from "../../redux/_http.methods";

export function getUserRights() {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        ResourceUrl:"v1/associates/userRights",
        isAssociate: true
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

  export function saveUserRights(updatedData) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Post(),
        Resource: updatedData,
        ResourceUrl:"v1/associates/AssignUserRights",
        isAssociate: true
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }
  